import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";

export default function Loader() {
  return (
    <LoaderStyles>
       <ClipLoader
        color={"#36d7b7"}
        loading={true}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </LoaderStyles>
  )
}

const LoaderStyles = styled.div`
     border: black solid 1px;
    position: fixed; 
    top: 0; 
    max-height: 100vh;
    overflow-y: hidden; 
    background: black; 
    opacity: 0.6;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center; 
    z-index: 105;
`