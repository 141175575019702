import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import {
  EntryAdvance,
  EntryData,
  EntryService,
  ProviderInterfaceOP,
  QuoteItemI,
} from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import deleteImage from "../../../../images/deletelogo.png";
import agregarImage from "../../../../images/AGREGAR.png";
import { Link } from "react-router-dom";
import HeaderInfo from "../HeaderInfo";
import edit from "../../../../images/edit.png";
import DesgloceHotel from "./DesgloceHotel";
import { OperationContext } from "../../../../context/operationContext";
import ElementDesgloce from "../Components/ElementDesgloce";
import { formatearCantidad } from "../../../components/functionsTs/utilidades";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";

interface DesgloceAlimentosI {
  isEditionMode: boolean;
  setIsEditionMode: React.Dispatch<React.SetStateAction<boolean>>;
  tableTitle: string;
  cantidad1: string;
  cantidad2: string;
}

export default function DesgloceAlimentos({
  isEditionMode,
  setIsEditionMode,
  tableTitle,
  cantidad1,
  cantidad2,
}: DesgloceAlimentosI) {
  ////currentEntrada guarda los detalles de una entrada en especificco

  const {
    currentEntrada,
    providerTypeOperacion,
    setCurrentEntrada,
    setProviderTypeOperacion,
  } = useContext(OperationContext);
  /////ANTICIPO SETTINGS
  const [anticipoInicial, setAnticipoInicial] = useState<number>(
    currentEntrada.entry_data.advance_mxn
  );
  const [pagoSinAnticipoInicial, setPagoSinAnticipoInicial] = useState<number>(
    currentEntrada.entry_data.total_without_advance_mxn
  );
  const [listServicesRestaurant, setListServicesRestaurant] = useState<
    EntryService[]
  >(currentEntrada.entry_services_list.mxn_services_list);
  const [entryData, setEntryData] = useState<EntryData>(
    currentEntrada.entry_data
  );
  /////ANTICIPO SETTINGS
  useEffect(() => {
    if (currentEntrada.entry_data.entry_number == 1) {
      setAnticipoInicial(0);
    }
  }, []);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [renderNumbers, setRenderNumbers] = useState<number>(1);
  useEffect(() => {
    console.log("Obteniendo datos de entrada:");
    console.log(currentEntrada);
    setCurrentEntrada({
      ...currentEntrada,
      entry_data: entryData,
      entry_services_list: {
        ...currentEntrada.entry_services_list,
        mxn_services_list: listServicesRestaurant,
      },
    });

    // console.log("ACtualizando contexto");
  }, [listServicesRestaurant, entryData]);
  useEffect(() => {
    setEntryData(currentEntrada.entry_data);
  }, [currentEntrada.entry_data]);

  useEffect(() => {
    console.log("CONTEXTO CAMBIO");
    console.log(currentEntrada);
  }, [currentEntrada]);

  ///////////CAMBIAR ESTO EN PESOS.
  useEffect(() => {
    // console.log("CONTEXTO CAMBIO");
    // console.log(currentEntrada);
    setListServicesRestaurant(
      currentEntrada.entry_services_list.mxn_services_list
    );
  }, [currentEntrada.entry_services_list.mxn_services_list]);

  const totalTarifa = listServicesRestaurant.reduce((total, item) => {
    // console.log("CALCULOS::::");
    // console.log("quantity1: ",item.quantity1, "quantity2: ", item.quantity2, "unitary_fee: ", item.unitary_fee);
    // console.log(total + (item.quantity1*item.quantity2*item.unitary_fee));
    return total + item.quantity1 * item.quantity2 * item.unitary_fee;
  }, 0);

  const subtotalServicio = listServicesRestaurant.reduce((total, item) => {
    return (
      total +
      item.quantity1 *
        item.quantity2 *
        item.unitary_fee *
        ((100 - (item.iva + item.taxes)) / 100)
    );
  }, 0);

  const precioIva = listServicesRestaurant.reduce((total, item) => {
    return (
      total +
      item.quantity1 * item.quantity2 * item.unitary_fee * (item.iva / 100)
    );
  }, 0);

  const precioPropinas = listServicesRestaurant.reduce((total, item) => {
    return (
      total +
      item.quantity1 * item.quantity2 * item.unitary_fee * (item.taxes / 100)
    );
  }, 0);

  const totalFinal = subtotalServicio + precioIva + precioPropinas;
  const precioSinAnticipo = totalFinal - entryData.advance_mxn;
  const [entryAdvancesServices, setEntryAdvancesServices] = useState<
    EntryAdvance[]
  >([]);

  useEffect(() => {
    if (currentEntrada.entry_advances) {
      const arrayToSave = currentEntrada.entry_advances.filter(
        (advance, index) => {
          return advance.currency_identifier == 2;
          //  && advance.entry_number <= entryData.entry_number
        }
      );
      setEntryAdvancesServices(arrayToSave);
    }
  }, [currentEntrada.entry_advances]);
  useEffect(() => {
    if (entryAdvancesServices) {
      if (entryAdvancesServices.length > 0) {
        const nuevoArreglo: EntryAdvance[] = entryAdvancesServices.map(
          (adelanto, index) => {
            return entryData.entry_number != adelanto.entry_number &&
              adelanto.currency_identifier == 2
              ? adelanto
              : {
                  ...adelanto,
                  advance: entryData.advance_mxn,
                };
          }
        );
        console.warn("REsultado del cambiio");
        console.log(nuevoArreglo);
        setEntryAdvancesServices(nuevoArreglo);
      }
    }
  }, [entryData.advance_mxn]);

  const totalAvances = entryAdvancesServices
    .filter((advance, index) => advance.entry_number <= entryData.entry_number)
    .reduce((total, item) => item.advance + total, 0);
  //////¿Está imprimiendo?-----------------------------------------------------------

  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    // Función para manejar los cambios en la impresión
    const handlePrintChange = (event: MediaQueryListEvent) => {
      setIsPrinting(event.matches);
    };

    // Verificar si se está imprimiendo
    const mediaQueryList = window.matchMedia("print");
    mediaQueryList.addListener(handlePrintChange);
    setIsPrinting(mediaQueryList.matches);

    // Limpiar el listener al desmontar el componente
    return () => {
      mediaQueryList.removeListener(handlePrintChange);
    };
  }, []);
  return (
    <DesgloceGuiaStyle>
      <h3 className="title-clase title-pesos">{tableTitle} (MXN)</h3>

      <div className="header-hotel">
        <div className="title">
          <h3>Nombre</h3>
        </div>
        <div className="title">
          <h3>Vig. Inicial</h3>
        </div>
        <div className="title">
          <h3>Vig Final</h3>
        </div>

        <div className="title">
          <h3>{cantidad1}</h3>
        </div>

        <div className="title">
          <h3>{cantidad2}</h3>
        </div>

        <div className="title">
          <h3>IVA</h3>
        </div>

        <div className="title">
          <h3>
            {currentEntrada.entry_data.provider_identifier == 11
              ? "Servicios(propina)"
              : "Servicios"}
          </h3>
        </div>

        <div className="title">
          <h3>Tarifa Unitario</h3>
        </div>

        <div className="title">
          <h3>Tarifa Total</h3>
        </div>

        <div className="title">
          <h3>Moneda</h3>
        </div>
      </div>
      <div className="titulo">
        <h3>{tableTitle}</h3>
      </div>
      {/*  */}
      {listServicesRestaurant.map((currentService, index) => {
        return (
          <>
            <ElementDesgloce
              key={currentService.entry_service_identifier}
              isEditionMode={isEditionMode}
              currentService={currentService}
              listServices={listServicesRestaurant}
              setListServices={setListServicesRestaurant}
            />
          </>
        );
      })}

      {/* Resumen resultados */}
      <div className="space title-precio">
        <h3>Total en Pesos ${formatearCantidad(totalTarifa)}</h3>
      </div>
      <div className="container-prices-box">
        <div>
          <h3>Subtotal del servicio</h3>
        </div>
        <div>
          <h3>Impuestos</h3>
        </div>
        <div>
          <h3>IVA</h3>
        </div>
        <div>
          <h3>Total</h3>
        </div>
        <div>
          <h3>Anticipo</h3>
        </div>
        <div>
          <h3>Total sin anticipo</h3>
        </div>
      </div>
      <div className="conatiner-data">
        <div>
          <h3>${formatearCantidad(subtotalServicio)}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(precioPropinas)}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(precioIva)}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(totalFinal)}</h3>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isEditionMode ? (
            <>
              <NumericFormat
                prefix="$"
                style={{
                  outline: "none",

                  WebkitTapHighlightColor: "transparent", // Para navegadores Webkit (como Chrome y Safari)
                  textAlign: "center",
                  width: "70%",
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  const precio = convertirStringANumero(value);
                  console.log("Valor convertido:");
                  console.log(precio);
                  if (isNaN(precio)) {
                    /////ANTICIPO SETTINGS

                    setEntryData({
                      ...entryData,
                      advance_mxn: 0,
                      total_without_advance_mxn: totalFinal,
                    });
                  } else {
                    const parsedValue = precio;
                    if (!isNaN(parsedValue)) {
                      /////ANTICIPO SETTINGS

                      setEntryData({
                        ...entryData,
                        advance_mxn: parsedValue,
                        total_without_advance_mxn:
                          entryData.entry_number == 1
                            ? totalFinal - parsedValue
                            : pagoSinAnticipoInicial - parsedValue,
                      });
                    }
                  }
                }}
                value={entryData.advance_mxn}
              />
              {/* 
            <input
              style={{
                textAlign: "center",
                width: "70%",
              }}
              type="number"
              inputMode="decimal"
              className="input-style-data"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  /////ANTICIPO SETTINGS
                  setEntryData({
                    ...entryData,
                    advance_mxn: 0,
                    total_without_advance_mxn: totalFinal,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    /////ANTICIPO SETTINGS
                    setEntryData({
                      ...entryData,
                      advance_mxn: parsedValue,
                      total_without_advance_mxn:
                        entryData.entry_number == 1
                          ? totalFinal - parsedValue
                          : pagoSinAnticipoInicial - parsedValue,
                    });
                  }
                }
              }}
              value={entryData.advance_mxn}
            /> */}
            </>
          ) : (
            <h3>${formatearCantidad(entryData.advance_mxn)}</h3>
          )}
        </div>
        <div>
          <h3> ${formatearCantidad(totalFinal - totalAvances)}</h3>
        </div>
      </div>
      <br />

      {/* Fin resumen resultados */}
      <div className="tables-resume-entradas">
        <div>
          <div className="table-one">
            <div>
              <h3>No de Anticipo</h3>
            </div>
            <div>
              <h3>1</h3>
            </div>
            <div>
              <h3>2</h3>
            </div>
            <div>
              <h3>3</h3>
            </div>
            <div>
              <h3>4</h3>
            </div>
            <div>
              <h3>5</h3>
            </div>
            <div>
              <h3>6</h3>
            </div>
          </div>
          <div className="table-one table-one-content">
            <div>
              <h3 className="resume-total">Total a pagar</h3>
            </div>
            {entryAdvancesServices &&
              entryAdvancesServices.length > 0 &&
              entryAdvancesServices
                .filter((entrada, index) => {
                  return entrada.currency_identifier == 2;
                })
                .map((entrada, index) => {
                  return (
                    <>
                      <div
                        className="container-results"
                        style={{
                          backgroundColor:
                            entrada.entry_number == entryData.entry_number &&
                            !isPrinting
                              ? "rgb(237, 244, 243)"
                              : "",
                        }}
                      >
                        <h3
                          style={{
                            color:
                              entrada.entry_number > entryData.entry_number
                                ? "grey"
                                : "",
                            fontWeight:
                              entrada.entry_number > entryData.entry_number
                                ? "600"
                                : "",
                          }}
                        >
                          {entrada.advance}
                        </h3>
                      </div>
                    </>
                  );
                })}
          </div>
          <div>
            <div></div>
          </div>
        </div>

        <div className="table-two">
          <div className="first-row resume-total">
            <h3 className="resume-pago">
              Total a pagar{" "}
              {totalFinal - entryData.advance_mxn == totalFinal
                ? "(Liquidación)"
                : "(Anticipo)"}
            </h3>
          </div>
          <div className="second-row">
            <h3 className="title-pesos">
              $
              {totalFinal - entryData.advance_mxn == totalFinal
                ? formatearCantidad(totalFinal - totalAvances)
                : entryAdvancesServices.find(
                    (servicio) =>
                      servicio.entry_number == entryData.entry_number &&
                      servicio.currency_identifier == 2
                  )?.advance} MXN
            </h3>
          </div>
        </div>
      </div>
      {/* FIN DE COMPONENTE */}
    </DesgloceGuiaStyle>
  );
}

const DesgloceGuiaStyle = styled.div`
  .title-pesos {
    font-weight: 600;
  }
  .input-style-data {
    max-width: 100%;
  }

  .input-style-data::-webkit-inner-spin-button,
  .input-style-data::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /////////////////NUEVO CSS
  .second-row h3 {
    color: black !important;
  }
  .table-two {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .table-two div h3 {
    text-align: center;
    font-size: 0.85vw;
    color: white;
  }
  .table-two div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first-row {
    border-bottom: black solid 1px;
    background-color: #85bb65;
  }
  .tables-resume-entradas {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap: 1rem;
    margin-bottom: 1rem;
  }
  .table-one {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
    background-color: rgb(133,187,101, 0.2);
  }
  .table-one-content {
    background-color: white;
    border-top: none;
    padding: 0;
  }
  .table-one-content div {
    padding: 0.5rem;
  }
  .table-one div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
  .table-two {
    border: black solid 1px;
  }
  .conatiner-data {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
  }
  .conatiner-data div h3 {
    font-size: 0.85vw;
    text-align: center;
    font-weight: 600;
  }
  .container-prices-box {
    border: black solid 1px;
    border-bottom: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
    background-color: rgb(133,187,101, 0.2);
  }
  .container-prices-box div h3 {
    font-size: 0.85vw;
    text-align: center;
    font-weight: 600;
  }
  .title-precio {
    padding: 0.5rem;
  }
  .title-precio h3 {
    font-size: 0.9vw;
    text-align: right;
    padding-right: 1rem;
  }

  .space {
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-top: black solid 1px;
    height: 2rem;
  }
  .header-hotel {
    background-color: orange;
  }
  .header-hotel div h3 {
    color: white;
  }
  ///////////FIN DEL NUEVO CSSS
  /////////////////////

  .container-prices-box {
    border: black solid 1px;
  }
  .header-hotel {
    border: black solid 1px;
    background-color: #85bb65;
  }
  .header-hotel div h3 {
    color: white;
  }
  .space {
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-top: black solid 1px;
    height: 2rem;
  }
  /////////////////////
  .container-hotel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    border-left: black solid 1px;

    border-top: black solid 1px;
  }
  .container-hotel div {
    padding: 0.8rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .container-hotel div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
  .container-hotel div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }
  ////////////////////
  .titulo {
    border-left: black solid 1px;
    border-right: black solid 1px;
    padding: 0.5rem;
  }
  .titulo h3 {
    font-size: 0.85vw;
  }
  .header-hotel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: black solid 1px;
    border-left: black solid 1px;

    border-top: black solid 1px;
  }
  .header-hotel div {
    padding: 0.4rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
  .header-hotel div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
  .header-hotel div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }

  ///////////////////////////

  .price-one {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    border-left: black solid 1px;

    border-top: black solid 1px;
  }
  .price-one div {
    padding: 0.8rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
  .price-one div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
  .price-one div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }
  .hidden-boder {
    border: transparent solid 1px !important ;
  }
  .total-pesos {
    border-left: black solid 1px;
  }
  .last-file {
    border-bottom: black solid 1px;
  }
  .title-clase {
    font-size: 0.85vw;
    margin-bottom: 0.4rem;
  }
  .resume-total {
    color: black !important;
  }
  .container-prices-box {
    border-bottom: none;
  }

  @media print {
    .container-hotel div {
      padding: 0.4rem;
    }
    .title-clase {
      margin-bottom: 0.5rem;
      font-size: 0.85vw;
    }

    .container-prices-box div h3 {
      font-weight: 300 !important;
      color: black !important;
    }

    .container-prices-box {
      background-color: white;
    }
    .header-hotel div h3 {
      color: black;
    }
    .resume-pago {
      color: black !important;
    }
    .conatiner-data div h3 {
      color: black;
      font-weight: 300;
    }
    .container-results {
      background-color: "";
    }
  }
`;

function convertirStringANumero(str: string): number {
  // Eliminar caracteres no numéricos excepto el punto decimal
  const numeroLimpio = str.replace(/[^0-9.-]/g, "");

  // Parsear el string a número
  const numero = parseFloat(numeroLimpio);

  return numero;
}
