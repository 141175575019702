import React from 'react'; 
import styled from "styled-components";
import TableAuxiliarElement from './TableAuxiliarElement';
import { Invoice, ProformaDataI } from './AuxiliaresInterface';

interface TableAuxiliarI{
    listInvoices: Invoice[],
    auxiliar: ProformaDataI,
    deleteInvoice: (currentInvoice: Invoice) => void,
    editInvoice: (currentInvoice: Invoice) => void,
    setModoEdicion: React.Dispatch<React.SetStateAction<boolean>>,
    modoEdicion: boolean,
    currentInvoiceToModify: Invoice
}
export default function TableAuxiliar({listInvoices, auxiliar, deleteInvoice, editInvoice, setModoEdicion, modoEdicion, currentInvoiceToModify}:TableAuxiliarI) {
  return (
    <TableAuxiliarStyles>
        <div className='header-table-auxiliar'>
            <div>
                <h3>Fecha</h3>
            </div>
            
            <div>
                <h3>Num. de Factura</h3>
            </div>
            
            <div>
                <h3>Tipo de Cambio</h3>
            </div>
            
            <div>
                <h3>Subtotal facturar</h3>
            </div>
            
            <div>
                <h3>IVA</h3>
            </div>
            
            <div>
                <h3>Total a Facturar</h3>
            </div>

            
            <div>
                <h3>Tipo de factura</h3>
            </div>
              <div>
                <h3>Anticipo</h3>
            </div>


        </div>
        {
            listInvoices.map((currentInvoice, index)=>{
                return    <TableAuxiliarElement
                currentInvoice = {currentInvoice}
                auxiliar = {auxiliar}
                deleteInvoice = {deleteInvoice}
                editInvoice={editInvoice}
                setModoEdicion={setModoEdicion}
                modoEdicion={modoEdicion}
                currentInvoiceToModify={currentInvoiceToModify}
                />
            })
        }
        <br /><br />
  
    </TableAuxiliarStyles>
  )
}


const TableAuxiliarStyles = styled.div`
    .header-table-auxiliar{
        border: black solid 1px;
        padding: 0.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr;
        background-color: rgb(8, 166, 165);
    }
    .header-table-auxiliar div{
        max-width: 100%;        
    }
    .header-table-auxiliar div h3{
        text-align: center;
        font-size: 0.85vw;
        color: white;
        font-weight: 300; 
    }
`