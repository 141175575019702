import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import SearchComponent from "./Components/SearchComponent";
import TableSearch from "./Components/TableSearch";
import {
  AñoObjeto,
  OperationSearchElementI,
  ResultSearch,
} from "./InterfacesContabilidad";
import { AppContext } from "../../../context/appContext";

export default function WholeSearch() {
  const { setShowLoader } = useContext(AppContext);
  const [searchParameter, setSearchParameter] = useState("");
  const [listResulsGroups, setListResultsGroups] = useState<
    OperationSearchElementI[]
  >([]);
  const listaAños: AñoObjeto[] = [
    { id: 2019, año: 2019 },
    { id: 2020, año: 2020 },
    { id: 2021, año: 2021 },
    { id: 2022, año: 2022 },
    { id: 2023, año: 2023 },
    { id: 2024, año: 2024 },
  ];
  const currentYear = new Date().getFullYear();
  const [yearSelect, setYearSelected] = useState<number>(currentYear);
  const searchResultsGrupo = async () => {
    console.log("Buscando...");
    if (!searchParameter.trim()) {
      return;
    }
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint + `/accounting/SearchOperations/${searchParameter}/${yearSelect}`;
    setShowLoader(true);
    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data: ResultSearch = await response.json();
        console.log(data.data.results);
        setListResultsGroups(data.data.results);
        setShowLoader(false);
        if (data.data.results) {
          if (data.data.results.length == 0) {
            alert("No se encontraron resultados");
          }
        }
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  return (
    <WholeSearchStyles>
      <div className="search-contenedor">
        <SearchComponent
          searchParameter={searchParameter}
          setSearchParameter={setSearchParameter}
          searchResultsGrupo={searchResultsGrupo}
          yearSelect={yearSelect}
          setYearSelected={setYearSelected}
          listaAños={listaAños}
        />
        <div className="box-select">
          {yearSelect && <h3 className="titulo-select">Año</h3>}
          <SelectListdefault
            className="select-default-dos"
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            id="sl6"
            onChange={(e) => {
              // setLocalTarifa({
              //   ...localTarifa,
              //   continent_identifier: parseInt(e.target.value),
              // });
              setYearSelected(parseInt(e.target.value))
            }}
            value = {yearSelect || ""}
          >
            <option value="" disabled selected>
              Año
            </option>

            {listaAños.map((item) => {
              return (
                <option
                  key={item.id}
                  value={item.id}
                  // selected={
                  //   item.identifier ==
                  //   localTarifa.continent_identifier
                  // }
                >
                  {item.año}
                </option>
              );
            })}
          </SelectListdefault>
        </div>
      </div>

      <TableSearch listResulsGroups={listResulsGroups} />
    </WholeSearchStyles>
  );
}
const WholeSearchStyles = styled.div`

  .search-contenedor {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 80%;
    margin: 0 auto;
    grid-column-gap: 2rem;
  }
  .box-select {
    position: relative;
  }

  .titulo-select {
    position: absolute;
    top: -0.5rem;
    font-size: 0.85vw;
    background-color: white;
    margin-left: 1rem;
    color: rgb(8, 166, 165);
    font-weight: 300;
  }
  @media screen and (max-width: 900px){
    .titulo-select{
      font-size: 1.5vw;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.7rem 0.7rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  min-height: 44.4px !important;
  max-height: 44.4px !important;
`;
