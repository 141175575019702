import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import { QuoteItemI } from "../InterfacesOperacion/OperacionesI";
import { servicioIndividual } from "../../../../context/InterfacesContext";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
interface IndividualServiceI{
    individualService: servicioIndividual,
    servicioIndividualDia:servicioDayElementI,
    checkboxAll: boolean,
    setCheckBoxAll: React.Dispatch<React.SetStateAction<boolean>>
}
export default function IndividualService({individualService,  servicioIndividualDia, checkboxAll, setCheckBoxAll}: IndividualServiceI) {
  const {
    dataCotizacionContext,
    setDataCotizacionContext
  
  } = useContext(AppContext);

  const [isChecked, setIsChecked] = useState(individualService.isIncludedOPS);
  // Controlador de eventos para el cambio del checkbox
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setIsChecked(event.target.checked);
    console.log("COMBO BOX!!!!!!!!!!!!!!!!!!!!!!!!::");
    console.log(event.target.checked); 
    if(dataCotizacionContext.serviciosDayElementsOpt){
        if(dataCotizacionContext.serviciosDayElementsOpt.servicioDayElement.length > 0){
            const newArrayService = dataCotizacionContext.serviciosDayElementsOpt.servicioDayElement.map((diaServicios, index)=>{
                return diaServicios.identifierDay !== servicioIndividualDia.identifierDay ? diaServicios : {
                  ...diaServicios,
                  serviciosIndividuales: diaServicios.serviciosIndividuales.map((currentService, index)=>{
                    return currentService.identifierService !== individualService.identifierService ? currentService : {
                      ...currentService,
                      isIncludedOPS: event.target.checked
                    }
                  })
                }
              })
              console.log("Asi queda el nuevo arreglo de servicios:");
              console.log(newArrayService); 
              console.log("-------------------------------------------------------")
              setDataCotizacionContext({
                ...dataCotizacionContext,
                serviciosDayElementsOpt: {
                  servicioDayElement: newArrayService
                }
              }); 
              setIsChecked(event.target.checked);
              if(!event.target.checked){
                setCheckBoxAll(false); 
              }
        }
    }else{
        return; 
    }
 
  };

  useEffect(()=>{
    console.log("CONTEXTO:::");
    console.log(dataCotizacionContext);
     setIsChecked(individualService.isIncludedOPS); 
  },[individualService.isIncludedOPS])
  return (
    <IndividualServiceStyle>
             <div className="header-table-content">
        <div>
          <h3>{individualService.providerDescripcion}</h3>
        </div>
        <div>
          <h3>{individualService.service_name}</h3>
        </div>
        <div>
          <h3>{individualService.tarifa.toFixed(2)}</h3>
        </div>
        <div>
          <h3>Vigencia</h3>
        </div>
        <div>
          <h3>{individualService.dateRequired}</h3>
        </div>
     

   
        <div className="combo">
        <input type="checkbox"
        checked ={isChecked}
        onChange={handleCheckboxChange}
        />
        </div>

      </div>
    </IndividualServiceStyle>
  )
}


const IndividualServiceStyle = styled.div`
    .header-table-content{
        border: black solid 1px; 
   padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    align-items: center;
    }
    h3{
        color: black  !important;; 
    }
    .combo{
        display: flex;
        justify-content: center;
        align-items: center;
    }
`