import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import GElement from "./GElement";
import {
  CommentI,
  GeneralServiceI,
  IndividualServiceI,
  QuoteGeneralElement,
  QuoteObjectI,
  ServiceIndividualI,
} from "../../../interfaces/GeneralTypes";
import guardardatos from "../../../images/GUARDARDATOS.png";
import cancelar from "../../../images/CANCELAR.png";
import { dataCotizacionInicial } from "../../../context/initialContext";
import { useNavigate } from "react-router-dom";
import { OpcionalCotizacion } from "./interfaces/CotizacionI";
import { OperationContext } from "../../../context/operationContext";
export default function SaveCotizacion() {
  const navigate = useNavigate();
  const {
    dataCotizacionContext,
    currentCurrency,
    setDataCotizacionContext,
    listOpcionalesContext,
    setListOpcionalesContext,
    setIdOperation,
  } = useContext(AppContext);
  const {
    showLoader,
    setShowLoader,
    setTitleAlert,
    currentUser,
    setCurrentUser,
    cotizationExtraProperties,
  } = useContext(AppContext);
  const { showAlert, setShowAlert, cotizacionMode, setCotizacionMode } =
    useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const editQuote = async()=>{
    console.log("Here");
    const serviciosIndivualesRequest: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (serviceDay, index) => {
          const currentIndividualServices: ServiceIndividualI[] =
            serviceDay.serviciosIndividuales.map((serviceD, index) => {
              const serviceIndividual: ServiceIndividualI = {
                service_type_identifier: serviceD.serviceType,
                occupation: serviceD.ocupacionDescripcion,
                provider_identifier: serviceD.providerType,
                service_identifier: serviceD.service_name_identifier,
                category_identifier: 1,
                fee: serviceD.tarifa,
                IsActive: true,
                single_complement:
                  serviceD.tarifaSingleComplementConverted ?? 0,
                triple_complement:
                  serviceD.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  serviceD.individual_service_identifier ?? 0,
                status_identifier: serviceD.status_identifier ?? 1,
              };
              return serviceIndividual;
            });

          const individualService: IndividualServiceI = {
            service_date: serviceDay.validityStartDay,
            service_tittle: serviceDay.travelRoute,
            services: currentIndividualServices,
            isActive: true,
            service_identifier: 0,
            status_identifier: 1,
          };
          return individualService;
        }
      );

    const general_transport_services: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: sI.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: 20,
                triple_complement: 10,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                number_of_services: sI.number_of_services ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosIndivuales: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: currentService.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: obtenerCalificacion(sI.category),
                fee: sI.tarifa,
                IsActive: true,
                single_complement: sI.tarifaSingleComplementConverted ?? 0,
                triple_complement: sI.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosGenerales: GeneralServiceI[] =
      dataCotizacionContext.generalServices
        .map((currentService, index) => {
          const generalService: GeneralServiceI = {
            lender_identifier: currentService.service_type,
            provider_identifier: currentService.provider_id ?? 0,
            service_identifier: currentService.service_name_id ?? 0,
            fee: currentService.fee_price,
            number_of_services: currentService.number_days,
            service_total:
              currentService.fee_price * currentService.number_days,
            general_service_identifier:
              currentService.general_service_identifier,
            status_identifier: currentService.status_identifier,
            fee_identifier: currentService.fee_identifier ?? 0,
          };
          return generalService;
        })
        .filter((currentService, index) => {
          return (
            currentService.fee != 0 && currentService.number_of_services != 0
          );
        });
    const dataCommentsArray: CommentI[] =
      dataCotizacionContext.feeCharges.comments.map(
        (currentComment, index) => {
          return {
            comment_identifier: currentComment.comment_identifier,
            comment: currentComment.comment,
            comment_date: currentComment.comment_date,
            status_identifier: currentComment.status_identifier,
            commentIdentifier: currentComment.comment_identifier.toString(),
          };
        }
      );

    const listCotizacionesOpcionalesToSend: OpcionalCotizacion[] =
      listOpcionalesContext
        .map((opcionalCotizacion, indice) => {
          const currentOpcionalCotizacion: OpcionalCotizacion = {
            quote_identifier: opcionalCotizacion.identifier,
            optional_group_identifier:
              opcionalCotizacion.optional_group_identifier,
            status_identifier: 1,
          };
          return currentOpcionalCotizacion;
        })
        .filter((opcional, index) => {
          return opcional.optional_group_identifier == 0;
        });

    const bodySaveRequest: QuoteObjectI = {
      user_identifier: 1,
      quote: {
        private_quote: true,
        name: dataCotizacionContext.personalInfo.name,
        type: dataCotizacionContext.personalInfo.typeCotizacion,
        agency: dataCotizacionContext.personalInfo.agencia,
        series: dataCotizacionContext.personalInfo.serieId,
        PAX: dataCotizacionContext.personalInfo.pax,
        TL: dataCotizacionContext.personalInfo.TL,
        MIN: dataCotizacionContext.personalInfo.min,
        MAX: dataCotizacionContext.personalInfo.max,
        arrival_date:
          dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
            ? ""
            : dataCotizacionContext.personalInfo.arrivingData,
        departure_date:
          dataCotizacionContext.personalInfo.departureDate == "departureData"
            ? ""
            : dataCotizacionContext.personalInfo.departureDate,
        currency_identifier: currentCurrency,
        commission: dataCotizacionContext.feeCharges.comision,
        commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
        courtesy: dataCotizacionContext.feeCharges.cortesia,
        exchange_rate: dataCotizacionContext.feeCharges.changeType,
        comments: dataCotizacionContext.feeCharges.comments,
        total: dataCotizacionContext.totalPrice.totalPrice,
        quote_register_type:
          dataCotizacionContext.personalInfo.typeCotizacion,
        number_of_days:
          dataCotizacionContext.personalInfo.numberDaysOpenQuote,
        serie:
          dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
            ? true
            : false,
        status_identifier:
          dataCotizacionContext.personalInfo.status_identifier,
        quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
        courtesy_type_identifier:
          dataCotizacionContext.feeCharges.courtesy_type_identifier,
        total_base_sgl: cotizationExtraProperties.total_base_sgl,
        total_base_dbl: cotizationExtraProperties.total_base_dbl,
        total_base_tpl: cotizationExtraProperties.total_base_tpl,
        simple_supplement: cotizationExtraProperties.simple_supplement,
        triple_reduction: cotizationExtraProperties.triple_reduction,
        optional_quote:
          dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
            ? true
            : false,
      },
      quote_general_services: serviciosGenerales,
      quote_individual_services: serviciosIndivuales,
      general_transport_services: general_transport_services,
      optional_groups: listCotizacionesOpcionalesToSend,
    };
    console.log("body request");
    console.log(bodySaveRequest);

    const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/update`;
    setShowLoader(true);
    try {
      const response = await fetch(urlModified, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodySaveRequest),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response Server");
        console.log(data);
        setShowLoader(false);
        setTitleAlert(`La cotización se ha guardado correctamente. `);
        setContentAlert(`ID de cotización: ${data.data.quote_identifier}`);

        setShowAlert(true);

        navigate("/dashboard/List-Cotizacion");
        setDataCotizacionContext(dataCotizacionInicial);
        setListOpcionalesContext([]);
        alert("cambbio");
      } else {
        setShowLoader(false);
        if(cotizacionMode == 3){
          return; 
        }
        setTitleAlert(`La cotización  no se ha guardado correctamente. `);
        setContentAlert(`Vuelve a intentarlo.`);
      }
    } catch (error) {
      ///ERROR AL REALIZAR PETICION
      setShowLoader(false);
      return;
      setTitleAlert(`La cotización  no se ha guardado correctamente error. `);
      setContentAlert(`Vuelve a intentarlo.`);
    }
  }
  const updateOptionalsQuote = async() =>{
    const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/optionalsOpsUpdate`;
    const listCotizacionesOpcionalesToSend: OpcionalCotizacion[] =
    listOpcionalesContext
      .map((opcionalCotizacion, indice) => {
        const currentOpcionalCotizacion: OpcionalCotizacion = {
          quote_identifier: opcionalCotizacion.identifier,
          optional_group_identifier:
            opcionalCotizacion.optional_group_identifier,
          status_identifier: 1,
        };
        return currentOpcionalCotizacion;
      })
      .filter((opcional, index) => {
        return opcional.optional_group_identifier == 0;
      });
    const bodySaveRequest: QuoteObjectI = {
      user_identifier: 1,
      quote: {
        private_quote: true,
        name: dataCotizacionContext.personalInfo.name,
        type: dataCotizacionContext.personalInfo.typeCotizacion,
        agency: dataCotizacionContext.personalInfo.agencia,
        series: dataCotizacionContext.personalInfo.serieId,
        PAX: dataCotizacionContext.personalInfo.pax,
        TL: dataCotizacionContext.personalInfo.TL,
        MIN: dataCotizacionContext.personalInfo.min,
        MAX: dataCotizacionContext.personalInfo.max,
        arrival_date:
          dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
            ? ""
            : dataCotizacionContext.personalInfo.arrivingData,
        departure_date:
          dataCotizacionContext.personalInfo.departureDate == "departureData"
            ? ""
            : dataCotizacionContext.personalInfo.departureDate,
        currency_identifier: currentCurrency,
        commission: dataCotizacionContext.feeCharges.comision,
        commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
        courtesy: dataCotizacionContext.feeCharges.cortesia,
        exchange_rate: dataCotizacionContext.feeCharges.changeType,
        comments: dataCotizacionContext.feeCharges.comments,
        total: dataCotizacionContext.totalPrice.totalPrice,
        quote_register_type:
          dataCotizacionContext.personalInfo.typeCotizacion,
        number_of_days:
          dataCotizacionContext.personalInfo.numberDaysOpenQuote,
        serie:
          dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
            ? true
            : false,
        status_identifier:
          dataCotizacionContext.personalInfo.status_identifier,
        quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
        courtesy_type_identifier:
          dataCotizacionContext.feeCharges.courtesy_type_identifier,
        total_base_sgl: cotizationExtraProperties.total_base_sgl,
        total_base_dbl: cotizationExtraProperties.total_base_dbl,
        total_base_tpl: cotizationExtraProperties.total_base_tpl,
        simple_supplement: cotizationExtraProperties.simple_supplement,
        triple_reduction: cotizationExtraProperties.triple_reduction,
        optional_quote:
          dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
            ? true
            : false,
      },
      quote_general_services: [],
      quote_individual_services: [],
      general_transport_services: [],
      optional_groups: listCotizacionesOpcionalesToSend,
    };
    try {
      const response = await fetch(urlModified, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodySaveRequest),
      });
      console.log("Analisis de respuesta");
      console.log(response);
      setIdOperation(
        dataCotizacionContext.personalInfo.operation_identifier ?? 0
      );

      
      // if (response.ok) {
      const data = await response.json();
      console.log("data Optional Quotes");
      console.log(data);

      return;


    
    } catch (error) {
      ///ERROR AL REALIZAR PETICION
      setShowLoader(false);
    
      return;
      setTitleAlert(`La cotización  no se ha guardado correctamente. `);
      setContentAlert(`Vuelve a intentarlo.`);
    }
      
  }
  
  
  const saveQuotation = async () => {
    ////////////////Guardado tipo 4 funciona para modificar una versión de la cotización y a su vez modificar la operación
    if(cotizacionMode == 4){
      console.log("Here");
      const serviciosIndivualesRequest: IndividualServiceI[] =
        dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
          (serviceDay, index) => {
            const currentIndividualServices: ServiceIndividualI[] =
              serviceDay.serviciosIndividuales.map((serviceD, index) => {
                const serviceIndividual: ServiceIndividualI = {
                  service_type_identifier: serviceD.serviceType,
                  occupation: serviceD.ocupacionDescripcion,
                  provider_identifier: serviceD.providerType,
                  service_identifier: serviceD.service_name_identifier,
                  category_identifier: 1,
                  fee: serviceD.tarifa,
                  IsActive: true,
                  single_complement:
                    serviceD.tarifaSingleComplementConverted ?? 0,
                  triple_complement:
                    serviceD.tarifaTripleSuplementConverted ?? 0,
                  individual_service_identifier:
                    serviceD.individual_service_identifier ?? 0,
                  status_identifier: serviceD.status_identifier ?? 1,
                };
                return serviceIndividual;
              });
  
            const individualService: IndividualServiceI = {
              service_date: serviceDay.validityStartDay,
              service_tittle: serviceDay.travelRoute,
              services: currentIndividualServices,
              isActive: true,
              service_identifier: 0,
              status_identifier: 1,
            };
            return individualService;
          }
        );
  
      const general_transport_services: IndividualServiceI[] =
        dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
          (currentService, index) => {
            const individualServices: ServiceIndividualI[] =
              currentService.serviciosIndividuales.map((sI, index) => {
                const servicioI: ServiceIndividualI = {
                  service_type_identifier: sI.serviceType,
                  occupation: sI.ocupacionDescripcion,
                  provider_identifier: sI.providerType,
                  service_identifier: sI.service_name_identifier,
                  category_identifier: 2,
                  fee: sI.tarifa,
                  IsActive: true,
                  single_complement: 20,
                  triple_complement: 10,
                  individual_service_identifier:
                    sI.individual_service_identifier ?? 0,
                  status_identifier: sI.status_identifier ?? 1,
                  number_of_services: sI.number_of_services ?? 1,
                  fee_identifier: sI.fee_identifier,
                };
                return servicioI;
              });
  
            const servicioIndividual: IndividualServiceI = {
              service_date: currentService.validityStartDay,
              service_tittle: currentService.travelRoute,
              services: individualServices,
              isActive: true,
              service_identifier: currentService.service_identifier,
              status_identifier: 1,
            };
  
            return servicioIndividual;
          }
        );
  
      const serviciosIndivuales: IndividualServiceI[] =
        dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
          (currentService, index) => {
            const individualServices: ServiceIndividualI[] =
              currentService.serviciosIndividuales.map((sI, index) => {
                const servicioI: ServiceIndividualI = {
                  service_type_identifier: currentService.serviceType,
                  occupation: sI.ocupacionDescripcion,
                  provider_identifier: sI.providerType,
                  service_identifier: sI.service_name_identifier,
                  category_identifier: obtenerCalificacion(sI.category),
                  fee: sI.tarifa,
                  IsActive: true,
                  single_complement: sI.tarifaSingleComplementConverted ?? 0,
                  triple_complement: sI.tarifaTripleSuplementConverted ?? 0,
                  individual_service_identifier:
                    sI.individual_service_identifier ?? 0,
                  status_identifier: sI.status_identifier ?? 1,
                  fee_identifier: sI.fee_identifier,
                };
                return servicioI;
              });
  
            const servicioIndividual: IndividualServiceI = {
              service_date: currentService.validityStartDay,
              service_tittle: currentService.travelRoute,
              services: individualServices,
              isActive: true,
              service_identifier: currentService.service_identifier,
              status_identifier: 1,
            };
  
            return servicioIndividual;
          }
        );
  
      const serviciosGenerales: GeneralServiceI[] =
        dataCotizacionContext.generalServices
          .map((currentService, index) => {
            const generalService: GeneralServiceI = {
              lender_identifier: currentService.service_type,
              provider_identifier: currentService.provider_id ?? 0,
              service_identifier: currentService.service_name_id ?? 0,
              fee: currentService.fee_price,
              number_of_services: currentService.number_days,
              service_total:
                currentService.fee_price * currentService.number_days,
              general_service_identifier:
                currentService.general_service_identifier,
              status_identifier: currentService.status_identifier,
              fee_identifier: currentService.fee_identifier ?? 0,
            };
            return generalService;
          })
          .filter((currentService, index) => {
            return (
              currentService.fee != 0 && currentService.number_of_services != 0
            );
          });
      const dataCommentsArray: CommentI[] =
        dataCotizacionContext.feeCharges.comments.map(
          (currentComment, index) => {
            return {
              comment_identifier: currentComment.comment_identifier,
              comment: currentComment.comment,
              comment_date: currentComment.comment_date,
              status_identifier: currentComment.status_identifier,
              commentIdentifier: currentComment.comment_identifier.toString(),
            };
          }
        );
  
      const listCotizacionesOpcionalesToSend: OpcionalCotizacion[] =
        listOpcionalesContext
          .map((opcionalCotizacion, indice) => {
            const currentOpcionalCotizacion: OpcionalCotizacion = {
              quote_identifier: opcionalCotizacion.identifier,
              optional_group_identifier:
                opcionalCotizacion.optional_group_identifier,
              status_identifier: 1,
            };
            return currentOpcionalCotizacion;
          })
          .filter((opcional, index) => {
            return opcional.optional_group_identifier == 0;
          });
  
      const bodySaveRequest: QuoteObjectI = {
        user_identifier: 1,
        quote: {
          private_quote: true,
          name: dataCotizacionContext.personalInfo.name,
          type: dataCotizacionContext.personalInfo.typeCotizacion,
          agency: dataCotizacionContext.personalInfo.agencia,
          series: dataCotizacionContext.personalInfo.serieId,
          PAX: dataCotizacionContext.personalInfo.pax,
          TL: dataCotizacionContext.personalInfo.TL,
          MIN: dataCotizacionContext.personalInfo.min,
          MAX: dataCotizacionContext.personalInfo.max,
          arrival_date:
            dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
              ? ""
              : dataCotizacionContext.personalInfo.arrivingData,
          departure_date:
            dataCotizacionContext.personalInfo.departureDate == "departureData"
              ? ""
              : dataCotizacionContext.personalInfo.departureDate,
          currency_identifier: currentCurrency,
          commission: dataCotizacionContext.feeCharges.comision,
          commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
          courtesy: dataCotizacionContext.feeCharges.cortesia,
          exchange_rate: dataCotizacionContext.feeCharges.changeType,
          comments: dataCotizacionContext.feeCharges.comments,
          total: dataCotizacionContext.totalPrice.totalPrice,
          quote_register_type:
            dataCotizacionContext.personalInfo.typeCotizacion,
          number_of_days:
            dataCotizacionContext.personalInfo.numberDaysOpenQuote,
          serie:
            dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
              ? true
              : false,
          status_identifier:
            dataCotizacionContext.personalInfo.status_identifier,
          quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
          courtesy_type_identifier:
            dataCotizacionContext.feeCharges.courtesy_type_identifier,
          total_base_sgl: cotizationExtraProperties.total_base_sgl,
          total_base_dbl: cotizationExtraProperties.total_base_dbl,
          total_base_tpl: cotizationExtraProperties.total_base_tpl,
          simple_supplement: cotizationExtraProperties.simple_supplement,
          triple_reduction: cotizationExtraProperties.triple_reduction,
          optional_quote:
            dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
              ? true
              : false,
        },
        quote_general_services: serviciosGenerales,
        quote_individual_services: serviciosIndivuales,
        general_transport_services: general_transport_services,
        optional_groups: listCotizacionesOpcionalesToSend,
      };
      console.log("body request");
      console.log(bodySaveRequest);

   
      setShowLoader(false);
try {
  await editQuote(); // Espera a que editQuote() termine antes de continuar
  await updateOptionalsQuote(); // Espera a que updateOptionalsQuote() termine antes de continuar

} catch (error) {
  // Manejar errores aquí si es necesario
  
  alert("No fue posible guardar la actualización"); 
}
    const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/addNewQuoteServices`;
    setShowLoader(true);
    try {
      const response = await fetch(urlModified, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodySaveRequest),
      });
      console.log("Analisis de respuesta");
      console.log(response);
      setIdOperation(
        dataCotizacionContext.personalInfo.operation_identifier ?? 0
      );

      
      // if (response.ok) {
      const data = await response.json();
      console.log("data");
      console.log(data);
      setShowLoader(false);
      setTitleAlert(`La cotización se ha guardado correctamente. `);
      setContentAlert(`ID de cotización: ${data.data.quote_identifier}`);
      
      setShowAlert(true);
      // setDataCotizacionContext(dataCotizacionInicial);
      // setListOpcionalesContext([]);

      navigate("/dashboard/Operaciones/Manage-Operacion");
     

      // } else {
      //   setTitleAlert(`La cotización  no se ha guardado correctamente. `);
      //   setContentAlert(`Vuelve a intentarlo por favor.`);
      // }
    } catch (error) {
      ///ERROR AL REALIZAR PETICION
      setShowLoader(false);
    
      return;
      setTitleAlert(`La cotización  no se ha guardado correctamente. `);
      setContentAlert(`Vuelve a intentarlo.`);
    }
      
      return ;
    }

    ////GUARDADO TIPO 3 funciona para crear una nueva versión de la cotización, sin afectar a la cotización original
    if (cotizacionMode == 3) {
      console.log("Here");
    const serviciosIndivualesRequest: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (serviceDay, index) => {
          const currentIndividualServices: ServiceIndividualI[] =
            serviceDay.serviciosIndividuales.map((serviceD, index) => {
              const serviceIndividual: ServiceIndividualI = {
                service_type_identifier: serviceD.serviceType,
                occupation: serviceD.ocupacionDescripcion,
                provider_identifier: serviceD.providerType,
                service_identifier: serviceD.service_name_identifier,
                category_identifier: 1,
                fee: serviceD.tarifa,
                IsActive: true,
                single_complement:
                  serviceD.tarifaSingleComplementConverted ?? 0,
                triple_complement:
                  serviceD.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  serviceD.individual_service_identifier ?? 0,
                status_identifier: serviceD.status_identifier ?? 1,
              };
              return serviceIndividual;
            });

          const individualService: IndividualServiceI = {
            service_date: serviceDay.validityStartDay,
            service_tittle: serviceDay.travelRoute,
            services: currentIndividualServices,
            isActive: true,
            service_identifier: 0,
            status_identifier: 1,
          };
          return individualService;
        }
      );

    const general_transport_services: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: sI.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: 20,
                triple_complement: 10,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                number_of_services: sI.number_of_services ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosIndivuales: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: currentService.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: obtenerCalificacion(sI.category),
                fee: sI.tarifa,
                IsActive: true,
                single_complement: sI.tarifaSingleComplementConverted ?? 0,
                triple_complement: sI.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosGenerales: GeneralServiceI[] =
      dataCotizacionContext.generalServices
        .map((currentService, index) => {
          const generalService: GeneralServiceI = {
            lender_identifier: currentService.service_type,
            provider_identifier: currentService.provider_id ?? 0,
            service_identifier: currentService.service_name_id ?? 0,
            fee: currentService.fee_price,
            number_of_services: currentService.number_days,
            service_total:
              currentService.fee_price * currentService.number_days,
            general_service_identifier:
              currentService.general_service_identifier,
            status_identifier: currentService.status_identifier,
            fee_identifier: currentService.fee_identifier ?? 0,
          };
          return generalService;
        })
        .filter((currentService, index) => {
          return (
            currentService.fee != 0 && currentService.number_of_services != 0
          );
        });
    const dataCommentsArray: CommentI[] =
      dataCotizacionContext.feeCharges.comments.map(
        (currentComment, index) => {
          return {
            comment_identifier: currentComment.comment_identifier,
            comment: currentComment.comment,
            comment_date: currentComment.comment_date,
            status_identifier: currentComment.status_identifier,
            commentIdentifier: currentComment.comment_identifier.toString(),
          };
        }
      );

    const listCotizacionesOpcionalesToSend: OpcionalCotizacion[] =
      listOpcionalesContext
        .map((opcionalCotizacion, indice) => {
          const currentOpcionalCotizacion: OpcionalCotizacion = {
            quote_identifier: opcionalCotizacion.identifier,
            optional_group_identifier:
              opcionalCotizacion.optional_group_identifier,
            status_identifier: 1,
          };
          return currentOpcionalCotizacion;
        })
        .filter((opcional, index) => {
          return opcional.optional_group_identifier == 0;
        });

    const bodySaveRequest: QuoteObjectI = {
      user_identifier: 1,
      quote: {
        private_quote: true,
        name: dataCotizacionContext.personalInfo.name,
        type: dataCotizacionContext.personalInfo.typeCotizacion,
        agency: dataCotizacionContext.personalInfo.agencia,
        series: dataCotizacionContext.personalInfo.serieId,
        PAX: dataCotizacionContext.personalInfo.pax,
        TL: dataCotizacionContext.personalInfo.TL,
        MIN: dataCotizacionContext.personalInfo.min,
        MAX: dataCotizacionContext.personalInfo.max,
        arrival_date:
          dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
            ? ""
            : dataCotizacionContext.personalInfo.arrivingData,
        departure_date:
          dataCotizacionContext.personalInfo.departureDate == "departureData"
            ? ""
            : dataCotizacionContext.personalInfo.departureDate,
        currency_identifier: currentCurrency,
        commission: dataCotizacionContext.feeCharges.comision,
        commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
        courtesy: dataCotizacionContext.feeCharges.cortesia,
        exchange_rate: dataCotizacionContext.feeCharges.changeType,
        comments: dataCotizacionContext.feeCharges.comments,
        total: dataCotizacionContext.totalPrice.totalPrice,
        quote_register_type:
          dataCotizacionContext.personalInfo.typeCotizacion,
        number_of_days:
          dataCotizacionContext.personalInfo.numberDaysOpenQuote,
        serie:
          dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
            ? true
            : false,
        status_identifier:
          dataCotizacionContext.personalInfo.status_identifier,
        quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
        courtesy_type_identifier:
          dataCotizacionContext.feeCharges.courtesy_type_identifier,
        total_base_sgl: cotizationExtraProperties.total_base_sgl,
        total_base_dbl: cotizationExtraProperties.total_base_dbl,
        total_base_tpl: cotizationExtraProperties.total_base_tpl,
        simple_supplement: cotizationExtraProperties.simple_supplement,
        triple_reduction: cotizationExtraProperties.triple_reduction,
        optional_quote:
          dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
            ? true
            : false,
      },
      quote_general_services: serviciosGenerales,
      quote_individual_services: serviciosIndivuales,
      general_transport_services: general_transport_services,
      optional_groups: listCotizacionesOpcionalesToSend,
    };
    console.log("body request");
    console.log(bodySaveRequest);

      const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/addNewQuoteServices`;
      setShowLoader(true);
      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodySaveRequest),
        });
        console.log("Analisis de respuesta");
        console.log(response);
        setIdOperation(
          dataCotizacionContext.personalInfo.operation_identifier ?? 0
        );

        
        // if (response.ok) {
        const data = await response.json();
        console.log("data");
        console.log(data);
        setShowLoader(false);
        setTitleAlert(`La cotización se ha guardado correctamente. `);
        setContentAlert(`ID de cotización: ${data.data.quote_identifier}`);
        
        setShowAlert(true);
        // setDataCotizacionContext(dataCotizacionInicial);
        // setListOpcionalesContext([]);
        setShowLoader(false);
          ////La cotización original no debe editarse
        // editQuote(); 
        navigate("/dashboard/Operaciones/Manage-Operacion");
       
        updateOptionalsQuote(); 
        // } else {
        //   setTitleAlert(`La cotización  no se ha guardado correctamente. `);
        //   setContentAlert(`Vuelve a intentarlo por favor.`);
        // }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
        setShowLoader(false);
        if(cotizacionMode == 3){
          return;
        }
        return;
        setTitleAlert(`La cotización  no se ha guardado correctamente. `);
        setContentAlert(`Vuelve a intentarlo.`);
      }

      return;
    }

    //////////////////Guardado tipo 2 funciona para hacer un guardado normal de edición
    if (cotizacionMode == 2) {
      editQuote(); 
      
      navigate("/dashboard/List-Cotizacion");
      return;
    }

    const serviciosIndivualesRequest: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (serviceDay, index) => {
          const currentIndividualServices: ServiceIndividualI[] =
            serviceDay.serviciosIndividuales.map((serviceD, index) => {
              const serviceIndividual: ServiceIndividualI = {
                service_type_identifier: serviceD.serviceType,
                occupation: serviceD.ocupacionDescripcion,
                provider_identifier: serviceD.providerType,
                service_identifier: serviceD.service_name_identifier,
                category_identifier: obtenerCalificacion(serviceD.category),
                fee: serviceD.tarifa,
                IsActive: true,
                single_complement:
                  serviceD.tarifaSingleComplementConverted ?? 0,
                triple_complement: serviceD.tarifaTripleSuplementConverted ?? 0,
                status_identifier: serviceD.status_identifier ?? 0,
                individual_service_identifier:
                  serviceD.individual_service_identifier ?? 1,
                fee_identifier: serviceD.fee_identifier,
              };
              return serviceIndividual;
            });

          const individualService: IndividualServiceI = {
            service_date: serviceDay.validityStartDay,
            service_tittle: serviceDay.travelRoute,
            services: currentIndividualServices,
            isActive: true,
            service_identifier: 0,
            status_identifier: 1,
          };
          return individualService;
        }
      );

    const general_transport_services: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: sI.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: 20,
                triple_complement: 10,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                number_of_services: sI.number_of_services ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: 0,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );
    //     console.log("Esto es lo que estás enviando:");
    //     console.log(general_transport_services);
    // return;
    const serviciosIndivuales: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: currentService.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.serviceType,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: 20,
                triple_complement: 10,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: 0,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosGenerales: GeneralServiceI[] =
      dataCotizacionContext.generalServices.map((currentService, index) => {
        const generalService: GeneralServiceI = {
          lender_identifier: currentService.service_type,
          provider_identifier: currentService.provider_id ?? 0,
          service_identifier: currentService.service_name_id ?? 0,
          fee: currentService.fee_price,
          number_of_services: currentService.number_days,
          service_total: currentService.fee_price * currentService.number_days,
          general_service_identifier: currentService.general_service_identifier,
          status_identifier: currentService.status_identifier,
          fee_identifier: currentService.fee_identifier ?? 0,
        };
        return generalService;
      });

    const listCotizacionesOpcionalesToSend: OpcionalCotizacion[] =
      listOpcionalesContext.map((opcionalCotizacion, indice) => {
        const currentOpcionalCotizacion: OpcionalCotizacion = {
          quote_identifier: opcionalCotizacion.identifier,
          optional_group_identifier: null,
          status_identifier: 1,
        };
        return currentOpcionalCotizacion;
      });
    const bodySaveRequest: QuoteObjectI = {
      user_identifier: 1,
      quote: {
        private_quote: true,
        name: dataCotizacionContext.personalInfo.name,
        type: dataCotizacionContext.personalInfo.typeCotizacion,
        agency: dataCotizacionContext.personalInfo.agencia,
        series: dataCotizacionContext.personalInfo.serieId,
        PAX: dataCotizacionContext.personalInfo.pax,
        TL: dataCotizacionContext.personalInfo.TL,
        MIN: dataCotizacionContext.personalInfo.min,
        MAX: dataCotizacionContext.personalInfo.max,
        arrival_date:
          dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
            ? ""
            : dataCotizacionContext.personalInfo.arrivingData !== ""
            ? dataCotizacionContext.personalInfo.arrivingData
            : dataCotizacionContext.personalInfo.dateStartOpen,
        departure_date:
          dataCotizacionContext.personalInfo.departureDate == "departureData"
            ? ""
            : dataCotizacionContext.personalInfo.departureDate !== ""
            ? dataCotizacionContext.personalInfo.departureDate
            : dataCotizacionContext.personalInfo.dateEndOpen,
        currency_identifier: currentCurrency,
        commission: dataCotizacionContext.feeCharges.comision,
        commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
        courtesy: dataCotizacionContext.feeCharges.cortesia,
        exchange_rate: dataCotizacionContext.feeCharges.changeType,
        comments: dataCotizacionContext.feeCharges.comments,
        total: dataCotizacionContext.totalPrice.totalPrice,
        quote_register_type: dataCotizacionContext.personalInfo.typeCotizacion,
        number_of_days: dataCotizacionContext.personalInfo.numberDaysOpenQuote,
        serie:
          dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
            ? true
            : false,
        courtesy_type_identifier:
          dataCotizacionContext.feeCharges.courtesy_type_identifier,
        status_identifier: 5,
        quote_identifier: 0,
        optional_quote:
          dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
            ? true
            : false,
        total_base_sgl: cotizationExtraProperties.total_base_sgl,
        total_base_dbl: cotizationExtraProperties.total_base_dbl,
        total_base_tpl: cotizationExtraProperties.total_base_tpl,
        simple_supplement: cotizationExtraProperties.simple_supplement,
        triple_reduction: cotizationExtraProperties.triple_reduction,
      },
      quote_general_services: serviciosGenerales,
      quote_individual_services: serviciosIndivualesRequest,
      general_transport_services: general_transport_services,
      optional_groups: listCotizacionesOpcionalesToSend,
    };
   
    const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/register`;
    setShowLoader(true); 
    try {
      const response = await fetch(urlModified, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(bodySaveRequest),
      });

      if (response.ok) {
        const data = await response.json();

        setTitleAlert(`La cotización se ha guardado correctamente. `);
        setContentAlert(`ID de cotización: ${data.data.quote_identifier}`);

   
        setDataCotizacionContext(dataCotizacionInicial);
        setListOpcionalesContext([]);
        
        setShowLoader(false);
        setShowAlert(true);

        navigate("/dashboard/List-Cotizacion");
      } else {
        setShowLoader(false);
        setTitleAlert(`La cotización  no se ha guardado correctamente. `);
        setContentAlert(`Vuelve a intentarlo.`);
      }
    } catch (error) {
      ///ERROR AL REALIZAR PETICION
      alert("FALLO el guardado");
      setShowLoader(false);
      setTitleAlert(`La cotización  no se ha guardado correctamente. `);
      setContentAlert(`Vuelve a intentarlo.`);
    }
  };

  const deleteDataCotizacion = () => {
    setDataCotizacionContext(dataCotizacionInicial);
  };
  return (
    <SaveCotizacionStyles>
      <div className="buttons" style={{ backgroundColor: "transparent" }}>
        <img
          src={guardardatos}
          alt="leavePage"
          width="45px"
          onClick={saveQuotation}
          style={{ cursor: "pointer", marginBottom: "1rem" }}
        />
        {/* <img
          src={cancelar}
          alt="leavePage"
          width="45px"
          onClick={deleteDataCotizacion}
          style={{ cursor: "pointer", marginBottom: "1rem" }}
        /> */}
      </div>
    </SaveCotizacionStyles>
  );
}

const SaveCotizacionStyles = styled.div``;


function obtenerCalificacion(calificacion: string): number {
  switch (calificacion) {
    case "1 Estrella":
      return 1;
    case "2 Estrellas":
      return 2;
    case "3 Estrellas":
      return 3;
    case "4 Estrellas":
      return 4;
    case "5 Estrellas":
      return 5;
    default:
      console.error("Calificación no válida, se devuelve el número 2");
      return 1;
  }
}