import { Fragment, useState, Dispatch, SetStateAction } from "react";
import styled from 'styled-components'
import GrlLabel from "../labels/GrlLabel";

import Observacionesimg from "../../../images/observaciones.png"
import MoreInfoimg from "../../../images/moreinfo.png"
import Observaciones from "../modals/Observaciones";
import CRUDmodal from "../modals/CRUDmodal";

interface Props {
    columns: string[];
    action: string;
    data: any[];
}

type ModalProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
  }
  
  type ModalComponentType = React.FC<ModalProps>;
  
  let ModalComponent: ModalComponentType | null = null;

function StyledTable(props: Props) {
    const [showModal,setShowModal] = useState(false);
    
      
      

    function GetActionImg(): any {
        let tableInfo: { [key: string]: any } = {};
        switch (props.action){
            case "Observaciones":
                tableInfo = {
                    "img": Observacionesimg,
                    "action": Observaciones
                }
                ModalComponent = Observaciones;
            return tableInfo;

            case "CRUD":
                tableInfo = {
                    "img": MoreInfoimg,
                    "action": CRUDmodal
                }
                ModalComponent = CRUDmodal;
            return tableInfo

            default:
                return "";
       }
    }


    function GetColumns(): number {
        return props.columns.length;
    }

    const TableHeader = styled.div`
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(${GetColumns()}, 1fr);
        background-color: #08A6A5;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;

        @media screen and (max-width:900px){
            overflow: auto;
        }
    `;
    
    const TableRow = styled.div`
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(${GetColumns()}, 1fr);
        grid-template-rows: auto;
        background-color: #ffffff;
        border: 1px solid #b1b1b1;
    
        @media screen and (max-width:900px){
            overflow: auto;
        }
        `;


    return (
        <Fragment>
            <PrincipalContainer>
                <TableHeader>
                    {
                        props.columns.map((column) => (
                            <Fragment>
                                <ColumTittle>{column}</ColumTittle>
                            </Fragment>
                        ))
                    }
                </TableHeader>
                    {
                        props.data.length <= 0 ? (
                            <Fragment>
                                {
                                    <EmptyRow>No has creado ninguna tarifa.</EmptyRow>
                                }
                            </Fragment>
                        ) : (
                            <Fragment>
                                {
                                    props.data.map((item) => (
                                        <TableRow>
                                            {Object.keys(item).map((key) => (
                                                <RowContent key={key}>{item[key]}</RowContent>
                                            ))}
                                            <ActionRow>
                                                <ActionImg 
                                                    src={GetActionImg().img}
                                                    // onClick={(e) => {
                                                    //     console.log(ModalComponent);
                                                    //     setShowModal(!showModal)
                                                    // }}
                                                    ></ActionImg>
                                            </ActionRow>
                                        </TableRow>
                                    ))
                                }
                            </Fragment>
                        )
                    }
            </PrincipalContainer>
            {
                ModalComponent && (
                    <ModalComponent
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />)
            }
        </Fragment>
    );
}

export default StyledTable;

const PrincipalContainer = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (max-width:900px){
        max-width: 100vw;
    }
    `;


const ColumTittle = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 1vw;
    font-weight: bold;
    white-space: nowrap;
    padding: 10px 0px;
    `;


const RowContent = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 1vw;
    text-align: center;
    padding: 10px 0px;
    `;

const ActionRow = styled.div`
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 1vw;
    padding: 10px 0px;
    `;

const ActionImg = styled.img`
    width: 30px;
    height: auto;
    cursor: pointer;
    `;

    const EmptyRow = styled.div`
        width: auto;
        background-color: #ffffff;
        color: #c2c2c2;
        margin: 20px 0px;
    `


