import React, { useEffect , useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCopy, faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { transformarFecha } from "../../Cotizacion/funciontes";
import deletedatos from "../../../../images/deletelogo.png";

export default function ListServices(props) {
  const {
    setIsShowingList,
    isShowingList,
    dataServices,
    setIndexServiceSelectes,
    indexServiceSelected,
    deletedatos,
    deleteServicio,
    indexBlueServiceSelected,
    setIndexBlueServiceSelected
  } = props;

  const [newListFees, setNewListFees] = useState([]);

  useEffect(() => {
    const listServices = dataServices.services_fees;

    console.log("-----------------------LIST SERVICES----------------");
    console.log(listServices);
    const newListServices = dataServices.services_fees.map(
      (service, indexService) => {
        return service.fee_validity_list.map((validity, index) => {
          return {
            initialValidity: validity?.validity_startdate,
            finalValidity: validity?.validity_enddate,
            nameService: service.service_name.trim()
              ? service.service_name
              : "Servicio sin nombre",
            serviceIndex: indexService,
            serviceInfo: service,
          };
        });
      }
    );
    let newFormatService = [].concat(...newListServices);
    ///Array ordenado por fechas:
    newFormatService = newFormatService.sort(
      (a, b) => new Date(a.initialValidity) - new Date(b.initialValidity)
    );
    console.log("--------------New list services---------");
    console.log(newListServices);
    console.log("-----Array flat ordered by dates:----------------");
    console.log(newFormatService);
    setNewListFees(newFormatService); 
  }, [dataServices.services_fees]);

  return (
    <ListServicesStyles>
      {
        <div className="box-list-services">
          <div className="burger-button-container active-button-container">
            <h3>Consulta</h3>
            <FontAwesomeIcon
              icon={faBars}
              className="burger-icon menu-burger"
              onClick={() => {
                setIsShowingList(!isShowingList);
              }}
            />
          </div>
          <div className="container-list">
         
          {newListFees.map((servicio, indiceLista) => {
             

              return (
                <div
                  className="list-element"
                  onClick={() => {
                    setIndexServiceSelectes(servicio.serviceIndex);
                    setIndexBlueServiceSelected(indiceLista);
                  
                  }}
                  style={{
                    backgroundColor:
                      indiceLista == indexBlueServiceSelected
                        ? "rgba(8, 166, 165, 0.3)"
                        : "",
                  }}
                >
                  <div className="titulo-tarifa">
                    <div
                      onClick={() => {
                        setIsShowingList(!isShowingList);
                      }}
                    >
                      <h3 className="title-tarifa">
                      
                       {servicio.nameService.trim()
                          ? servicio.nameService
                          : "Servicio sin nombre"}
                      </h3>
                    </div>

                    <img
                      src={deletedatos}
                      alt="Delete"
                      width="25"
                      onClick={() =>{
                        deleteServicio(servicio)
                      }}
                    />
                  </div>
                  <div
                    className="vigencia-info"
                    onClick={() => {
                      setIsShowingList(!isShowingList);
                    }}
                  >
                    <h5>{
                       transformarFecha(servicio.initialValidity)  + ' / '+ transformarFecha(servicio.finalValidity)
                        }</h5>
                    <div></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
    </ListServicesStyles>
  );
}

const ListServicesStyles = styled.div``;
