import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import {
  EntryDataResponse,
  EntryService,
  ProviderInterfaceOP,
  QuoteItemI,
  operationDataI,
  operationUpdateI,
  updateProviderI,
} from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import IndividualService from "./IndividualService";
import deleteImage from "../../../../images/deletelogo.png";
import agregarImage from "../../../../images/AGREGAR.png";
import { Link, useNavigate } from "react-router-dom";
import { OperationContext } from "../../../../context/operationContext";
import { formatearCantidad } from "../../../components/functionsTs/utilidades";

interface ElementDesgloceI {
  isEditionMode: boolean;
  currentService: EntryService;
  listServices: EntryService[];
  setListServices: React.Dispatch<React.SetStateAction<EntryService[]>>;
}

export default function ElementDesgloce({
  isEditionMode,
  currentService,
  listServices,
  setListServices,
}: ElementDesgloceI) {
  const [servicio, setServicio] = useState(currentService);

  useEffect(() => {
    console.log("CAMBIO SERVCIOOOOO");
    console.log("ESTE ES TU SERVICIO: ");
    console.log(servicio);
    const newListServices = listServices.map((servicioActual, index) => {
      return servicioActual.entry_service_identifier !==
        currentService.entry_service_identifier
        ? servicioActual
        : servicio;
    });
    setListServices(newListServices);
  }, [servicio]);

  useEffect(() => {
    const servicioActual: EntryService | undefined = listServices.find(
      (service, index) => {
        return (
          service.entry_service_identifier == servicio.entry_service_identifier
        );
      }
    );
    if (servicioActual) {
      setServicio(servicioActual);
    }
  }, [listServices]);

  return (
    <ElementDesgloceStyle>
      {(servicio.occupation == "BB" || servicio.occupation == "MAID") && (
        <div className="titulo extra">
          <h3>{servicio.occupation}l</h3>
        </div>
      )}

      <div className="container-hotel">
        <div className="title">
          {isEditionMode ? (
            <input
              type="text"
              onChange={(e) => {
                setServicio({
                  ...servicio,
                  service_name: e.target.value,
                });
              }}
              value={servicio.service_name}
            />
          ) : (
            <h3>{servicio.service_name}</h3>
          )}
        </div>
        <div className="title">
          <h3>
            {servicio?.initial_validity
              ? transformarFecha(servicio?.initial_validity)
              : ""}
          </h3>
        </div>
        <div className="title">
          <h3>
            {servicio?.initial_validity
              ? transformarFecha(servicio?.final_validity)
              : ""}
          </h3>
        </div>

        <div className="title">
          {isEditionMode ? (
            <input
              type="text"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setServicio({
                    ...servicio,
                    quantity1: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setServicio({
                      ...servicio,
                      quantity1: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={servicio.quantity1}
            />
          ) : (
            <h3>{servicio.quantity1}</h3>
          )}
        </div>

        <div className="title">
          {isEditionMode ? (
            <input
              type="text"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setServicio({
                    ...servicio,
                    quantity2: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setServicio({
                      ...servicio,
                      quantity2: parsedValue,
                    });
                  }
                }
              }}
              value={servicio.quantity2}
            />
          ) : (
            <h3>{servicio.quantity2}</h3>
          )}
        </div>

        <div className="title">
          <h3>{servicio.iva}</h3>
        </div>

        <div className="title">
          <h3>{servicio.taxes}</h3>
        </div>

        <div className="title">
          {isEditionMode ? (
            <input
              type="text"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setServicio({
                    ...servicio,
                    unitary_fee: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setServicio({
                      ...servicio,
                      unitary_fee: parsedValue,
                    });
                  }
                }
              }}
              value={servicio.unitary_fee}
            />
          ) : (
            <h3>${formatearCantidad(servicio.unitary_fee)}</h3>
          )}
        </div>

        <div className="title">
          <h3>
            $
            {formatearCantidad(
              servicio.unitary_fee * servicio.quantity1 * servicio.quantity2
            )}
          </h3>
        </div>

        <div className="title">
          <h3>{servicio.currency}</h3>
        </div>
      </div>
    </ElementDesgloceStyle>
  );
}

const ElementDesgloceStyle = styled.div`
  .extra {
    border-top: black solid 1px;
  }
`;

function transformarFecha(fechaStr) {
  // Separar la fecha en partes
  const [anio, mes, dia] = fechaStr.split("-");

  // Formatear la fecha en el nuevo formato
  const nuevaFecha = `${dia}/${mes}/${anio.slice(2)}`;

  return nuevaFecha;
}
