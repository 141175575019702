import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { OperationISearch } from "../InterfacesContabilidad";
import AgenciaElement from "./Busqueda/AgenciaElement";
interface AgenciaInfoI {
  listSearchAgencias: OperationISearch[];
}
export default function AgenciaInfo({ listSearchAgencias }: AgenciaInfoI) {
  return (
    <AgenciaInfoStyles>
      {listSearchAgencias.length > 0 && (
        <div className="header-agencia">
          <div>
            <h3>Grupo</h3>
          </div>
          <div>
            <h3>Fecha de viaje</h3>
          </div>
          <div>
            <h3>Proforma</h3>
          </div>
        </div>
      )}

      {listSearchAgencias.map((agencia, index) => {
        return <AgenciaElement 
        agencia = {agencia}
        />;
      })}
    </AgenciaInfoStyles>
  );
}

const AgenciaInfoStyles = styled.div`
  .header-agencia {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 50%;
    margin: 0 auto;
    border: black solid 1px;
    padding: 0.5rem;
    background-color: rgb(8, 166, 165);
  }
  .header-agencia div h3 {
    text-align: center;
    color: white;
    font-size: 0.85vw;
    font-weight: 300;
  }
`;
