import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import CustomInput from "../entrys/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faFloppyDisk,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import CustomInputt from "../entrys/CustomInputt";
import deletelogo from "../../../images/deletelogo.png";
import agregar from "../../../images/AGREGAR.png";
import edit from "../../../images/edit.png";
import guardar from "../../../images/GUARDARDATOS.png";
import { jobI } from "../../../interfaces/GeneralTypes";
import DeleteConfirmation from "../common/DeleteConfirmation";

interface Contacto {
  contact_identifier: number;
  ///////////////////////
  identifier: string;
  name: string;
  email: string;
  personal_phone: string;
  extension: string;
  charge_identifier: number;
  web: string;
  status: number;
  charge: string
}

interface propsContacto {
  contacto: Contacto;
  indice: number;
  editContacto: (indice: number, contactoModified: Contacto) => void;
  deleteContacto: (indice: number) => void;
  initialState: boolean;
  addNewContacto: () => void;
}

//

export default function ContactLayoutNew({
  contacto,
  indice,
  editContacto,
  deleteContacto,
  initialState,
  addNewContacto,
}: propsContacto) {
  const {
    identifier,
    name,
    email,
    personal_phone,
    extension,
    charge_identifier,
    web,
    status,
    contact_identifier,
    charge
  } = contacto;
  const { catalogsData } = useCatalogs();

  const [jobList, setJobList] = useState<jobI[]>([]);
  
  const [contactoInfo, setContactoInfo] = useState<Contacto>({
    contact_identifier: contact_identifier,
    ///////////////////////////////////
    identifier: identifier,
    name: name,
    email: email,
    personal_phone: personal_phone,
    extension: extension,
    charge_identifier: charge_identifier,
    web: web,
    charge: charge,
    status: 8,
  });
  useEffect(() => {    
    if (catalogsData?.job_list) {
      // const countriesWithoutAccents = removeAccentsFromCountries(
      //   catalogsData.country_list
      // );
      // setCountries(countriesWithoutAccents);
      
      setJobList(catalogsData.job_list); 
    }
    editContacto(indice, contactoInfo);
  }, [contactoInfo, catalogsData]);
  const saveData = () => {};

  const deleteElement = () => {
    deleteContacto(indice);
  };
  const handleEnter = () => {
    // Tu lógica aquí
    console.log("Enter presionado");
    setEditionMode(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleEnter();
    }
  };
  const [editionMode, setEditionMode] = useState<boolean>(initialState);
  const [isShownDelete, setIsShownDelete] = useState<boolean>(false); 
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteElement(); 
    setIsShownDelete(false);
////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  return (
<>
<ContactLayoutStyle>
      <div className="box-container">
        <div className="fila">
          <div>
            {editionMode ? (
              <input
                value={contactoInfo.name}
                onChange={(e) => {
                  const name = e.target.value;
                  setContactoInfo({
                    ...contactoInfo,
                    name,
                  });
                }}
                onKeyDown={handleKeyDown}
              />
            ) : (
              <h3>{name}</h3>
            )}
          </div>
          <div>
            {editionMode ? (
              <input
                value={contactoInfo.email}
                onChange={(e) => {
                  setContactoInfo({
                    ...contactoInfo,
                    email: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
              />
            ) : (
              <h3>{email}</h3>
            )}
          </div>

          <div>
            {editionMode ? (
              <input
                value={contactoInfo.personal_phone}
                onChange={(e) => {
                  setContactoInfo({
                    ...contactoInfo,
                    personal_phone: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
              />
            ) : (
              <h3>{personal_phone}</h3>
            )}
          </div>
          <div>
            {editionMode ? (
              <input
                value={contactoInfo.extension}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue)) {
                    setContactoInfo({
                      ...contactoInfo,
                      extension: inputValue,
                    });
                  }
                }}
                onKeyDown={handleKeyDown}
              />
            ) : (
              <h3>{extension}</h3>
            )}
          </div>
          <div>
  
          <SelectListdefault
              style={{
                width: "90%",
                maxWidth: "90%",
              }}
              id="sl6"
              onChange={(e) => {
                // setGeneralInfo({
                //   ...generalInfo,
                //   provider_type_identifier: parseInt(e.target.value),
                // });
                setContactoInfo({
                  ...contactoInfo,
                  charge_identifier: parseInt(e.target.value)
                })
         
              }}
            >
              <option value="" disabled selected>
                Puesto
              </option>

              {jobList?.length > 0
                ? jobList.map((item) => {
                    return (
                      <option
                        key={item.identifier}
                        value={item.identifier}
                        selected={
                          item.identifier ==
                          contactoInfo.charge_identifier
                        }
                      >
                        {item.description}
                      </option>
                    );
                  })
                : null}
            </SelectListdefault>      
          </div>
          <div className="buttons-icons">
            {editionMode ? (
              <img
                src={guardar}
                width="25px"
                onClick={() => {
                  saveData();
                  setEditionMode(false);
                }}
                className="icon-contact-img"
              />
            ) : (
              <img
                src={edit}
                width="25px"
                className="icon-contact-img"
                onClick={() => setEditionMode(true)}
              />
            )}
            <img
              src={deletelogo}
              width="25px"
              className="icon-contact-img"
              onClick={() => {
                
                handleDeleteClick(); 
              }}
            />
            {initialState ? (
              <img
                src={agregar}
                onClick={addNewContacto}
                alt="add-icon"
                width="25px"
                className="icon-contact-img"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <div className="box-fake"></div>
            )}
          </div>
        </div>
      </div>
    </ContactLayoutStyle>
   {
    isShownDelete &&  <DeleteConfirmation
    onDelete={handleDeleteConfirm} onCancel={handleDeleteCancel} itemName={`¿Deseas borrar el contacto ${name}?`}
    setIsShownDelete = {setIsShownDelete}
    />
   }
</>
  );
}
const ContactLayoutStyle = styled.div`
.box-fake{
 border: none !important; 
 min-width: 25px;
}
  .icon-contact-save {
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 1rem;
  }
  .trash {
    margin-right: 1rem;
    cursor: pointer;
  }
  .icon-contact-img {
    cursor: pointer;
    margin-right: 0.5rem;
  }
  h3 {
    font-size: 0.8rem;
  }
  .fila {
    border: grey solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.8fr;
    width: 95%;
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 700px){
    .fila{
      width: 100%;
    }
  }
  .fila div {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: grey solid 1px;
    overflow: hidden;
  }
  .fila h3 {
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 30em;
  }
  input {
    width: 100%;
    max-width: 100%;
  }
  .buttons-icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 900px){
    .icon-contact-img{
      width: 15px;
      margin-right: 0.2rem;
    }
    .buttons-icons{      
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 100%;      
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0rem;  
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  border: none; 
  max-height: 40.8px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
    min-width: 100%;
  }
`;