import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import EDITAR from "../../../images/edit.png";
import StyledTable from "../tables/StyledTable";
import {
  GeneralsData,
  bodyResponseProvider,
  ContactData,
  Tax,
  Service,
  Contract,
} from "../../../interfaces/ProviderTypes";
import guardar from "../../../images/GUARDARDATOS.png";
import Generales from "../layout/Generales";
import ContactLayoutNew from "../layout/ContactLayoutNew";
import GetRowContact from "../layout/GetRowContact";
import GetContact from "../layout/GetContact";
import { v4 as uuidv4 } from "uuid";
import {
  objectCard,
  serviceObject,
  blackoutObject,
  validityDates,
  feeIndividual,
} from "../../../interfaces/GeneralTypes";
import { objetoPrueba } from "../../../context/initialContext";
import {
  fiscalContextInicial,
  registerGeneralData,
} from "../../../context/initialContext";
import GetFiscal from "../layout/GetFiscal";
import {
  faFilePdf,
  faFileImage,
  faClose,
  faFile,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Fee } from "../../../interfaces/ProviderTypes";
import ServiceGIndividual from "./ServiceGIndividual";
import ServiceHIndividual from "./ServiceHIndividual";
import { useCatalogs } from "../../../context/CatalogContext";
import { categoriaI } from "../../../interfaces/GeneralTypes";
import { transformarFecha } from "../../pages/Cotizacion/funciontes";

interface GetServicioI {
  serviciosList: Service[] | undefined;
  getInfoProvider: () => void;
  setShowInfoContact: (show: boolean) => void;
  servicio: Service;
  feeList: Fee[];
  generalData: GeneralsData | undefined;
}
export default function GetServicio({
  serviciosList,
  getInfoProvider,
  setShowInfoContact,
  servicio,
  feeList,
  generalData,
}: GetServicioI) {
  const [isServiceVisible, setIsVisibleService] = useState<boolean>(false);
  const { catalogsData } = useCatalogs();
  const [categoryServices, setCategoryServices] = useState<categoriaI[]>([]);
  const [categoriaName, setCategoriaName] = useState<string>();

  return (
    <GetServicioStyles>
      <div className="general-service service-individual">
        <div className="bar-show-fee">
          <h3 className="service-name">
    
            <span className="service-real-name">{servicio.service_name}</span>
          </h3>
          <div
            onClick={() => {
              setIsVisibleService(!isServiceVisible);
            }}
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              className="down-arrow-service"
            />
          </div>
        </div>
        {isServiceVisible && (
          <div className="visible-service">
            <div className="box-container container-tarifa">
              <div className="info-general-fee">
                <p>Tipo del servicio: {servicio.service_type}</p>
                {servicio.service_type_identifier == 10 && (
                  <p>Categoría: {generalData?.category}</p>
                )}
                {servicio.service_type_identifier == 18 && (
                  <p>Categoría: {generalData?.category}</p>
                )}

                {servicio.service_comments.trim() && (
                  <p>Comentarios: {servicio.service_comments}</p>
                )}
                {servicio.service_type_identifier == 10 && (
                  <p>Plan de Alimentos: {servicio.service_mealplan}</p>
                )}
              </div>
            </div>
            {servicio.service_validity_list.length > 0 &&
              servicio.service_type_identifier == 10 && (
                <div className="box-container">
                  {servicio.service_validity_list.map((validity, index) => {
                    return (
                      <div className="filaa-fee">
                        <p>
                          Vigencia: {transformarFecha(validity.validity_startdate)} /{" "}
                          {transformarFecha(validity.validity_enddate)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            {servicio.service_type_identifier != 10 && (
              <div className="header-resume">
                <div>
                  <h3>Moneda</h3>
                </div>
                <div>
                  <h3>Tarifa</h3>
                </div>
                <div>
                  <h3>IVA</h3>
                </div>
                <div>
                  <h3>
                    {servicio.service_type_identifier !== 18
                      ? "Impuesto"
                      : "Capacidad"}
                  </h3>
                </div>
                <div>
                  <h3>Total</h3>
                </div>
                <div>
                  <h3>Vigencia</h3>
                </div>
              </div>
            )}
            <div
              className="table-container-data"
              style={{
                borderBottom:
                  servicio.service_type_identifier != 10
                    ? "rgb(8, 166, 165) solid 2px"
                    : "",
              }}
            >
              {feeList.map((fee, indiceActual) => {
                if (servicio.service_type_identifier != 10) {
                  return (
                    <ServiceGIndividual
                      fee={fee}
                      serviceTypeId={servicio.service_type_identifier}
                      servicio={servicio}
                      indiceTarifa={indiceActual}
                    />
                  );
                } else {
                  return (
                    <>
                      <ServiceHIndividual
                        fee={fee}
                        serviceTypeId={servicio.service_type_identifier}
                      />
                    </>
                  );
                }
              })}
              {servicio.service_type_identifier == 10 &&
                servicio?.service_blackout_list.length > 0 && (
                  <div className="box-container blackout-container">
                    {servicio?.service_blackout_list.map((blackout, index) => {
                      return (
                        <div className="filaa-fee">
                          <p>
                            Blackout: {transformarFecha(blackout.blackout_startdate) } /{" "}
                            {transformarFecha(blackout.blackout_enddate)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </GetServicioStyles>
  );
}

const GetServicioStyles = styled.div`
  .header-resume {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 1fr 1fr;
    border: rgb(8, 166, 165) solid 2px;
    padding: 0.5rem;
    width: 90%;
    margin: 0 auto;
    border-bottom: none;
  }
  .table-container-data {
    margin-bottom: 2rem;

    width: 90%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .header-resume div h3 {
    font-weight: 300;
    text-align: center;
    font-size: 0.95vw;
  }
  .service-real-name {
    color: white;
  }
  .container-tarifa {
    padding-bottom: 0 !important;
  }
  .box-container {
    padding: 1rem;
    padding-bottom: 0.1rem;
    padding-top: 0;
  }
  .service-name {
    color: white;
  }
  .down-arrow-service {
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
  }
  .bar-show-fee {
    background-color: rgba(8, 166, 165, 0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  p {
    font-size: 1vw;
  }
  .service-name {
    font-size: 1.3vw;
  }
  @media screen and (max-width: 900px) {
    p {
      font-size: 1.35vw;
    }
    .service-name {
      font-size: 1.8vw;
    }
  }
  .visible-service {
    padding-top: 1rem;
  }
`;
