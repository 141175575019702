import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBars, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import { servicioIndividual } from "../../../context/InterfacesContext";

interface ResumenElementI{
    servicioIndividual: servicioIndividual
}
export default function ResumenElement({servicioIndividual}:ResumenElementI) {
    const {
        category ,
        lendertype,
        lenderDescripcion, 
        ocupacion,
        ocupacionDescripcion,
        providerType,
        providerDescripcion,
        serviceType,
        serviceDescripcion,
        tarifa,
        identifierService
    } = servicioIndividual; 
    
  

  return (
    <ResumenElementStyles>
   
      
        <div className="servicio-content">
          
          <h3 className="desc-service">{providerDescripcion} - {ocupacionDescripcion == "SGL" ? "SGL": ocupacionDescripcion == "DBL"? "DBL": ocupacionDescripcion == "TPL" ? "TPL": serviceDescripcion} </h3>
          <h3> ${tarifa.toFixed(2)}</h3>
         
        </div>
      
    </ResumenElementStyles>
  );
}

const ResumenElementStyles = styled.div`
.desc-service::before {
  content: "•"; /* Carácter del punto negro */
  display: inline-block;
  margin-right: 5px; /* Espacio entre el punto negro y el texto */
}
.servicio-content{
    
    margin-bottom: 0.3rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
}
  .title-resumen {
    border: rgb(8, 166, 165) solid 1px;
    padding: 0.3rem;
    display: grid;
    grid-template-columns: 5fr 1fr;
    align-items: center;
    justify-content: center;
    border-right: none;
    border-left: none;
    
  }
  .arrow-down {
    color: rgb(8, 166, 165);
    font-size: 1.5vw;
    margin-right: 1rem;
    cursor: pointer;
  
  }
  h3{
    font-weight: 400 ;
  }
  @media screen and (max-width: 900px) {
    .arrow-down {
      font-size: 2.5vw;
    }
  }
`;

