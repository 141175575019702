import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import {
  servicioDayElementI,
  generalElementI,
} from "../../../context/InterfacesContext";
import GeneralElement from "./GeneralElement";
import GList from "./GList";

export default function GeneralesList() {
  const {
    dataCotizacionContext,
    setDataCotizacionContext,
    setShowSearchServices,
    setServiciosType,
    currentCurrency,
  } = useContext(AppContext);

  const [servicioPasajeroList, setServicioPasajeroList] = useState<
    generalElementI[]
  >(dataCotizacionContext.generalsElements.generalElement);

  useEffect(() => {
    setServicioPasajeroList(
      dataCotizacionContext.generalsElements.generalElement
    );
  }, [dataCotizacionContext.generalsElements.generalElement]);

  const totalGeneral = servicioPasajeroList.reduce(
    (total, item) => total + item.tarifa,
    0
  );

  return (
    <GeneralesListStyles>
      <div className="general-services-title">
        <h3>
          Servicios generales{" "}
          <span
            className="title-style"
            style={{
              color: "#29A5DC",
            }}
          >
             (estos servicios se dividirán entre el número de pax)
          </span>{" "}
        </h3>
      </div>
 
   
      <GList />

    </GeneralesListStyles>
  );
}

const GeneralesListStyles = styled.div`
  .general-services-title {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .general-services-title h3{
    font-size: 1vw;
}
  .generales-suma {
    width: 70%;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 0.85vw;
  }
  @media screen and (max-width: 900px) {
    .generales-suma {
      width: 90%;
    }
    .generales-suma h3 {
      font-size: 1.6vw !important;
    }
    .general-services-title{
      margin-top: 0.5rem;
      width: 88%;
    }
    .general-services-title h3{
    font-size: 1.85vw;
    }
  }
  .container-generales {
    width: 100%;
    margin: 0 auto;
    display: flex;
  }

  .fake-icons-box {
    width: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fake-icons-box img {
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    .fake-icons-box img {
      width: 15px !important;
    }
    .container-generales {
      width: 100%;
      margin: 0 auto;
    }
    .container-generales h3 {
      font-size: 1.3vw !important;
    }
  }
  .header-title-generales {
    border: rgb(8, 166, 165) solid 1px;
    width: 93%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.3fr 0.4fr 0.5fr;
    align-items: center;
    justify-content: center;
  }
  
  .header-title-generales div {
    padding: 0.5rem;
  }
  .header-title-generales div h3 {
    font-size: 0.7vw;
    color: rgb(8, 166, 165);
    text-align: center;
  }
`;
