import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import HojaControlElement from "../../Operaciones/HojaControl/HojaControlElement";
import { ProviderResponse } from "../../Operaciones/InterfacesOperacion/OperacionesI";
import { formatearCantidad } from "../../../components/functionsTs/utilidades";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import HojaControlElementAux from "./HojaControlElementAux";

interface PrestadorActual {
  prestador: ProviderResponse[] | undefined;
  tipoPrestador: string;
}
export default function HojaControlPrestadorAux({
  prestador,
  tipoPrestador,
}: PrestadorActual) {
  return (
    <HojaControlPrestadorStyle>
      <div className="first-row">
        <div className="title-table">
          <h3>{tipoPrestador}</h3>
        </div>
        <div className="botons-one"></div>
      </div>
      {prestador &&
        prestador.map((currentPrestador, index) => {
          return <HojaControlElementAux currentPrestador={currentPrestador} />;
        })}
        
{/* 
      {prestador && prestador?.length > 0 && (
        <div className="element-container">
          <div className="element-data">
            <div>
              <h3></h3>
            </div>
            <div>
              <h3></h3>
            </div>
            <div>
              <h3></h3>
            </div>
            <div>
     

              <h3
                className="get-link totales"
                //    onClick={()=>{getEntradaN(4)}}
              >
                Total MXN:
              </h3>
              <h3 className="totales">Total USD: </h3>
            </div>
            <div>
              <h3
                className="get-link totales"
                //    onClick={()=>{getEntradaN(4)}}
              >
                ${formatearCantidad(prestador[prestador.length - 1].entry_total_mxn)} MXN
              </h3>
              <h3 className="totales">
                ${formatearCantidad(prestador[prestador.length - 1].entry_total_usd)} USD
              </h3>
            </div>
            <div>
              <h3
                className="get-link totales"
                //    onClick={()=>{getEntradaN(4)}}
              >
                Restante MXN:
              </h3>
              <h3 className="totales">Restante USD: </h3>
            </div>
            <div>
              <h3
                className="get-link totales"
                //    onClick={()=>{getEntradaN(4)}}
              >
                ${formatearCantidad(prestador[prestador.length - 1].entry_remaining_mxn)} MXN
              </h3>
              <h3 className="totales">
                ${formatearCantidad(prestador[prestador.length - 1].entry_remaining_usd)} USD
              </h3>
            </div>
            <div>
              <h3
                className="get-link"
                //    onClick={()=>{getEntradaN(5)}}
              ></h3>
            </div>
            <div>
              <h3
                className="get-link"
                //    onClick={()=>{getEntradaN(6)}}
              ></h3>
            </div>
          </div>
          <div className="element-buttons"></div>
        </div>
      )} */}
    </HojaControlPrestadorStyle>
  );
}

const HojaControlPrestadorStyle = styled.div`
  .totales {
    font-weight: 600 !important;
  }
  .get-link {
    cursor: pointer;
  }
  .icon {
    cursor: pointer;
  }
  .element-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .element-container {
    display: flex;
  }
  .element-data {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-right: black solid 1px;
    padding: 0.5rem;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    justify-content: center;
    align-items: center;
  }
  .element-buttons {
    width: 0%;
  }
  h3 {
    font-size: 0.85vw;
    text-align: center;
    font-weight: 300 !important;
  }
  /////////////////////////////////////////////////////////7
  .icon {
    cursor: pointer;
  }
  .first-row {
    display: flex;
  }
  .botons-one {
    width: 0%;
    display: flex;
    justify-content: center;
  }
  .box-buttons {
    margin-left: 1rem;
  }
  .box-buttons button {
    border: none;
    padding: 0.2rem;
    font-size: 0.85vw;
    cursor: pointer;
  }
  .guia-boton {
    margin-right: 1rem;
    background-color: #f6921e;
    color: white;
  }
  .desglose-boton {
    background-color: #29aae1;
    color: white;
  }
  .title-table {
    display: flex;
    align-items: center;
  }

  .list-elements {
    width: 100%;
  }
  .title-table {
    border: black solid 1px;
    color: black;
    background-color: rgba(8, 166, 165, 0.5);
    padding: 0.3rem !important;
    width: 100%;
    padding: 0.3rem;
  }
  .title-table {
    font-size: 0.75vw;
  }
`;
