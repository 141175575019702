import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faBell } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontawesomeObject } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import UserInfo from "../common/UserInfo";
import alicologo from "../../../images/alicologo.png";

interface navProps {
  showSideBar: boolean;
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
}
interface navStyleProps {
  showSideBar: boolean;
}
export default function Navbar({ showSideBar, setShowSideBar }: navProps) {
  return (
    <NavStyless showSideBar={showSideBar}>
      <div className="menu-bar-container">
        {" "}
        <FontAwesomeIcon
          className="icon-bars"
          icon={faBars}
          onClick={() => {
            setShowSideBar(!showSideBar);
          }}
        />
      </div>
      <img src={alicologo} width={"100"} />
      {/* <div className="user-info-box">
      <UserInfo />
      </div>
      <div className="icon-bars-box">
     <FontAwesomeIcon className="icon-bars" icon = {faBars} onClick= {()=>{
      setShowSideBar(!showSideBar); 
      }}/>
      </div> */}
      <FontAwesomeIcon icon={faBell} className="bell" />
    </NavStyless>
  );
}

const NavStyless = styled.div<navStyleProps>`
  .menu-bar-container {
    margin-left: 2rem;
  }
  .user-info-box {
    display: none;
  }
  .bell {
    font-size: 2rem;
    cursor: pointer;
  }
  width: ${(props) => (props.showSideBar ? "80%" : "100%")};
  left: ${(props) => (props.showSideBar ? "20%" : "0%")};
  height: 5rem;
  position: fixed;
  background-color: ${process.env.REACT_APP_BACKEND_ENDPOINT ==
  "https://corecms.alicomx.com/api"
    ? "white"
    : process.env.REACT_APP_BACKEND_ENDPOINT ==
      "https://dev.corecms.alicomx.com/api"
    ? "#DC3545"
    : "white"};
  z-index: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 2rem;
  .icon-bars {
    color: black;
    font-size: 2rem;
    cursor: pointer;
  }
  @media screen and (min-width: 900px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-bars {
    }
    .user-info-box {
      display: block;
    }
    .icon-bars-box {
      display: none;
    }
  }
`;
