import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import {
  EntryDataResponse,
  EntryService,
  GuideI,
  GuideNumberI,
  ProviderInterfaceOP,
  QuoteItemI,
  operationDataI,
  operationUpdateI,
  updateProviderI,
} from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import IndividualService from "./IndividualService";
import deleteImage from "../../../../images/deletelogo.png";
import agregarImage from "../../../../images/AGREGAR.png";
import { Link, useNavigate } from "react-router-dom";
import { OperationContext } from "../../../../context/operationContext";
import { useCatalogsOperacion } from "../../../../context/OperacionCatalog";
import deleteSymbol from "../../../../images/deletelogo.png";

interface ElementGuideI {
  guiaSelected: GuideNumberI;
  updateGuia: (guiaSelected: GuideNumberI) => void;
  deleteGuia: (guiaSelected: GuideNumberI) => void
}
export default function ElementGuide({
  guiaSelected,
  updateGuia,
  deleteGuia
}: ElementGuideI) {
  const { catalogsDataOperaciones } = useCatalogsOperacion();
  const [listGuides, setListGuide] = useState<GuideI[]>([]);
  const [currentGuia, setCurrentGuia] = useState<GuideNumberI>(guiaSelected);
  useEffect(() => {
    console.log("CATALOGOS DE OPERACIONES::::");
    console.log(catalogsDataOperaciones);
    setListGuide(catalogsDataOperaciones.guides_list);
  }, [catalogsDataOperaciones]);
  useEffect(() => {
    updateGuia(currentGuia);
  }, [currentGuia]);
  const deleteCurrentElement = () =>{
    deleteGuia(currentGuia); 
  }
  return (
    <ElementGuideStyles>
      <SelectListdefault
        style={{
          width: "100%",
          maxWidth: "100%",
        }}
        id="sl6"
        onChange={(e) => {
          //   setLocalTarifa({
          //     ...localTarifa,
          //     fee_currency_identifier: parseInt(e.target.value),
          //   });
          setCurrentGuia({
            ...currentGuia,
            guide_identifier: parseInt(e.target.value),
          });
        }}
      >
        <option value="" disabled selected>
          Guias
        </option>

        {listGuides.length > 0
          ? listGuides.map((item) => {
              return (
                <option
                  key={item.provider_identifier}
                  value={item.provider_identifier}
                  selected={
                    item.provider_type_identifier ==
                    currentGuia.guide_identifier
                  }
                >
                  {item.provider_name}
                </option>
              );
            })
          : null}
      </SelectListdefault>
      <img src={deleteSymbol} 
      onClick={deleteCurrentElement}
      alt="" width="25" className="delete-symbol" />
    </ElementGuideStyles>
  );
}

const ElementGuideStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .delete-symbol {
    margin-left: 1rem;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: rgb(8, 166, 165) solid 2px;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  min-height: 38.4px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
