import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import edit from "../../../images/edit.png";
import deletelogo from "../../../images/deletelogo.png";
import { PaymentSearchI } from "./InterfacesContabilidad";
import ElementPago from "./Components/ElementPago";
interface ListResultsPagoI {
  listResultSearch: PaymentSearchI[];
  setListResultsSearch: React.Dispatch<React.SetStateAction<PaymentSearchI[]>>
}
export default function ListResultsPago({
  listResultSearch,
  setListResultsSearch,
}: ListResultsPagoI) {
  return (
    <ListResultsPagoStyle>
      
      {
        listResultSearch.length != 0 &&  <div className="container-header">
        <div className="header-table">
          <div>
            <h3>Fecha</h3>
          </div>
          <div>
            <h3>Agencia</h3>
          </div>
          <div>
            <h3>Grupo</h3>
          </div>
          <div>
            <h3>Prestador</h3>
          </div>
          <div>
            <h3>Entrada</h3>
          </div>
          <div>
            <h3>Importe Pesos</h3>
          </div>
          <div>
            <h3>Importe Dolares</h3>
          </div>
          <div>
            <h3>Banco</h3>
          </div>
          <div>
            <h3>Cuenta</h3>
          </div>
          <div className="last">
            <h3>Transferencia</h3>
          </div>
          <div>
            <h3>Concepto</h3>
          </div>
        </div>
        <div className="second-box">
          {/* <img src={deletelogo} alt=""  width={25}/>
            <img src={edit} alt="" width={25} /> */}
        </div>
      </div>
      }
     
      {listResultSearch.map((curentPago, index) => {
        return <ElementPago key={curentPago.payment_identifier}
        curentPago={curentPago} 
        listResultSearch = {listResultSearch}
        setListResultsSearch={setListResultsSearch}

        />;
      })}

    </ListResultsPagoStyle>
  );
}

const ListResultsPagoStyle = styled.div`
  margin-top: 2rem;

  .input-dates {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: 0 auto;
  }
  .header-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: black solid 1px;
    justify-content: center;
    width: 90%;
  }
  .header-table div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-table div h3 {
    text-align: center;
    font-size: 0.85vw;
    padding: 0.5rem;
  }
  .container-header {
    width: 98%;
    display: flex;
    margin: 0 auto;
  }
  .second-box {
    width: 5%;
   
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;
