export const translationsThree = {
    es: {
      greeting: "Hola",
      welcome: "Bienvenido",
      // ...otras traducciones en español
    },
    en: {
      greeting: "Hello",
      welcome: "Welcome",
      // ...other translations in English
    },
    fr: {
      greeting: "Bonjour",
      welcome: "Bienvenue",
      // ...other translations in French
    },
  };

  export const translations = {
    es: {
      header_table: {
        date_creation: "Fecha de creación",
        date_start_travel: "Fecha Inicio de Viaje",
        name_passengers: "Nombre de los Pasajeros",
        number_passengers: "Número de pasajeros",
        creator: "Elaboró",
        number_rooms: "Número de habitaciones",
        room_price: "Precio de habitación"
      },
      hotels_table: {
        title_hotels: "Hoteles",
        city: "Ciudad",
        status: "Estatus",
        arrive: "Llegada",
        departure: "Salida",
        nights: "Noches",
        SGL: "SLG",
        DBL: "DBL",
        TPL: "TPL",
        number_rooms: "Número de habitaciones",
        price_rooms: "Precio de habitación",
        total: "Total",
      },
      hotels_optionals_table: {
        title_services: "Servicios extra",
        city: "Ciudad",
        status: "Estatus",
        arrive: "Llegada",
        departure: "Salida",
        number_passenger: "Número de pasajeros",
        prices: "Precios",
        total: "total",
      },
      box_page_limit_payment: {
        text: `Nosotros debemos recibir el pago en la fecha indicada para garantizar
        las reservación de hoteles y servicios, en caso de que el depósito
        o la transferencia bancaria no se haya realizado para la fecha indicada
        no nos haremos responsables por las cancelaciones de los
        servicios descritos en esta pro -forma`,
        limit_payment: "Tiempo Límite para el Pago:",
        amount: "Monto de pago"
      },
      small_box_address: {
        address: "Dirección",
      },
      box_payment_combobox: {
        text: "FAVOR DE REALIZAR SU PAGO EN LA SIGUIENTE CUENTA",
      },
      box_instructions_proforma: {
        title: `
        Esta confirmación ProForma está basada en los servicios solicitados.
        La factura final se ajustará de acuerdo al número total de pasajeros, hoteles y servicios solicitados de última hora.
        `,
        subtitle: "IMPORTANTE",
        one: `
        1. Todos los precios están sujetos a cambios mientras no se haya recibido
        el pago parcial o total en las fechas estipuladas.
        `,
        two: "2. En México, las habitaciones triples NO tienen 3 camas. Los hoteles solo tienen 2 camas.",
        three: `
        3. No incluye cargos por cambios en el programa o reservaciones causados 
        por eventos de fuerza mayor.
        `,
        four: `
        4. Las tinas en los baños no están garantizadas a menos que sean solicitadas al hotel.
        `,
        five: `
        5. Todos los servicios serán cancelados automáticamente a menos que el
        pago sea recibido dentro de los límites de tiempo para pagar indicados en la
        proforma. No nos responsabilizamos por informar sobre cancelaciones, ya que
        los servicios se cancelan automáticamente con los proveedores si no están pagados.
        `,
        six: `
        6. Consideramos que un pago ha sido efectuado por el cliente siempre y 
        cuando esté acreditado en nuestra cuenta bancaria. Una copia de banco
        no se considera como pago.
        `,
        seven: `
        7. Favor de revisar nuestras políticas de cancelación.
        `,
        refund_policy: `
        En caso de cancelaciones por parte del pasajero y/o agencia y de solicitar el reembolso, se hará un cargo de 30 USD correspondientes al costo de la transferencia bancaria,
        además de los cargos por cancelación que apliquen según las políticas de cada hotel.
        `,
      },
      extra_fields: {
        limit_date: "Fecha límite",
        required_rooms: "Habitaciones requeridas",
        accomodation: "Acomodo",
        remaining_payment: "Pago restante",
        important: "IMPORTANTE",
        fees_refund: "CARGOS POR REEMBOLSO",
        title_hotels: "Confirmación de hoteles y servicios"
      },
      payment_information_card: {
        title_combobox: "Tarjetas de pago",
        name: "NOMBRE",
        bank_name: "BANCO",
        branch: "SUCURSAL",
        account: "CUENTA",
        clabe: "CLABE",
        swift: "SWIFT",
        intermediary_bank_usa: "BANCO INTERMEDIARIO EN USA"
      }
    },
    en: {
      header_table: {
        date_creation: "Creation Date",
        date_start_travel: "Travel Start Date",
        name_passengers: "Passenger Names",
        number_passengers: "Number of Passengers",
        creator: "Created by",
        number_rooms: "Number of rooms",
        room_price: "Room price"
      },
      hotels_table: {
        title_hotels: "Hotels",
        city: "City",
        status: "Status",
        arrive: "Arrival",
        departure: "Departure",
        nights: "Nights",
        SGL: "SGL",
        DBL: "DBL",
        TPL: "TPL",
        number_rooms: "Number of Rooms",
        price_rooms: "Room Price",
        total: "Total",
      },
      hotels_optionals_table: {
        title_services: "Extra Services",
        city: "City",
        status: "Status",
        arrive: "Arrival",
        departure: "Departure",
        number_passenger: "Number of Passengers",
        prices: "Prices",
        total: "Total",
      },
      box_page_limit_payment: {
        text: `We must receive the payment by the indicated date to guarantee
        hotel and service reservations. If the deposit or bank transfer
        is not made by the indicated date, we will not be responsible for
        cancellations of the services described in this proforma.`,
        limit_payment: "Payment Deadline:",
        amount: "payment amount",
        small_box_address: {
          address: "Address",
        },
        box_payment_combobox: {
          text: "PLEASE MAKE YOUR PAYMENT TO THE FOLLOWING ACCOUNT",
        },
        box_instructions_proforma: {
          title: `
      This ProForma confirmation is based on the requested services.
      The final invoice will be adjusted according to the total number of passengers, hotels, and last-minute requested services.
      `,
          subtitle: "IMPORTANT",
          one: `
      1. All prices are subject to change until partial or full payment is received
      on the stipulated dates.
      `,
          two: "2. In Mexico, Triple rooms do NOT have 3 beds. Hotels only have 2 beds.",
          three: `
      3. Does not include charges for program changes or reservations caused by
      force majeure events.
      `,
          four: `
      4. Bathtubs in bathrooms are not guaranteed unless requested from the hotel.
      `,
          five: `
      5. All services will be automatically canceled unless payment is received
      within the specified payment deadlines stated in the proforma. We are not responsible for informing about cancellations, as
      services are automatically canceled with providers if not paid.
      `,
          six: `
      6. We consider a payment to be made by the client only if it is credited to our bank account. A bank statement
      is not considered as payment.
      `,
          seven: `
      7. Please check our cancellation policies.
      `,
          refund_policy: `
      In case of cancellations by the passenger and/or agency and a refund is requested, a charge of 30 USD will be made for the bank transfer fee,
      in addition to cancellation charges applied according to the policies of each hotel.
      `,
        },
      },
      small_box_address: {
        address: "Address",
      },
      box_payment_combobox: {
        text: "PLEASE MAKE YOUR PAYMENT TO THE FOLLOWING ACCOUNT",
      },
      box_instructions_proforma: {
        title: `
        This ProForma confirmation is based on the requested services.
        The final invoice will be adjusted according to the total number of passengers, hotels, and last-minute requested services.
        `,
        subtitle: "IMPORTANT",
        one: `
        1. All prices are subject to change until partial or full payment is received
        on the stipulated dates.
        `,
        two: "2. In Mexico, Triple rooms do NOT have 3 beds. Hotels only have 2 beds.",
        three: `
        3. Does not include charges for program changes or reservations caused by
        force majeure events.
        `,
        four:`
        4. Bathtubs in bathrooms are not guaranteed unless requested from the hotel.
        ` ,
        five:`
        5. All services will be automatically canceled unless payment is received
        within the specified payment deadlines stated in the proforma. We are not responsible for informing about cancellations, as
        services are automatically canceled with providers if not paid.
        `,
        six: `
        6. We consider a payment to be made by the client only if it is credited to our bank account. A bank statement
        is not considered as payment.
        `,
        seven: `
        7. Please check our cancellation policies.
        `,
        refund_policy: `
        In case of cancellations by the passenger and/or agency and a refund is requested, a charge of 30 USD will be made for the bank transfer fee,
        in addition to cancellation charges applied according to the policies of each hotel.
        `
      },
      extra_fields: {
        limit_date: "Deadline Date",
        required_rooms: "Required Rooms",
        accomodation: "Accommodation",
        remaining_payment: "Remaining Payment",
        important: "IMPORTANT",
        fees_refund: "REFUND FEES",
        title_hotels: "Hotels and Services Confirmation"
      },
      payment_information_card: {
        title_combobox: "Payment Cards",
        name: "NAME",
        bank_name: "BANK",
        branch: "BRANCH",
        account: "ACCOUNT",
        clabe: "CLABE",
        swift: "SWIFT",
        intermediary_bank_usa: "INTERMEDIARY BANK IN USA"
      }
    },
    fr: {
      header_table: {
        date_creation: "Date de création",
        date_start_travel: "Date de début du voyage",
        name_passengers: "Noms des passagers",
        number_passengers: "Nombre de passagers",
        creator: "Créé par",
        number_rooms: "Nombre de chambres",
        room_price: "Prix de la chambre"
      },
      hotels_table: {
        title_hotels: "Hôtels",
        city: "Ville",
        status: "Statut",
        arrive: "Arrivée",
        departure: "Départ",
        nights: "Nuits",
        SGL: "SLG",
        DBL: "DBL",
        TPL: "TPL",
        number_rooms: "Nombre de chambres",
        price_rooms: "Prix de la chambre",
        total: "Total",
      },
      hotels_optionals_table: {
        title_services: "Services supplémentaires",
        city: "Ville",
        status: "Statut",
        arrive: "Arrivée",
        departure: "Départ",
        number_passenger: "Nombre de passagers",
        prices: "Prix",
        total: "Total",
      },
      box_page_limit_payment: {
        text: `Nous devons recevoir le paiement à la date indiquée pour garantir
        les réservations d'hôtels et de services. Si le dépôt ou le virement
        bancaire n'est pas effectué d'ici la date indiquée, nous ne serons pas
        responsables des annulations des services décrits dans cette proforma.`,
        limit_payment: "Date Limite de Paiement:",
        amount: "montant du paiement"
      },
      small_box_address: {
        address: "Adresse",
      },
      box_payment_combobox: {
        text: "VEUILLEZ EFFECTUER VOTRE PAIEMENT SUR LE COMPTE SUIVANT",
      },
      box_instructions_proforma: {
        title: `
        Cette confirmation ProForma est basée sur les services demandés.
        La facture finale sera ajustée en fonction du nombre total de passagers, d'hôtels et de services demandés à la dernière minute.
        `,
        subtitle: "IMPORTANT",
        one: `
        1. Tous les prix sont sujets à changement tant que le paiement partiel ou total n'a pas été reçu
        aux dates stipulées.
        `,
        two: "2. Au Mexique, les chambres triples n'ont PAS 3 lits. Les hôtels n'ont que 2 lits.",
        three: `
        3. Ne comprend pas les frais de modification de programme ou les réservations causés par des
        événements de force majeure.
        `,
        four: `
        4. Les baignoires dans les salles de bains ne sont pas garanties à moins d'être demandées à l'hôtel.
        `,
        five: `
        5. Tous les services seront automatiquement annulés à moins que le paiement ne soit reçu
        dans les délais de paiement spécifiés dans la proforma. Nous ne sommes pas responsables d'informer sur les annulations, car
        les services sont automatiquement annulés auprès des fournisseurs s'ils ne sont pas payés.
        `,
        six: `
        6. Nous considérons qu'un paiement a été effectué par le client uniquement s'il est crédité sur notre compte bancaire. Un relevé bancaire
        n'est pas considéré comme un paiement.
        `,
        seven: `
        7. Veuillez vérifier nos politiques d'annulation.
        `,
        refund_policy: `
        En cas d'annulation par le passager et/ou l'agence et de demande de remboursement, des frais de 30 USD seront facturés pour les frais de transfert bancaire,
        en plus des frais d'annulation appliqués selon les politiques de chaque hôtel.
        `,
      },
      extra_fields: {
        limit_date: "Date limite",
        required_rooms: "Chambres requises",
        accomodation: "Hébergement",
        remaining_payment: "Paiement restant",
        important: "IMPORTANT",
        fees_refund: "FRAIS DE REMBOURSEMENT",
        title_hotels: "Confirmation des hôtels et des services"
      },
      payment_information_card: {
        title_combobox: "Cartes de paiement",
        name: "NOM",
        bank_name: "BANQUE",
        branch: "AGENCE",
        account: "COMPTE",
        clabe: "CLABE",
        swift: "SWIFT",
        intermediary_bank_usa: "BANQUE INTERMÉDIAIRE AUX ÉTATS-UNIS"
      }
    },
  };