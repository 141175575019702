import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ResultSearchGrupos from "./ResultSearchGrupos";
import { OperationSearchElementI } from "../InterfacesContabilidad";

interface TableSearchI {
  listResulsGroups: OperationSearchElementI[];
}

export default function TableSearch({ listResulsGroups }: TableSearchI) {
  return (
    <TableSearchStyle>
        <br /><br />
      <div className="table-container">
        {listResulsGroups.map((grupo, index) => {
          return (
            <ResultSearchGrupos
              key={grupo.operation_identifier}
              grupo={grupo}
              indice = {index}            />
          );
        })}
      </div>
    </TableSearchStyle>
  );
}

const TableSearchStyle = styled.div`
 
 .table-container{
    width: 80%;
    margin: 0 auto;
    overflow-y: auto;
    max-height: 80vh;
 }
  .table-header {
    border: rgb(8, 166, 165) solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr 0.7fr;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
 
    background-color: rgb(8, 166, 165);
    
  }
  .table-header div h3 {
    text-align: center;
    font-size: 0.85vw;
    font-weight: 300;
    color: white;
  }
`;
