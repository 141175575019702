import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ReembolsoList from "./ReembolsoList";
import { fetchServer } from "../../../../services/fetchServer";

export default function Reembolsos() {

  return (
    <ReembolsoStyle>
        <ReembolsoList/>
    </ReembolsoStyle>
  )
}


const ReembolsoStyle = styled.div`

`