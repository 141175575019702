import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Provider } from "../../../../../interfaces/interfaces";
import { ApiResponse } from "../../../../../interfaces/interfaces";
import {
  ApiResponseISearch,
  BusquedaPagosI,
  OperationISearch,
  PaymentSearch,
  ProviderDataSearch,
  RootObjectSearch,
} from "../../InterfacesContabilidad";
import { fetchServer } from "../../../../../services/fetchServer";
import { AppContext } from "../../../../../context/appContext";

interface searchBarInterface {
  results: Provider[];
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setData: React.Dispatch<
    React.SetStateAction<{
      provider_list: Provider[];
    }>
  >;
  setResultsList: React.Dispatch<
    React.SetStateAction<{
      provider_list: Provider[];
    }>
  >;
  busquedaPagos: BusquedaPagosI;
  resultsPagos: PaymentSearch[];
  setResultsPagos: React.Dispatch<React.SetStateAction<PaymentSearch[]>>;
  providerData: ProviderDataSearch;
  setProviderData: React.Dispatch<React.SetStateAction<ProviderDataSearch>>;
  selectedOption: string;
  listSearchAgencias: OperationISearch[];
  setListSearchAgencias: React.Dispatch<React.SetStateAction<OperationISearch[]>>
  }
export default function SearchResultsClientE({
  results,
  setSearchText,
  setData,
  setResultsList,
  busquedaPagos,
  setResultsPagos,
  providerData,
  setProviderData,
  selectedOption,
  listSearchAgencias, 
  setListSearchAgencias
}: searchBarInterface) {
  const { setShowLoader } = useContext(AppContext);

  const handleClick = (elementProvider: Provider) => {
    console.log("Diste click en la busqueda!!");
    console.log(elementProvider);
    const result: Provider[] = [elementProvider];
    console.log(result);

    setSearchText(elementProvider.name);
    // setCatalogElements(result);
    setData({
      provider_list: result,
    });

    // setData(resultsSearch);

    setResultsList({
      provider_list: result,
    });
    if (selectedOption == "Prestador") {
      bringResults(elementProvider.identifier);
    } else if (selectedOption == "Agencia") {
      // alert("Buscando agencia.");
      bringResultsAgencia(elementProvider.identifier); 
    }
  };
  const bringResults = async (providerId: number) => {
    console.warn("Cuerpo de busqueda");
    const bodySearch = {
      ...busquedaPagos,
      provider_identifier: providerId,
      payment_type_identifier: 1, // 1 pendiente, 2 pagado
      account_status_type_identifier:
        busquedaPagos?.account_status_type_identifier?.trim()
          ? busquedaPagos.account_status_type_identifier
          : null, // proveedor o agencia
      search_parameter: null,
      initial_date: busquedaPagos.initial_date.trim()
        ? busquedaPagos.initial_date
        : null,
      final_date: busquedaPagos.final_date.trim()
        ? busquedaPagos.final_date
        : null,
    };
    setShowLoader(true);
    const resultsPagos: RootObjectSearch = await fetchServer(
      "/accounting/accountStatus/search",
      "post",
      bodySearch
    );
    if (resultsPagos.data.payments) {
      if (resultsPagos.data.payments.length > 0) {
        alert("Se accedió a los pagos");
        setShowLoader(false);
        setResultsPagos(resultsPagos.data.payments);
        setProviderData(resultsPagos.data.provider_data);
        return;
      } else {
        alert("Este proveedor no contiene pagos");
        setShowLoader(false);
        setResultsPagos([]);
        return;
      }
    } else {
      alert("Este proveedor no contiene pagos");
      return;
    }
    console.warn("--", resultsPagos);
    console.log(resultsPagos.data.provider_data);
    console.log(resultsPagos.data.payments);
    console.log(resultsPagos.data.providers_list);
  };
  const bringResultsAgencia = async (providerId: number) => {
    console.warn("Cuerpo de busqueda");
    const bodySearch = {
      provider_identifier: null, 
      search_parameter: providerId,

    };
    setShowLoader(true);
    const resultsPagos: ApiResponseISearch = await fetchServer(
      "/accounting/agencyAccountStatus/search",
      "post",
      bodySearch
    ); 
      console.warn("Respuesta de busqueda");
      console.log(resultsPagos.data.provider_data);
      console.log(resultsPagos.data.operation_list);   
      setShowLoader(false)
      console.warn("Respuesta de busqueda");
      console.log(resultsPagos.data.provider_data);
      console.log(resultsPagos.data.operation_list);   
      setShowLoader(false)
      if(resultsPagos.data.operation_list){
        if(resultsPagos.data.operation_list.length > 0 ){
          setListSearchAgencias(resultsPagos.data.operation_list); 
        }else{
          alert("Esta agencia no contiene pagos");
          setListSearchAgencias([]); 
        }
      }else{
        alert("Esta agencia no contiene pagos");
        setListSearchAgencias([]); 
      }
    // if (resultsPagos.data.payments) {
    //   if (resultsPagos.data.payments.length > 0) {
    //     alert("Se accedió a los pagos");
    //     setShowLoader(false);
    //     setResultsPagos(resultsPagos.data.payments);
    //     setProviderData(resultsPagos.data.provider_data);
    //     return;
    //   } else {
    //     alert("Este proveedor no contiene pagos");
    //     setShowLoader(false);
    //     setResultsPagos([]);
    //     return;
    //   }
    // } else {
    //   return;
    // }

    
  };
  return (
    <SearchResultsClientStyles>
      {results.map((result: Provider) => (
        <div
          //   key={result.clave}
          className="search-result"
          onClick={() => {
            handleClick(result);
          }}
        >
          <p>{result.name}</p>
        </div>
      ))}
    </SearchResultsClientStyles>
  );
}

const SearchResultsClientStyles = styled.div`
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid black;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  .search-result {
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: lightgray;
    }
  }
  width: 80%;
  margin: 0 auto;
`;
