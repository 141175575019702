import { v4 as uuidv4 } from "uuid";
import { servicioDayElementI } from "../../../context/InterfacesContext";
export function getDaysDifference(startDate: string, endDate: string): number {
 
    const oneDay = 24 * 60 * 60 * 1000; // Número de milisegundos en un día
  
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    // Establecemos las horas a las 0:00:00 para obtener la fecha completa del día
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
  
    const diffDays = Math.round(Math.abs((end.getTime() - start.getTime()) / oneDay)) + 1;
  
    return isNaN(diffDays) ? 0 : diffDays;
  }
  export function areDatesValid(startDateStr: string, endDateStr: string): boolean {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
  
    // Verificar si las fechas son válidas
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return false;
    }
  
    // Verificar si la fecha de inicio es mayor a la fecha de fin
    if (startDate.getTime() > endDate.getTime()) {
      return false;
    }
  
    return true;
  }
  export function isValidDate(dateString: string): boolean {
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const matches = dateString.match(dateRegex);
  
    if (!matches) return false;
  
    const day = parseInt(matches[1]);
    const month = parseInt(matches[2]);
    const year = parseInt(matches[3]);
  
    // Check if the parsed day, month, and year are within valid ranges
    if (
      isNaN(day) || isNaN(month) || isNaN(year) ||
      day < 1 || day > 31 || month < 1 || month > 12 || year < 1000 || year > 9999
    ) {
      return false;
    }
  
    // Check if the date is a valid Date object (considering leap years, etc.)
    const date = new Date(year, month - 1, day);
    const isValidDate = date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  
    return isValidDate;
  }

  export function createServicioPasajeroListOpen(numberDays: number): servicioDayElementI[] {
  
    const servicioPasajeroList: servicioDayElementI[] = [];

  
    for (let i = 0; i <= numberDays; i++) {

      // const date = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
      const dateString = "";
  
      // Evitar agregar el primer elemento al arreglo
      if (i > 0) {
        let identificador = uuidv4();
        let identifierDay = uuidv4();
        const servicioDayElement: servicioDayElementI = {
          identifierDay: identifierDay,
          validityStartDay: dateString,
          validityEndDay: dateString,
          category: 0,
          lendertype: 0,
          ocupacion: 0,
          providerType: 0,
          serviceType: 0,
          tarifa: 0,
          isAvailable: true,
          travelRoute: "",
          serviciosIndividuales:[],
          service_identifier: 1,
   
        };
  
        servicioPasajeroList.push(servicioDayElement);
      }
    }
  
    return servicioPasajeroList;
  }

  export function createServicioPasajeroList(startDate: string, endDate: string): servicioDayElementI[] {
    const diff = getDaysDifference(startDate, endDate); // Obtener la diferencia de días
    const servicioPasajeroList: servicioDayElementI[] = [];
  
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
  
    for (let i = 0; i <= diff; i++) {

      const date = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
      const dateString = date.toISOString().split('T')[0];
  
      // Evitar agregar el primer elemento al arreglo
      if (i > 0) {
        let identificador = uuidv4();
        let identifierDay = uuidv4();
        const servicioDayElement: servicioDayElementI = {
          identifierDay: identifierDay,
          validityStartDay: dateString,
          validityEndDay: dateString,
          category: 0,
          lendertype: 0,
          ocupacion: 0,
          providerType: 0,
          serviceType: 0,
          tarifa: 0,
          isAvailable: true,
          travelRoute: "",
          serviciosIndividuales:[],
          service_identifier: 1,
     
        };
  
        servicioPasajeroList.push(servicioDayElement);
      }
    }
  
    return servicioPasajeroList;
  }

  export function createServicioPasajeroListPlus(startDate: string, endDate: string,servicioList: servicioDayElementI[]): servicioDayElementI[] {
    const diff = getDaysDifference(startDate, endDate); // Obtener la diferencia de días
    const servicioPasajeroList: servicioDayElementI[] = [];
  
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
    let firstArray: servicioDayElementI[] = [];
    let secondArray: servicioDayElementI[] = [];
    let thirdArray: servicioDayElementI[] = [];
   firstArray =  servicioList.map((currentDay, i)=>{
    const date = new Date(start.getTime() + (i + 1) * 24 * 60 * 60 * 1000);
      const dateString = date.toISOString().split('T')[0];

    const diaActual: servicioDayElementI = {
      ...currentDay,
      validityStartDay: dateString,
      validityEndDay: dateString
    }
    return diaActual          
   })
   if(servicioList.length == diff){
    return firstArray; 
   }else if(servicioList.length > diff){
    return firstArray.slice(0, diff);
   }
    for (let i = firstArray.length + 1; i <= diff; i++) {

      const date = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
      const dateString = date.toISOString().split('T')[0];
  
      // Evitar agregar el primer elemento al arreglo
      if (i > 0) {
        let identificador = uuidv4();
        let identifierDay = uuidv4();
        const servicioDayElement: servicioDayElementI = {
          identifierDay: identifierDay,
          validityStartDay: dateString,
          validityEndDay: dateString,
          category: 0,
          lendertype: 0,
          ocupacion: 0,
          providerType: 0,
          serviceType: 0,
          tarifa: 0,
          isAvailable: true,
          travelRoute: "",
          serviciosIndividuales:[],
          service_identifier: 1, 
          
        };
  
        servicioPasajeroList.push(servicioDayElement);
        secondArray.push(servicioDayElement); 
      }
    }
    thirdArray = [...firstArray, ...secondArray];
    return thirdArray;
  }

  export function validarFormatoFecha(fecha: string): boolean {
    // Expresión regular para validar el formato YYYY-MM-DD
    const formatoFechaRegExp = /^\d{4}-\d{2}-\d{2}$/;
  
    // Verificar si la cadena coincide con el formato esperado
    if (!formatoFechaRegExp.test(fecha)) {
      return false;
    }
  
    // Extraer año, mes y día de la cadena
    const partesFecha = fecha.split('-');
    const year = parseInt(partesFecha[0], 10);
    const month = parseInt(partesFecha[1], 10);
    const day = parseInt(partesFecha[2], 10);
  
    // Verificar si los valores son válidos para año, mes y día
    if (year < 1000 || year > 9999 || month < 1 || month > 12 || day < 1 || day > 31) {
      return false;
    }
  
    return true;
  }
  
  export function agregarDiasAFecha(fecha: string, dias: number): string {
    // Convertir la cadena de fecha en un objeto Date
    const fechaOriginal = new Date(fecha);
  
    // Calcular la nueva fecha sumando o restando días
    fechaOriginal.setDate(fechaOriginal.getDate() + dias);
  
    // Formatear la nueva fecha en el formato "YYYY-MM-DD"
    const nuevoAnio = fechaOriginal.getFullYear();
    const nuevoMes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0');
    const nuevoDia = fechaOriginal.getDate().toString().padStart(2, '0');
  
    const nuevaFechaFormateada = `${nuevoAnio}-${nuevoMes}-${nuevoDia}`;
    return nuevaFechaFormateada;
  }
  
  export function obtenerFechaDesdeString(fechaCompleta: string): string {
    // Dividir la cadena en fecha y hora
    const partes = fechaCompleta.split(' ');
    const fecha = partes[0]; // "31/12/2023"
  
    return fecha;
  }