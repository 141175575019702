import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { AppProvider } from "./context/appContext";
import { OperationProvider } from "./context/operationContext";
import { LanguageProvider } from "./alico/pages/Operaciones/Proforma/contextLanguage/LanguageContext";

library.add(faCoffee);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <LanguageProvider>
        <OperationProvider>
          <App titulo="React and Typescript" />
        </OperationProvider>
      </LanguageProvider>
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
