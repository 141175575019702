import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import WholeSearch from "./WholeSearch";

export default function ContabilidadSearch() {
  return (
    <ContabilidadSearchStyles>
<br /><br />
      <WholeSearch/>
      
    </ContabilidadSearchStyles>
  )
}

const ContabilidadSearchStyles = styled.div``;