import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import HojaControl from "../../Operaciones/HojaControl/HojaControl";
import HojaControlAux from "./HojaControlAux";
import FormAuxiliares from "./FormAuxiliares";
import { TotalesOperacionI } from "./AuxiliaresInterface";


export default function Auxiliares() {
    const [auxiliar, setAuxiliar] = useState(0); 
    const [totalOperacion, setTotalOperacion] =  useState<TotalesOperacionI>({
        total_operacion_usd: 0,
        total_operacion_mxn: 0,
        total_proforma: 0,
        currency_identifier: 1, 
        saveMode: 1, 
        datosGeneralesOperacion: null,
        tipoCambio: 0,
        status_identifier: 0, 
    }); 
    const [statusOperacion, setStatusOperacion] = useState<number>(0);
    useEffect(()=>{
        // alert(totalOperacion.saveMode); 
    },[totalOperacion.saveMode])
    const saveHojaControl = () =>{
      console.log("Guardando hoja de control"); 
      console.log(totalOperacion); 
    }
  return (
    <AuxiliaresStyles>
  
        <div className="aux-container">
            <HojaControlAux
            totalOperacion = {totalOperacion}
            setTotalOperacion = {setTotalOperacion}
            statusOperacion = {statusOperacion}
            setStatusOperacion = { setStatusOperacion}
            />
            <br />
            <h4>Tipo de moneda proforma: {totalOperacion.currency_identifier == 1 ? "Dolar": "Peso"}</h4>
            <br />
       
            <FormAuxiliares
            statusOperacion= {statusOperacion}
              totalOperacion = {totalOperacion}
              setTotalOperacion = {setTotalOperacion}
            />
      
         
        </div>
    </AuxiliaresStyles>
  )
}


const AuxiliaresStyles = styled.div`
    .aux-container{
        width: 85%;
        margin: 0 auto;
    }       
`