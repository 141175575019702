import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  setCurrentPage
}) => {
  const [pageNumbers, setPageNumbers] = useState<(number | string)[]>([]);

  useEffect(() => {
    const maxPageNumbers = 4;
    const middle = Math.floor(maxPageNumbers / 2);

    if (totalPages <= maxPageNumbers) {
      setPageNumbers(Array.from({ length: totalPages }, (_, i) => i + 1));
    } else if (currentPage <= middle) {
      setPageNumbers([1, 2, 3, "...", totalPages]);
    } else if (currentPage >= totalPages - middle) {
      setPageNumbers([1, "...", totalPages - 2, totalPages - 1, totalPages]);
    } else {
      setPageNumbers([
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      ]);
    }
  }, [currentPage, totalPages]);

  const handlePageChange = (pageNumber: number | string) => {
    if(typeof(pageNumber) == "string"){

        return;
    }
        
    onPageChange(pageNumber);
    setCurrentPage(pageNumber); 
  };

  return (
    <PaginationStyles>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="boton-paginacion left"
        >
          Anterior
        </button>
        {pageNumbers.map((pageNumber, index) => (
          <span
            key={index}
            className={`page-number ${pageNumber === currentPage ? 'active' : ''}`}
            onClick={() => handlePageChange(pageNumber as number)}
          >
            {pageNumber}
          </span>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="boton-paginacion right"
        >
          Siguiente
        </button>
      </div>
    </PaginationStyles>
  );
};

export default Pagination;

const PaginationStyles = styled.div`
.page-number {
  display: inline-block;
  margin: 4px;
  padding: 8px;
  border-radius: 50%;
  background-color: #f2f2f2;
  cursor: pointer;

}

.page-number.active {
  background-color: rgb(8, 166, 165); /* Cambia este color al que desees para destacar el elemento seleccionado */
  color: #fff; /* Cambia el color del texto al que desees */
  font-weight: bold;
}
.boton-paginacion{
    background-color: rgb(8, 166, 165);
    padding: 0.8rem; 
    color: white;
    outline: none;
    border: none;
  cursor: pointer;
}
.left{
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
}
.right{
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
}
`;
