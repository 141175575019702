import { ValiditySearch, ValiditySearchDos } from "../../../../interfaces/Cotizacion";
import { ServiceFees } from "../../../../interfaces/GuardadoTypes";

export function thirdValidation(servicesFees: ServiceFees[] | null): ServiceFees[] {
    console.log('EJECUTANDO SECOND VALIDATION-----------------------------------------: ');
    if(!servicesFees) return [];

    const arrayServicios = servicesFees.filter((fee, index)=>{
        return   fee.service_type_identifier !== 0         
    });
    console.log('RESULTADO DEL ARRAY A ENVIAR-----------------------------------------: ');
    console.log(arrayServicios); 
    if(arrayServicios.length == 0){
        return []; 
    }else{
        return arrayServicios
    }

  
  }

  export function verificarFechaEnIntervalo(fecha: string, vigencias: ValiditySearch[] | undefined): boolean {
    if(!vigencias){
      console.log("Las vigencias no son validas"); 
      return false;
    }
    if(!fecha.trim()){
      /////Aquí significa que estamos evaluando una serie: 
      return true; 
    }
    console.warn("Vigencias y fechas analizadas:"); 
    console.log(fecha); 
    console.log(vigencias); 
    
    const fechaComparar = new Date(fecha);
  
    for (const vigencia of vigencias) {
    console.log("FEcha sin parsear: ");
    console.log(vigencia.validity_start_date); 
    console.log(vigencia.validity_end_date);  
      const inicioVigencia = new Date(convertirFechaHora(vigencia.validity_start_date));
      const finVigencia = new Date(convertirFechaHora(vigencia.validity_end_date));
      console.log("FECHA  A ANALIZAR sin parsear: ");
      console.log(fecha);
      console.log("Fecha formateada 1: ");
      console.log(obtenerFechaSinHora(vigencia.validity_start_date));
      console.log("Fecha formateada 2: ");
      console.log(obtenerFechaSinHora(vigencia.validity_end_date));
      console.log("fechaComparar >= inicioVigencia && fechaComparar <= finVigencia");
      console.log(fechaComparar >= inicioVigencia, " && " ,fechaComparar <= finVigencia); 

      console.log("fechaComparar <= finVigencia");  
      console.log(inicioVigencia, " <= ", finVigencia); 
      
      console.log(fechaComparar, " <= ",  finVigencia)
      if (fechaComparar >= inicioVigencia && fechaComparar <= finVigencia) {
        return true; // La fecha está dentro de al menos una vigencia
      }
    }
  
    return false; // La fecha no está dentro de ninguna vigencia
    
  }
  
  export function obtenerFechaSinHora(fechaStr: string): string{
    return fechaStr.split(' ')[0];
  

  }

  export function verificarFechaEnIntervaloDos(fecha: string, vigencias: ValiditySearchDos[] | undefined): boolean {
    if(!vigencias){
      console.log("Las vigencias no son validas"); 
      return false;
    }
    const fechaComparar = new Date(fecha);
  
    for (const vigencia of vigencias) {
    console.log("FEcha sin parsear: ");
    console.log(vigencia.validity_startdate); 
    console.log(vigencia.validity_enddate);  
    const finVigencia = new Date(transformarFecha(vigencia.validity_enddate));

      const inicioVigencia = new Date(transformarFecha(vigencia.validity_startdate));
     
      console.log("FECHA  A ANALIZAR sin parsear: ");
      console.log(fecha);
      console.log("Fecha formateada 1: ");
      console.log(transformarFecha(vigencia.validity_startdate));
      console.log("Fecha formateada 2: ");
      console.log(transformarFecha(vigencia.validity_enddate));
      console.log("fechaComparar >= inicioVigencia && fechaComparar <= finVigencia");
      console.log(fechaComparar >= inicioVigencia, " && " ,fechaComparar <= finVigencia); 

      console.log("fechaComparar <= finVigencia");  
      console.log(inicioVigencia, " <= ", finVigencia); 
      
      console.log(fechaComparar, " <= ",  finVigencia)
      if (fechaComparar >= inicioVigencia && fechaComparar <= finVigencia) {
        return true; // La fecha está dentro de al menos una vigencia
      }
    }
  
    return false; // La fecha no está dentro de ninguna vigencia
    
  }
  
  function transformarFecha(inputDate: string): string {
    // Verificar si la entrada es una fecha válida en formato ISO 8601
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!isoDateRegex.test(inputDate)) {
      throw new Error("La cadena de entrada no es una fecha válida en formato 'AAAA-MM-DD'.");
    }
  
    // Dividir la cadena en año, mes y día
    const [year, month, day] = inputDate.split("-");
  
    // Construir la fecha en formato "DD/MM/AAAA"
    const formattedDate = `${day}/${month}/${year}`;
  
    return formattedDate;
  }
  
  function convertirFechaHora(fechaHora: string): string {
    const match = fechaHora.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2}) (AM|PM)/);
  
    if (!match) {
      return ""; // La fecha y hora no coincide con el formato esperado
    }
  
    const [, dia, mes, anio, horas, minutos, segundos, ampm] = match;
  
    let hora = parseInt(horas, 10);
  
    if (ampm === "PM" && hora !== 12) {
      hora += 12;
    } else if (ampm === "AM" && hora === 12) {
      hora = 0;
    }
  
    return `${anio}-${mes}-${dia}`;
  }