import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../../components/entrys/StyledEntry";
import styled from "styled-components";
import { GeneralI } from "../../../../interfaces/RegisterProvider-Client/General";
import { useCatalogs } from "../../../../context/CatalogContext";
import { AppContext } from "../../../../context/appContext";
import { FileI, contractInfoI } from "../../../../interfaces/GeneralTypes";
import FileElement from "../../../components/layout/FileElement";
import { v4 as uuidv4 } from "uuid";
import DateInput from "../../../components/entrys/DateInput";
import FileInputDrop from "../../../components/common/FileInputDrop";
import { contratoContextInterface } from "../../../../context/InterfacesContext";
import { GeneralModifiedI } from "../../../../interfaces/GuardadoTypes";
import {
  FilesDataDelete,
  DeleteDataContract,
  contractObjectModified,
} from "../../../../interfaces/DeleteTypes";
import FileElementOP from "../FileElementOP";
import { OperationContext } from "../../../../context/operationContext";
import { FileOP, InitialContextOP, isBase64 } from "../InterfacesOperacion/OperacionesI";

export default function UploadDocuments() {
  const { catalogsData } = useCatalogs();
  const {
    registerData,
    setRegisterData,
    contratoContext,
    setContratoContext,
    idCurrentProvider,
    registerGeneral,
    setTitleAlert,
    setContentAlert,
    setShowAlert,
    setShowLoader,
  } = useContext(AppContext);
  const { currentEntrada, currentOperation } = useContext(OperationContext);
  const [currentPicture, setCurrentPicture] = useState(
    "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
  );
  const [contractObject, setcontractObject] = useState({
    start_date: "2023-05-17'",
    end_date: "",
    file: "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg",
    file_extension: "png",
  });

  const [imagen, setImagen] = useState(
    "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
  );
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;

    console.log(newDate);
    // console.log(new Date(newDate).toLocaleString('en-US', { timeZone: 'America/Mexico_City' }))
    setcontractObject({ ...contractObject, start_date: newDate });
    setRegisterData({
      ...registerData,
      contract: { ...contractObject, start_date: newDate },
    });
  };

  const handleDateChangeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;

    console.log(newDate);
    setcontractObject({ ...contractObject, end_date: newDate });
    setRegisterData({
      ...registerData,
      contract: { ...contractObject, end_date: newDate },
    });
  };

  async function getFile(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      const fileName = file.name; // Obtener el nombre del archivo
      const fileType = file.type;
      const fileExtension = fileType.split("/").pop();
      console.log("EXTENSION: ");
      console.log(fileExtension);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        const base64WithoutHeader = base64.split(",")[1];
        setImagen(base64);
        console.log("ESTA ES TU IMAGEN : ");
        console.log(base64);
        setcontractObject({
          ...contractObject,
          file: base64,
          file_extension: fileExtension ?? "",
        });
        setFileLocal(base64);
        setRegisterData({
          ...registerData,
          contract: {
            ...contractObject,
            file: base64,
            file_extension: fileExtension ?? "",
          },
        });

        setLocalFileData({
          ...fileLocalName,
          file_name: "",
          file: base64,
          file_extension: fileExtension ?? "",
          fileName: fileName,
          file_identifier: 0,
          contract_identifier: localFileData?.contract_identifier
            ? localFileData.contract_identifier
            : 0,
          status_identifier: 0,
        });
      };
    }
  }

  useEffect(() => {
    console.log("Datos globales: ");
    console.log(registerData);
  }, [registerData]);
  useEffect(() => {
    console.log("Imagen guardada: ");
    console.log(contractObject);
    console.log("DAtos globales: ");
    console.log(registerData);
  }, [contractObject]);
  let identificador = uuidv4();
  const [documentsArray, setDocumentsArray] = useState<FileI[]>(
    contratoContext.files.length == 0 ? [] : contratoContext.files
  );

  const [contractRules, setContractRules] = useState<contractInfoI>({
    cancellation_policies: contratoContext.cancellation_policies,
    observations: contratoContext.observations,
  });
  const [localFileData, setLocalFileData] = useState<FileI>();
  const [dateObject, setDateObject] = useState({
    start_date: contratoContext.start_date,
    end_date: contratoContext.end_date,
  });
  const [fileLocalName, setFileLocalName] = useState({
    file: "",
    start_date: "",
    end_date: "",
    file_extension: "png",
    nameFile: "",
    identificador: identificador,
  });
  const [fileLocal, setFileLocal] = useState("");
  const saveNewFile = () => {
    if (
      localFileData &&
      currentPicture !==
        "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
    ) {
      let idRandom = uuidv4();
      const newObjectData: contratoContextInterface = {
        file: localFileData.file,
        start_date: localFileData.start_date,
        end_date: localFileData.end_date,
        file_extension: localFileData.file_extension,
        fileName: localFileData.fileName,
        identificador: idRandom,
        file_identifier: 0,
        contract_identifier: localFileData.contract_identifier,
        file_name: localFileData.file_name,
        status_identifier: 0,
      };

      setDocumentsArray([...documentsArray, newObjectData]);
      setContratoContext({
        ...contratoContext,
        files: [...contratoContext.files, newObjectData],
      });
      setCurrentPicture(
        "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
      );
    } else {
      alert("Agrega un archivo.");
    }
  };
  useEffect(() => {
    console.log("LISTA DE DOCUMENTOS EN FORMA DE ARRAY:");
    console.log(documentsArray);
  }, [documentsArray]);

  useEffect(() => {
    if (localFileData) {
      setRegisterData({
        ...registerData,
        contract: {
          ...contractObject,
          end_date: localFileData.end_date ? localFileData.end_date : "",
          start_date: localFileData.start_date
            ? localFileData.start_date
            : "  ",
        },
      });
    }
    setcontractObject({
      ...contractObject,
      end_date: dateObject.end_date,
      start_date: dateObject.start_date,
    });
    setContratoContext({
      ...contratoContext,
      start_date: dateObject.start_date,
      end_date: dateObject.end_date,
    });

    console.log("LA FECHA CAMBIO:::::");
  }, [localFileData?.start_date, localFileData?.end_date, dateObject]);

  useEffect(() => {
    console.log("Contrato contexto cambió:::");
    console.log(contratoContext);
  }, [contratoContext]);
  const deleteContrato = async (elementIdentifier: string) => {
    const newContractArray = documentsArray
    .filter((documento, index) => {
      return documento.identificador !== elementIdentifier;
    });

    const arregloDelete: FileOP[] = documentsArray
      .filter((documento, index) => {
        return documento.identificador == elementIdentifier;
      })
      .map((documento, index) => {
        console.log(documento); 
        const document: FileOP = {
          file_identifier: documento.file_identifier,
          file_name: "string",
          file_url: documento.file,
          file_alt_name: "",
          status_identifier: 2
        };
        return document;
      });
 
    ///////////////Borrando desde servicios:
    if (arregloDelete) {
      if(arregloDelete.length > 0){
        if(!isBase64(arregloDelete[0].file_url)){
          setShowLoader(true);
          const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/entryUpdate`;
          const bodyRequest: InitialContextOP = {
            entry_data: {
              entry_identifier: currentEntrada.entry_data.entry_identifier,
              operation_identifier: currentEntrada.entry_data.operation_identifier,
              provider_identifier: currentEntrada.entry_data.provider_identifier,
              status_identifier: 1,
              entry_number: currentEntrada.entry_data.entry_number,
              initial_date: currentEntrada.entry_data.initial_date,
              final_date: currentEntrada.entry_data.final_date,
              sgl: currentEntrada.entry_data.sgl,
              dbl: currentEntrada.entry_data.dbl,
              tpl: currentEntrada.entry_data.tpl,
              cpl: currentEntrada.entry_data.cpl,
              adults: currentEntrada.entry_data.adults,
              childs: currentEntrada.entry_data.childs,
              confirmation_number: currentEntrada.entry_data.confirmation_number,
              creator_user_identifier:
                currentEntrada.entry_data.creator_user_identifier,
              contact1_identifier: currentEntrada.entry_data.contact1_identifier,
              contact2_identifier: currentEntrada.entry_data.contact2_identifier,
              guide_identifier: currentEntrada.entry_data.guide_identifier,
              payday_limit: currentEntrada.entry_data.payday_limit,
              comment: currentEntrada.entry_data.comment,
              total_mxn: currentEntrada.entry_data.total_mxn,
              services_tips_mxn: currentEntrada.entry_data.services_tips_mxn,
              lodging_taxes_mxn: currentEntrada.entry_data.lodging_taxes_mxn,
              iva_mxn: currentEntrada.entry_data.iva_mxn,
              advance_mxn: currentEntrada.entry_data.advance_mxn,
              total_usd: currentEntrada.entry_data.total_usd,
              services_tips_usd: currentEntrada.entry_data.services_tips_usd,
              lodging_taxes_usd: currentEntrada.entry_data.lodging_taxes_usd,
              iva_usd: currentEntrada.entry_data.iva_usd,
              advance_usd: currentEntrada.entry_data.advance_usd,
              guide_courtesy: currentEntrada.entry_data.guide_courtesy,
              provider_name: currentEntrada.entry_data?.provider_name,
              total_without_advance_usd: currentEntrada.entry_data.total_without_advance_usd,
              total_without_advance_mxn: currentEntrada.entry_data.total_without_advance_mxn, 
              creator_user: currentEntrada.entry_data.creator_user
            },
            entry_services_list: {
              mxn_services_list:
                currentEntrada.entry_services_list.mxn_services_list,
              usd_services_list:
                currentEntrada.entry_services_list.usd_services_list,
            },
            files: arregloDelete,
          };
    
          try {
            const response = await fetch(urlUpdate, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(bodyRequest),
            });
    
            if (response.ok) {
              const data = await response.json();
    
              alert("El valor se ha enviado correctamente");
    
              ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
              setDocumentsArray(newContractArray);
              setContratoContext({
                ...contratoContext,
                files: newContractArray,
              });
              // navigate("/dashboard/Operaciones/Definicion-Prestador");
              setShowLoader(false);
              ///////////////////////////////////////////////////////////
            } else {
              console.error("Error in response:", response);
              alert("Error al guardar");
              setShowLoader(false);
              // setTitleAlert('Error al iniciar sesión');
              // setContentAlert('Por favor, digite un email y contraseña valido.');
              // setShowAlert(true);
            }
          } catch (error) {
            alert("Error al guardar");
            console.error("Error in request:", error);
    
            alert("Error en el servicio");
            // setShowLoader(false);
          }
        }
      }
    }

    ///////////////////////////////////FIN DE BORRADO DESDE SERVICIOS

    setDocumentsArray(newContractArray);
    setContratoContext({
      ...contratoContext,
      files: newContractArray,
    });
  };
  useEffect(() => {
    console.log("CONTRATO CONTEXT CAMBIO: ");
    console.log(contratoContext);
  }, [contratoContext]);
  return (
    <ContratoStyle>
      <div className="box-container">
        {/* <h2 className="title-contrato">Datos de Contrato</h2> */}
        <br />
        <br />
        <div className="box-documents">
          <div className="data-container">
            <FileInputDrop
              setcontractObject={setcontractObject}
              setLocalFileData={setLocalFileData}
              contractObject={contractObject}
              fileLocalName={fileLocalName}
              currentPicture={currentPicture}
              setCurrentPicture={setCurrentPicture}
            />
            {/* <div className="picture-container">
              <div className="picture-box">
                <img
                  src={contractObject.file}
                  alt="Archivo seleccionado"
                  className="picture-img"
                />
              </div>

              <input
                type="file"
                id="image-upload"
                // accept="image/*"
                onChange={(e) => getFile(e)}
              />
            </div>
            */}

            <div className="date-container">
              <div>
                <div className="botton-container">
                  <button
                    onClick={saveNewFile}
                    className="add-file-button"
                    disabled={
                      currentPicture ==
                      "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
                    }
                  >
                    Guardar Archivo
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="list-documents">
            <div className="list-documents-title">
              <h3>Tus archivos</h3>
            </div>

            <br />
            {documentsArray.length > 0 &&
              documentsArray.map((document) => {
                return (
                  <FileElementOP
                    key={document.identificador}
                    start_date={document.start_date}
                    end_date={document.end_date}
                    file_extension={document.file_extension}
                    nameFile={document.fileName}
                    file={document.file}
                    identificador={document.identificador}
                    deleteContrato={deleteContrato}
                  />
                );
              })}
          </div>
        </div>
        <div></div>
      </div>
    </ContratoStyle>
  );
}

const ContratoStyle = styled.div`
  .box-fields {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
  }

  .box-field {
    width: 40%;
  }
  .box-field h3 {
    color: rgb(8, 166, 165);
    margin-bottom: 1rem;
  }
  .text-container {
    resize: none;
    width: 100%;
    height: 200px;
    padding: 0.9rem;
    border: rgb(8, 166, 165) solid 2px;
    outline: none;
  }
  @media screen and (max-width: 900px) {
    .box-fields {
      width: 80%;
      margin: 0 auto;
      margin-top: 2rem;
      flex-direction: column;
    }
    .box-field {
      width: 100%;
    }
    .box-field h3 {
      font-size: 0.85rem !important;
    }
  }

  .dates-container {
    display: flex;
    justify-content: space-around;
  }
  @media screen and (max-width: 900px) {
    .dates-container {
      flex-direction: column;
    }
    .start-date,
    .end-date {
      width: 100% !important;
    }
  }
  .botton-container {
    display: flex;
    justify-content: center;
  }
  .start-date,
  .end-date {
    width: 45%;
  }
  .add-file-button {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .box-documents {
    display: flex;
  }
  .list-documents-title {
    background-color: rgb(8, 166, 165);
    color: white;
    padding: 0.8rem;
  }
  .list-documents-title h3 {
    font-size: 1.2vw;
  }
  @media screen and (max-width: 900px) {
    .list-documents-title h3 {
      font-size: 1.8vw;
    }
  }
  .list-documents {
    width: 40%;
    border: rgb(8, 166, 165) solid 1px;
    height: auto;
  }
  .list-documents {
  }
  .list-documents h2 {
    padding: 1rem;
  }
  .picture-img {
    width: 100%;
    height: 100%;
  }
  .title-contrato {
    color: rgb(8, 166, 165);
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .data-container {
    display: flex;
    justify-content: space-around;
    align-items: center;

    flex-direction: column;
    width: 55%;
  }
  .picture-box {
    border: rgb(8, 166, 165) dashed 7px;
    height: 20vw;
    width: 20vw;
    cursor: pointer;
    border-radius: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .picture-box p {
    text-align: center;
    font-size: 1vw;
  }
  .picture-box div {
    align-items: center;
  }
  .picture-box div div {
    display: flex;
    justify-content: center;
    margin-bottom: 0.9rem;
  }
  @media screen and (max-width: 900px) {
    .picture-box p {
      text-align: center;
      font-size: 1.6vw;
    }
  }
  .icon-upload {
    font-size: 5vw;
    color: grey;
  }
  .date-container {
    width: 70%;
  }
  .date-container div {
    margin-bottom: 0.5rem;
  }
`;
