import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { PaymentDataGastosIC, PendingPaymentGastosComprobar } from "./InterfacesContabilidad";
import ElementPayGastos from "./Components/ElementPayGastos";
interface TablePagoGastosI{
    setPaymentsGastos: React.Dispatch<React.SetStateAction<PendingPaymentGastosComprobar[]>>;
    paymentsGastos: PendingPaymentGastosComprobar[];
    paymentData: PaymentDataGastosIC ;
}

export default function TablePagoGastos({setPaymentsGastos, paymentsGastos, paymentData}:TablePagoGastosI) {

    const sumaMXN = paymentsGastos.reduce(
        (total, item) => total + item.advance_mxn,
        0
      );
      const sumaUSD = paymentsGastos.reduce(
        (total, item) => total + item.advance_usd,
        0
      );
  return (
    <TablePagoGastosStyles>
         
      <br />
      <div className="header-table">
        <div>
          <h3>Liquidar</h3>
        </div>
        <div>
          <h3>Prestador</h3>
        </div>
        <div>
          <h3>Clave</h3>
        </div>
        <div>
          <h3>Guia</h3>
        </div>
        <div>
          <h3>Total Pesos</h3>
        </div>
        <div>
          <h3>Total Dolares</h3>
        </div>
        <div>
          <h3>Grupo</h3>
        </div>
        <div>
          <h3>Enviado a Pago</h3>
        </div>
        <div>
          <h3>Fecha Llegada</h3>
        </div>
        <div>
          <h3>Fecha Salida</h3>
        </div>
      </div>
      {
        paymentsGastos.map((paymentCurrent, index)=>{
            return <>
                <ElementPayGastos
                key={paymentCurrent.id_local}
                paymentCurrent={paymentCurrent}
                paymentsGastos = {paymentsGastos}
                setPaymentsGastos={setPaymentsGastos}
                paymentData = {paymentData}
                />
            </>
        })
      }
       <div className="container-prices">
        <h3>Total pesos: ${sumaMXN}</h3>
        <h3>Total dolares: ${sumaUSD}</h3>
      </div>

    </TablePagoGastosStyles>
  )
}
const TablePagoGastosStyles = styled.div`
 .container-prices {
    width: 85%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .container-prices h3{
    font-size: 0.9vw;
  }
  .header-table {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 85%;
    margin: 0 auto;
    padding: 0.55rem;
  }
  .header-table div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
`