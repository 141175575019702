import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { OperationOPSearch } from "../Cotizacion/interfaces/CotizacionI";
import agregarIcon from "../../../images/AGREGAR.png";
import menos from "../../../images/Menos.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/appContext";

interface ElementSearchOPI {
  currentOperacion: OperationOPSearch;
  handleItemClick: (index: any) => void;
  index: number;
  selectedIndex: number | null;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>
}

export default function ElementSearchOPT({
  currentOperacion,
  handleItemClick,
  index,
  selectedIndex,
  setSelectedIndex
}: ElementSearchOPI) {
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false);
  const navigate = useNavigate();

  const { idOperation, setIdOperation, setIdProforma } = useContext(AppContext);
  const getDefinicionPrestadores = () => {
    setIdOperation(currentOperacion.operation_identifier);
    setIdProforma(currentOperacion.proforma_identifier);
    navigate("/dashboard/Operaciones/Definicion-Prestador");
  };

  const getDesgloseGastos = () => {
    setIdOperation(currentOperacion.operation_identifier);

    setIdProforma(currentOperacion.proforma_identifier);
    navigate("/dashboard/Operaciones/Desgloce-Guia");
  };

  const getQuejas = () => {
    setIdOperation(currentOperacion.operation_identifier);
    setIdProforma(currentOperacion.proforma_identifier);
    navigate("/dashboard/Operaciones/Quejas-Operacion");
  };
  const getProforma = () => {
    setIdOperation(currentOperacion.operation_identifier);
    setIdProforma(currentOperacion.proforma_identifier);
    navigate("/dashboard/Operaciones/Proforma-Operacion");
  };
  const getHojaControl = () => {
    setIdOperation(currentOperacion.operation_identifier);
    setIdProforma(currentOperacion.proforma_identifier);
    navigate("/dashboard/Operaciones/Hoja-Control");
  };
  const handleClose = () => {
    setSelectedIndex(null);
  };
  return (
    <>
      <tr>
        <td>{currentOperacion.alias}</td>

        <td>{currentOperacion.user ? currentOperacion.user : ""}</td>
        <td>{reemplazarGuionesConDiagonales(currentOperacion.arrival_date)}</td>
        <td>
          {reemplazarGuionesConDiagonales(currentOperacion.departure_date)}
        </td>
        <td>{currentOperacion.user ? currentOperacion.user : ""}</td>
        <td>
          {" "}
          <button
            className="btn btn-primary"
            onClick={() => {
                handleItemClick(index); 
              setIsShowingDetails(!isShowingDetails);
            }}
          >
            Consultar
          </button>
        </td>
      </tr>
      {selectedIndex == index && (
        <ElementSearchOPTI>
          <div className="box-2">
            <div className="header-box-2">
              <h3>
                {currentOperacion.alias.trim()
                  ? currentOperacion.alias
                  : currentOperacion.user}
              </h3>
              <img
                src={menos}
                alt="Alternativo"
                className="plus-imagen"
                width={25}
                onClick={() => {
                 setSelectedIndex(null); 
                }}
              />
            </div>
            <div className="link-directions">
              <ul></ul>
              <li onClick={getHojaControl}>Hoja de control</li>
              <li onClick={getDefinicionPrestadores}>
                Definición de Prestadores
              </li>
              <li onClick={getDesgloseGastos}>
                Desglose de Gastos a Comprobar
              </li>
              <li onClick={getProforma}>Proforma</li>
              <li onClick={getQuejas}>Quejas</li>
            </div>
          </div>
        </ElementSearchOPTI>
      )}
    </>
  );
}

function reemplazarGuionesConDiagonales(cadena) {
  // Utilizamos el método replace con una expresión regular para reemplazar todos los guiones con diagonales
  const resultado = cadena.replace(/-/g, "/");
  return resultado;
}

const ElementSearchOPTI = styled.div`
  z-index: 100;
  position: fixed;
  top: calc(50% - 123px);
  background-color: white;
  left: calc(50% - 137.5px);
  margin-bottom: 0.5rem;
  .header-box-2 {
    display: flex;
    padding: 0.5rem;
    min-width: 350px;
    justify-content: space-between;
    align-items: center;
    max-height: 50px;
    min-height: 50px;
    max-width: 350px;
  }
  .boxes-container {
    display: flex;
    align-items: flex-start;
  }
  .box-1 {
    border: rgb(8, 166, 165) solid 2px;
    display: flex;
    padding: 0.5rem;
    min-width: 350px;
    justify-content: space-between;
    align-items: center;

    max-width: 350px;
    border-radius: 0.2rem;
  }
  .box-2 {
    min-height: 250px;
    border: rgb(8, 166, 165) solid 2px;
    min-width: 300px;
    border-radius: 0.2rem;

    padding: 0.8rem;
  }
  .link-directions {
    padding: 0.5rem;
    margin-top: 0.8rem;
  }
  li {
    font-size: 1vw;
    margin-bottom: 0.5rem;
    font-weight: 200;
  }

  .boxes-container {
    display: flex;
  }

  .container-detail-op {
    max-width: 300px;
    border: 2px solid rgb(8, 166, 165);
    margin-bottom: 1rem;
    border-radius: 0.2rem;
    position: relative;
    min-width: 300px;
  }
  /* .extra-container{
    position: absolute;
    right: -400px;
    top: 0; 

  } */
  .first-row-op {
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
  }
  .plus-imagen {
    cursor: pointer;
  }
  .link-directions {
    padding: 0.5rem;
    color: blue;
    cursor: pointer;
  }
  .link-directions h3 {
    font-size: 0.85vw;
    margin-bottom: 0.5rem;
  }
  .box-1 h3 {
    font-size: 0.9vw;
  }
`;
