import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import { QuoteItemI } from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import IndividualService from "./IndividualService";

interface IndividualDayI{
    servicioIndividualDia: servicioDayElementI,
    checkboxAll: boolean,
    setCheckBoxAll: React.Dispatch<React.SetStateAction<boolean>>

}
export default function IndividualDay({servicioIndividualDia,checkboxAll, setCheckBoxAll}:IndividualDayI) {

  return (
    <IndividualDayStyle>
        {
            servicioIndividualDia.serviciosIndividuales.map(((individualService, index)=>{
                return <IndividualService
                key = {individualService.identifierService}
                individualService = {individualService}
                servicioIndividualDia = {servicioIndividualDia}
                checkboxAll = {checkboxAll}
                 setCheckBoxAll = {setCheckBoxAll}
                />
            }))
        }

    </IndividualDayStyle>
  )
}

const IndividualDayStyle = styled.div`

`