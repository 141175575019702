import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  Proforma,
  ProformaOptionalPackage,
  ProformaPrincipalPackage,
} from "./ProformaI";
import ElementProforma from "./ElementProforma";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";

interface PrincipalPackagesI {
  proformaPrincipalPackages: ProformaPrincipalPackage[];
  proforma: Proforma;
  setProformaPrincipalPackages: React.Dispatch<
    React.SetStateAction<ProformaPrincipalPackage[]>
  >;
}

export default function PrincipalPackages({
  proformaPrincipalPackages,
  proforma,
  setProformaPrincipalPackages,
}: PrincipalPackagesI) {
  const { language, setLanguage } = useLanguage();
  return (
    <PrincipalPackagesStyle>
      {" "}
      <div className="header-table-one extra-style">
        <div>
          <h3></h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div className="grid-item-wide">
          <h3>{translations[language].header_table.number_rooms}</h3>
        </div>
       
        <div>
          <h3></h3>
        </div>

        <div className="grid-item-wide">
          <h3>{translations[language].header_table.room_price}</h3>
        </div>
        
        <div>
          <h3></h3>
        </div>

        <div>
          <h3>{translations[language].hotels_table.total}</h3>
        </div>
      </div>
      <div className="header-table-one">
        <div>
          <h3>{translations[language].hotels_table.title_hotels}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_table.city}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_table.status}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_table.arrive}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_table.departure}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_table.nights}</h3>
        </div>
        <div>
          <h3>SGL</h3>
        </div>
        <div>
          <h3>DBL</h3>
        </div>
        <div>
          <h3>TPL</h3>
        </div>

        <div>
          <h3>SGL</h3>
        </div>
        <div>
          <h3>DBL</h3>
        </div>
        <div>
          <h3>TPL</h3>
        </div>

        <div>
          <h3>{translations[language].hotels_table.total}</h3>
        </div>
      </div>
      {proformaPrincipalPackages.map((currentCotizacion, index) => {
        return (
          <>
            <ElementProforma
              currentCotizacion={currentCotizacion}
              proforma={proforma}
              setProformaPrincipalPackages={setProformaPrincipalPackages}
              proformaPrincipalPackages={proformaPrincipalPackages}
            />
          </>
        );
      })}
    </PrincipalPackagesStyle>
  );
}
const PrincipalPackagesStyle = styled.div`
  .container-principal-package {
    border: black solid 1px;
  }
  .header-table-one {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: black solid 1px;
    padding: 0.5rem;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
  }
  .header-table-one div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
  .extra-style {
    margin-bottom: -1rem;
    border-bottom: none;
  }
  .grid-item-wide {
    grid-column: span 2;
  }
`;
