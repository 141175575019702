import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";

export default function AdressTwo() {
  return (
    <AdressTwoStyle>
        <div className="box-adress">
        <div className="title-box-adress">
            <h3>DIRECCIÓN TRAVEL ATOMEX</h3>
        </div>
        <div className="box-adress-content">
            <h3>AVENIDA REPUBLICAS 39</h3>
            <h3>COLONIA PORTALES ORIENTE</h3>
            <h3>C.P: 03570</h3>
            <h3>CIUDAD DE MÉXICO</h3>
        </div>
        </div>
    </AdressTwoStyle>
  )
}

const AdressTwoStyle = styled.div`
margin-top: 1rem;
    .box-adress{
        width: 40%;
        border: black solid 1px; 
        
    }
    .title-box-adress{
        padding: 0.5rem;
        border-bottom: black solid 1px ;
    }
    .title-box-adress h3{
        font-weight: 600;
    }
    .box-adress-content{
        padding: 0.5rem;
    }
    h3{
        font-size: 0.85vw;
        margin-bottom: 0.3rem;
        font-weight: 300;
    }
`