import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import RowCotizacion from "./Components/RowCotizacion";

export default function HeaderInfo() {

  const {
    dataCotizacionContext,
  } = useContext(AppContext);
  return (
    <HeaderInfoStyles>
     
      <div className="container-info-header-one">
        <div>
          <h3>Nombre de cotización</h3>
          <h3 className="sub">{dataCotizacionContext.personalInfo.name}</h3>
        </div>
        <div>
          <h3>Fecha de llegada</h3>
          <h3 className="sub">{ dataCotizacionContext.personalInfo.arrivingData}</h3>
        </div>
        <div>
          <h3>Fecha de salida</h3>
          <h3 className="sub">{dataCotizacionContext.personalInfo.departureDate}</h3>
        </div>
        <div>
          <h3>Agencia</h3>
          <h3 className="sub">{dataCotizacionContext.personalInfo.agencia}</h3>
        </div>
      </div>
      <div className="container-info-header-two">
        <div>
          <h3>Temporada</h3>
          <h3 className="sub"></h3>
        </div>
        <div>
          <h3>Número pax</h3>
          <h3 className="sub">{dataCotizacionContext.personalInfo.pax}</h3>
        </div>
        <div>
          <h3>SGL</h3>
          <h3 className="sub">0</h3>
        </div>
        <div>
          <h3>DBL</h3>
          <h3 className="sub">0</h3>
        </div>
        <div>
          <h3>TPL</h3>
          <h3 className="sub">0</h3>
        </div>
        <div>
          <h3>CPL</h3>
          <h3 className="sub">0</h3>
        </div>
        <div>
          <h3>Adultos</h3>
          <h3 className="sub">0</h3>
        </div>
        <div>
          <h3>Menores</h3>
          <h3 className="sub">0</h3>
        </div>
      </div>
    </HeaderInfoStyles>
  );
}

const HeaderInfoStyles = styled.div`
  border: rgb(8, 166, 165) solid 2px;
  padding: 0.8rem;

  .alias-box {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
    align-items: center;
  }
  .alias-box h3 {
    margin-right: 1rem;
    font-size: 0.85vw;
  }
  .alias-box input {
    padding: 0.5rem;
    font-size: 0.85vw;
    outline: none;
    border: grey solid 1px;
  }
  .container-info-header-one {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .container-info-header-one div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .container-info-header-one h3 {
    text-align: center;
    font-size: 0.85vw;
  }

  .container-info-header-two {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .container-info-header-two div {
    display: flex;
    flex-direction: column;
  }
  .container-info-header-two h3 {
    text-align: center;
    font-size: 0.85vw;
  }
  .sub {
    font-weight: 300;
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 900px) {
    .container-info-header-two h3 {
      text-align: center;
      font-size: 1.1vw;
    }
    .container-info-header-one h3 {
      text-align: center;
      font-size: 1.3vw;
    }
    .alias-box h3 {
      font-size: 1.3vw;
    }
    .alias-box input {
      font-size: 1.3vw;
    }
    .container-info-header-one h3 {
      font-size: 1.3vw;
    }
    .container-info-header-two h3 {
      font-size: 1.3vw;
    }
  }
`;
