import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { PaymentSearch } from "../InterfacesContabilidad";
import { ocultarNumerosTarjeta } from "../../../components/functionsTs/utilidades";
interface ElementEstadosI{
    paymentData: PaymentSearch
}

export default function ElementEstados({paymentData}:ElementEstadosI) {
  return (
    <ElementEstadosStyles>
        <div className="element">
            <div>
                <h3>{paymentData.provider_name}</h3>
            </div>
            <div>
                <h3>{paymentData.payday_limit}</h3>
            </div>
            <div>
                <h3>{paymentData.agency}</h3>
            </div>
            <div>
                <h3>{paymentData.alias}</h3>
            </div>
            <div>
                <h3>{paymentData.entry_number}</h3>
            </div>
            <div>
                <h3>{paymentData.total}</h3>
            </div>
            <div>
                <h3>Tipo de cambio</h3>
            </div>
            <div>
                <h3>{paymentData.payment_date}</h3>
            </div>
            <div>
                <h3>{ocultarNumerosTarjeta(paymentData.bank_account)}</h3>
            </div>
            <div>
                <h3>{paymentData.initial_date} - {paymentData.final_date}</h3>
            </div>
        
        </div>
    </ElementEstadosStyles>
  )
}


const ElementEstadosStyles = styled.div`
    .element{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
        border: black solid 1px; 
        margin-left: 2rem;
        padding: 0.5rem; 
        width: 120%;

    }
    .element div h3{
        text-align: center;
        font-size: 0.85vw;
        font-weight: 300;
    }
`   