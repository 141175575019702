import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontawesomeObject } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import { catalogElement } from "../../../interfaces/interfaces";
import SearchResult from "../layout/SearchResult";
import { catalogo } from "../../mockData/mock";

interface searchBoxProps {
  catalogElements: Array<catalogElement>;
  setCatalogElements: React.Dispatch<React.SetStateAction<catalogElement[]>>;
}
export default function SearchBox({
  catalogElements,
  setCatalogElements,
}: searchBoxProps) {
  const [searchText, setSearchText] = useState<string>("");
  const [resultsSearch, setResultsSearch] = useState<catalogElement[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  useEffect(() => {
    console.log("STATE CHANGED");
    console.log(searchText);
    if (searchText.length > 0) {
      setShowResults(true);
      setResultsSearch(buscarElementos(searchText));
    } else {
      ////LLAMAR A SERVICIO PARA REESTABLECER DATOS:
      setShowResults(false);
      setResultsSearch([]);
      setCatalogElements(catalogo);
    }
  }, [searchText]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.trim(); // Elimina espacios en blanco al inicio y al final del texto
    setSearchText(searchText);
    // if (searchText.length > 0) {
    //   setShowResults(true);
    //   setResultsSearch(buscarElementos(searchText));

    // } else {
    //   setShowResults(false);
    //   setResultsSearch([]);
    //   setCatalogElements(catalogo);

    // }
  };
  function buscarElementos(texto: string): catalogElement[] {
    const resultados: catalogElement[] = catalogElements.filter((elemento) => {
      // Verificar si el texto coincide con alguna propiedad del objeto
      const textoSinTildes = texto
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return (
        elemento.clave.includes(textoSinTildes) ||
        elemento.temporada
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(textoSinTildes.toLowerCase()) ||
        elemento.nombreViaje
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(textoSinTildes.toLowerCase()) ||
        elemento.tarifa.toString().includes(textoSinTildes)
      );
    });
    console.log(resultados);
    return resultados;
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      console.log("Presionaste enter");
      setCatalogElements(resultsSearch);
      setResultsSearch([]);
    }
  };
  const handleClick = (e: MouseEvent) => {
    setShowResults(false);
    setResultsSearch([]);
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <SearchBoxStyles>
      <div className="search-container">
        <h3>¿Qué buscas?</h3>
        <div className="search-input">
          <input
            type="text"
            className="input-data"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Busca por categoría, temporada, clave, etc.."
            value={searchText}
          />
          <button className="search-button">Buscar</button>
        </div>
        {resultsSearch.length > 0 && showResults && (
          <SearchResult
            results={resultsSearch}
            setResultsSearch={setResultsSearch}
            catalogElements={catalogElements}
            setCatalogElements={setCatalogElements}
            searchText = {searchText}
            setSearchText = {setSearchText}
          />
        )}
      </div>
    </SearchBoxStyles>
  );
}

const SearchBoxStyles = styled.div`
  .search-input {
    background-color: white;
    width: auto;
    border-radius: 0.6rem;
  }
  .search-button {
    border: none;
    width: 25%;
    background-color: #08a6a5;
    padding: 0.35rem;
    color: white;
    border-radius: 0.5rem;
  }
  .input-data {
    width: 75%;
    padding: 0.8rem;
    border: none;
    outline: none;
    border-radius: 0.6rem;
  }
  @media screen and (min-width: 900px) {
    .search-button {
      width: 15%;
    }
    .input-data {
      width: 85%;
    }
  }
  h3 {
    margin-bottom: 0.5rem;
  }
  background-color: #f4f4f4;
  width: 100%;
  height: 6rem;
  padding-top: 1rem;
  .search-container {
    width: 80%;
    height: 5rem;
    margin: 0 auto;
  }
`;
