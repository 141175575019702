import { catalogElement } from "../../interfaces/interfaces";

let catalogo: catalogElement[] = [
    {
        nombreViaje: "CDMX-Cancún",
        clave: "010",
        moneda: "USD",
        tarifa: 450,
        iva: 0.10,
        impuesto: 0.05,
        total: 518,
        temporada: "alta",
        fechaInicial: "2023-12-24",
        fechaFinal: "2023-12-24"
      },
      {
        nombreViaje: "CDMX-NY",
        clave: "011",
        moneda: "USD",
        tarifa: 250,
        iva: 0.08,
        impuesto: 0.04,
        total: 287,
        temporada: "baja",
        fechaInicial: "2023-06-15",
        fechaFinal: "2023-12-24"
      },
      {
        nombreViaje: "CDMX-CDMX",
        clave: "012",
        moneda: "MXN",
        tarifa: 800,
        iva: 0.16,
        impuesto: 0.03,
        total: 1024,
        temporada: "alta",
        fechaInicial: "2023-08-01",
        fechaFinal: "2023-12-24"
      },
      {
        nombreViaje: "CDMX-Paris",
        clave: "013",
        moneda: "EUR",
        tarifa: 600,
        iva: 0.20,
        impuesto: 0.06,
        total: 810,
        temporada: "media",
        fechaInicial: "2023-10-10",
        fechaFinal: "2023-12-24"
      },
      {
        nombreViaje: "CDMX-LA",
        clave: "014",
        moneda: "USD",
        tarifa: 200,
        iva: 0.05,
        impuesto: 0.03,
        total: 215,
        temporada: "baja",
        fechaInicial: "2023-05-01",
        fechaFinal: "2023-12-24"
      },
      {
        nombreViaje: "CDMX-Madrid",
        clave: "015",
        moneda: "EUR",
        tarifa: 550,
        iva: 0.21,
        impuesto: 0.08,
        total: 748,
        temporada: "media",
        fechaInicial: "2023-09-20",
        fechaFinal: "2023-12-24"
      },
      {
        nombreViaje: "CDMX-Tokyo",
        clave: "016",
        moneda: "JPY",
        tarifa: 70000,
        iva: 0.08,
        impuesto: 0.02,
        total: 80200,
        temporada: "alta",
        fechaInicial: "2023-12-10",
        fechaFinal: "2023-12-24"
      },
      {
        nombreViaje: "CDMX-Roma",
        clave: "017",
        moneda: "EUR",
        tarifa: 500,
        iva: 0.22,
        impuesto: 0.05,
        total: 672,
        temporada: "media",
        fechaInicial: "2023-11-01",
        fechaFinal: "2023-12-24"
      },
      {
        nombreViaje: "CDMX-Monterrey",
        clave: "018",
        moneda: "MXN",
        tarifa: 600,
        iva: 0.16,
        impuesto: 0.04,
        total: 780,
        temporada: "baja",
        fechaInicial: "2023-04-01",
        fechaFinal: "2023-12-24"
      }
];

export {catalogo}; 