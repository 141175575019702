import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";

interface deleteConfirmationProps {
  onDelete: () => void;
  onCancel: () => void;
  itemName: string;
  setIsShownDelete: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function DeleteConfirmation({
  onDelete,
  onCancel,
  itemName,
  setIsShownDelete,
}: deleteConfirmationProps) {
  const { showDeleteNotification, setShowDeleteNotification } =
    useContext(AppContext);
  const handleClose = () => {
    setIsShownDelete(false);
  };
  return (
    <DeleteConfirmationStyle>
      <div className="card-message-container">
        <div className="card-box-x">
          <FontAwesomeIcon
            icon={faXmark}
            className="x-mark"
            onClick={() => handleClose()}
          />
        </div>
        <div className="card-box-text">
          <h4>Notificación de borrado</h4>
          <br />
          <h5>{itemName}</h5>
        </div>
        <div>
          <div className="button-container-box">
            <button onClick={onDelete} className="boton-estilos">
              Aceptar
            </button>
            <button onClick={onCancel} className="boton-estilos">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </DeleteConfirmationStyle>
  );
}

const DeleteConfirmationStyle = styled.div`
  .boton-estilos {
    padding: 0.5rem;
    border: none;
    background-color: transparent;
    border: none;
    padding: 1rem;
    background-color: rgb(8, 166, 165);
    border-radius: 0.8rem;
    padding: 0.5rem 2rem;
    color: white;
    cursor: pointer;
    margin-left: 1rem;
  }
  .button-container-box {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
  }
  @media screen and (max-width: 900px) {
  }
  border: black solid 1px;
  position: fixed;
  top: 0;
  left: 0;
  max-height: 100vh;
  overflow-y: hidden;

  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .card-message-container {
    border: white solid 1px;
    width: 300px;
    height: 200px;
    background-color: white;
    border-radius: 0.8rem;
  }
  .card-box-x {
    margin: 0rem 0.5rem 0.5rem 0rem;
    display: flex;
    justify-content: flex-end;
  }
  .card-box-text {
    width: 90%;
    margin: 0 auto;
  }
  .card-box-text h4 {
    font-size: 1.1rem;
    color: #08a6a5;
  }
  .card-box-text h5 {
    font-size: 0.9rem;
  }
  .x-mark {
    font-size: 2rem;
    cursor: pointer;
  }

  @media screen and (min-width: 900px) {
    .card-message-container {
      border: black solid 1px;
      width: 500px;
      height: 260px;
      padding-top: 1rem;
      padding-right: 0.3rem;
    }
  }
`;
