import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import GerenteTable from "../GerenteTable";
import { QuoteItemI } from "../InterfacesOperacion/OperacionesI";
import { QuoteDetail } from "../../Cotizacion/interfaces/CotizacionI";
import {
  feeChargesI,
  generalsElementsI,
  personalInfoI,
  servicioDayElementI,
  servicioIndividual,
  serviciosDaysElementsI,
  userI,
} from "../../../../context/InterfacesContext";
import {
  CommentI,
  QuoteGeneralElement,
} from "../../../../interfaces/GeneralTypes";
import { v4 as uuidv4 } from "uuid";
import { useCatalogsOperacion } from "../../../../context/OperacionCatalog";
import { useHref } from "react-router-dom";
import { OperationContext } from "../../../../context/operationContext";
interface stylesTableI {
  roleUser: string;
  optionTableSelected: number;
  group_identifier: number;
}

interface RowI {
  optionTableSelected: number;
  quote: QuoteItemI;
  listUsers: userI[];
  setListUsers: React.Dispatch<React.SetStateAction<userI[]>>;
  tableQuotes: QuoteItemI[];
  setTableQuotes: React.Dispatch<React.SetStateAction<QuoteItemI[]>>;
}
export default function RowCotizacion({
  optionTableSelected,
  quote,
  listUsers,
  setListUsers,
  tableQuotes,
  setTableQuotes,
}: RowI) {
  const { currentUser, setCurrentUser, idOperation } = useContext(AppContext);
  const { currentOperation } = useContext(OperationContext);
  const { catalogsDataOperaciones } = useCatalogsOperacion();
  const [listUsuariosOperacion, setListUsuariosOperacion] = useState<userI[]>(
    []
  );

  const {
    dataCotizacionContext,
    currentCurrency,
    setCurrentCurrency,
    setDataCotizacionContext,
    cotizacionMode,
    setCotizacionMode,
    setShowLoader,
  } = useContext(AppContext);
  const { showAlert, setShowAlert } = useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const { titleAlert, setTitleAlert } = useContext(AppContext);
  const scrollToComponent2 = () => {
    const componente2 = document.getElementById("data-cotization");
    if (componente2) {
      componente2.scrollIntoView({ behavior: "smooth" });
    }
  };
  // useEffect(() => {
  //   console.log(currentOperation.quote.quote_identifier);
  //   if (currentOperation.quote.quote_identifier !== 0) {
  //     getDataCotization(currentOperation.quote.quote_identifier);
  //   }
  // }, [currentOperation.quote.quote_identifier]);
  const getCotizacion = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (optionTableSelected == 1) {
      return;
    }

    getDataCotization(quote.quote_identifier);

    scrollToComponent2();
  };
  const getDataCotization = async (idOperacion: number) => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    let urlModified = backEndPoint + `/operations/QuoteDetail/${idOperacion}`;
setShowLoader(true); 
    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data = await response.json();

        console.log("VALOR DE LA CONSULTA:");
        console.log(data.data);
        const quoteResponse: QuoteDetail = data.data.quote_detail;
        console.log("RESPUESTA DEL SERVIDOR con nueva interfaz---------------------:");
        console.log(quoteResponse);

        const personalInfoInicial: personalInfoI = {
          name: quoteResponse.quote.name,
          idCotizacion: quoteResponse.quote.quote_identifier.toString(),
          typeCotizacion: quoteResponse.quote.type,
          typeAgency: 1,
          serieId: quoteResponse.quote.serie_identifier,
          includesSerie: true,
          pax: quoteResponse.quote.PAX,
          min: quoteResponse.quote.MIN,
          max: quoteResponse.quote.MAX,
          TL: quoteResponse.quote.TL,
          arrivingData: quoteResponse.quote.arrival_date,
          departureDate: quoteResponse.quote.departure_date,
          agencia: quoteResponse.quote.agency,
          typeRoom: 2,
          numberDaysOpenQuote: quoteResponse.quote.number_of_days,
          modeCotizacion: quoteResponse.quote.serie
            ? "Serie"
            : quoteResponse.quote.optional_quote
            ? "Opcional"
            : "Normal",
          selectedSerieOption: "No",
          serieSelected: 0,
          isShowingDates: false,
          dateStartOpen: "",
          dateEndOpen: "",
          status_identifier: quoteResponse.quote.status_identifier,
          quote_identifier: quoteResponse.quote.quote_identifier,
          optional_quote: quoteResponse.quote.optional_quote,
          operation_identifier: quoteResponse.quote.operation_identifier ?? 0,
        };

        ///////////////////Recuperando servicios individuales

        const serviciosDaysElementsInicial: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.quote_individual_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();

                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: serviceDayy.occupation,
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    status_identifier: serviceDayy.status_identifier,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: 0,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    vigencias: serviceDayy.validity_list,
                    fee_identifier: serviceDayy.fee_identifier,
                    isIncludedOpt: false,
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              };
              return servicio;
            }
          ),
        };
        let serviciosDaysElementsInicialOpt: serviciosDaysElementsI | undefined;
        ////Validando servicios individuales opcionales.
if(quoteResponse.quote_individual_services_opt){
  if(quoteResponse.quote_individual_services_opt.length > 0){
    serviciosDaysElementsInicialOpt = {
      servicioDayElement: quoteResponse?.quote_individual_services_opt?.map(
        (individualCurrentService, index) => {
          let identifierDayUID = uuidv4();
          const serviciosIndividualesArray: servicioIndividual[] =
            individualCurrentService.services.map((serviceDayy, index) => {
              let identifierDayUIDK = uuidv4();

              const individualServicio: servicioIndividual = {
                category: serviceDayy.category,
                lendertype: serviceDayy.lender_type_identifier,
                lenderDescripcion: serviceDayy.lender_type,
                ocupacion: serviceDayy.occupation,
                ocupacionDescripcion: serviceDayy.occupation,
                providerType: serviceDayy.provider_identifier,
                providerDescripcion: serviceDayy.provider_name,
                serviceType: serviceDayy.service_type_identifier,
                serviceDescripcion: serviceDayy.service_name,
                tarifa: serviceDayy.fee,
                identifierService: identifierDayUIDK,
                typeRoom:
                  serviceDayy.occupation == "SGL"
                    ? 0
                    : serviceDayy.occupation == "DBL"
                    ? 1
                    : serviceDayy.occupation == "TPL"
                    ? 2
                    : 3,
                dateRequired: individualCurrentService.service_date,
                currencyName: quoteResponse.quote.currency,
                currencyId: quoteResponse.quote.currency_identifier,
                tarifaSingleComplementConverted:
                  serviceDayy.single_complement,
                tarifaTripleSuplementConverted:
                  serviceDayy.triple_complement,
                status_identifier: serviceDayy.status_identifier,
                individual_service_identifier:
                  serviceDayy.individual_service_identifier,
                number_of_services: 0,
                service_name_identifier: serviceDayy.service_identifier,
                service_name: serviceDayy.service_name,
                vigencias: serviceDayy.validity_list,
                fee_identifier: serviceDayy.fee_identifier,
                isIncludedOpt: false,
              };
              return individualServicio;
            });

          const servicio: servicioDayElementI = {
            identifierDay: identifierDayUID,
            validityStartDay: individualCurrentService.service_date,
            validityEndDay: individualCurrentService.service_date,
            category: 0,
            lendertype: 0,
            ocupacion: 0,
            providerType: 0,
            serviceType: 0,
            tarifa: 0,
            isAvailable: true,
            travelRoute: individualCurrentService.service_tittle,
            serviciosIndividuales: serviciosIndividualesArray,
            service_identifier: individualCurrentService.service_identifier,
          };
          return servicio;
        }
      ),
    };
  }
}
////Validando servicios generales opcionales
let serviciosGeneralesOpt: QuoteGeneralElement[] = [];
if(quoteResponse.general_transport_services_opt){
    if(quoteResponse.general_transport_services_opt.length > 0){
      if (quoteResponse.quote_general_services) {
        serviciosGeneralesOpt = quoteResponse.quote_general_services.map(
          (generalS, index) => {
            let identifierGUID = uuidv4();
            const currentServicioGeneral: QuoteGeneralElement = {
              service_type: generalS.service_type_identifier,
              provider_id: generalS.provider_identifier,
              service_name_id: generalS.service_identifier,
              fee_price: generalS.fee,
              number_days: generalS.number_of_services,
              total_price: generalS.fee * generalS.number_of_services,
              identifierGeneralService: identifierGUID,
              currency: quoteResponse.quote.currency,
              currency_identifier: quoteResponse.quote.currency_identifier,
              expenses_type_identifier: generalS.expenses_type_identifier,
              general_service_identifier: generalS.general_service_identifier,
              status_identifier: generalS.service_identifier,
              service_name: generalS.service_name,
              provider_name: generalS.provider_name,
              fee_identifier: generalS.fee_identifier,
              isIncludedOpt: false,
            };
            return currentServicioGeneral;
          }
        );
      } 

    }else {
      serviciosGeneralesOpt = [];
    }
}


/////Validando servicios de transporte
let serviciosTransporteGeneralesOpt : serviciosDaysElementsI | undefined; 
if(quoteResponse.general_transport_services_opt){
  if(quoteResponse.general_transport_services_opt.length > 0){
    serviciosTransporteGeneralesOpt = {
      servicioDayElement: quoteResponse.general_transport_services_opt.map(
        (individualCurrentService, index) => {
          let identifierDayUID = uuidv4();
          const serviciosIndividualesArray: servicioIndividual[] =
            individualCurrentService.services.map((serviceDayy, index) => {
              let identifierDayUIDK = uuidv4();
              const individualServicio: servicioIndividual = {
                category: serviceDayy.category,
                lendertype: serviceDayy.lender_type_identifier,
                lenderDescripcion: serviceDayy.lender_type,
                ocupacion: "",
                ocupacionDescripcion: serviceDayy.occupation,
                providerType: serviceDayy.provider_identifier,
                providerDescripcion: serviceDayy.provider_name,
                serviceType: serviceDayy.service_type_identifier,
                serviceDescripcion: serviceDayy.service_name,
                tarifa: serviceDayy.fee,
                identifierService: identifierDayUIDK,
                typeRoom:
                  serviceDayy.occupation == "SGL"
                    ? 0
                    : serviceDayy.occupation == "DBL"
                    ? 1
                    : serviceDayy.occupation == "TPL"
                    ? 2
                    : 3,
                dateRequired: individualCurrentService.service_date,
                currencyName: quoteResponse.quote.currency,
                currencyId: quoteResponse.quote.currency_identifier,
                tarifaSingleComplementConverted:
                  serviceDayy.single_complement,
                tarifaTripleSuplementConverted:
                  serviceDayy.triple_complement,
                individual_service_identifier:
                  serviceDayy.individual_service_identifier,
                number_of_services: serviceDayy.number_of_services ?? 1,
                service_name_identifier: serviceDayy.service_identifier,
                service_name: serviceDayy.service_name,
                fee_identifier: serviceDayy.fee_identifier,
                isIncludedOpt: false,
              };
              return individualServicio;
            });
    
          const servicio: servicioDayElementI = {
            identifierDay: identifierDayUID,
            validityStartDay: individualCurrentService.service_date,
            validityEndDay: individualCurrentService.service_date,
            category: 0,
            lendertype: 0,
            ocupacion: 0,
            providerType: 0,
            serviceType: 0,
            tarifa: 0,
            isAvailable: true,
            travelRoute: individualCurrentService.service_tittle,
            serviciosIndividuales: serviciosIndividualesArray,
            service_identifier: individualCurrentService.service_identifier,
          };
          return servicio;
        }
      ),
    };
  }
} 

console.log("servicios opcionales"); 
console.log(serviciosTransporteGeneralesOpt);
console.log(serviciosGeneralesOpt);
console.log(serviciosDaysElementsInicialOpt);

//////

        //////////////////Recuperando servicios Generales.
        console.log("Getting comments: ");
        console.log(quoteResponse.quote.comments);
        const dataCommentsArray: CommentI[] = quoteResponse.quote.comments.map(
          (currentComment, index) => {
            return {
              comment_identifier: currentComment.comment_identifier,
              comment: currentComment.comment,
              comment_date: currentComment.comment_date,
              status_identifier: currentComment.status_identifier,
              commentIdentifier: currentComment.comment_identifier.toString(),
            };
          }
        );

        const cargosFee: feeChargesI = {
          currentMoney: 0,
          changeType: quoteResponse.quote.exchange_rate,
          comision: quoteResponse.quote.commission,
          comisonAuto: quoteResponse.quote.commission_auto,
          cortesia: quoteResponse.quote.courtesy,
          comments: dataCommentsArray,
          courtesy_type_identifier:
            quoteResponse.quote.courtesy_type_identifier,
        };
        const generalElementos: generalsElementsI = {
          generalElement: [],
        };
        let serviciosGenerales: QuoteGeneralElement[] = [];
        let serviciosTransporteGenerales: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.general_transport_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: "",
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: serviceDayy.number_of_services ?? 1,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    fee_identifier: serviceDayy.fee_identifier,
                    isIncludedOpt: false,
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              };
              return servicio;
            }
          ),
        };
        if (quoteResponse.quote_general_services) {
          serviciosGenerales = quoteResponse.quote_general_services.map(
            (generalS, index) => {
              let identifierGUID = uuidv4();
              const currentServicioGeneral: QuoteGeneralElement = {
                service_type: generalS.service_type_identifier,
                provider_id: generalS.provider_identifier,
                service_name_id: generalS.service_identifier,
                fee_price: generalS.fee,
                number_days: generalS.number_of_services,
                total_price: generalS.fee * generalS.number_of_services,
                identifierGeneralService: identifierGUID,
                currency: quoteResponse.quote.currency,
                currency_identifier: quoteResponse.quote.currency_identifier,
                expenses_type_identifier: generalS.expenses_type_identifier,
                general_service_identifier: generalS.general_service_identifier,
                status_identifier: generalS.service_identifier,
                service_name: generalS.service_name,
                provider_name: generalS.provider_name,
                fee_identifier: generalS.fee_identifier,
                isIncludedOpt: false,
              };
              return currentServicioGeneral;
            }
          );
        } else {
          serviciosGenerales = [];
        }
        console.log(serviciosTransporteGeneralesOpt);
        console.log(serviciosGeneralesOpt);
        console.log(serviciosDaysElementsInicialOpt);
        setDataCotizacionContext({
          ...dataCotizacionContext,
          personalInfo: personalInfoInicial,
          serviciosDayElements: serviciosDaysElementsInicial,
          generalsElements: generalElementos,
          serviciosDayElementsTransporte: serviciosTransporteGenerales,
          totalPrice: {
            totalPrice: 0,
            granTotal: 0,
          },
          feeCharges: cargosFee,
          generalServices: serviciosGenerales,
          serviciosDayElementsTransporteOpt: serviciosTransporteGeneralesOpt,
          serviciosDayElementsOpt: serviciosDaysElementsInicialOpt,
          generalServicesOpt: serviciosGeneralesOpt, 
        });
        setShowLoader(false); 
        // setTitleAlert("La solicitud se realizó correctamente");
        // setContentAlert("Accediste a la siguiente información de cotización.");
        // setCotizacionMode(2);
        // setShowAlert(true);
        // setShowLoader(false);
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al realizar la solicitud!:");
        // setContentAlert(
        //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        // );
        // setShowAlert(true);
        // setShowLoader(false);
        setShowLoader(false); 
        alert("Respuesta incorrecta de servidor.");
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      alert("Error al realizar la petición.");
    }
  };
  useEffect(() => {
    // console.log("DAtos que vienen del servicio: ");
    // console.log(catalogsData);
    console.log("CATALOGO DE OPERACIONES::");
    console.log(catalogsDataOperaciones);

    let tableQuotes: userI[] = catalogsDataOperaciones.users_list;
    // if(currentUser.group_identifier == 1 || currentUser.group_identifier == 1017 || currentUser.group_identifier == 1013){
    //         tableQuotes = tableQuotes.filter((usuario, index)=>{
    //           return usuario.group_identifier == 1 || usuario.group_identifier == 1017 || usuario.group_identifier == 1013 || usuario.group_identifier == 1012
    //         })

    //       }else if(currentUser.group_identifier == 1012){
    //         tableQuotes = tableQuotes.filter((usuario, index)=>{
    //           return usuario.group_identifier == 1012 || usuario.group_identifier == 1012
    //         })
    //       }

    console.log("TABLEQUOTES:::");
    console.log(tableQuotes);
    setListUsers(tableQuotes);
    // setListUsers(catalogsDataOperaciones.users_list);
    setListUsuariosOperacion(catalogsDataOperaciones.ops_users_list);
    console.log("DATOS DE CATALOGO DE OPERACION:::");
    console.log(catalogsDataOperaciones.ops_users_list);
  }, [catalogsDataOperaciones]);
  useEffect(() => {
    console.log("cambio en lista de usuarios::");
    console.log(listUsers);
  }, [listUsers]);
  const manejarSelectClick = (
    event: React.MouseEvent<HTMLSelectElement, MouseEvent>
  ) => {
    // Evita que el evento se propague al div principal

    event.stopPropagation();
  };
  const assignQuote = async (idUser: number) => {
    console.log("USER ELEGIDO-------------------------------");
    console.log(idUser);
    const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/assignQuote`;

    const bodySearch = {
      quote_identifier: quote.quote_identifier,
      assigned_user_identifier: idUser,
    };
    setShowLoader(true); 
    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodySearch),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Datos de busqueda:", data);

        const newArrayQuotes: QuoteItemI[] = tableQuotes.map(
          (currentCotizacion, index) => {
            return currentCotizacion.quote_identifier !== quote.quote_identifier
              ? currentCotizacion
              : {
                  ...currentCotizacion,
                  assigned_user_identifier: idUser,
                };
          }
        );
        console.log("ASI QUEDA EL NUEVO ARRAY, Despues ");
        console.log(newArrayQuotes);
        setTableQuotes(newArrayQuotes);
        setShowLoader(false); 
      } else {
        console.error("Error in response:", response);
        alert("Error al asignar usuario");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al realizar la busqueda");
      console.error("Error in request:", error);
      alert("Error en el servicio");
      // setShowLoader(false);
      setShowLoader(false); 
    }
  };
  const [grupoActual, setGrupoActual] = useState(() => {
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null ? parseInt(grupo) : currentUser.group_identifier;
  });
  return (
    <RowCotizacionStyles
      roleUser={currentUser.role}
      optionTableSelected={optionTableSelected}
      group_identifier={grupoActual}
    >
      <div className="content-row" onClick={getCotizacion}>
        <div>
          <h3>{quote.creation_date}</h3>
        </div>
        <div>
          <h3>{quote.creator_user}</h3>
        </div>
        <div>
          <h3>{quote.agency}</h3>
        </div>
        <div>
          <h3>{quote.quote_identifier}</h3>
        </div>
        <div>
          <h3>{quote.name}</h3>
        </div>
        <div>
          <h3>{quote.arrival_date}</h3>
        </div>
        <div>
          <h3>{quote.departure_date} </h3>
        </div>
        {(grupoActual == 1 ||
          grupoActual == 1017 ||
          grupoActual == 1013 ||
          grupoActual == 1012) &&
          optionTableSelected !== 2 && (
            <div data-no-clickeable>
              <SelectListdefault
                style={{
                  width: "90%",
                  maxWidth: "90%",
                }}
                id="sl6"
                onClick={manejarSelectClick}
                onChange={(e) => {
                  console.log("user_identifier: ");
                  console.log(quote.assigned_user_identifier);
                  console.log("ELEMENTO ACTUAL:");
                  console.log(e.target.value);

                  assignQuote(parseInt(e.target.value));
                }}
                value={
                  (quote.assigned_user_identifier &&
                    listUsuariosOperacion.find(
                      (user, index) =>
                        user.user_identifier == quote.assigned_user_identifier
                    )?.user_identifier) ||
                  ""
                }
              >
                <option value="" disabled selected>
                  usuario
                </option>

                {listUsuariosOperacion?.length > 0
                  ? listUsuariosOperacion.map((item) => {
                      return (
                        <option
                          key={item.user_identifier}
                          value={item.user_identifier}
                          // selected={
                          //   item.user_identifier ==
                          //   quote.assigned_user_identifier
                          // }
                        >
                          {item.user_name}
                        </option>
                      );
                    })
                  : null}
              </SelectListdefault>
            </div>
          )}
      </div>
    </RowCotizacionStyles>
  );
}

const RowCotizacionStyles = styled.div<stylesTableI>`
  .content-row {
    display: grid;
    grid-template-columns: ${(props) =>
      (props.group_identifier == 1 ||
        props.group_identifier == 1017 ||
        props.group_identifier == 1013 ||
        props.group_identifier == 1012) &&
      props.optionTableSelected !== 2
        ? "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        : "1fr 1fr 1fr 1fr 1fr 1fr 1fr"};
    border: grey solid 1px;
    align-items: center;
    justify-content: center;
  }
  .content-row div {
    padding: 0.6rem;
  }
  .content-row div h3 {
    font-weight: 200;
    text-align: center;
    font-size: 0.85vw;
  }
  @media screen and (max-width: 900px) {
    .content-row div h3 {
      font-size: 1.2vw;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  .fake-icon {
    max-width: 10px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
