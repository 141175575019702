import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import RowCotizacion from "./Components/RowCotizacion";
import { QuoteItemI } from "./InterfacesOperacion/OperacionesI";
import IndividualDay from "./Components/IndividualDay";
import IndividualTransporte from "./Components/IndividualTransporte";
import IndividualGeneral from "./Components/IndividualGeneral";
import IndividualDayOpt from "./Components/IndividualDayOpt";
import IndividualTransporteOpt from "./Components/IndividualTransporteOpt";
import IndividualGeneralOpt from "./Components/IndividualGeneralOpt";

export default function ListServices() {
  const { dataCotizacionContext, setDataCotizacionContext } =
    useContext(AppContext);

  const [checkboxAll, setCheckBoxAll] = useState<boolean>(
    dataCotizacionContext.personalInfo.checkCopyAllDays ? true : false
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /////////////////Tocaste servicios individuales

    const newArrayServiceDays =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (diaServicios, index) => {
          return {
            ...diaServicios,
            serviciosIndividuales: diaServicios.serviciosIndividuales.map(
              (currentService, index) => {
                return {
                  ...currentService,
                  isIncludedOPS: event.target.checked,
                };
              }
            ),
          };
        }
      );
    ///////////////////////////////////Tocaste Transporte

    const newArrayServiceTransporte =
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
        (diaServicios, index) => {
          return {
            ...diaServicios,
            serviciosIndividuales: diaServicios.serviciosIndividuales.map(
              (currentService, index) => {
                return {
                  ...currentService,
                  isIncludedOPS: event.target.checked,
                };
              }
            ),
          };
        }
      );

    /////////////////////////////    general
    const newArrayServiceGeneral = dataCotizacionContext.generalServices.map(
      (currentGeneral, index) => {
        return {
          ...currentGeneral,
          isIncludedOPS: event.target.checked,
        };
      }
    );

    ///////////////////////////////////////////////
    setDataCotizacionContext({
      ...dataCotizacionContext,
      generalServices: newArrayServiceGeneral,
      serviciosDayElementsTransporte: {
        servicioDayElement: newArrayServiceTransporte,
      },
      serviciosDayElements: {
        servicioDayElement: newArrayServiceDays,
      },
    });

    setCheckBoxAll(event.target.checked);
  };
  useEffect(() => {
    console.log("Cambio GLOBAL DE COTIZACION::");
    console.log(dataCotizacionContext);
  }, [dataCotizacionContext]);
  return (
    <ListServicesStyles>
      <div className="header-table-quotes">
        <div>
          <h3>Proveedor</h3>
        </div>
        <div>
          <h3>Servicio</h3>
        </div>
        <div>
          <h3>Tarifa</h3>
        </div>
        <div>
          <h3>Vigencia</h3>
        </div>
        <div>
          <h3>Fecha</h3>
        </div>

        <div className="combo">
          <h3>Seleccionar todo</h3>

          <input
            type="checkbox"
            checked={checkboxAll}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
      {dataCotizacionContext?.serviciosDayElements?.servicioDayElement.map(
        (servicioIndividualDia, index) => {
          return (
            <>
              <IndividualDay
                key={servicioIndividualDia.identifierDay}
                servicioIndividualDia={servicioIndividualDia}
                checkboxAll={checkboxAll}
                setCheckBoxAll={setCheckBoxAll}
              />
            </>
          );
        }
      )}
      {dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
        (servicioDayTransporte, index) => {
          return (
            <>
              <IndividualTransporte
                key={servicioDayTransporte.service_identifier}
                servicioDayTransporte={servicioDayTransporte}
                checkboxAll={checkboxAll}
                setCheckBoxAll={setCheckBoxAll}
              />
            </>
          );
        }
      )}
      {dataCotizacionContext?.generalServices.map((servicioGeneral, index) => {
        return (
          <>
            <IndividualGeneral
              key={servicioGeneral.identifierGeneralService}
              servicioGeneral={servicioGeneral}
              checkboxAll={checkboxAll}
              setCheckBoxAll={setCheckBoxAll}
            />
          </>
        );
      })}
      <br />
      {/*Traer opcionales*/}
      {/* OPCIONALES DEL DIA */}
      {dataCotizacionContext?.serviciosDayElementsOpt?.servicioDayElement.map(
        (servicioIndividualDia, index) => {
          return (
            <>
              <IndividualDayOpt
                key={servicioIndividualDia.identifierDay}
                servicioIndividualDia={servicioIndividualDia}
                checkboxAll={checkboxAll}
                setCheckBoxAll={setCheckBoxAll}
              />
            </>
          );
        }
      )}
      {/* Opcionales transporte */}
      {dataCotizacionContext?.serviciosDayElementsTransporteOpt?.servicioDayElement.map(
        (servicioDayTransporte, index) => {
          return (
            <>
              <IndividualTransporteOpt
                key={servicioDayTransporte.service_identifier}
                servicioDayTransporte={servicioDayTransporte}
                checkboxAll={checkboxAll}
                setCheckBoxAll={setCheckBoxAll}
              />
            </>
          );
        }
      )}
      {/* OPCIONALES GENERAL */}
      {dataCotizacionContext?.generalServicesOpt?.map((servicioGeneral, index) => {
        return (
          <>
            <IndividualGeneralOpt
              key={servicioGeneral.identifierGeneralService}
              servicioGeneral={servicioGeneral}
              checkboxAll={checkboxAll}
              setCheckBoxAll={setCheckBoxAll}
            />
          </>
        );
      })}
    </ListServicesStyles>
  );
}

const ListServicesStyles = styled.div`
  .combo input {
    margin-left: 0.5rem;
  }
  .header-table-quotes {
    border: black solid 1px;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0.8rem;
    background-color: rgb(8, 166, 165);
  }
  .header-table-quotes div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h3 {
    font-weight: 300;
    font-size: 0.85vw;
    color: white;
    text-align: center;
  }
`;
