import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDown,
  faBan,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import edit from "../../../images/edit.png";
import {
  cotizacionListIndividual,
  cotizacionListResults,
  cotizacionListTriple,
  servicioDayElementI,
  servicioIndividual,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import deletelogo from "../../../images/deletelogo.png";
import ElementIndividualService from "./ElementIndividualService";
import copiar from "../../../images/DUPLICAR.png";
import CopyDay from "./CopyDay";
import {
  obtenerFechaSinHora,
  verificarFechaEnIntervalo,
} from "../RegisterNewProvider-Client/ExtraFunctions.ts/FunctionsTwo";
import { ValiditySearch } from "../../../interfaces/Cotizacion";
import { transformarFecha } from "./funciontes";

interface ElementServiceDayI {
  servicio: servicioDayElementI;
  numberDay: number;
  servicioPasajeroList: servicioDayElementI[];
  setServicioPasajeroList: React.Dispatch<
    React.SetStateAction<servicioDayElementI[]>
  >;
  listSingle: cotizacionListIndividual[];
  setListSingle: React.Dispatch<
    React.SetStateAction<cotizacionListIndividual[]>
  >;
  listTriple: cotizacionListTriple[];
  setListTriple: React.Dispatch<React.SetStateAction<cotizacionListTriple[]>>;
}
interface ElementServiceStylesI {
  serviciosType: number;
}

export default function ElementServiceDay({
  servicio,
  numberDay,
  servicioPasajeroList,
  setServicioPasajeroList,
  listSingle,
  setListSingle,
  listTriple,
  setListTriple,
}: ElementServiceDayI) {
  const [showElement, setShowElement] = useState<boolean>(false);
  const [editionMode, setEditionMode] = useState<boolean>(false);
  const [blockMode, setBlockMode] = useState<boolean>(false);
  const {
    setShowSearchServices,
    setIdCurrentDayService,
    setCotizationDate,
    setServiciosType,
    dataCotizacionContext,
    setDataCotizacionContext,
    route,
    setRoute,
    currentCurrency,
    serviciosType,
    numberDaySearch,
    setNumberDaySearch,
  } = useContext(AppContext);
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Enter") {
        setEditionMode(false);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Verificar si se hizo clic fuera del componente ElementServiceDay
      if (
        event.target instanceof Element &&
        !event.target.closest(".header-service-day")
      ) {
        setEditionMode(false);
      }
    };

    // Agregar el evento de escucha para el clic fuera del componente
    document.addEventListener("mousedown", handleClickOutside);

    // Limpiar el evento de escucha al desmontar el componente
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeRoute = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newServicios: Array<servicioDayElementI> =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (servicioDia, index) => {
          return servicioDia.identifierDay != servicio.identifierDay
            ? servicioDia
            : {
                ...servicioDia,
                travelRoute: e.target.value,
              };
        }
      );
    setDataCotizacionContext({
      ...dataCotizacionContext,
      serviciosDayElements: {
        servicioDayElement: newServicios,
      },
    });
  };
  const toggleDay = () => {
    const newServicios: Array<servicioDayElementI> =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (servicioDia, index) => {
          return servicioDia.identifierDay != servicio.identifierDay
            ? servicioDia
            : {
                ...servicioDia,
                isAvailable: !servicioDia.isAvailable,
              };
        }
      );

    setDataCotizacionContext({
      ...dataCotizacionContext,
      serviciosDayElements: {
        servicioDayElement: newServicios,
      },
    });
    // setBlockMode(!blockMode);
  };
  const totalDiaTarifa = servicio?.serviciosIndividuales?.reduce(
    (total, item) =>
      total +
      item.tarifa *
        (item.number_of_services == 0 ? 1 : item.number_of_services),
    0
  );

  const [isShownCopy, setIsShownCopy] = useState<boolean>(false);
  const copyDay = () => {
 
    const daysToCopyList: servicioIndividual[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement[
        numberDay - 1
      ].serviciosIndividuales;

    // const currentDay = servicio
    if (daysToCopyList.length == 0) {
      alert("El día está vacio.");
      setIsShownCopy(false);
      return;
    }
    let numeroIngresado: number | boolean =
      dataCotizacionContext.personalInfo.dayToCopy ?? false;
    if (
      !numeroIngresado &&
      !dataCotizacionContext.personalInfo.checkCopyAllDays
    ) {
      alert("Por favor, introduce el día al cual quieres copiar.");
      return;
    }
    if (
      (dataCotizacionContext.serviciosDayElements.servicioDayElement.length <
        (dataCotizacionContext.personalInfo.dayToCopy ?? -1) ||
        (dataCotizacionContext.personalInfo.dayToCopy ?? -1) < 0) &&
      !dataCotizacionContext.personalInfo.checkCopyAllDays
    ) {
      alert("Tu número no está dentro del rango.");
      return;
    }
    if (!dataCotizacionContext.personalInfo.checkCopyAllDays) {
      ////////////Aquí sólo se copia un día
      const daysToCopy: servicioIndividual[] =
        dataCotizacionContext.serviciosDayElements.servicioDayElement[
          numberDay - 1
        ].serviciosIndividuales;
      const dayNeeded: number =
        dataCotizacionContext.personalInfo.dayToCopy ?? -1;
      if (dayNeeded == -1) {
        alert("Por favor, introduce el día al cual quieres copiar--");
        return;
      }
      const newDaysServices: servicioDayElementI[] =
        dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
          (dia, index) => {
            let daysToCopyValidated: servicioIndividual[] = [];
            if (dayNeeded - 1 == index) {
              // const diasAnalizados = daysToCopy.map((diaActual, index)=>{
              //   return {
              //     nombreServicio: diaActual.service_name,
              //     vigenciaValida:  verificarFechaEnIntervalo(dia.validityStartDay, diaActual.vigencias)
              //   }
              // })
              // console.log("A este día se va a copiar: ", dia.validityStartDay);
              // console.log("Así queda el array de vigencias: ");
              // console.log(diasAnalizados);
              daysToCopyValidated = daysToCopy.map((diaActual, index) => {
                const servicioModified: servicioIndividual = {
                  ...diaActual,
                  isValidVigencia: verificarFechaEnIntervalo(
                    dia.validityStartDay,
                    diaActual.vigencias
                  ),
                  individual_service_identifier: 0,
                };

                return servicioModified;
              });
            }
            //// SE copian los viejos y los nuevos servicios, en caso de encontrar el match
            return dayNeeded - 1 != index
              ? dia
              : {
                  ...dia,
                  serviciosIndividuales: [
                    ...dia.serviciosIndividuales,
                    ...daysToCopyValidated,
                  ],
                };
          }
        );
      setDataCotizacionContext({
        ...dataCotizacionContext,
        serviciosDayElements: {
          servicioDayElement: newDaysServices,
        },
      });
      setIsShownCopy(false);
      return;
    }
    console.log("Se muestran todos los servicios");
    console.log(
      dataCotizacionContext.serviciosDayElements.servicioDayElement[0]
    );
    console.log("Desde el día: ");
    console.log(numberDay);
    console.log("Lo copiaras al día: ");
    console.log(dataCotizacionContext.personalInfo.dayToCopy);
    console.log("Los siguientes servicios:");
    console.log(
      dataCotizacionContext.serviciosDayElements.servicioDayElement[
        numberDay - 1
      ].serviciosIndividuales
    );
    const daysToCopy: servicioIndividual[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement[
        numberDay - 1
      ].serviciosIndividuales;

    const newDaysServices: servicioDayElementI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (dia, index) => {
          // console.log(verificarFechaEnIntervalo(servicio.validityStartDay, dia));
          let daysToCopyValidated: servicioIndividual[] = daysToCopy.map(
            (diaActual, index) => {
              const servicioModified: servicioIndividual = {
                ...diaActual,
                isValidVigencia:   
                verificarFechaEnIntervalo(
                  dia.validityStartDay,
                  diaActual.vigencias
                ),
                individual_service_identifier: 0,
              };

              return servicioModified;
            }
          );
          return index == numberDay - 1
            ? dia
            : {
                ...dia,
                serviciosIndividuales: [
                  ...dia.serviciosIndividuales,
                  ...daysToCopyValidated,
                ],
              };
        }
      );
    setDataCotizacionContext({
      ...dataCotizacionContext,
      serviciosDayElements: {
        servicioDayElement: newDaysServices,
      },
    });
    console.log("Nueva forma");
    console.log(newDaysServices);
    setIsShownCopy(false);
  };
  const cancelCopy = () => {
    setIsShownCopy(false);
    setDataCotizacionContext({
      ...dataCotizacionContext,
      personalInfo: {
        ...dataCotizacionContext.personalInfo,
        dayToCopy: undefined,
        dayFromToCopy: undefined,
      },
    });
  };
  return (
    <>
      <ElementServiceDayStyles serviciosType={serviciosType}>
        <div className="header-service-day">
          <div className="row-service">
            <div
              className="fist-header-child"
              style={{
                backgroundColor: !servicio.isAvailable ? "grey" : "",
              }}
            >
              <div className="fist-header-child-child">
                {serviciosType == 1 ? (
                  <>
                    <div>
                      <h3>Día {numberDay}</h3>
                    </div>
                    <div>
                      <h3>
                        {!servicio.validityStartDay.trim()
                          ? ""
                          : transformarFecha(servicio.validityStartDay)}
                      </h3>
                    </div>
                  </>
                ) : (
                  <div>
                    <h3>Servicios de Transporte</h3>
                  </div>
                )}

                {serviciosType !== 3 && (
                  <div
                    onClick={() => {
                      setEditionMode(true);
                    }}
                  >
                    {!editionMode ? (
                      <h3>
                        {!servicio.travelRoute.trim()
                          ? "Ingresa ruta"
                          : servicio.travelRoute}
                      </h3>
                    ) : (
                      <input
                        type="text"
                        value={servicio.travelRoute}
                        onChange={(e) => changeRoute(e)}
                      />
                    )}
                  </div>
                )}
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="arrow-down"
                  onClick={() => {
                    setShowElement(!showElement);
                  }}
                />
              </div>
            </div>
            <div className="icons-container">
              {/* {servicio.isAvailable ? (
                <FontAwesomeIcon
                  icon={faBan}
                  className="fa-ban-icon"
                  onClick={toggleDay}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faRotateRight}
                  className="fa-ban-icon reset-icon"
                  onClick={toggleDay}
                />
              )} */}
              {serviciosType == 1 && (
                <img
                  src={copiar}
                  width="20px"
                  className="copy-symbol"
                  onClick={() => {
                    setDataCotizacionContext({
                      ...dataCotizacionContext,
                      personalInfo: {
                        ...dataCotizacionContext.personalInfo,
                        dayFromToCopy: numberDay,
                      },
                    });
                    setIsShownCopy(true);
                  }}
                />
              )}
            </div>
          </div>

          {showElement && servicio.isAvailable && (
            <div className="box-subheader">
              <div className="sub-header-cotizacion">
                <div>
                  <h3>Servicio</h3>
                </div>
                <div>
                  {serviciosType == 1 ? <h3>Categoría</h3> : <h3>Capacidad</h3>}
                </div>
                {serviciosType == 1 && (
                  <div>
                    <h3>Vigencia</h3>
                  </div>
                )}

                <div>
                  <h3>Proveedor</h3>
                </div>
                <div>
                  {serviciosType == 1 ? (
                    <h3>Ocupación</h3>
                  ) : (
                    <h3>Número de servicios</h3>
                  )}
                </div>
                <div>
                  <h3>Tarifa[{currentCurrency == 1 ? "USD" : "MXN"}]</h3>
                </div>
              </div>
              <div className="box-buttons">
                {servicio.serviciosIndividuales.length == 0 && (
                  <img
                    src={agregar}
                    alt=""
                    width={"25"}
                    onClick={() => {
                      console.log("TOCASTE EL DIA CON ID: ");

                      console.log(servicio.identifierDay);
                      setShowSearchServices(true);
                      setIdCurrentDayService(servicio.identifierDay);
                      console.log("DIA: ");
                      console.log(servicio.validityStartDay);
                      setCotizationDate(servicio.validityStartDay);
                      setRoute(servicio.travelRoute);
                      setNumberDaySearch(numberDay);
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {showElement && servicio.isAvailable && (
            <div>
              {servicio.serviciosIndividuales.map(
                (serviceIndividual, index) => {
                  return (
                    <>
                      <ElementIndividualService
                        serviceIndividual={serviceIndividual}
                        servicioPasajeroList={servicioPasajeroList}
                        setServicioPasajeroList={setServicioPasajeroList}
                        servicioDay={servicio}
                        key={serviceIndividual.identifierService}
                        isShowingAddButton={
                          servicio.serviciosIndividuales.length - 1 == index
                            ? true
                            : false
                        }
                        listSingle={listSingle}
                        setListSingle={setListSingle}
                        listTriple={listTriple}
                        setListTriple={setListTriple}
                      />
                    </>
                  );
                }
              )}
              {showElement &&
                servicio.isAvailable &&
                servicio.serviciosIndividuales.length > 0 && (
                  <div className="suma-datos">
                    <h3>Total del día: {totalDiaTarifa.toFixed(2)}</h3>
                  </div>
                )}
            </div>
          )}
        </div>
      </ElementServiceDayStyles>
      {isShownCopy && (
        <CopyDay
          setIsShownCopy={setIsShownCopy}
          onCopy={copyDay}
          onCancel={cancelCopy}
          numberDay="s"
        />
      )}
    </>
  );
}

const ElementServiceDayStyles = styled.div<ElementServiceStylesI>`
  .suma-datos {
    width: 95%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .copy-symbol {
    margin-left: 0.3rem;
    cursor: pointer;
  }
  .suma-datos h3 {
    font-size: 0.85vw;
  }
  @media screen and (max-width: 900px) {
    .suma-datos h3 {
      font-size: 1.6vw;
    }
    .suma-datos {
      width: 95%;
    }
  }
  .service-individual {
    display: flex;
    width: 95%;
    margin: 0 auto;
  }
  .botones-servicio img {
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    .service-individual {
      width: 95%;
    }
    .info-servicio div h3 {
      text-align: center;
      font-size: 1.5vw !important;
    }
    .botones-servicio img {
      width: 25px;
    }
  }
  .info-servicio {
    border: black solid 1px;
    width: 88%;
    display: grid;
    grid-template-columns: ${(props) =>
      props.serviciosType == 1
        ? "1fr 1fr 1fr 1fr 0.5fr 1fr"
        : "1fr 1fr 1fr 1fr 1fr"};
    grid-column-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }
  .info-servicio div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
  .botones-servicio {
    width: 12%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .box-subheader {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  margin-bottom: 1.5rem;
  .icons-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .fa-ban-icon {
    font-size: 1.3vw;
    cursor: pointer;
    color: #ff8400;
  }
  @media screen and (max-width: 900px) {
    /* .fa-ban-icon{
    font-size: 2.5vw;
    
  }  */
  }
  .sub-header-cotizacion {
    width: 88%;
    border: grey solid 1px;

    padding: 0.5rem;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
    grid-template-columns: ${(props) =>
      props.serviciosType == 1
        ? "1fr 1fr 1fr 1fr 0.5fr 1fr"
        : "1fr 1fr 1fr 1fr 1fr"};
  }
  .sub-header-cotizacion div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
  @media screen and (max-width: 900px) {
    .sub-header-cotizacion {
    }
  }

  h3 {
    /* color: white; */
  }
  .arrow-down {
    color: white;
    font-size: 1.5vw;
    margin-right: 1rem;
    cursor: pointer;
  }
  .row-service {
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
  .fist-header-child {
    width: 88%;
    background-color: ${(props) =>
      props.serviciosType == 3 ? "orange" : "rgb(8, 166, 165)"};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fist-header-child-child {
    display: flex;
    padding: 0.7rem;
  }
  .fist-header-child div h3 {
    margin-right: 1rem;
    color: white;
    font-size: 0.85vw;
  }
  .box-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 12%;
  }
  .box-buttons img {
    cursor: pointer;
  }
  .reset-icon {
    color: #59b033 !important;
  }
  @media screen and (max-width: 900px) {
    .box-buttons img {
      width: 15px;
    }
    .box-service,
    .row-subheader {
      width: 100%;
    }
    .box-subheader,
    .row-service {
      min-width: 95% !important;
    }
    .sub-header-cotizacion div h3 {
      font-size: 1.5vw;
    }
    .fist-header-child div h3 {
      font-size: 1.5vw;
    }
    .fa-ban-icon {
      font-size: 2.5vw;
    }
  }
`;
