import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ListResultsPago from "./ListResultsPago";
import { fetchServer } from "../../../services/fetchServer";
import { AppContext } from "../../../context/appContext";
// import clean from '../../../images/c'
import {
  PaymentSearchI,
  ResponsePaymentSearch,
} from "./InterfacesContabilidad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom } from "@fortawesome/free-solid-svg-icons";

export default function SearchPagos() {
  const { setShowLoader } = useContext(AppContext);
  const fechaActual: Date = new Date();

  // Obtiene los componentes de la fecha
  const año: number = fechaActual.getFullYear();
  const mes: number = fechaActual.getMonth() + 1; // ¡Recuerda que los meses comienzan desde 0!
  const dia: number = fechaActual.getDate();

  // Formatea la fecha en el formato deseado
  const fechaFormateada: string = `${año}-${mes < 10 ? "0" : ""}${mes}-${
    dia < 10 ? "0" : ""
  }${dia}`;
  const [objectDates, setObjectDates] = useState({
    startDate: fechaFormateada,
    endDate: "",
  });

  const [listResultSearch, setListResultsSearch] = useState<PaymentSearchI[]>(
    []
  );

  const searchResults = async () => {
    const bodySearch = {
      initial_date: objectDates.startDate,
      final_date: objectDates.endDate,
    };
    setShowLoader(true);
    const resJson: ResponsePaymentSearch = await fetchServer(
      "/accounting/payments/search",
      "post",
      bodySearch
    );
    setShowLoader(false);
    console.log("Respuesta JSON");
    console.log(resJson);
    console.log(resJson.data.payments);
    if(resJson.data.payments){
      if(resJson.data.payments.length > 0){
        setListResultsSearch(resJson.data.payments);
      }else{
        alert("Sin resultados"); 
      }
    }
   
  };
  const cleanInformation = () => {
    setObjectDates({
      startDate: "",
      endDate: "",
    });
  };

  return (
    <SearchPagosStyle>
      <h3 className="title-section">Pagos Realizados</h3>
      <br />
      <br />
      <div className="input-dates">
        <input
          type="date"
          onChange={(e) => {
            setObjectDates({
              ...objectDates,
              startDate: e.target.value,
            });
          }}
          value={objectDates.startDate}
        />
        <input
          type="date"
          value={objectDates.endDate}
          onChange={(e) => {
            setObjectDates({
              ...objectDates,
              endDate: e.target.value,
            });
          }}
        />

        <button onClick={searchResults}>Buscar</button>
        <div className="deleteIcon-box">
          <FontAwesomeIcon
            className="deleteIcon"
            icon={faBroom}
            onClick={cleanInformation}
          />
        </div>
      </div>

      <ListResultsPago
        listResultSearch={listResultSearch}
        setListResultsSearch={setListResultsSearch}
      />
    </SearchPagosStyle>
  );
}

const SearchPagosStyle = styled.div`
  margin-top: 2rem;
  .input-dates {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: 0 auto;
    align-items: center;
  }
  .title-section {
    color: rgb(8, 166, 165);
    text-align: center;
  }
  .deleteIcon {
    font-size: 1.2rem;
    border-radius: 50%;
    padding: 0.6rem;
    cursor: pointer;
    background-color: #29a5dc;
    color: white;
  }
`;
