import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";

interface copyProps {
  onCopy: () => void;
  onCancel: () => void;
  numberDay: string;
  setIsShownCopy: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CopyDay({
  setIsShownCopy,
  onCopy,
  onCancel,
  numberDay,
}: copyProps) {
  const {
    setShowSearchServices,
    setIdCurrentDayService,
    setCotizationDate,
    setServiciosType,
    dataCotizacionContext,
    setDataCotizacionContext,
    route,
    setRoute,
    currentCurrency,
    serviciosType,
    numberDaySearch,
    setNumberDaySearch,
  } = useContext(AppContext);
  const handleClose = () => {
    setIsShownCopy(false);
    setDataCotizacionContext({
      ...dataCotizacionContext,
      personalInfo: {
        ...dataCotizacionContext.personalInfo,
        dayToCopy: undefined,
        dayFromToCopy: undefined,
      },
    });
  };
  const [saveAllDays, setSaveAllDays] = useState<boolean>(false);
  
  const handleCheckboxChange = () => {
    setSaveAllDays(!saveAllDays);
    setDataCotizacionContext({
        ...dataCotizacionContext,
        personalInfo: {
            ...dataCotizacionContext.personalInfo,
            checkCopyAllDays: !saveAllDays
        }
    })
  };
  return (
    <CopyDayStyle>
      <div className="card-message-container">
        <div className="card-box-x">
          <FontAwesomeIcon
            icon={faXmark}
            className="x-mark"
            onClick={() => handleClose()}
          />
        </div>
        <div className="card-box-text">
          <h4>¿A qué día deseas copiar los servicios?</h4>
          <br />
          {/* <h5>{itemName}</h5> */}
        </div>
        <div className="container-inputs">
          <div>
            <h3 className="title-day">Del día {dataCotizacionContext.personalInfo.dayFromToCopy}</h3>
          </div>
          <div>
            <h3 className="title-day">Al día</h3>
            <input
              type="text"
              onChange={(e) => {
                setDataCotizacionContext({
                  ...dataCotizacionContext,
                  personalInfo: {
                    ...dataCotizacionContext.personalInfo,
                    dayToCopy: parseInt(e.target.value),
                  },
                });
              }}
            />
          </div>
          
        </div>
        <div className="container-checbox">
        <input
          type="checkbox"
          checked={saveAllDays}
          onChange={handleCheckboxChange}
        />
      <label  >
        Todos los días
      </label>
   
    </div>
        <div>
          <div className="button-container-box">
            <button onClick={onCopy} className="boton-estilos">
              Aceptar
            </button>
            <button onClick={onCancel} className="boton-estilos">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </CopyDayStyle>
  );
}

const CopyDayStyle = styled.div`
.container-checbox{
    width: 90%;
    margin: 0 auto;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
}
.container-checbox label{
    display: flex;
    align-items: center;
}
.container-checbox label{
    margin-left: 1rem;
}
label{
    font-size: 0.85rem;
    
}
.title-day{
    color: black;
    font-size : 0.86rem;
    margin-right: 0.5rem;
}
  .container-inputs {
 
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto; 
  }
  .container-inputs div{
    display: flex;
    align-items: center;
  }
  .container-inputs div input{
    display: flex;
    width: 100px;
    border: rgb(8, 166, 165) 2px solid;
    outline: none;
    padding: 0.25rem;
  }
  .boton-estilos {
    padding: 0.5rem;
    border: none;
    background-color: transparent;
    border: none;
    padding: 1rem;
    background-color: rgb(8, 166, 165);
    border-radius: 0.8rem;
    padding: 0.5rem 2rem;
    color: white;
    cursor: pointer;
    margin-left: 1rem;
  }
  .button-container-box {
    display: flex;
    justify-content: center;
    margin-top: 1.2rem;
  }
  @media screen and (max-width: 900px) {
  }
  border: black solid 1px;
  position: fixed;
  top: 0;
  left: 0;
  max-height: 100vh;
  overflow-y: hidden;

  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .card-message-container {
    border: white solid 1px;
    width: 380px;
    height: 240px;
    background-color: white;
    border-radius: 0.8rem;
  }
  .card-box-x {
    margin: 0rem 0.5rem 0.5rem 0rem;
    display: flex;
    justify-content: flex-end;
  }
  .card-box-text {
    width: 90%;
    margin: 0 auto;
  }
  .card-box-text h4 {
    font-size: 1.1rem;
    color: #08a6a5;
  }
  .card-box-text h5 {
    font-size: 0.9rem;
  }
  .x-mark {
    font-size: 2rem;
    cursor: pointer;
  }

  @media screen and (min-width: 900px) {
    .card-message-container {
      border: black solid 1px;
      width: 500px;
      height: 260px;
      padding-top: 1rem;
      padding-right: 0.3rem;
    }
  }
`;
