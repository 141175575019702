import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import { QuoteItemI } from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import IndividualService from "./IndividualService";
import { QuoteGeneralElement } from "../../../../interfaces/GeneralTypes";

interface IndividualGeneralI {
  servicioGeneral: QuoteGeneralElement;
  checkboxAll: boolean;
  setCheckBoxAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function IndividualGeneral({
  servicioGeneral,
  checkboxAll,
  setCheckBoxAll,
}: IndividualGeneralI) {
  const { dataCotizacionContext, setDataCotizacionContext } =
    useContext(AppContext);

  const [isChecked, setIsChecked] = useState(servicioGeneral.isIncludedOPS);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setIsChecked(event.target.checked);
    console.log("COMBO BOX!!!!!!!!!!!!!!!!!!!!!!!!::");
    console.log(event.target.checked);
    const newArrayService = dataCotizacionContext.generalServices.map(
      (currentGeneral, index) => {
        return currentGeneral.identifierGeneralService !==
          servicioGeneral.identifierGeneralService
          ? currentGeneral
          : {
              ...currentGeneral,
              isIncludedOPS: event.target.checked,
            };
      }
    );
    // const newArrayService = dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map((diaServicios, index)=>{
    //   return diaServicios.identifierDay !== servicioDayTransporte.identifierDay ? diaServicios : {
    //     ...diaServicios,
    //     serviciosIndividuales: diaServicios.serviciosIndividuales.map((currentService, index)=>{
    //       return currentService.identifierService !== servicioTransporte.identifierService ? currentService : {
    //         ...currentService,
    //         isIncludedOPS: event.target.checked
    //       }
    //     })
    //   }
    // })
    console.log("Asi queda el nuevo arreglo de servicios:");
    // console.log(newArrayService);
    console.log("-------------------------------------------------------");
    setDataCotizacionContext({
      ...dataCotizacionContext,
      generalServices: newArrayService,
    });
    setIsChecked(event.target.checked);
    if(!event.target.checked){
      setCheckBoxAll(false); 
    }
  };
  useEffect(() => {
    setIsChecked(servicioGeneral.isIncludedOPS);
  }, [servicioGeneral.isIncludedOPS]);
  return (
    <IndividualGeneralStyle>
      <div className="header-table-content">
        <div>
          <h3>{servicioGeneral.provider_name}</h3>
        </div>
        <div>
          <h3>{servicioGeneral.service_name}</h3>
        </div>
        <div>
          <h3>{servicioGeneral.fee_price.toFixed(2)}</h3>
        </div>
        <div>
          <h3>Vigencia {servicioGeneral.provider_id}</h3>
        </div>
        <div>
          <h3>fecha requerida</h3>
        </div>

        <div className="combo">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
    </IndividualGeneralStyle>
  );
}

const IndividualGeneralStyle = styled.div`
  .header-table-content {
    border: black solid 1px;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    align-items: center;
  }
  h3 {
    color: black !important;
  }
  .combo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
