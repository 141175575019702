import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDown,
  faBan,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import edit from "../../../images/edit.png";
import {
  cotizacionListIndividual,
  cotizacionListResults,
  cotizacionListTriple,
  servicioDayElementI,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import deletelogo from "../../../images/deletelogo.png";
import { servicioIndividual } from "../../../context/InterfacesContext";
import {
  GeneralServiceI,
  IndividualServiceI,
  QuoteObjectI,
  ServiceIndividualI,
} from "../../../interfaces/GeneralTypes";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import Draggable from "react-draggable";
import { obtenerFechaSinHora } from "../RegisterNewProvider-Client/ExtraFunctions.ts/FunctionsTwo";
interface ElementI {
  serviceIndividual: servicioIndividual;
  servicioPasajeroList: servicioDayElementI[];
  setServicioPasajeroList: React.Dispatch<
    React.SetStateAction<servicioDayElementI[]>
  >;
  servicioDay: servicioDayElementI;
  isShowingAddButton: boolean;
  listSingle: cotizacionListIndividual[];
  setListSingle: React.Dispatch<
    React.SetStateAction<cotizacionListIndividual[]>
  >;
  listTriple: cotizacionListTriple[];
  setListTriple: React.Dispatch<React.SetStateAction<cotizacionListTriple[]>>;
}
interface deleteI {
  idServiceIndividual: string;
  idServiceDay: string;
}
interface ElementServiceStyleI {
  serviciosType: number;
}
export default function ElementIndividualService({
  serviceIndividual,
  servicioPasajeroList,
  setServicioPasajeroList,
  servicioDay,
  isShowingAddButton,
  listSingle,
  setListSingle,
  listTriple,
  setListTriple,
}: ElementI) {
  const {
    setShowSearchServices,
    idCurrentDayService,
    setIdCurrentDayService,
    setDataCotizacionContext,
    dataCotizacionContext,
    cotizationDate,
    setCotizationDate,
    setServiciosType,
    cotizacionMode,
    currentCurrency,
    serviciosType,
    showDeleteNotification,
    setShowDeleteNotification,
  } = useContext(AppContext);

  const [editionMode, setEditionMode] = useState<boolean>(false);
  const [dataToDelete, setDataToDelete] = useState<deleteI>({
    idServiceDay: "",
    idServiceIndividual: "",
  });
  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    setIsShownDelete(false);
    borrarServicios(
      serviceIndividual.identifierService,
      servicioDay.identifierDay
    );
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  const changeTarifa = async (multiplo: number) => {


    if (serviciosType == 3) {
      const newServicios: Array<servicioDayElementI> =
        dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
          (servicioDia, index) => {
            return servicioDia.identifierDay != servicioDay.identifierDay
              ? servicioDia
              : {
                  ...servicioDia,
                  serviciosIndividuales: [
                    ...servicioDia.serviciosIndividuales.map(
                      (servicioActual, index) => {
                        return servicioActual.identifierService !=
                          serviceIndividual.identifierService
                          ? servicioActual
                          : {
                              ...servicioActual,
                              number_of_services: multiplo,
                            };
                      }
                    ),
                  ],
                };
          }
        );
      setServicioPasajeroList(newServicios);
    } else {
      const newServicios: Array<servicioDayElementI> =
        dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
          (servicioDia, index) => {
            return servicioDia.identifierDay != servicioDay.identifierDay
              ? servicioDia
              : {
                  ...servicioDia,
                  serviciosIndividuales: [
                    ...servicioDia.serviciosIndividuales.map(
                      (servicioActual, index) => {
                        return servicioActual.identifierService !=
                          serviceIndividual.identifierService
                          ? servicioActual
                          : {
                              ...servicioActual,
                              tarifa: servicioActual.tarifa,
                              number_of_services: multiplo,
                            };
                      }
                    ),
                  ],
                };
          }
        );
      // setDataCotizacionContext({
      //   ...dataCotizacionContext,
      //   serviciosDayElements: {
      //     servicioDayElement: newServicios
      //   }
      // })
    }
  };

  const deleteFromServices = async (idCurrentServicio: string) => {

    if (cotizacionMode == 2 || cotizacionMode == 3 || cotizacionMode == 4)  {
      const arrayIndividual: Array<servicioDayElementI> =
        serviciosType == 1
          ? dataCotizacionContext.serviciosDayElements.servicioDayElement
          : dataCotizacionContext.serviciosDayElementsTransporte
              .servicioDayElement;
      // alert("Eliminando elemento desde Backend");
      const serviciosIndivuales: IndividualServiceI[] = arrayIndividual.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.filter((sI, index)=>{
              // console.log("DISCCRIMINANDO:::");
              // console.log(sI.individual_service_identifier); 
              // console.log(sI);
              // console.log( sI.individual_service_identifier != undefined); 
              return sI.individual_service_identifier != undefined
            }).map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: currentService.serviceType,
                occupation: "Ocupación",
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: sI.tarifaSingleComplementConverted ?? 0,
                triple_complement: sI.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier:
                  sI.identifierService == idCurrentServicio ? 2 : 1,
                number_of_services: sI.number_of_services ?? 1,
                fee_identifier: sI.fee_identifier??0 ,
               
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

      const bodySaveRequest: QuoteObjectI = {
        user_identifier: 1,
        quote: {
          private_quote: true,
          name: dataCotizacionContext.personalInfo.name,
          type: dataCotizacionContext.personalInfo.typeCotizacion,
          agency: dataCotizacionContext.personalInfo.agencia,
          series: dataCotizacionContext.personalInfo.serieId,
          PAX: dataCotizacionContext.personalInfo.pax,
          TL: dataCotizacionContext.personalInfo.TL,
          MIN: dataCotizacionContext.personalInfo.min,
          MAX: dataCotizacionContext.personalInfo.max,
          arrival_date:
            dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
              ? ""
              : dataCotizacionContext.personalInfo.arrivingData,
          departure_date:
            dataCotizacionContext.personalInfo.departureDate == "departureData"
              ? ""
              : dataCotizacionContext.personalInfo.departureDate,
          currency_identifier: currentCurrency,
          commission: dataCotizacionContext.feeCharges.comision,
          commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
          courtesy: dataCotizacionContext.feeCharges.cortesia,
          exchange_rate: dataCotizacionContext.feeCharges.changeType,
          comments: dataCotizacionContext.feeCharges.comments,
          total: dataCotizacionContext.totalPrice.totalPrice,
          quote_register_type:
            dataCotizacionContext.personalInfo.typeCotizacion,
          number_of_days:
            dataCotizacionContext.personalInfo.numberDaysOpenQuote,
          serie:
            dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
              ? true
              : false,
          status_identifier:
            dataCotizacionContext.personalInfo.status_identifier,
          quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
          courtesy_type_identifier:
            dataCotizacionContext.feeCharges.courtesy_type_identifier,
            optional_quote:  dataCotizacionContext.personalInfo.modeCotizacion == "Opcional" ? true: false,
            total_base_sgl: dataCotizacionContext.personalInfo.total_base_dbl,
            total_base_dbl: dataCotizacionContext.personalInfo.total_base_dbl,
            total_base_tpl:dataCotizacionContext.personalInfo.total_base_tpl,
            simple_supplement: dataCotizacionContext.personalInfo.simple_supplement,
            triple_reduction: dataCotizacionContext.personalInfo.triple_reduction,
            operation_identifier: dataCotizacionContext.personalInfo.operation_identifier
        },
        quote_general_services: [],
        quote_individual_services:
          serviciosType == 1 ? serviciosIndivuales : [],
        general_transport_services:
          serviciosType == 3 ? serviciosIndivuales : [],
          optional_groups: [],
          
      };

      const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/update`;
      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodySaveRequest),
        });

        if (response.ok) {
          const data = await response.json();
        } else {
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
      }
      return;
    }
  };
  const borrarServicios = (idCurrentServicio: string, idCurrentDay: string) => {

    console.log("id del tarifa actual!!!"); 
    console.log(idCurrentServicio); 
    console.log("SERVICIO ACTUAL:::");
    console.log(servicioDay); 
    deleteFromServices(idCurrentServicio);
    const arrayIndividual: Array<servicioDayElementI> =
      serviciosType == 1
        ? dataCotizacionContext.serviciosDayElements.servicioDayElement
        : dataCotizacionContext.serviciosDayElementsTransporte
            .servicioDayElement;
    const currentDay: servicioIndividual | undefined=
      dataCotizacionContext.serviciosDayElements.servicioDayElement.filter(
        (servicioDia, index) => {
          return servicioDia.identifierDay == servicioDay.identifierDay;
        }
      )[0]?.serviciosIndividuales.find((servI, index)=>{
        return servI.identifierService == idCurrentServicio
      });
   console.log("servicio actual:");
   console.log(currentDay); 
  
      
    const newListSingles: cotizacionListIndividual[] = listSingle.filter(
      (single, index) => {
        return single.idTarifaIndividual != idCurrentServicio;
      }
    );
    setListSingle(newListSingles);

    const newListTriple: cotizacionListTriple[] = listTriple.filter(
      (triple, index) => {
        return triple.idTarifaIndividual != idCurrentServicio;
      }
    );
    setListTriple(newListTriple);
    // alert(currentDay?.general_service_identifier);
    /////////////////////////////En esta parte se borra un servicio/////////////////////////////
    // if(currentDay?.general_service_identifier == undefined){
      
      
    // }else{
    //   alert("No se está borrando desde servicio"); 
    // }
    

    //////////////////////////////////Fin de borrado de servicios/////////////////////////

    const newServicios: Array<servicioDayElementI> = arrayIndividual.map(
      (servicioDia, index) => {
        return servicioDia.identifierDay != servicioDay.identifierDay
          ? servicioDia
          : {
              ...servicioDia,
              serviciosIndividuales: [
                ...servicioDia.serviciosIndividuales.filter(
                  (servicioActual, index) => {
                    return (
                      servicioActual.identifierService != idCurrentServicio
                    );
                  }
                ),
              ],
            };
      }
    );
    setDataCotizacionContext({
      ...dataCotizacionContext,
      serviciosDayElements: {
        servicioDayElement:
          serviciosType == 1
            ? newServicios
            : dataCotizacionContext.serviciosDayElements.servicioDayElement,
      },
      serviciosDayElementsTransporte: {
        servicioDayElement:
          serviciosType == 3
            ? newServicios
            : dataCotizacionContext.serviciosDayElementsTransporte
                .servicioDayElement,
      },
    });
  };
  // useEffect(()=>{
  //   const getValueCotization: number = dataCotizacionContext.serviciosDayElements.servicioDayElement.filter((servicioDia, index)=>{
  //     return servicioDia.identifierDay == servicioDay.identifierDay
  //   })[0].serviciosIndividuales.find((servicio,i)=>{
  //     return servicio.identifierService ==
  //   })

  // },[dataCotizacionContext])
  const [initialValue, setInitialValue] = useState<string>(
    serviceIndividual.tarifa.toString()
  );
  const [multipleValue, setMultipleValue] = useState<number>(
    serviceIndividual.number_of_services
  );
  useEffect(() => {
    changeTarifa(multipleValue);
  }, [multipleValue]);

const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
  console.log("COMBO BOX!!!!!!!!!!!!!!!!!!!!!!!!::");
  console.log(event.target.checked); 
  if(serviciosType == 3){
    const newArrayService = dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map((diaServicios, index)=>{
      return diaServicios.identifierDay !== servicioDay.identifierDay ? diaServicios : {
        ...diaServicios,
        serviciosIndividuales: diaServicios.serviciosIndividuales.map((currentService, index)=>{
          return currentService.identifierService !== serviceIndividual.identifierService ? currentService : {
            ...currentService,
            isIncludedOpt: event.target.checked
          }
        })
      }
    })
    setDataCotizacionContext({
      ...dataCotizacionContext,
      serviciosDayElementsTransporte: {
        servicioDayElement: newArrayService
      }
    }); 
  }else{
    const newArrayService = dataCotizacionContext.serviciosDayElements.servicioDayElement.map((diaServicios, index)=>{
      return diaServicios.identifierDay !== servicioDay.identifierDay ? diaServicios : {
        ...diaServicios,
        serviciosIndividuales: diaServicios.serviciosIndividuales.map((currentService, index)=>{
          return currentService.identifierService !== serviceIndividual.identifierService ? currentService : {
            ...currentService,
            isIncludedOpt: event.target.checked
          }
        })
      }
    })
    console.log("Así podría quedar tu array:");
    console.log(newArrayService); 
    setDataCotizacionContext({
      ...dataCotizacionContext,
      serviciosDayElements: {
        servicioDayElement: newArrayService
      }
    }); 
  }

} 
  return (
    <>
      <ElementIndividualStyles
        serviciosType={serviciosType}
        className="service-individual"
      >
        <div className={`info-servicio ${serviceIndividual.isValidVigencia == false ? "invalid-date": ""}`}>
          <div>
            <h3>{serviceIndividual.serviceDescripcion}</h3>
          </div>
          <div>
            <h3>{serviciosType == 1 ? serviceIndividual.category: serviceIndividual.capacidad ?? ""}</h3>
          </div>
          {serviciosType == 1 && (
            <div className="box-vigencia">
              {serviceIndividual.vigencias &&
                serviceIndividual.vigencias.map((vigencia, index) => {
                  return (
                    <div>
                          <h3>
                      {obtenerFechaSinHora(vigencia.validity_start_date)} -{" "}
                      {obtenerFechaSinHora(vigencia.validity_end_date)}
                    </h3>
                    </div>
                
                  );
                })}
            </div>
          )}
          <div>
            <h3>{serviceIndividual.providerDescripcion}</h3>
          </div>
          <div>
            {serviciosType == 1 ? (
              <h3>{serviceIndividual.ocupacionDescripcion}</h3>
            ) : (
              <div className="input-container ">
                <input
                  type="text"
                  value={multipleValue}
                  onKeyPress={(e) => {
                    const keyCode = e.keyCode || e.which;
                    const keyValue = String.fromCharCode(keyCode);
                    const regex = /[0-9.]|\./; // Expresión regular para números y punto decimal

                    if (!regex.test(keyValue)) {
                      console.log("BLOQUEADO");
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (value === "") {
                      // setGeneralServiceData({
                      //   ...generalServiceData,
                      //   number_days: 0,
                      // });
                      setMultipleValue(0);
                    } else {
                      const parsedValue = parseFloat(value);
                      if (!isNaN(parsedValue)) {
                        // setGeneralServiceData({
                        //   ...generalServiceData,
                        //   number_days: parsedValue,
                        // });
                        setMultipleValue(parsedValue);
                      }
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div>
            {!editionMode ? (
              <h3>
                $
                {serviciosType == 1
                  ? serviceIndividual.tarifa.toFixed(2)
                  : (
                      serviceIndividual.tarifa *
                      serviceIndividual.number_of_services
                    ).toFixed(2)}
              </h3>
            ) : (
              <input
                type="text"
                className="input-tarifa"
                value={(
                  serviceIndividual.tarifa *
                  serviceIndividual.number_of_services
                ).toString()}
                onKeyPress={(e) => {
                  const keyCode = e.keyCode || e.which;
                  const keyValue = String.fromCharCode(keyCode);
                  const regex = /[0-9.]|\./; // Expresión regular para números y punto decimal

                  if (!regex.test(keyValue)) {
                    console.log("BLOQUEADO");
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  setInitialValue(e.target.value);
                  console.log("VALOR SIN PARSEAR:");
                  console.log(value);
                  if (value === "") {
                    changeTarifa(0);
                  } else {
                    const parsedValue = parseFloat(value);
                    if (!isNaN(parsedValue)) {
                      changeTarifa(parsedValue);
                    }
                  }
                }}
              />
            )}
          </div>
       
        </div>
        <div className="botones-servicio">
          <img
            src={deletelogo}
            alt=""
            width={"25"}
            onClick={() => {
              setIsShownDelete(true);

              handleDeleteClick();
            }}
          />

          {isShowingAddButton ? (
            <img
              src={agregar}
              alt=""
              width={"25"}
              onClick={() => {
                console.log("TOCASTE EL DIA CON ID: ");
                console.log(servicioDay.identifierDay);
                setShowSearchServices(true);
                setIdCurrentDayService(servicioDay.identifierDay);
                console.log("DIA: ");
                console.log(servicioDay.validityStartDay);
                setCotizationDate(servicioDay.validityStartDay);
              }}
            />
          ) : (
            <div className="fake-element"></div>
          )}
        </div>
        {isShownDelete && (
          <DeleteConfirmation
            onDelete={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            itemName={`¿Deseas borrar el servicio ${serviceIndividual.serviceDescripcion}?`}
            setIsShownDelete={setIsShownDelete}
          />
        )}
      </ElementIndividualStyles>
    </>
  );
}

const ElementIndividualStyles = styled.div<ElementServiceStyleI>`
.box-vigencia{
  flex-direction: column;
  
}
  .info-servicio h3 {
    font-weight: 500;
  }
  .input-container input {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .info-servicio div {
    display: flex;
    justify-content: center;
  }
  .invalid-date{
    background-color: #FF5733;
    color: white; 
  }
  .input-tarifa {
    width: 80%;
  }
  .fake-element {
    width: 25px;
  }
  @media screen and (max-width: 900px) {
    .fake-element {
      width: 15px;
    }
  }
`;
