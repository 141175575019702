import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import deletelogo from "../../../../images/deletelogo.png";
import edit from "../../../../images/edit.png";
import { PaymentSearchI } from "../InterfacesContabilidad";
import { fetchServer } from "../../../../services/fetchServer";
import { OperationContext } from "../../../../context/operationContext";
import { useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../../components/common/DeleteConfirmation";
import { AppContext } from "../../../../context/appContext";
import {
  formatearCantidad,
  ocultarNumerosTarjeta,
} from "../../../components/functionsTs/utilidades";
interface ElementoPagoI {
  curentPago: PaymentSearchI;
  listResultSearch: PaymentSearchI[];
  setListResultsSearch: React.Dispatch<React.SetStateAction<PaymentSearchI[]>>;
}
export default function ElementPago({
  curentPago,
  listResultSearch,
  setListResultsSearch,
}: ElementoPagoI) {
  const { setIdProveedorSelected, setIdCurrentPayment, setContabilidadMode } =
    useContext(OperationContext);
  const { setShowLoader } = useContext(AppContext);
  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteElement();
    setIsShownDelete(false);
    ////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  const deleteElement = () => {
    console.log("borrando elemento");
    const newArray: PaymentSearchI[] = listResultSearch.filter(
      (resultCurrent, index) => {
        return (
          resultCurrent.payment_identifier !== curentPago.payment_identifier
        );
      }
    );
    const elementToDelete: PaymentSearchI[] = listResultSearch.filter(
      (resultCurrent, index) => {
        return (
          resultCurrent.payment_identifier == curentPago.payment_identifier
        );
      }
    );
    console.log("elementToDelete");
    console.log(elementToDelete);

    if (elementToDelete.length !== 1) {
      alert("Error al borrar");
      return;
    }
    const bodyDelete = {
      payment_identifier: elementToDelete[0].payment_identifier,
    };
    try {
      setShowLoader(true);
      const resJson = fetchServer(
        "/accounting/payment/delete",
        "post",
        bodyDelete
      );
      setShowLoader(false);
      setListResultsSearch(newArray);
    } catch (error) {}
  };

  const navigate = useNavigate();
  const editPayment = async () => {
    console.log("Editar pago");
    ////accounting/paymentDetail/{paymentID}/{providerID}
    //  const resJson = await fetchServer(`/accounting/paymentDetail/16/953`);
    setContabilidadMode(2);
    setIdCurrentPayment(curentPago.payment_identifier);
    console.log("ACtual current type identifier");
    console.log(curentPago.provider_type_identifier);
    setIdProveedorSelected(curentPago.provider_identifier);
    if (curentPago.provider_type_identifier !== 6) {
      navigate("/dashboard/Contabilidad/Contabilidad-Pago");
    } else {
      navigate("/dashboard/Contabilidad/Contabilidad-Pago-Gastos-Comprobar");
    }
  };

  return (
    <ElementoPagoStyles>
      <div className="container-header">
        <div className="header-table">
          <div>
            <h3>{curentPago.payment_date}</h3>
          </div>
          <div>
            <h3>{curentPago.agency}</h3>
          </div>
          <div>
            <h3>{curentPago.alias}</h3>
          </div>
          <div>
            <h3>{curentPago.provider_name}</h3>
          </div>
          <div>
            <h3>{curentPago.entry_number}</h3>
          </div>
          <div>
            <h3>
              $
              {curentPago.currency_identifier == 2
                ? formatearCantidad(curentPago.total)
                : 0}
            </h3>
          </div>
          <div>
            <h3>
              $
              {curentPago.currency_identifier == 1
                ? formatearCantidad(curentPago.total)
                : 0}
            </h3>
          </div>
          <div>
            <h3>{curentPago.bank}</h3>
          </div>
          <div>
            <h3>{ocultarNumerosTarjeta(curentPago.bank_account)}</h3>
          </div>
          <div className="last">
            <h3>{curentPago.policy}</h3>
          </div>
          <div className="father-box">
            <div className="child-box">
              <h3>{curentPago.concept}</h3>
            </div>
          </div>
        </div>
        <div className="second-box">
          <img
            src={deletelogo}
            alt=""
            width={25}
            className="button-element"
            onClick={() => setIsShownDelete(true)}
          />
          <img
            src={edit}
            onClick={editPayment}
            alt=""
            width={25}
            className="button-element"
          />
        </div>
      </div>
      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar el contacto ${curentPago.concept}?`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </ElementoPagoStyles>
  );
}

const ElementoPagoStyles = styled.div`
  .father-box {
    width: 100%;
    height: 40px;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    white-space: nowrap;
    max-width: 100%;
    max-height: 100%;
    position: relative;
  }
  .child-box {
    position: absolute;
    left: 0px;
  }

  .container-header {
 
  }
  .button-element {
    cursor: pointer;
  }
  .container-header div h3 {
    font-weight: 300 !important;
    text-align: center;
  }
`;
