import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { ComplaintContent } from "../InterfacesOperacion/OperacionesI";
interface ElementQuejaI {
  complaintCurrent: ComplaintContent;
}
export default function ElementQueja({ complaintCurrent }: ElementQuejaI) {
  return (
    <ElementQuejaStyle>
        <h3 className="title-queja-element">{complaintCurrent.provider.trim() ? complaintCurrent.provider: complaintCurrent.agency}</h3>
        
      <div className="container-queja">
        <div
          dangerouslySetInnerHTML={{ __html: complaintCurrent.complaint_body }}
        />
      </div>
      <h3 className="footer-queja">Nombre de quién realizó la queja: <span className="user">{complaintCurrent.user}</span></h3>
      
    </ElementQuejaStyle>
  );
}

const ElementQuejaStyle = styled.div`
margin-bottom: 2rem;
    .container-queja{
        border: rgb(8, 166, 165) solid 2px;
        padding: 2rem; 
        max-height : 50vh;
        overflow-y: auto;
    }
    .title-queja-element{
        color: rgb(8, 166, 165) ; 
        margin-bottom: 1rem;
    }
    .footer-queja{
        margin-top: 1rem;
        color: red; 
        font-size: 0.85vw;
        text-align: end;
    }
    .user{
        color: black; 
    }
`;
