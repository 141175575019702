import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  Country,
  City,
  Neighborhood,
  Zipcode,
  RegistrationType,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from '../../../images/AGREGAR.png'; 
import { ContactDeleteModel, DeleteDataContact } from "../../../interfaces/DeleteTypes";
import { GeneralModifiedI } from "../../../interfaces/GuardadoTypes";
import { ContactoI } from "../../../interfaces/GeneralTypes";

const key = uuidv4(); //
console.log(key);
export default function Contacto() {
  const { catalogsData } = useCatalogs();
  const { registerData, setRegisterData, idCurrentProvider, registerGeneral, setShowLoader } = useContext(AppContext);
  const [contactFinalList, setContactFinalList] = useState<ContactoI[]>(registerData.contact_list);
  const {showAlert, setShowAlert} = useContext(AppContext); 
  const {contentAlert, setContentAlert} = useContext(AppContext);
  const {titleAlert, setTitleAlert} = useContext(AppContext); 
  const addNewContacto = () => {
    console.log("Agregando nuevo Contacto: ");
    const isEmpty = contactFinalList.some(item => {
      const fieldsToCheck = ["name", "email", "personal_phone",  "charge_identifier"];
      return fieldsToCheck.some(field => item[field as keyof typeof item] === "" && field !== "web");
    });
    
    if (isEmpty) {
      console.log("Existen elementos en el arreglo con campos vacíos.");
      alert('Aún hay campos vacios');
      return; 
    } else {
      console.log("No hay elementos en el arreglo con campos vacíos.");
    }
    let identificador = uuidv4();
    setContactFinalList([
      ...contactFinalList,
      {
        contact_identifier: 0,
        ///////////////////////
        identifier: identificador,
        name: "",
        email: "",
        personal_phone: "",
        extension: "",
        charge_identifier: 1,
        web: "",
        status: 8,
        charge: ""
      },
    ]);
    setRegisterData({
      ...registerData,
      contact_list: [
        ...contactFinalList,
        {
          contact_identifier: 0,
          /////////////////////////////
          identifier: identificador,
          name: "",
          email: "",
          personal_phone: "",
          extension: "",
          charge_identifier: 1,
          web: "",
          status: 8,
          charge: ""
        },
      ],
    });
  };

  const [mounted, setMounted] = useState(false);
  const editContacto = (indice: number, contactoModified: ContactoI) => {
    const newContactList = contactFinalList.map((contacto, index) => {
      return indice != index ? contacto : contactoModified;
    });

    // console.log(newContactList);
    setContactFinalList(newContactList);
    setRegisterData({
      ...registerData,
      contact_list: [...newContactList],
    });
  };
  
  const deleteContacto = async(indice: number) => {
    if (contactFinalList.length === 1) {
      setContentAlert("No puedes borrar más elementos");
      setTitleAlert("Error al borrar");
      setShowAlert(true);
      return;
    }
    console.log("BORRANDO EL ELEMENTO");
    console.log(indice);
    const newContactList = contactFinalList.filter((contacto, index)=>{
      return indice != index; 
    })

    ///////////////Borrando desde servicios: 
    if (idCurrentProvider != 0) {
      setShowLoader(true);
      const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
      console.log('REACT BACK END POINT: ');
      console.log(backEndPoint); 
  
      const urlModified = backEndPoint + "/provider/update"; 
      const modifiedGeneral: GeneralModifiedI = {      
        register_identifier: idCurrentProvider,
        name: registerGeneral.name,
        registration_type_identifier:
          registerGeneral.registration_type_identifier,
        lender_type_identifier:
          registerGeneral.lender_type_identifier == 0
            ? 1
            : registerGeneral.lender_type_identifier,
        provider_type_identifier: registerGeneral.provider_type_identifier
          ? registerGeneral.provider_type_identifier
          : 1,
        business_name: registerGeneral.business_name,
        street: registerGeneral.calles,
        number: registerGeneral.telefono,
        neighborhood_identifier: registerGeneral.neighborhood_identifier,
        city_identifier: registerGeneral.city_identifier
          ? registerGeneral.city_identifier
          : 1,
        country_identifier: registerGeneral.country_identifier,
        zip_code: registerGeneral.zip_code,
        important_notes: registerGeneral.important_notes,
        description: registerGeneral.lead,
        id: registerGeneral.id,
        key: registerGeneral.claveGeneral,
        rfc: registerGeneral.rfc,
        category: registerGeneral.categoriaGeneral == 0 ? null: registerGeneral.categoriaGeneral,
        last_purchase: registerGeneral.ultimaCompra,
        feedback: registerGeneral.feedback,
        lead: registerGeneral.lead,
        commission_scheme: registerGeneral.comisionEsquema,
        web: registerGeneral.web,
        comments: registerGeneral.comments,
        category_identifier: registerGeneral.category_identifier
      };
      const contactDeleteList: ContactDeleteModel[] | null = contactFinalList?.filter((contacto, index)=>{
        return indice == index; 
      }).map((contacto, index)=>{
        const contatoForDelete: ContactDeleteModel = {
          contact_identifier: contacto.contact_identifier,
          status_identifier: 2,
          name: contacto.name,
          email: contacto.email,
          personal_phone: contacto.personal_phone,
          extension: parseInt(contacto.extension),
          charge_identifier: contacto.charge_identifier,
        } 
        return contatoForDelete;
      });

      const modifiedObject: DeleteDataContact = {
          user_identifier: 1,
          general: modifiedGeneral,
          contact_list: contactDeleteList
      }
      console.log('CUERPO DE PETICION: ' );
      console.log(modifiedObject); 
  
      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(modifiedObject),
        });
        if (response.ok) {
          ////RESPUESTA CORRECTA
          console.log(response);
          console.log("bien");
          
          setTitleAlert("Modificación correcta correcto");
          setContentAlert(
            "Tus datos fueron modificados correctamente, puedes consultarlos en el catalogo."
          );
  
          setShowAlert(true);
          setShowLoader(false); 
      
        } else {
          ///RESPUESTA INCORRECTA DE SERVICIOS
          // setShowLoader(false);
          setTitleAlert("Error al BORRAR:");
          setContentAlert(
            "Tus datos no fueron guardados correctamente, por favor, verificalos."
          );
          setShowAlert(true);
          setShowLoader(false); 
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
        alert("ERROR AL REALIZAR BORRADO");
        setShowLoader(false);  
      }
      
    }
   
    //////////////////////////////////////////
  
   
    setRegisterData({
      ...registerData,
      contact_list: [...newContactList],
    });
    setContactFinalList(newContactList);
  };
  useEffect(() => {
    
    

  
  }, [contactFinalList]);


  return (
    <ContactoStyle>
      <div className="box-container">

      <br />
      <br />
      <div className="title-header">
      <div>
        <h2>Nombre de contacto</h2>
      </div>
      <div>
        <h2>Correo electrónico</h2>
      </div>
    
      <div>
        <h2>Teléfono</h2>
      </div>
      <div>
        <h2>Ext.</h2>
      </div>
      <div>
        <h2>Puesto</h2>
      </div>
      <div></div>

      </div>
        {contactFinalList.map((contacto, index) => {
          return (
            <ContactLayoutNew
              key={contacto.identifier}
              contacto={contacto}
              indice={index}
              editContacto={editContacto}
              deleteContacto={deleteContacto}
              initialState={contactFinalList.length - 1 == index ? true: false}
              addNewContacto = {addNewContacto}
            />
          );
        })}        
       
      </div>
    </ContactoStyle>
  );
}

const ContactoStyle = styled.div`
.title-header div h2{  
  font-size: 0.85vw !important;
}
@media screen and (max-width: 900px){
  .title-header div h2{  
  font-size: 1.6vw !important;
} 
}
.button-container{ 
  display: flex;
  justify-content: right;
  padding-right: 3vw;
  margin-top: 1rem;
}
.plus-symbol{
  color: rgb(8, 166, 165) !important; 
}
.title-header{
  border: rgb(8, 166, 165) solid 2px;
  padding: 0.5rem;  
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.8fr;
  width: 95%;
  margin: 0 auto;
}

@media screen and (max-width: 700px){
  max-width: 100%;
 

  overflow: auto;
  .box-container{
    width: 150%;
 
overflow: none;
background-color: white;

  }
  .title-header{
    width: 150%;
  }
}
.title-header div h2{
  color: rgb(8, 166, 165); 
  font-size: medium;
  text-align: center;
}
.title-header div{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

  .box-container {
  }
  .plus-symbol {
    font-size: 2rem;
  }
  .title-contacto {
    color: rgb(8, 166, 165);
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .container-fields-ones,
  .container-fields-two {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 90%;
    margin: 0 auto; 
    padding: 0.5rem; 
  }
  
`;
