import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { AñoObjeto } from "../InterfacesContabilidad";
interface SearchComponentI {
  searchParameter: string;
  setSearchParameter: React.Dispatch<React.SetStateAction<string>>;
  searchResultsGrupo: () => void;
  yearSelect: number,
  setYearSelected: React.Dispatch<React.SetStateAction<number>>,
  listaAños: AñoObjeto[]
}
export default function SearchComponent({
  searchParameter,
  setSearchParameter,
  searchResultsGrupo,
  listaAños
}: SearchComponentI) {
  const [textSearch, setTextSeach] = useState<string>("");
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      searchResultsGrupo();
    }
  };
  return (
    <SearchComponentStyle>
      <div className="search-box-container">
        <div className="input-container">
          <input
            type="text"
            value={searchParameter}
            onChange={(e) => {
              setSearchParameter(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="icon-container" onClick={searchResultsGrupo}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
      </div>
    </SearchComponentStyle>
  );
}

const SearchComponentStyle = styled.div`
  .elements-search {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
  }
  .combo-box-container {
    width: 200px;
    height: 10px;
  }
  .search-box-container {
    border: rgb(8, 166, 165) solid 2px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    /* margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
    */
  }
  .input-container {
    width: 85%;
  }
  .input-container input {
    width: 100%;
    padding: 0.8rem;
    border: none;
    outline: none;
    border-right: rgb(8, 166, 165) solid 2px;
  }
  .icon-container {
    width: 15%;
    background-color: rgb(8, 166, 165);

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: ease all 0.7s;
  }
  .icon-container:hover {
    background-color: white;
    color: rgb(8, 166, 165);
  }
  /* position: absolute; */
  .title-box {
    text-align: center;

    margin-bottom: 1rem;
    border-radius: 0.6rem;
  }
  .title-box h3 {
    font-size: 2.7rem;
    font-weight: bolder;
    color: rgb(8, 166, 165);
  }
  .element-client-box {
    border: rgb(8, 166, 165);
    background-color: rgba(133, 198, 195, 0.5);
    min-height: 100vh;
    width: 85%;
    margin: 0 auto;
    border-radius: 0.4rem;
    padding: 1rem 0 1.3rem 1.3rem;
    margin-top: 2rem;
  }
  .box-container {
    margin-bottom: 3rem;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #4caf50;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  @media screen and (max-width: 900px) {
    /* Estilos para dispositivos móviles */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    tr {
      margin-bottom: 10px;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
      text-align: left;
    }

    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
      font-weight: bold;
    }
  }
`;
