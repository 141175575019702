import { validityDates } from "../../../../interfaces/GeneralTypes";
import { ServiceBlackout } from "../../../../interfaces/ProviderTypes";
import { blackoutObject} from "../../../../interfaces/GeneralTypes";
import { objectCard } from "../../../../interfaces/GeneralTypes";
import { ContactoI } from "../../../../interfaces/GeneralTypes";


export function filterEmptyDates(arr: Array<validityDates>): Array<validityDates> | null {
    const filteredList = arr.filter((validity: validityDates) => {
      return (
        validity.validity_startdate.trim() !== '' &&
        validity.validity_enddate.trim() !== ''
      );
    });
  
    if (filteredList.length === 0) {
      return null;
    }
  
    return filteredList;
  }

export  function filterEmptyDatesBlackout(arr: ServiceBlackout[]): ServiceBlackout[] | null {
    const filteredList = arr.filter((blackout: ServiceBlackout) => {
      return (
        blackout.validity_startdate.trim() !== '' &&
        blackout.validity_enddate.trim() !== ''
      );
    });
  
    if (filteredList.length === 0) {
      return null;
    }
  
    return filteredList;
  }

  export function filterEmptyDatesBlackoutTwo(arr: blackoutObject[]): blackoutObject[] | null {
    const filteredList = arr.filter((blackout: blackoutObject) => {
      return (
        blackout.blackout_startdate.trim() !== '' &&
        blackout.blackout_enddate.trim() !== ''
      );
    });
  
    if (filteredList.length === 0) {
      return null;
    }
  
    return filteredList;
  }
  
  export function filterBankAccounts(arr: objectCard[]): objectCard[] | null {
    const filteredList = arr.filter((bankAccount: objectCard) => {
      return (
        bankAccount.bank_identifier !== 0 &&
        bankAccount.currency_element !== 0 &&
        bankAccount.clabe.trim() !== '' &&
        bankAccount.numberCard.trim() !== ''
      );
    });
  
    if (filteredList.length === 0) {
      return null;
    }
  
    return filteredList;
  }

  export function filterContactAccounts(arr: ContactoI[]): ContactoI[] | null{
    const filteredList = arr.filter((contacto: ContactoI) => {
      return (
        contacto.charge_identifier !== 0 ||
        contacto.email.trim() !== "" ||
        contacto.name.trim() !== '' ||
        contacto.personal_phone.trim() || ''
      );
    });

    if (filteredList.length === 0) {
      return null;
    }

    return filteredList;
    
  }