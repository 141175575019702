import { EntryDataResponse, InitialContextOP, OperationI } from "../alico/pages/Operaciones/InterfacesOperacion/OperacionesI";
import { userI } from "./InterfacesContext";

export const userInicial: userI = {
    user_identifier: 0,
    role: "",
    user_name: "",
    status: "",
    group: "",
    user_role: 0,
    user_role_name: "",
    group_identifier: 0, 
    alias: "",
    agency: ""
  }

  export const operationInitialData:OperationI = {
    quote: {
        quote_identifier: 0,
        name: "",
        alias: "",
        agency: "",
        arrival_date: "",
        departure_date: "",
        pax: 0,
        sgl: 0,
        dbl: 0,
        tpl: 0,
        cpl: 0,
        adults: 0,
        childs: 0,
    },
    providers: {
        hotel: [],
        restaurant: [],
        agency: [],
        transport: [],
        expenses: [],
        honorary: [],
        optionals: [],
    }
  } 
  

  //////ENTRADAS:::

  export const initialResponseDataOP: InitialContextOP = {
      entry_data: {
        entry_identifier: 0,
        operation_identifier: 0,
        provider_identifier: 0,
        status_identifier: 1,
        entry_number: 0,
        initial_date: "",
        final_date: "",
        sgl: 0,
        dbl: 0,
        tpl: 0,
        cpl: 0,
        adults: 0,
        childs: 0,
        confirmation_number: " ",
        creator_user_identifier: 0,
        contact1_identifier: 0,
        contact2_identifier: 0,
        guide_identifier: 0,
        payday_limit: " ",
        comment: " ",
        total_mxn: 0.0,
        services_tips_mxn: 0.0,
        lodging_taxes_mxn: 0.0,
        iva_mxn: 0.0,
        advance_mxn: 0.0,
        total_usd: 0.0,
        services_tips_usd: 0.0,
        lodging_taxes_usd: 0.0,
        iva_usd: 0.0,
        advance_usd: 0.0,
        guide_courtesy: 0,
        provider_name: "",
        total_without_advance_usd: 0,
        total_without_advance_mxn: 0, 
        creator_user: ""
      },
      entry_services_list: {
        mxn_services_list:  [],
        usd_services_list: []
      }
    
  };