import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import GerenteTable from "../../Operaciones/GerenteTable";
import OptionSwitch from "../../Operaciones/Components/OptionSwitch";
import CotizacionDetails from "../../Operaciones/CotizacionDetails";
import { BrowserRouter, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import HeaderInfo from "../../Operaciones/HeaderInfo";
import PrestadoresList from "../../Operaciones/PrestadoresList";
import {
  OperationI,
  OperationISend,
  ProviderIdI,
  ProviderInterfaceOP,
} from "../../Operaciones/InterfacesOperacion/OperacionesI";
import ElementPrestador from "../../Operaciones/Components/ElementPrestador";
import { Link, useNavigate } from "react-router-dom";
// import agregarImage from "../../../images/AGREGAR.png";
import { OperationContext } from "../../../../context/operationContext";
import ElementPrestadorCont from "./ElementPrestadorCont";
import { ApiResponseGastosComprobar, PendingPaymentGastosComprobar } from "../InterfacesContabilidad";

interface ListPrestadoresI {
  nameList: string;
  listProvider: ProviderInterfaceOP[];
  setListProvider: React.Dispatch<React.SetStateAction<ProviderInterfaceOP[]>>;
  isGastosComprobar?: boolean;
}

export default function ListPrestadoresCont({
  nameList,
  listProvider,
  setListProvider,
  isGastosComprobar,
}: ListPrestadoresI) {
  const navigate = useNavigate();
  const { setShowSearchProviders, setShowLoader } = useContext(AppContext);
  const { currentOperation } = useContext(OperationContext);

  const asignarGuia = async () => {
    await asignarGastos();
  };
  const desgloseGuia = () => {
    navigate("/dashboard/Operaciones/Desgloce-Guia");
  };
  const asignarGastos = async () => {
    const getIdProvedores: ProviderIdI[] = listProvider.map((provedor, id) => {
      const provedor_objeto: ProviderIdI = {
        provider_identifier: provedor.register_identifier,
      };
      return provedor_objeto;
    });

    const objetoEnviar: OperationISend = {
      operation_identifier: currentOperation.quote.quote_identifier,
      provider_list: getIdProvedores,
    };

    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/ExpensesEntries/register`;
    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objetoEnviar),
      });

      if (response.ok) {
        const data = await response.json();

        alert("El valor se ha enviado correctamente");

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
        console.log(
          "-------------------------------------------DATA--------------------------------------"
        );
        console.log(data.data);
        
        navigate("/dashboard/Operaciones/Desgloce-Asignar-Guia");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      // setShowLoader(false);
    }
    console.log("ASIGNANDO GASTOS A COMPROBAR:::");
  };

  const payGastosComprobar = async() =>{
    console.log("Pay gastos  comprobar"); 
    navigate("/dashboard/Contabilidad/Contabilidad-Pago-Gastos-Comprobar");


  //   const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
  //   const urlModified =
  //   backEndPoint +
  //   `/accounting/PendingPaymentsExpenses/${currentOperation.quote.quote_identifier}/${0}`;

  // try {
  //   const response: Response = await fetch(urlModified, {
  //     method: "GET", // Cambio del método POST a GET
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     // Eliminar el cuerpo ya que las peticiones GET no llevan body
  //   });

  //   if (response.ok) {
  //     console.log("bien");
  //     const data: ApiResponseGastosComprobar = await response.json();
  //     console.log("Gastos a comprobar");
  //     console.log(data.data.guides_list);
  //     console.log(data.data.pending_payments);
  //     if (data.data.pending_payments) {
  //       if (data.data.pending_payments.length > 0) {

  //       }
  //     }else{
  //       alert("Este registro no tiene pagos"); 
  //       return;
  //     }
    

  //     setShowLoader(false);
  //   } else {
  //     setShowLoader(false);
  //     alert("Este registro no tiene pagos"); 
  //     return;
  //   }
  // } catch (error) {
  //   alert("Este registro no tiene pagos"); 
  //   setShowLoader(false);
  //   return;
  // }
   
  }
  return (
    <ListPrestadoresStyle>
      <div className="first-row">
        <div className="title-table">
          <h3 className="namelist-title">{nameList}</h3>
          {isGastosComprobar && <button
          onClick={payGastosComprobar}
          >Pagar</button>}
        </div>
        <div className="botons-one"></div>
      </div>

      {listProvider.map((prestador, index) => {
        return (
          <ElementPrestadorCont
            prestador={prestador}
            listProvider={listProvider}
            setListProvider={setListProvider}
            isGastosComprobar={isGastosComprobar}
          />
        );
      })}
      <div className="list-container">
        <div className="list-elements">
          <div></div>
        </div>
        <div></div>
      </div>
    </ListPrestadoresStyle>
  );
}

const ListPrestadoresStyle = styled.div`
  .namelist-title {
    margin-right: 1rem;
  }
  .icon {
    cursor: pointer;
  }
  .first-row {
    display: flex;
  }
  /* .botons-one {
    width: 8%;
    display: flex;
    justify-content: center;
  } */
  .box-buttons {
    margin-left: 1rem;
  }
  .box-buttons button {
    border: none;
    padding: 0.2rem;
    font-size: 0.85vw;
    cursor: pointer;
  }
  .guia-boton {
    margin-right: 1rem;
    background-color: #f6921e;
    color: white;
  }
  .desglose-boton {
    background-color: #29aae1;
    color: white;
  }
  .title-table {
    display: flex;
    align-items: center;
  }

  .list-elements {
    width: 92%;
  }
  .title-table {
    border: black solid 1px;
    color: black;
    background-color: rgba(8, 166, 165, 0.5);
    padding: 0.3rem !important;
    width: 100%;
    padding: 0.3rem;
  }
  .title-table {
    font-size: 0.75vw;
  }
`;
