import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import GerenteTable from "../GerenteTable";

interface optionI{
    optionTableSelected: number;
    setOptionTableSelected : React.Dispatch<React.SetStateAction<number>>;
    currentPage: number; 
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export default function OptionSwitch({optionTableSelected, setOptionTableSelected, currentPage, setCurrentPage }:optionI) {
  return (
    <OptionSwitchStyles>
        <div className="container-options">
            <div className={`${optionTableSelected == 1 ? "active-boton":"" }`}
            onClick={()=>{
                setOptionTableSelected(1)
                setCurrentPage(1);
            }}
            >
                <h3>Asignar</h3>
            </div>
            <div className={`${optionTableSelected == 2 ? "active-boton":"" }`}
            onClick={()=>{
                setOptionTableSelected(2);
                setCurrentPage(1); 
            }}
            >
                <h3>Asignadas</h3>
            </div>
        </div>
    </OptionSwitchStyles>
  )
}


const OptionSwitchStyles = styled.div`
    .container-options{
       border-radius: 0.5rem;
        width: 250px;
       padding: 0.5rem;
        display: flex;
        justify-content: space-around;
        box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 6px 2px;
     
    }
    .container-options div{
     
        border-radius: 0.3rem;
        padding: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        cursor: pointer;
    }
    .active-boton{
        border: #F6921E solid 1px; 
        background-color: #F6921E;
   
        
    }
    h3{
        font-weight: 600;
        font-size: 0.9vw;
    }
    @media screen and (max-width: 900px){
        h3{
        
        font-size: 1.5vw;
    }
    }
`