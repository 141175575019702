import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import {
  EntryDataResponse,
  EntryService,
  ProviderInterfaceOP,
  QuoteItemI,
  operationDataI,
  operationUpdateI,
  updateProviderI,
} from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import IndividualService from "./IndividualService";
import deleteImage from "../../../../images/deletelogo.png";
import agregarImage from "../../../../images/AGREGAR.png";
import { Link, useNavigate } from "react-router-dom";
import { OperationContext } from "../../../../context/operationContext";
import { isEqual } from "lodash";
interface ElementReadDesgloceI{
    service: EntryService;
    updatePrice: (servicioActual: EntryService, tipoMoneda: number) => void
}
export default function ElementReadDesgloce({service, updatePrice}:ElementReadDesgloceI) {
    const [currentService, setCurrentService] = useState<EntryService>(service); 
    useEffect(()=>{
      if(!isEqual(service, currentService)){
        updatePrice(currentService, currentService.currency_identifier); 
      }

    },[currentService])

    useEffect(()=>{
      // setCurrentService(service); 
      console.log("NUEVO SERVICIOOOOOO------------------------------------------------");
      console.log(service); 
      setCurrentService(service); 
    },[service]);

    return (
    <ElementReadDesgloseStyle>
        <div className="header-container-desglose">
        <div>
          <h3>{currentService.service_name}</h3>
        </div>
        <div>
          <h3>fecha llegada</h3>
        </div>
        <div>
          <input type="text"
               onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                    setCurrentService({
                        ...currentService,
                        quantity1: 0
                    })
            
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setCurrentService({
                        ...currentService,
                        quantity1: parsedValue
                    })
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }} 
          value = {currentService.quantity1}

          />
    
        </div>
        <div>
          <input type="text"
               onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                    setCurrentService({
                        ...currentService,
                        quantity2: 0
                    })
            
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setCurrentService({
                        ...currentService,
                        quantity2: parsedValue
                    })
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
          value = {currentService.quantity2}
          />
     
        </div>
        <div>
          <input type="text" 
               onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                    setCurrentService({
                        ...currentService,
                        unitary_fee: 0
                    })
            
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setCurrentService({
                        ...currentService,
                        unitary_fee: parsedValue
                    })
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
            value = {currentService.unitary_fee}          
          />
         
        </div>
        <div>
          
          <h3>{currentService.quantity1*currentService.quantity2*currentService.unitary_fee}</h3>
          
          
        </div>
        <div>
          <h3>{currentService.currency}</h3>
        </div>
      </div>

    </ElementReadDesgloseStyle>
  )
}



const ElementReadDesgloseStyle = styled.div`
    .header-container-desglose div h3{
        font-weight: 300 !important;
    }
    .header-container-desglose div input{
      width: 100%;
      max-width: 100%;
      font-size: 0.85vw;
      text-align: center;
    }
`