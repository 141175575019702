import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { AppContext } from "../../../context/appContext";
import { ApiResponse } from "../../../interfaces/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {
  IndividualServiceDetailI,
  OptionalQuote,
  QuoteDetail,
  QuoteElementI,
} from "./interfaces/CotizacionI";
import {
  feeChargesI,
  generalElementI,
  generalsElementsI,
  personalInfoI,
  servicioDayElementI,
  servicioIndividual,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import { v4 as uuidv4 } from "uuid";
import {
  CommentI,
  QuoteGeneralElement,
} from "../../../interfaces/GeneralTypes";
import { useNavigate } from "react-router-dom";
import { ValiditySearch } from "../../../interfaces/Cotizacion";

interface busquedaI {
  quote: QuoteElementI;
}
export default function SearchElement({ quote }: busquedaI) {
  const { quote_identifier, name } = quote;

  const navigate = useNavigate();
  const {
    dataCotizacionContext,
    currentCurrency,
    setCurrentCurrency,
    setDataCotizacionContext,
    cotizacionMode,
    setCotizacionMode,
    listOpcionalesContext,
    setListOpcionalesContext
  } = useContext(AppContext);
  const {
    registerData,
    setRegisterData,
    fiscalContext,
    setFiscalContext,
    registerGeneral,
    setRegisterGeneral,
    dataServicesContext,
    idCurrentProvider,
    setShowLoader,
  } = useContext(AppContext);
  const { showAlert, setShowAlert } = useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const { titleAlert, setTitleAlert } = useContext(AppContext);

  const getCotizacion = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint + `/quote/QuoteDetail/${quote.quote_identifier}`;

    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data = await response.json();

        console.log("VALOR DE LA CONSULTA:");
        console.log(data.data);
        const quoteResponse: QuoteDetail = data.data.quote_detail;
        console.log("RESPUESTA DEL SERVIDOR PARAMETRIZADA EN INTERFAZ");
        console.log(quoteResponse);

        const personalInfoInicial: personalInfoI = {
          name: quoteResponse.quote.name,
          idCotizacion: quoteResponse.quote.quote_identifier.toString(),
          typeCotizacion: quoteResponse.quote.type,
          typeAgency: 1,
          serieId: quoteResponse.quote.serie_identifier,
          includesSerie: true,
          pax: quoteResponse.quote.PAX,
          min: quoteResponse.quote.MIN,
          max: quoteResponse.quote.MAX,
          TL: quoteResponse.quote.TL,
          arrivingData: quoteResponse.quote.arrival_date,
          departureDate: quoteResponse.quote.departure_date,
          agencia: quoteResponse.quote.agency,
          typeRoom: 2,
          numberDaysOpenQuote: quoteResponse.quote.number_of_days,
          modeCotizacion: quoteResponse.quote.serie ? "Serie" : quoteResponse.quote.optional_quote ? 'Opcional': "Normal",
          selectedSerieOption: "No",
          serieSelected: 0,
          isShowingDates: false,
          dateStartOpen: "",
          dateEndOpen: "",
          status_identifier: quoteResponse.quote.status_identifier,
          quote_identifier: quoteResponse.quote.quote_identifier,
          optional_quote: quoteResponse.quote.optional_quote,
          total_base_sgl: quoteResponse.quote.total_base_sgl,
          total_base_dbl: quoteResponse.quote.total_base_dbl,
          total_base_tpl: quoteResponse.quote.total_base_tpl,
          simple_supplement: quoteResponse.quote.simple_supplement,
          triple_reduction: quoteResponse.quote.triple_reduction,
        };

        ///////////////////Recuperando servicios individuales

        const serviciosDaysElementsInicial: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.quote_individual_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
               
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: serviceDayy.occupation,
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    status_identifier: serviceDayy.status_identifier,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: 0,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    vigencias:serviceDayy.validity_list,
                    fee_identifier: serviceDayy.fee_identifier,
                    isIncludedOpt: false,
                    
                  };
                  return individualServicio;
                });
                
              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              
                
              };
              return servicio;
            }
          ),
        };
     
        //////////////////Recuperando servicios Generales.
        console.log("Getting comments: ");
        console.log(quoteResponse.quote.comments);
        const dataCommentsArray: CommentI[] = quoteResponse.quote.comments.map(
          (currentComment, index) => {
            return {
              comment_identifier: currentComment.comment_identifier,
              comment: currentComment.comment,
              comment_date: currentComment.comment_date,
              status_identifier: currentComment.status_identifier,
              commentIdentifier: currentComment.comment_identifier.toString(),
            };
          }
        );
      
        const cargosFee: feeChargesI = {
          currentMoney: 0,
          changeType: quoteResponse.quote.exchange_rate,
          comision: quoteResponse.quote.commission,
          comisonAuto: quoteResponse.quote.commission_auto,
          cortesia: quoteResponse.quote.courtesy,
          comments: dataCommentsArray,
          courtesy_type_identifier:
            quoteResponse.quote.courtesy_type_identifier,
        };
        const generalElementos: generalsElementsI = {
          generalElement: [],
        };
        let serviciosGenerales: QuoteGeneralElement[] = [];
        let serviciosTransporteGenerales: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.general_transport_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: "",
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: serviceDayy.number_of_services ?? 1,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    fee_identifier: serviceDayy.fee_identifier,
                    isIncludedOpt: false,
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
           
              };
              return servicio;
            }
          ),
        };
        if (quoteResponse.quote_general_services) {
          serviciosGenerales = quoteResponse.quote_general_services.map(
            (generalS, index) => {
              let identifierGUID = uuidv4();
              const currentServicioGeneral: QuoteGeneralElement = {
                service_type: generalS.service_type_identifier,
                provider_id: generalS.provider_identifier,
                service_name_id: generalS.service_identifier,
                fee_price: generalS.fee,
                number_days: generalS.number_of_services,
                total_price: generalS.fee * generalS.number_of_services,
                identifierGeneralService: identifierGUID,
                currency: quoteResponse.quote.currency,
                currency_identifier: quoteResponse.quote.currency_identifier,
                expenses_type_identifier: generalS.expenses_type_identifier,
                general_service_identifier: generalS.general_service_identifier,
                status_identifier: generalS.service_identifier,
                fee_identifier: generalS.service_identifier,
                isIncludedOpt: false, 
              };
              return currentServicioGeneral;
            }
          );
        } else {
          serviciosGenerales = [];
        }

        const optionalContextList:OptionalQuote[]  = quoteResponse.optional_groups.map((opcional, index)=>{
          let id_localString = uuidv4();
          const elementOpcional:OptionalQuote = {
            identifier: opcional.optional_quote_identifier,
            name: opcional.optional_quote_name,
            total_base_sgl: opcional.total_base_sgl,
            total_base_dbl: opcional.total_base_dbl,
            total_base_tpl: opcional.total_base_tpl,
            arrival_date: opcional.arrival_date,
            departure_date: opcional.departure_date,
            id_local: id_localString,
            optional_group_identifier: opcional.optional_group_identifier,
            optional_quote_identifier: opcional.optional_quote_identifier
          }
          return elementOpcional
        })
      
        setDataCotizacionContext({
          ...dataCotizacionContext,
          personalInfo: personalInfoInicial,
          serviciosDayElements: serviciosDaysElementsInicial,
          generalsElements: generalElementos,
          serviciosDayElementsTransporte: serviciosTransporteGenerales,
          totalPrice: {
            totalPrice: 0,
            granTotal: 0,
          },
          feeCharges: cargosFee,
          generalServices: serviciosGenerales,
        });
        setListOpcionalesContext(optionalContextList); 
        setTitleAlert("La solicitud se realizó correctamente");
        setContentAlert("Accediste a la siguiente información de cotización.");
        setCotizacionMode(2);
        setShowAlert(true);
        setShowLoader(false);
        navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        setTitleAlert("Error al realizar la solicitud!:");
        setContentAlert(
          "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        );
        setShowAlert(true);
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      alert("ERROR AL REALIZAR LA SOLICITUD");

      setTitleAlert("Error al realizar la solicitud:");
      setContentAlert(
        "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      );
      setShowLoader(false);
    }
  };

  return (
    <SearchElementStyles>
      <h3>
        {name} {quote.quote_identifier}
      </h3>
      <button onClick={getCotizacion}>Ver cotización</button>
    </SearchElementStyles>
  );
}

const SearchElementStyles = styled.div`
  border: rgb(8, 166, 165) solid 2px;
  width: 65%;

  background-color: white;
  padding: 0.5rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.4rem;
  button {
    border: none;
    outline: none;
    background-color: rgb(8, 166, 165);
    padding: 0.5rem 1.5rem;
    color: white;
    border-radius: 0.8rem;
    cursor: pointer;
  }
  h3 {
    font-size: 0.85vw;
  }
  margin-bottom: 1rem;
`;
