import { Fragment,useState } from "react";
import styled from 'styled-components'
import GrlLabel from "../labels/GrlLabel";

import Observacionesimg from "../../../images/observaciones.png"
import MoreInfoimg from "../../../images/moreinfo.png"
import deleteItem from "../../../images/borrar.png"
import hideItem from "../../../images/ocultar.png"
import updateItem from "../../../images/editar.png"

interface Props {
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

function CRUDmodal({showModal, setShowModal}:Props) {

    const PrincipalContainer = styled.div`
        width: 100%;
        height: 100%;
        display: ${showModal ? "flex" : "none"};
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        `;
    return (
        <Fragment>
            <PrincipalContainer onClick={(e) => setShowModal(!showModal)} >
                <Modal>
                    <Modalimg src={MoreInfoimg}></Modalimg>
                    <ModalLabel>Selecciona la opción:</ModalLabel>
                    <OptionContainer>
                        <HideElement>
                            <CRUDimg src={hideItem}></CRUDimg>
                            <ModalSecondLabel>Ocultar</ModalSecondLabel>
                        </HideElement>
                        <ModifyElement>
                            <CRUDimg src={updateItem}></CRUDimg>
                            <ModalSecondLabel>Editar</ModalSecondLabel>
                        </ModifyElement>
                        <DeleteElement>
                            <DeleteElementimg src={deleteItem}></DeleteElementimg>
                            <ModalSecondLabel>Borrar</ModalSecondLabel>
                        </DeleteElement>
                    </OptionContainer>
                </Modal>
            </PrincipalContainer>
        </Fragment>
    );
}

export default CRUDmodal;

const Modal = styled.div`
    width: 80%;
    height: auto;
    background-color: #ffffff;
    border-radius: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Modalimg = styled.img`
    width: 50px;
    height: 50px;
    margin-top: 50px;
`;

const OptionContainer = styled.div`
    width: 60%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 60px 0px 100px 0px;
`;

const CRUDimg = styled.img`
    width: 65px;
    height: 65px;
    cursor: pointer;
`;

const DeleteElement = styled.div`
    width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const DeleteElementimg = styled.img`
    width: 50px;
    height: 70px;
    cursor: pointer;
`;

const HideElement = styled.div`
    width: 150px;
    height: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-align: center;
`;

const ModifyElement = styled.div`
    width: 150px;
    height: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const ModalLabel = styled.div`
    font-size: 20px;
    width: 60%;
    height: auto;
    padding-top: 10%;
`;

const ModalSecondLabel = styled.div`
    font-size: 20px;
    width: 60%;
    height: auto;
    color: #08a6a5;
    font-weight: bold;
    text-align: center;
`;