import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";
import { ProformaAdvance } from "./ProformaI";
import ElementLimitPayment from "./ElementLimitPayment";

interface AnticiposI {
  proformaAdvances: ProformaAdvance[];
  setProformaAdvances: React.Dispatch<React.SetStateAction<ProformaAdvance[]>>;
}
export default function LimitPayment({
  proformaAdvances,
  setProformaAdvances,
}: AnticiposI) {
  const { language, setLanguage } = useLanguage();
  return (
    <LimitPaymentStyles>
      <div className="box-limit-payment">
        <h3>{translations[language].box_page_limit_payment.text}</h3>
        <div className="limit-payment">
          <div>
            <h3>{translations[language].box_page_limit_payment.limit_payment}</h3>
          </div>
          <div>
            <h3>{translations[language].box_page_limit_payment.amount}</h3>
          </div>
        </div>
        {proformaAdvances.map((anticipo, index) => {
          return <ElementLimitPayment anticipo={anticipo} />;
        })}
      </div>
    </LimitPaymentStyles>
  );
}

const LimitPaymentStyles = styled.div`
  .limit-payment {
    border: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    margin-top: 1rem;
  }
  .limit-payment div h3 {
    text-align: center;
    font-weight: bold;
  }
  .limit-payment-element {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
  }
  .limit-payment-element div h3 {
    text-align: center;
  }
  .box-limit-payment {
    width: 40%;
    border: black solid 1px;
    padding: 0.8rem;
  }
  .box-limit-payment h3 {
    font-size: 0.85vw;
    font-weight: 400;
  }
  @media screen and (max-width: 900px) {
    .box-limit-payment h3 {
      font-size: 1.6vw;
    }

    .box-limit-payment {
      width: 60%;
    }
  }
  .limit-payment {
  }
`;
