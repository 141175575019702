import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import DateInput from "../../components/entrys/DateInput";
import { bodyI, quoteSearchParams } from "../../../interfaces/GeneralTypes";
import {
  OperationOPSearch,
  ResponseDataOPSearch,
  SearchOperation,
} from "../Cotizacion/interfaces/CotizacionI";
import ElementSearchOP from "./ElementSearchOP";
import { AppContext } from "../../../context/appContext";
import { useCatalogsOperacion } from "../../../context/OperacionCatalog";
import { userI } from "../../../context/InterfacesContext";
import { AñoObjeto } from "../Contabilidad/InterfacesContabilidad";
import ElementSearchOPT from "./ElementSearchOPT";

export default function SearchOperacion() {
  const [listParams, setListParams] = useState<quoteSearchParams[]>([]);
  const { catalogsDataOperaciones } = useCatalogsOperacion();
  const [idParamSelected, setIdParamSelected] = useState<number>(1);
  const [listResultsOP, setListResultsOP] = useState<OperationOPSearch[]>([]);
  const { setShowLoader } = useContext(AppContext);
  const [bodySearch, setBodySearch] = useState<bodyI>({
    dateSearch: "",
    textSearch: "",
    idUser: 0,
  });
  const [listOP, setListOP] = useState<userI[]>([]);

  useEffect(() => {
    console.log("CATALOGOS DE OPERACIONES::::");
    console.log(catalogsDataOperaciones);
    setListOP(catalogsDataOperaciones.ops_users_list);
  }, [catalogsDataOperaciones]);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      findOperaciones(); /// FIND OPERACIONES
    }
  };

  const listSearch = [
    {
      id: 1,
      description: "Usuario",
    },
    {
      id: 2,
      description: "Fecha de creación",
    },
    {
      id: 3,
      description: "Alias",
    },
    {
      id: 4,
      description: "Nombre",
    },
    {
      id: 5,
      description: "Agencia",
    },
  ];
  const listSearchYears = [
    {
      id: 1,
      description: "2000",
    },
    {
      id: 2,
      description: "Fecha de creación",
    },
    {
      id: 3,
      description: "Alias",
    },
    {
      id: 4,
      description: "Nombre",
    },
    {
      id: 5,
      description: "Agencia",
    },
  ];
  // const [bodyRequest , setBodyRequest] = useState<SearchOperation>({
  //   user_identifier: null,
  //   alias:  null,
  //   quote_name:  null,
  //   agency:  null,
  //   creation_date: null,
  //   year: null,
  // })

  const findOperaciones = async () => {
    console.log("En esta parte esta el código para buscar operaciones");
    console.log("PARAMETROS DE BUSQUEDA:");

    if (true) {
      const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/search`;
      setShowLoader(true);
      const user = "userEmail";
      const password = "userPassword";
      const bodyRequest: SearchOperation = {
        user_identifier: idParamSelected == 1 ? bodySearch.idUser ?? 0 : null,
        creation_date: idParamSelected == 2 ? bodySearch.dateSearch : null,
        alias: idParamSelected == 3 ? bodySearch.textSearch : null,
        quote_name: idParamSelected == 10 ? bodySearch.textSearch : null,
        agency: idParamSelected == 5 ? bodySearch.textSearch : null,
        year: idParamSelected != 2 ? yearSelect : null,
      };

      //  setShowLoader(true);
      console.log("cuerpo de busqueda");
      console.log(bodyRequest);

      try {
        const response = await fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyRequest),
        });

        if (response.ok) {
          const data: ResponseDataOPSearch = await response.json();
          console.log("Datos de busqueda:", data);
          console.log(data.data);
          const resultadosBusqueda: OperationOPSearch[] = data.data;
          console.log("RESULTADO BUSQUEDA");
          console.log(data.data);
          if (resultadosBusqueda) {
            setListResultsOP(resultadosBusqueda);
          }

          setShowLoader(false);
        } else {
          console.error("Error in response:", response);
          alert("Error al realizar la busqueda");
          setShowLoader(false);
          // setTitleAlert('Error al iniciar sesión');
          // setContentAlert('Por favor, digite un email y contraseña valido.');
          // setShowAlert(true);
        }
      } catch (error) {
        alert("Error al realizar la busqueda");
        console.error("Error in request:", error);
        alert("Error en el servicio");
        setShowLoader(false);
        // setShowLoader(false);
      }
    }
  };
  useEffect(() => {
    console.log("Valor seleccionado");
    console.log(idParamSelected);
  }, [idParamSelected]);
  const currentYear = new Date().getFullYear();
  const [yearSelect, setYearSelected] = useState<number>(currentYear);
  const listaAños: AñoObjeto[] = [
    { id: 2019, año: 2019 },
    { id: 2020, año: 2020 },
    { id: 2021, año: 2021 },
    { id: 2022, año: 2022 },
    { id: 2023, año: 2023 },
    { id: 2024, año: 2024 },
    { id: 2025, año: 2025 },
    { id: 2026, año: 2026 },
    { id: 2027, año: 2027 },
    { id: 2028, año: 2028 },
  ];

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleItemClick = (index:number) => {
    setSelectedIndex(index === selectedIndex ? null : index); // Alternar el índice seleccionado
  };
  return (
    <SearchOperacionStyles>
      <div className="box-container">
        <div className="element-client-box">
          <div className="title-box">
            <h3>Busqueda de operaciones</h3>
            <div className="elements-search">
              <div className="combo-box-container">
                <SelectListdefault
                  style={{
                    width: "80%",
                    maxWidth: "80%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    setIdParamSelected(parseInt(e.target.value));
                    setBodySearch({
                      textSearch: "",
                      dateSearch: "",
                      idUser: 0,
                    });
                  }}
                  value={idParamSelected || ""}
                >
                  <option value="" disabled selected>
                    Criterio de busqueda
                  </option>

                  {listSearch?.length > 0
                    ? listSearch
                        .sort((a, b) =>
                          a.description.localeCompare(b.description)
                        )
                        .map((item) => {
                          return (
                            <>
                              <option
                                key={item.id}
                                value={item.id}
                                // selected={item.identifier == idParamSelected}
                              >
                                {item.description}
                              </option>
                            </>
                          );
                        })
                    : null}
                </SelectListdefault>
              </div>
              {idParamSelected == 2 ? (
                <>
                  <div className="container-busqueda">
                    <DateInput
                      text="Fecha"
                      txtWidth={80}
                      setGeneralData={setBodySearch}
                      field="dateSearch"
                      value={bodySearch.dateSearch}
                      height={46.6}
                    />
                    <div className="icon-container" onClick={findOperaciones}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>
                  </div>
                </>
              ) : idParamSelected == 1 ? (
                <>
                  <div className="container-busqueda">
                    <SelectListdefaultDos
                      style={{
                        width: "80%",
                        maxWidth: "80%",
                      }}
                      id="sl6"
                      onChange={(e) => {
                        setBodySearch({
                          textSearch: "",
                          dateSearch: "",
                          idUser: parseInt(e.target.value),
                        });
                      }}
                      value={bodySearch.idUser || ""}
                    >
                      <option value="" disabled selected>
                        Usuario
                      </option>

                      {listOP?.length > 0
                        ? listOP.map((item) => {
                            return (
                              <>
                                <option
                                  key={item.user_identifier}
                                  value={item.user_identifier}
                                  // selected={item.identifier == idParamSelected}
                                >
                                  {item.user_name}
                                </option>
                              </>
                            );
                          })
                        : null}
                    </SelectListdefaultDos>
                    <div className="icon-container" onClick={findOperaciones}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>
                  </div>
                </>
              ) : (
                <div className="search-box-container">
                  <div className="input-container">
                    <input
                      type="text"
                      value={bodySearch.textSearch}
                      onChange={(e) => {
                        setBodySearch({
                          ...bodySearch,
                          textSearch: e.target.value,
                        });
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="icon-container" onClick={findOperaciones}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </div>
                </div>
              )}
              {idParamSelected !== 2 && (
                <div className="box-select">
                  {yearSelect && <h3 className="titulo-select">Año</h3>}
                  <SelectListdefault
                    className="select-default-dos"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                    }}
                    id="sl6"
                    onChange={(e) => {
                      // setLocalTarifa({
                      //   ...localTarifa,
                      //   continent_identifier: parseInt(e.target.value),
                      // });
                      setYearSelected(parseInt(e.target.value));
                    }}
                    value={yearSelect || ""}
                  >
                    <option value="" disabled selected>
                      Año
                    </option>

                    {listaAños.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={item.id}
                          // selected={
                          //   item.identifier ==
                          //   localTarifa.continent_identifier
                          // }
                        >
                          {item.año}
                        </option>
                      );
                    })}
                  </SelectListdefault>
                </div>
              )}
            </div>

            <div></div>
          </div>

          <table className="table table-striped">
            {listResultsOP.length > 0 && (
              <thead>
                <tr>
                  <th>Alias</th>
                  <th>Agencia</th>
                  <th>Fecha de Inicio</th>
                  <th>Fecha fin</th>
                  <th>Usuario</th>
                  <th></th>
                </tr>
              </thead>
            )}

            <tbody>
              {listResultsOP?.map((operacion, index) => {
                return (
                  <>
                    <ElementSearchOPT
                      key={operacion.operation_identifier}
                      currentOperacion={operacion}
                      handleItemClick = {handleItemClick}
                      index = {index}
                      selectedIndex = {selectedIndex}
                      setSelectedIndex = {setSelectedIndex}
                    />
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </SearchOperacionStyles>
  );
}

const SearchOperacionStyles = styled.div`
  @import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
  .container-busqueda {
    width: 100%;
    display: flex;
  }
  .search-contenedor {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 80%;
    margin: 0 auto;
    grid-column-gap: 2rem;
  }
  .box-select {
    position: relative;
  }

  .titulo-select {
    position: absolute;
    top: -0.5rem;
    font-size: 0.85vw !important;
    background-color: white;
    margin-left: 1rem;
    color: rgb(8, 166, 165);
    font-weight: 300;
  }
  @media screen and (max-width: 900px) {
    .titulo-select {
      font-size: 1.5vw;
    }
  }
  .box-search-elements {
    display: flex;
    flex-direction: column;
  }
  .elements-search {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-right: 1rem;
  }
  .combo-box-container {
    width: 200px;
    height: 10px;
  }
  .search-box-container {
    border: rgb(8, 166, 165) solid 2px;
    width: 95%;
    display: flex;
    max-height: 46.6px;
    /* margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
    */
  }
  .input-container {
    width: 85%;
    max-height: 46.6px;
  }
  .input-container input {
    width: 100%;
    padding: 0.8rem;
    border: none;
    outline: none;
    /* border-right: rgb(8, 166, 165) solid 2px; */
    min-height: 100%;
  }
  .icon-container {
    width: 15%;
    background-color: rgb(8, 166, 165);

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: ease all 0.7s;
    max-height: 46.6px;
  }
  .icon-container:hover {
    background-color: white;
    color: rgb(8, 166, 165);
  }
  /* position: absolute; */
  .title-box {
    text-align: center;

    margin-bottom: 1rem;
    border-radius: 0.6rem;
  }
  .title-box h3 {
    font-size: 2.7rem;
    font-weight: bolder;
    color: rgb(8, 166, 165);
  }
  .element-client-box {
    border: rgb(8, 166, 165);
  
    min-height: 100vh;
    width: 85%;
    margin: 0 auto;
    border-radius: 0.4rem;
    padding: 1rem 0 1.3rem 1.3rem;
    margin-top: 2rem;
  }
  .box-container {
    margin-bottom: 3rem;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #4caf50;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  @media screen and (max-width: 900px) {
    /* Estilos para dispositivos móviles */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    tr {
      margin-bottom: 10px;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
      text-align: left;
    }

    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
      font-weight: bold;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 46.6px !important;
  min-height: 46.6px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  ///////////////////////////////////
`;

const SelectListdefaultDos = styled.select`
  width: 50% !important;
  border: 2px solid rgb(8, 166, 165);
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  max-height: 46.6px !important;
  min-height: 46.6px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  ///////////////////////////////////
`;
