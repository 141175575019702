import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBars, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import { servicioIndividual } from "../../../context/InterfacesContext";
import { servicioDayElementI } from "../../../context/InterfacesContext";
import ResumenElement from "./ResumenElement";
import { transformarFecha } from "./funciontes";

interface DayResumenI {
  dayServicios: servicioDayElementI;
  numberDay: number;
}
export default function DayResumen({ dayServicios, numberDay }: DayResumenI) {
  const [isShowingService, setIsShowingService] = useState<boolean>(false);

  const totalDay = dayServicios.serviciosIndividuales.reduce(
    (total, item) => total + item.tarifa,
    0
  );

  return (
    <DayResumenStyles>
      <div className="title-resumen">
        <h3>
          Día {numberDay} {transformarFecha(dayServicios.validityStartDay)}{" "}
          {dayServicios.travelRoute.trim()
            ? " - " + dayServicios.travelRoute
            : ""}
        </h3>
        <div>
          <FontAwesomeIcon
            icon={faAngleDown}
            className="arrow-down"
            onClick={() => {
              setIsShowingService(!isShowingService);
            }}
          />
        </div>
      </div>

      {isShowingService && (
        <div className="content-tarifa">
          {dayServicios.serviciosIndividuales.map(
            (servicioIndividual, index) => {
              return (
                <>
                  <ResumenElement servicioIndividual={servicioIndividual} />
                </>
              );
            }
          )}
          {
            totalDay > 0 && <>
            Total: ${totalDay}
            </>
          }
          
        </div>
      )}
    </DayResumenStyles>
  );
}

const DayResumenStyles = styled.div`
  .content-tarifa {
    padding: 0.5rem;
  }

  .title-resumen {
    border: #f6921e solid 1px;
    padding: 0.3rem;
    display: grid;
    grid-template-columns: 5fr 1fr;
    align-items: center;
    justify-content: center;
    border-right: none;
    border-left: none;
    background-color: rgba(246, 146, 30, 0.3);
  }
  .arrow-down {
    color: #f6921e;
    font-size: 1.5vw;
    margin-right: 1rem;
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    .arrow-down {
      font-size: 2.5vw;
    }
  }

  .title-resumen {
    border: #f6921e solid 1px;
    padding: 0.3rem;
    display: grid;
    grid-template-columns: 5fr 1fr;
    align-items: center;
    justify-content: center;
    border-right: none;
    border-left: none;
  }
  .arrow-down {
    color: #f6921e;
    font-size: 1.5vw;
    margin-right: 1rem;
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    .arrow-down {
      font-size: 2.5vw;
    }
  }
`;
