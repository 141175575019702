import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  Country,
  City,
  Neighborhood,
  Zipcode,
  RegistrationType,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import EDITAR from "../../../images/edit.png";
import { Tax } from "../../../interfaces/ProviderTypes";
import { RegisterGeneralI } from "../../../interfaces/GeneralTypes";
import { useNavigate } from "react-router-dom";

interface GetFiscalI {
  taxes: Tax | undefined;
  registerGeneral: RegisterGeneralI;
  getInfoProvider: () => void;
  nombre: string | undefined
  business_name: string | undefined
}

export default function GetFiscal({
  taxes,
  registerGeneral,
  getInfoProvider,
  nombre,
  business_name
}: GetFiscalI) {
  const navigate = useNavigate();
  const { setShowInfoContact } = useContext(AppContext);

  const editarFiscal = () => {
    getInfoProvider();
    navigate("/dashboard/Registro/Fiscal");
    setShowInfoContact(false);
  };
  return (
    <GetFiscalStyles>
      <div className="title-first">
        <h3>Fiscal</h3>
        <img src={EDITAR} onClick={editarFiscal}></img>
      </div>

      <div className="titles-fiscal">
        <br />
        <h3>Nombre: {nombre}</h3>
        <h3>Razón social: {business_name}</h3>
        <h3>RFC: {taxes?.rfc}</h3>
        <h3>Correo electrónico: {taxes?.email}</h3>
      </div>
      <br />
      {taxes?.bank_accounts_list && (
        <div className="second-box">
          <div>
            <h3>Moneda</h3>
          </div>
          <div>
            <h3>Cuenta bancaria</h3>
          </div>
          <div>
            <h3>CLABE</h3>
          </div>
          <div>
            <h3>Banco</h3>
          </div>
          <div></div>
        </div>
      )}

      {taxes?.bank_accounts_list &&
        taxes.bank_accounts_list.map((tax, index) => {
          return (
            <div className="second-box third-box data-accounts">
              <div className="element-fiscal">
                <h3>{tax.currency}</h3>
              </div>
              <div className="element-fiscal">
                <h3>{tax.bank_account}</h3>
              </div>
              <div className="element-fiscal">
                <h3>{tax.clabe}</h3>
              </div>
              <div className="element-fiscal">
                <h3>{tax.bank}</h3>
              </div>
              
            </div>
          );
        })}
    </GetFiscalStyles>
  );
}
const GetFiscalStyles = styled.div`
  h3{
    font-size: 0.8vw;    
  }

  @media screen and (max-width: 900px){
    h3{
    font-size: 1.6vw;    
  }
  
  }

  .data-accounts{
    border: black solid 20px;
  }
  .element-fiscal{
    
    max-width: 100%;
    text-align: center;
    word-wrap: break-word;
  word-break: break-all;
  max-width: 30em; 
  }
 .titles-fiscal h3{
margin-bottom: 0.5rem;
 }
  
`;
