import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ElementEstados from "./ElementEstados";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { PaymentSearch } from "../InterfacesContabilidad";

interface EstadosCuentaI {
  resultsPagos: PaymentSearch[];
}
export default function EstadosCuentaList({ resultsPagos }: EstadosCuentaI) {
  const [pesosPayment, setPesosPayment] = useState<PaymentSearch[]>([]);
  const [dolaresPayment, setDolaresPayment] = useState<PaymentSearch[]>([]);

  useEffect(() => {
    const pesosArray = resultsPagos.filter((pago, index) => {
      return pago.currency_identifier == 2;
    });
    setPesosPayment(pesosArray);
    const dolarArray = resultsPagos.filter((pago, index) => {
      return pago.currency_identifier == 1;
    });
    setDolaresPayment(dolarArray);
  }, [resultsPagos]);
  return (
    <EstadosCuentaListStyles>
      <br />
      <br />
      {dolaresPayment.length > 0 && (
        <>
          <h3 className="title-money">Pagados (Dolares)</h3>
          <div className="header-table-cuenta">
            <div>
              <h3>Prestador</h3>
            </div>
            <div>
              <h3>Fecha</h3>
            </div>
            <div>
              <h3>Agencia</h3>
            </div>
            <div>
              <h3>Grupo</h3>
            </div>
            <div>
              <h3>Entrada(s)</h3>
            </div>
            <div>
              <h3>Cargo</h3>
            </div>

            <div>
              <h3>T.C</h3>
            </div>
            <div>
              <h3>Fecha Pagado</h3>
            </div>
            <div>
              <h3>Cuenta de Banco</h3>
            </div>
            <div>
              <h3>Fecha Viaje</h3>
            </div>
          </div>
        </>
      )}

      {dolaresPayment &&
        dolaresPayment.map((dolarPayment, index) => {
          return <ElementEstados paymentData={dolarPayment} />;
        })}

      <br />
      <br />
      {pesosPayment.length > 0 && (
        <>
          <h3 className="title-money">Pagados (Pesos)</h3>
          <div className="header-table-cuenta">
            <div>
              <h3>Prestador</h3>
            </div>
            <div>
              <h3>Fecha</h3>
            </div>
            <div>
              <h3>Agencia</h3>
            </div>
            <div>
              <h3>Grupo</h3>
            </div>
            <div>
              <h3>Entrada(s)</h3>
            </div>
            <div>
              <h3>Cargo</h3>
            </div>

            <div>
              <h3>T.C</h3>
            </div>
            <div>
              <h3>Fecha Pagado</h3>
            </div>
            <div>
              <h3>Cuenta de Banco</h3>
            </div>
            <div>
              <h3>Fecha Viaje</h3>
            </div>
          </div>
        </>
      )}

      {pesosPayment &&
        pesosPayment.map((pesoPayment, index) => {
          return <ElementEstados paymentData={pesoPayment} />;
        })}
    </EstadosCuentaListStyles>
  );
}
const EstadosCuentaListStyles = styled.div`
  .title-money {
    text-align: center;
    color: rgb(8, 166, 165);
  }
  .header-table-cuenta {
    border: rgb(8, 166, 165) solid 1px;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 120%;
    padding: 0.5rem;
    background-color: rgb(8, 166, 165);
    margin-left: 2rem;
  }
  .header-table-cuenta div h3 {
    text-align: center;
    color: white;
    font-size: 0.85vw;
    font-weight: 300;
  }
`;
