import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { ProformaAdvance } from "./ProformaI";
import AnticipoElement from "./AnticipoElement";

interface AnticiposI {
  proformaAdvances: ProformaAdvance[];
  setProformaAdvances: React.Dispatch<React.SetStateAction<ProformaAdvance[]>>;
}

export default function Anticipos({
  proformaAdvances,
  setProformaAdvances,
}: AnticiposI) {
  return (
    <AnticiposStyles>
      {proformaAdvances.map((currentProformaAdvance, index) => {
        return (
          <>
            <AnticipoElement 
          key = {currentProformaAdvance.id_local}
            currentProformaAdvance={currentProformaAdvance}
            proformaAdvances={proformaAdvances}
            setProformaAdvances= {setProformaAdvances}
            isIncluidingPlus = {index == proformaAdvances.length - 1}
            />
          </>
        );
      })}
    </AnticiposStyles>
  );
}
const AnticiposStyles = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: end;
  align-items: end;
`;
