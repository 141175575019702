import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import RowCotizacion from "./Components/RowCotizacion";
import { QuoteItemI } from "./InterfacesOperacion/OperacionesI";
import { infoPaginationI, userI } from "../../../context/InterfacesContext";
import Pagination from "./Pagination";
interface stylesTableI {
  roleUser: string;
  optionTableSelected: number;
  group_identifier: number;
}

interface GerenteTableI {
  optionTableSelected: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}
export default function GerenteTable({
  optionTableSelected,
  currentPage,
  setCurrentPage,
}: GerenteTableI) {
  //////Llamar a servicios para traer datos de la tabla de Gerente.
  const { currentUser, setCurrentUser } = useContext(AppContext);
  const { setShowLoader } = useContext(AppContext);
  const cotizacionesMockNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const totalPages = 22; // Cambia esto según el número total de páginas en tu caso.
  const [totalPagesNumber, setTotalPagesNumber] = useState(0);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    // Aquí deberías cargar los datos de la página seleccionada desde tu fuente de datos.
  };

  const [tableQuotes, setTableQuotes] = useState<QuoteItemI[]>([]);

  const [listUsers, setListUsers] = useState<userI[]>([]);

  const [infoPagination, setInfoPagination] = useState<infoPaginationI>({
    list_type: "",
    count: 0,
    pages: 0,
  });
  const [usuarioActual, setUsuarioActual] = useState(() => {
    const usuario = localStorage.getItem("usuarioLogeado");
    return usuario !== null ? usuario : currentUser.user_name;
  });

  const [currentUserId, setCurrentUserId] = useState(() => {
    const userIdNumber = localStorage.getItem("userId");

    return userIdNumber ? userIdNumber : currentUser.user_identifier;
  });
  const getInfoTable = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    setShowLoader(true);
    const urlModified =
      backEndPoint +
      `/operations/QuotesList/userid=${currentUserId}/listtype=${
        optionTableSelected ?? 2
      }/page=${currentPage ?? 1}`;

    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data = await response.json();
        const tablaUsuarios: userI[] = data.data.user_list;

        setInfoPagination(data.data.info);
        if (optionTableSelected == 2) {
          setTableQuotes(data.data.assigned_quotes_list);
        } else {
          setTableQuotes(data.data.all_quotes_list);
        }

        let tableQuotes: userI[] = [];

        //   if(currentUser.group_identifier == 1 || currentUser.group_identifier == 1017 || currentUser.group_identifier == 1013){
        //     tableQuotes = tableQuotes.filter((usuario, index)=>{
        //       return usuario.group_identifier == 1 || usuario.group_identifier == 1017 || usuario.group_identifier == 1013 || usuario.group_identifier == 1012
        //     })

        //   }else if(currentUser.group_identifier == 1012){
        //     tableQuotes = tableQuotes.filter((usuario, index)=>{
        //       return usuario.group_identifier == 1012 || usuario.group_identifier == 1012
        //     })
        //   }
        //   console.log("TABLEQUOTES:::");
        //   console.log(tableQuotes);
        // setListUsers(tableQuotes);

        // const quoteResponse: QuoteDetail = data.data.quote_detail;
        // console.log("RESPUESTA DEL SERVIDOR PARAMETRIZADA EN INTERFAZ");
        // console.log(quoteResponse);

        // const personalInfoInicial: personalInfoI = {
        //   name: quoteResponse.quote.name,
        //   idCotizacion: quoteResponse.quote.quote_identifier.toString(),
        //   typeCotizacion: quoteResponse.quote.type,
        //   typeAgency: 1,
        //   serieId: quoteResponse.quote.serie_identifier,
        //   includesSerie: true,
        //   pax: quoteResponse.quote.PAX,
        //   min: quoteResponse.quote.MIN,
        //   max: quoteResponse.quote.MAX,
        //   TL: quoteResponse.quote.TL,
        //   arrivingData: quoteResponse.quote.arrival_date,
        //   departureDate: quoteResponse.quote.departure_date,
        //   agencia: quoteResponse.quote.agency,
        //   typeRoom: 2,
        //   numberDaysOpenQuote: quoteResponse.quote.number_of_days,
        //   modeCotizacion: quoteResponse.quote.serie ? "Serie" : "Normal",
        //   selectedSerieOption: "No",
        //   serieSelected: 0,
        //   isShowingDates: false,
        //   dateStartOpen: "",
        //   dateEndOpen: "",
        //   status_identifier: quoteResponse.quote.status_identifier,
        //   quote_identifier: quoteResponse.quote.quote_identifier,
        // };

        //////////////////Recuperando servicios Generales.

        // setTitleAlert("La solicitud se realizó correctamente");
        // setContentAlert("Accediste a la siguiente información de cotización.");
        // setCotizacionMode(2);
        // setShowAlert(true);
        setShowLoader(false);
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al realizar la solicitud!:");
        // setContentAlert(
        //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        // );
        // setShowAlert(true);
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      //   alert("ERROR AL REALIZAR LA SOLICITUD");

      //   setTitleAlert("Error al realizar la solicitud:");
      //   setContentAlert(
      //     "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      //   );
      setShowLoader(false);
    }
  };
  const [grupoActual, setGrupoActual] = useState(() => {
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null ? parseInt(grupo) : currentUser.group_identifier;
  });

  useEffect(() => {
    getInfoTable();
  }, [optionTableSelected, currentPage]);
  useEffect(() => {
    console.log("INFO PAGINATION:::::::");
    console.log(infoPagination);
  }, [infoPagination]);
  useEffect(() => {
    console.log("NUEVA TABLA GUARDADA:::::::::::::::::::::::::::::.");
    console.log(tableQuotes);
  }, [tableQuotes]);
  useEffect(() => {
    console.log("LA PAGINA ACTUAL HA CAMBIADO::::>>>>>>>");
    console.log(currentPage);
  }, [currentPage]);

  return (
    <GerenteTableStyles
      roleUser={currentUser.role}
      optionTableSelected={optionTableSelected}
      group_identifier={grupoActual}
    >
      {/*----------------------------------------------------- Encabezado de tabla------------------------------ */}
      <div className="header-table-quotes">
        <div>
          <h3>Fecha de Elaboración</h3>
        </div>
        <div>
          <h3>Elaboró</h3>
        </div>
        <div>
          <h3>Agencia</h3>
        </div>
        <div>
          <h3>ID</h3>
        </div>
        <div>
          <h3>Nombre</h3>
        </div>
        <div>
          <h3>Fecha llegada</h3>
        </div>
        <div>
          <h3>Fecha salida</h3>
        </div>
        {(grupoActual == 1 ||
          grupoActual == 1017 ||
          grupoActual == 1013 ||
          grupoActual == 1012) &&
          optionTableSelected !== 2 && (
            <div>
              <h3>Asignar</h3>
            </div>
          )}
      </div>
      {tableQuotes.map((quote, index) => {
        return (
          <RowCotizacion
            quote={quote}
            optionTableSelected={optionTableSelected}
            listUsers={listUsers}
            setListUsers={setListUsers}
            tableQuotes={tableQuotes}
            setTableQuotes={setTableQuotes}
          />
        );
      })}
      <br />
      {true && (
        <Pagination
          totalPages={infoPagination.pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onPageChange={handlePageChange}
        />
      )}

      {/* {cotizacionesMockNumbers.map((numero, index) => {
        return (
          <>
            <RowCotizacion optionTableSelected={optionTableSelected} />
          </>
        );
      })} */}
      {/* -----------------------------------------------------Datos de la tabla-------------------------------- */}
    </GerenteTableStyles>
  );
}

const GerenteTableStyles = styled.div<stylesTableI>`
  .header-table-quotes {
    display: grid;
    grid-template-columns: ${(props) =>
      (props.group_identifier == 1 ||
        props.group_identifier == 1017 ||
        props.group_identifier == 1013 ||
        props.group_identifier == 1012) &&
      props.optionTableSelected !== 2
        ? "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        : "1fr 1fr 1fr 1fr 1fr 1fr 1fr"};
    border: grey solid 1px;
    align-items: center;
    justify-content: center;
    background-color: rgb(8, 166, 165);
  }
  .header-table-quotes div {
    padding: 0.6rem;
  }
  .header-table-quotes div h3 {
    font-weight: 200;
    text-align: center;
    font-size: 0.85vw;
    color: white;
  }

  @media screen and (max-width: 900px) {
    .header-table-quotes div h3 {
      font-size: 1.2vw;
    }
  }
`;
