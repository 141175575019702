import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import SearchBoxClientE from "./SearchBoxClientE";
import { ApiResponse } from "../../../../../interfaces/interfaces";
import { AppContext } from "../../../../../context/appContext";
import { fetchServer } from "../../../../../services/fetchServer";
import { BusquedaPagosI, OperationISearch, PaymentSearch, ProviderDataSearch } from "../../InterfacesContabilidad";
interface BusquedaEstadosCuentaI{
    busquedaPagos: BusquedaPagosI,
    resultsPagos: PaymentSearch[],
    setResultsPagos: React.Dispatch<React.SetStateAction<PaymentSearch[]>>,
    providerData: ProviderDataSearch,
    setProviderData: React.Dispatch<React.SetStateAction<ProviderDataSearch>>,
    selectedOption: string,
    listSearchAgencias: OperationISearch[],
    setListSearchAgencias: React.Dispatch<React.SetStateAction<OperationISearch[]>>
}
export default function BusquedaEstadosCuenta({busquedaPagos, resultsPagos, setResultsPagos, providerData, setProviderData, selectedOption, listSearchAgencias, setListSearchAgencias}:BusquedaEstadosCuentaI) {
  const { setShowLoader } = useContext(AppContext);
  const [data, setData] = useState<ApiResponse["data"]>({
    provider_list: [],
  });
  const [initialData, setInitialData] = useState<ApiResponse["data"]>({
    provider_list: [],
  });
  const [resultsList, setResultsList] = useState<ApiResponse["data"]>({
    provider_list: [],
  });

  const getData = async () => {
    setShowLoader(true);
    const lugares = await fetchServer("/provider/GetProviderList");
    console.log("INFO");
    console.log(lugares);
    setData(lugares.data);
    setInitialData(lugares.data);
    setShowLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <BusquedaEstadosCuentaStyles>
      <SearchBoxClientE
        data={data}
        setData={setData}
        initialData={initialData}
        resultsList={resultsList}
        setResultsList={setResultsList}
        busquedaPagos ={busquedaPagos}
        resultsPagos={resultsPagos}
        setResultsPagos={setResultsPagos}
        providerData = {providerData}
         setProviderData ={setProviderData}
         selectedOption={selectedOption}
         listSearchAgencias = {listSearchAgencias} 
          setListSearchAgencias = {setListSearchAgencias }
      />
    </BusquedaEstadosCuentaStyles>
  );
}

const BusquedaEstadosCuentaStyles = styled.div`
  .title-box {
    text-align: center;

    margin: 0 auto;
    padding: 2rem 2.5rem;
    margin-bottom: 1rem;
    border-radius: 0.6rem;
  }
  .title-box h3 {
    font-size: 2.7rem;
    font-weight: bolder;
    color: rgb(8, 166, 165);
  }
  .element-client-box {
    border: rgb(8, 166, 165);
    background-color: rgba(133, 198, 195, 0.5);
    min-height: 100vh;
    width: 85%;
    margin: 0 auto;
    border-radius: 0.4rem;
    padding: 1rem 0 1.3rem 1.3rem;
    margin-top: 2rem;
  }
  .box-container {
    margin-bottom: 3rem;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #4caf50;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  @media screen and (max-width: 900px) {
    /* Estilos para dispositivos móviles */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    tr {
      margin-bottom: 10px;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
      text-align: left;
    }

    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
      font-weight: bold;
    }
  }
`;
