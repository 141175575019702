import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import {
  feeChargesI,
  generalsElementsI,
  personalInfoI,
  servicioDayElementI,
  servicioIndividual,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import CustomInputt from "../../components/entrys/CustomInputt";
import {
  AgenciaElement,
  Country,
  QuoteGeneralElement,
  QuoteStatusI,
  SeriesCotizacionI,
} from "../../../interfaces/GeneralTypes";
import { useCatalogs } from "../../../context/CatalogContext";
import DateInput from "../../components/entrys/DateInput";
import { useCatalogsDos } from "../../../context/CatalogContextDos";
import { QuoteType, Series } from "../../../interfaces/GeneralTypes";
// import { CatalogsDataDos } from "../../../interfaces/GeneralTypes";
import deletelogo from "../../../images/deletelogo.png";
import CustomInputtt from "../../components/entrys/CustomInputtt";
import CustomInputttt from "../../components/entrys/Inputttt";
import { QuoteDetail } from "./interfaces/CotizacionI";
import {
  validarFormatoFecha,
  agregarDiasAFecha,
  createServicioPasajeroListPlus,
} from "../RegisterNewProvider-Client/FunctionsTarifa";

interface PersonalInfoI {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  handleOptionChange: (option: string) => void;
  handleSerieChange: (option: string) => void;
  selectedSerieOption: string;
  setSelectedSerieOption: React.Dispatch<React.SetStateAction<string>>;
}
export default function PersonalInfo({
  selectedOption,
  setSelectedOption,
  handleOptionChange,
  handleSerieChange,
  selectedSerieOption,
  setSelectedSerieOption,
}: PersonalInfoI) {
  const {
    dataCotizacionContext,
    setDataCotizacionContext,
    openCotizaciconNumber,
    setOpenCotizacionNumber,
    cotizacionMode,
  } = useContext(AppContext);
  const { catalogsDataDos } = useCatalogsDos();

  const [personalInfo, setPersonalInfo] = useState<personalInfoI>(
    dataCotizacionContext.personalInfo
  );

  const [quoteTypeList, setQuoteTypeList] = useState<QuoteType[]>([]);
  const [serieList, setSerieList] = useState<Series[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [seriesCatalog, setSeriesCatalog] = useState<SeriesCotizacionI[]>([]);
  const [statusList, setStatusList] = useState<QuoteStatusI[]>([]);
  const [agenciaList, setAgenciaList] = useState<AgenciaElement[]>([]);

  const { catalogsData } = useCatalogs();
  useEffect(() => {
    console.log("DAtos que vienen del servicio: ");
    console.log(catalogsDataDos);
    setQuoteTypeList(catalogsDataDos?.quote_types_list);
    setSerieList(catalogsDataDos?.series_list);
    setStatusList(catalogsDataDos?.quote_status_list);
  }, [catalogsDataDos]);
  useEffect(() => {
    setAgenciaList(catalogsData?.agencias_list);
  }, [catalogsData]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/Series`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Tatayendo datos de CATALOGOS SERIES");
        console.log(data.data);
        setSeriesCatalog(data.data.series_list);
      })
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    console.log("Nuevo valor de seriesCatalog");
    console.log(seriesCatalog);
  }, [seriesCatalog]);
  const getSerieCotizacion = async (idSerie: number) => {
    console.log(
      "En este bloque de codigo obtendremos la información relacionada con las series"
    );
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified = backEndPoint + `/quote/QuoteDetail/${idSerie}`;
    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data = await response.json();

        console.log("VALOR DE LA CONSULTA:");
        console.log(data.data);
        const quoteResponse: QuoteDetail = data.data.quote_detail;
        console.log("RESPUESTA DEL SERVIDOR PARAMETRIZADA EN INTERFAZ");
        console.log(quoteResponse);

        const personalInfoInicial: personalInfoI = {
          name: quoteResponse.quote.name,
          idCotizacion: quoteResponse.quote.quote_identifier.toString(),
          typeCotizacion: quoteResponse.quote.type,
          typeAgency: 1,
          serieId: quoteResponse.quote.serie_identifier,
          includesSerie: true,
          pax: quoteResponse.quote.PAX,
          min: quoteResponse.quote.MIN,
          max: quoteResponse.quote.MAX,
          TL: quoteResponse.quote.TL,
          arrivingData: quoteResponse.quote.arrival_date,
          departureDate: quoteResponse.quote.departure_date,
          agencia: quoteResponse.quote.agency,
          typeRoom: 2,
          numberDaysOpenQuote: quoteResponse.quote.number_of_days,
          modeCotizacion: quoteResponse.quote.serie ? "Serie" : "Normal",
          selectedSerieOption: "Yes",
          serieSelected: 0,
          isShowingDates: false,
          dateStartOpen: "",
          dateEndOpen: "",
          status_identifier: quoteResponse.quote.status_identifier,
          quote_identifier: quoteResponse.quote.quote_identifier,
          optional_quote: quoteResponse.quote.optional_quote,
        };

        ///////////////////Recuperando servicios individuales

        const serviciosDaysElementsInicial: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.quote_individual_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: 0,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: "",
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: "",
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    number_of_services: 0,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    isIncludedOpt: false,
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              };
              return servicio;
            }
          ),
        };

        //////////////////Recuperando servicios Generales.

        const cargosFee: feeChargesI = {
          currentMoney: 0,
          changeType: quoteResponse.quote.exchange_rate,
          comision: quoteResponse.quote.commission,
          comisonAuto: quoteResponse.quote.commission_auto,
          cortesia: quoteResponse.quote.courtesy,
          comments: quoteResponse.quote.comments,
          courtesy_type_identifier:
            quoteResponse.quote.courtesy_type_identifier,
        };

        const generalElementos: generalsElementsI = {
          generalElement: [],
        };

        let serviciosTransporteGenerales: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.general_transport_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: 0,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: "",
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: "",
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    number_of_services: serviceDayy.number_of_services ?? 1,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    isIncludedOpt: false,
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              };
              return servicio;
            }
          ),
        };

        let serviciosGenerales: QuoteGeneralElement[] = [];
        if (quoteResponse.quote_general_services) {
          serviciosGenerales = quoteResponse.quote_general_services.map(
            (generalS, index) => {
              let identifierGUID = uuidv4();
              const currentServicioGeneral: QuoteGeneralElement = {
                service_type: generalS.service_type_identifier,
                provider_id: generalS.provider_identifier,
                service_name_id: generalS.service_identifier,
                fee_price: generalS.fee,
                number_days: generalS.number_of_services,
                total_price: generalS.fee * generalS.number_of_services,
                identifierGeneralService: identifierGUID,
                currency: quoteResponse.quote.currency,
                currency_identifier: quoteResponse.quote.currency_identifier,
                expenses_type_identifier: generalS.expenses_type_identifier,
                general_service_identifier: generalS.general_service_identifier,
                status_identifier: generalS.service_identifier,
                isIncludedOpt: false,
              };
              return currentServicioGeneral;
            }
          );
        } else {
          serviciosGenerales = [];
        }

        setDataCotizacionContext({
          ...dataCotizacionContext,
          personalInfo: personalInfoInicial,
          serviciosDayElements: serviciosDaysElementsInicial,
          generalsElements: generalElementos,
          totalPrice: {
            totalPrice: 0,
            granTotal: 0,
          },
          feeCharges: cargosFee,
          generalServices: serviciosGenerales,
          serviciosDayElementsTransporte: serviciosTransporteGenerales,
        });
        console.log("SERVICIOS GENERALES DE TRANSPORTE:::");
        console.log(serviciosTransporteGenerales);
      } else {
      }
    } catch (error) {
      alert("ERROR AL REALIZAR LA SOLICITUD");
    }
  };

  useEffect(() => {
    console.log(
      "Nuevo valor de DATA COTIZACION CONTEXT::::------------------->"
    );
    console.log(dataCotizacionContext);
  }, [dataCotizacionContext]);

  useEffect(() => {
    console.log("Useeffect 1");

    console.log("Hora de ajustar la segunda fecha::: ");
    console.log(
      "FECHA INICIAL: ",
      dataCotizacionContext.personalInfo.dateStartOpen
    );
    if (
      validarFormatoFecha(dataCotizacionContext.personalInfo.dateStartOpen) &&
      !validarFormatoFecha(dataCotizacionContext.personalInfo.dateEndOpen)
    ) {
      console.log(
        `CALCULANDO NUEVA FECHA <<Fecha inicial ${dataCotizacionContext.personalInfo.dateStartOpen}>>`
      );
      console.log("Dias de intervalo");
      console.log(dataCotizacionContext.personalInfo.numberDaysOpenQuote);
      console.log(
        agregarDiasAFecha(
          dataCotizacionContext.personalInfo.dateStartOpen,
          dataCotizacionContext.personalInfo.numberDaysOpenQuote
        )
      );
      setDataCotizacionContext({
        ...dataCotizacionContext,
        personalInfo: {
          ...dataCotizacionContext.personalInfo,
          dateEndOpen: agregarDiasAFecha(
            dataCotizacionContext.personalInfo.dateStartOpen,
            dataCotizacionContext.personalInfo.numberDaysOpenQuote
          ),
          modeCotizacion: "Normal",
        },
      });
      return;
    }

    if (
      validarFormatoFecha(dataCotizacionContext.personalInfo.dateStartOpen) &&
      validarFormatoFecha(dataCotizacionContext.personalInfo.dateEndOpen)
    ) {
      console.log(
        "Insertando cada nueva fecha en los servicios precargados de series!!!!"
      );
      const serviciosConFechaIndividuales: servicioDayElementI[] =
        createServicioPasajeroListPlus(
          dataCotizacionContext.personalInfo.dateStartOpen,
          dataCotizacionContext.personalInfo.dateEndOpen,
          dataCotizacionContext.serviciosDayElements.servicioDayElement
        );
      const serviciosConFechaTransporte: servicioDayElementI[] =
        createServicioPasajeroListPlus(
          dataCotizacionContext.personalInfo.dateStartOpen,
          dataCotizacionContext.personalInfo.dateEndOpen,
          dataCotizacionContext.serviciosDayElementsTransporte
            .servicioDayElement
        );

      console.log("Arreglo final con fecha");
      console.log(serviciosConFechaIndividuales);
      setDataCotizacionContext({
        ...dataCotizacionContext,
        serviciosDayElements: {
          servicioDayElement: serviciosConFechaIndividuales,
        },
        serviciosDayElementsTransporte: {
          servicioDayElement: serviciosConFechaTransporte,
        },
      });
      return;
    }
  }, [
    dataCotizacionContext.personalInfo.dateStartOpen,
    dataCotizacionContext.personalInfo.dateEndOpen,
  ]);

  /////CUIDADO CON EL USE EFFECT 2

  useEffect(() => {
    console.log("Useeffect 2");
    setPersonalInfo(dataCotizacionContext.personalInfo);
    console.log("ACtualizando estado local de personal info");
    console.log(dataCotizacionContext.personalInfo);
  }, [dataCotizacionContext.personalInfo]);

  useEffect(() => {
    console.log("Useeffect 3");
    console.log("NUEVOS VALORES DE PERSONAL INFO!!!!!");
    console.log(personalInfo);
    setDataCotizacionContext({
      ...dataCotizacionContext,
      personalInfo: personalInfo,
    });
  }, [personalInfo]);

  return (
    <PersonalInfoStyles>
      <div className=""></div>
      <div className="container-additional-options">
        <div>
          <h3 className="title-options">
            Indica el tipo de cotización que deseas guardar
          </h3>
          <div className="box-radio">
            <div className="box-radio-one">
              <label>
                <input
                  type="radio"
                  value="Normal"
                  checked={
                    dataCotizacionContext.personalInfo.modeCotizacion ===
                    "Normal"
                  }
                  onChange={() => handleOptionChange("Normal")}
                />
                Normal
              </label>
            </div>
            <div className="box-radio-two">
              <label>
                <input
                  type="radio"
                  value="Serie"
                  checked={
                    dataCotizacionContext.personalInfo.modeCotizacion ===
                    "Serie"
                  }
                  onChange={() => handleOptionChange("Serie")}
                />
                Serie
              </label>
            </div>
            <div className="box-radio-two">
              <label>
                <input
                  type="radio"
                  value="Opcional"
                  checked={
                    dataCotizacionContext.personalInfo.modeCotizacion ===
                    "Opcional"
                  }
                  onChange={() => handleOptionChange("Opcional")}
                />
                Opcional
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="container-additional-options">
        <div>
          <h3 className="title-options">¿Desea usar una serie existente?</h3>
          <div className="box-radio">
            <div className="box-radio-one">
              <label>
                <input
                  type="radio"
                  value="Normal"
                  checked={
                    dataCotizacionContext.personalInfo.selectedSerieOption ===
                    "Yes"
                  }
                  onChange={() => handleSerieChange("Yes")}
                />
                Sí
              </label>
            </div>
            <div className="box-radio-two">
              <label>
                <input
                  type="radio"
                  value="Serie"
                  checked={
                    dataCotizacionContext.personalInfo.selectedSerieOption ===
                    "No"
                  }
                  onChange={() => handleSerieChange("No")}
                />
                No
              </label>
            </div>
          </div>
          <br />
          {dataCotizacionContext.personalInfo.selectedSerieOption == "Yes" && (
            <SelectListdefault
              style={{
                width: "80%",
                maxWidth: "80%",
              }}
              id="sl6"
              onChange={(e) => {
                setPersonalInfo({
                  ...personalInfo,
                  serieSelected: parseInt(e.target.value),
                });
                console.log("Identificador de serie seleccionado");
                console.log(personalInfo.serieSelected);
                getSerieCotizacion(parseInt(e.target.value));
              }}
            >
              <option value="" disabled selected>
                Series
              </option>

              {seriesCatalog?.length > 0
                ? seriesCatalog.map((item) => {
                    return (
                      <Fragment>
                        <option
                          key={item.name}
                          value={item.identifier}
                          selected={
                            item.identifier == personalInfo.typeCotizacion
                          }
                        >
                          {item.name}
                        </option>
                      </Fragment>
                    );
                  })
                : null}
            </SelectListdefault>
          )}
        </div>
      </div>

      <div className="personal-info-box-one">
        <CustomInputt<personalInfoI>
          text={`${
            dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
              ? "Nombre de serie"
              : "Nombre"
          }`}
          txtWidth={80}
          setGeneralData={setPersonalInfo}
          field="name"
          value={personalInfo.name}
        />
        <CustomInputt<personalInfoI>
          text="ID Cotización"
          txtWidth={80}
          setGeneralData={setPersonalInfo}
          field="idCotizacion"
          value={personalInfo.idCotizacion}
        />
        <div className="box-select">
          {personalInfo.typeCotizacion !== 0 && (
            <h3 className="titulo-select">Tipo de cotización</h3>
          )}
          <SelectListdefault
            style={{
              width: "90%",
              maxWidth: "90%",
            }}
            id="sl6"
            onChange={(e) => {
              setPersonalInfo({
                ...personalInfo,
                typeCotizacion: parseInt(e.target.value),
              });
            }}
          >
            <option value="" disabled selected>
              Tipo de cotización
            </option>

            {quoteTypeList?.length > 0
              ? quoteTypeList.map((item) => {
                  return (
                    <Fragment>
                      <option
                        key={item.description}
                        value={item.identifier}
                        selected={
                          item.identifier == personalInfo.typeCotizacion
                        }
                      >
                        {item.description}
                      </option>
                    </Fragment>
                  );
                })
              : null}
          </SelectListdefault>
        </div>
        <div className="box-select">
          {personalInfo.agencia.trim() && (
            <h3 className="titulo-select">Agencia</h3>
          )}
          <SelectListdefault
            style={{
              width: "90%",
              maxWidth: "90%",
            }}
            id="sl6"
            onChange={(e) => {
              console.log("PAIS CON EL SIGUIENTE IDENTIFICADOR: ");
              console.log(e.target.value);
              
              setPersonalInfo({
                ...personalInfo,
                agencia: e.target.value,
              });
            }}
            value={personalInfo.agencia || "Sin agencias"}
          >
            <option value="" disabled selected>
              Agencia
            </option>

            {agenciaList?.length > 0
              ? agenciaList.map((item) => {
                  return (
                    <Fragment>
                      <option
                        key={item.description}
                        value={item.description}
                        // selected={
                        //   item.identifier == generalInfo.country_identifier
                        // }
                      >
                        {item.description}
                      </option>
                    </Fragment>
                  );
                })
              : null}
          </SelectListdefault>
        </div>
        
        {/* <CustomInputt<personalInfoI>
          text="Agencia"
          txtWidth={80}
          setGeneralData={setPersonalInfo}
          field="agencia"
          value={personalInfo.agencia}
        /> */}
        <div className="box-select">
          {personalInfo.typeRoom !== 0 && (
            <h3 className="titulo-select">Tipo de habitación</h3>
          )}
          <SelectListdefault
            style={{
              width: "90%",
              maxWidth: "90%",
            }}
            id="sl6"
            onChange={(e) => {
              setPersonalInfo({
                ...personalInfo,
                typeRoom: parseInt(e.target.value),
              });
            }}
          >
            <option value="" disabled selected>
              Tipo de habitación
            </option>

            <Fragment>
              <option key={1} value={1} selected={1 == personalInfo.typeRoom}>
                SGL
              </option>
              <option key={2} value={2} selected={2 == personalInfo.typeRoom}>
                DBL
              </option>
              <option key={3} value={3} selected={3 == personalInfo.typeRoom}>
                TPL
              </option>
            </Fragment>
          </SelectListdefault>
        </div>
        <div className="box-select">
          {personalInfo.status_identifier && (
            <h3 className="titulo-select">Estatus</h3>
          )}
          <SelectListdefault
            style={{
              width: "90%",
              maxWidth: "90%",
            }}
            id="sl6"
            onChange={(e) => {}}
          >
            <option value="" disabled selected>
              Estatus
            </option>

            {statusList?.length > 0
              ? statusList.map((item) => {
                  return (
                    <Fragment>
                      <option
                        key={item.description}
                        value={item.identifier}
                        selected={
                          item.identifier == personalInfo.status_identifier
                        }
                      >
                        {item.description}
                      </option>
                    </Fragment>
                  );
                })
              : null}
          </SelectListdefault>
        </div>
      </div>

      <div className="second-part-box">
        <div className="box-passengers">
          <CustomInputtt
            text="PAX"
            txtWidth={100}
            setGeneralData={setPersonalInfo}
            field="pax"
            value={personalInfo.pax}
            typeData="number"
          />
          <CustomInputtt
            text="MIN"
            txtWidth={100}
            setGeneralData={setPersonalInfo}
            field="min"
            value={personalInfo.min}
            typeData="number"
          />
          <CustomInputtt
            text="MAX"
            txtWidth={100}
            setGeneralData={setPersonalInfo}
            field="max"
            value={personalInfo.max}
            typeData="number"
          />
          <CustomInputtt
            text="TL"
            txtWidth={100}
            setGeneralData={setPersonalInfo}
            field="TL"
            value={personalInfo.TL}
            typeData="number"
          />
        </div>
        <div className="date-box">
          {(personalInfo.modeCotizacion == "Normal" ||
            personalInfo.modeCotizacion == "Opcional") &&
          personalInfo.dateEndOpen == "" ? (
            <>
              <DateInput
                text="Fecha llegada"
                txtWidth={100}
                setGeneralData={setPersonalInfo}
                field="arrivingData"
                value={personalInfo.arrivingData}
                isEditableS={cotizacionMode != 1 ? false : true}
              />
              <DateInput
                text="Fecha salida"
                txtWidth={100}
                setGeneralData={setPersonalInfo}
                field="departureDate"
                value={personalInfo.departureDate}
                minDate={personalInfo.arrivingData}
                isEditableS={cotizacionMode != 1 ? false : true}
              />
            </>
          ) : (personalInfo.modeCotizacion == "Normal" &&
              personalInfo.dateEndOpen !== "") ||
            personalInfo.modeCotizacion == "Serie" ? (
            <div className="container-actions-buttons">
              <div className="box-one-child">
                <CustomInputtt
                  text="No días"
                  txtWidth={30}
                  setGeneralData={setPersonalInfo}
                  field="numberDaysOpenQuote"
                  value={personalInfo.numberDaysOpenQuote}
                  typeData="number"
                />
                <button
                  onClick={() => {
                    setDataCotizacionContext({
                      ...dataCotizacionContext,
                      personalInfo: {
                        ...dataCotizacionContext.personalInfo,
                        isShowingDates:
                          !dataCotizacionContext.personalInfo.isShowingDates,
                      },
                    });
                  }}
                >
                  Insertar fechas
                </button>
              </div>
              {dataCotizacionContext.personalInfo.isShowingDates && (
                <div className="box-one-child">
                  <DateInput
                    text="Fecha llegada"
                    txtWidth={80}
                    setGeneralData={setPersonalInfo}
                    field="dateStartOpen"
                    value={personalInfo.dateStartOpen}
                  />
                  <DateInput
                    text="Fecha salida"
                    txtWidth={80}
                    setGeneralData={setPersonalInfo}
                    field="dateEndOpen"
                    value={personalInfo.dateEndOpen}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div></div>
    </PersonalInfoStyles>
  );
}

const PersonalInfoStyles = styled.div`
  @media screen and (max-width: 900px) {
    .titulo-select {
      font-size: 1.5vw;
    }
  }
  .box-select {
    position: relative;
    width: 90%;
  }

  .titulo-select {
    position: absolute;
    top: -0.5rem;
    font-size: 0.85vw;
    background-color: white;
    margin-left: 1rem;
    color: rgb(8, 166, 165);
    font-weight: 300;
  }
  .container-actions-buttons {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
  .box-one-child {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .title-options {
    color: rgb(8, 166, 165);
  }
  .box-radio {
    display: flex;
    margin-top: 1rem;
  }
  .box-radio-two {
    margin-left: 1rem;
  }
  .container-additional-options {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .additional-info {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
  .second-part-box {
    display: flex;
    justify-content: space-around;
  }

  .box-passengers {
    /* border: black solid 1px;  */
    padding: 1rem;
    width: 40%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
  .personal-info-box-one {
    /* border: black solid 1px; */
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
  }
  .second-part-box {
    /* border: black solid 1px; */
    width: 70%;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .date-box {
    /* border: red solid 1px; */
    width: 40%;
    display: grid;
    grid-column: 1fr;
    grid-row-gap: 1.5rem;
  }

  @media screen and (max-width: 900px) {
    .personal-info-box-one {
      width: 95%;
    }
    .second-part-box {
      width: 95%;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  ///////////////////////////////////
`;

const removeAccentsFromCountries = (countries: Country[]): Country[] => {
  const newCountries = countries.map((country) => {
    const { description, ...rest } = country;
    return {
      description: removeAccents(description),
      ...rest,
    };
  });
  return newCountries;
};

const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
