import { v4 as uuidv4 } from "uuid";
import { fileImagen } from "./base64Prueba";
import { clientInterface } from "../interfaces/interfaces";
import {
  contratoContextI,
  contratoContextInterface,
  contextFiscalI,
  contextCotizacionI,
  personalInfoI,
  serviciosDaysElementsI,
  servicioDayElementI,
  generalsElementsI,
  generalElementI,
  totalPricesI,
  feeChargesI,
  userI,
} from "./InterfacesContext";
import { QuoteGeneralElement, dataServicesI } from "../interfaces/GeneralTypes";
import { OptionalQuote } from "../alico/pages/Cotizacion/interfaces/CotizacionI";

export const registerGeneralData = {
  register_identifier: 1,
  ///////////////////////
  lender_type_identifier: 0,
  registration_type_identifier: 1,
  imagen: "",
  provider_type_identifier: 0,
  claveGeneral: "",
  razonSocial: "",
  nombreComercial: "",
  rfc: "",
  id: "",
  categoriaGeneral: 0,
  ultimaCompra: "",
  telefono: "",
  web: "",
  country_identifier: 0,
  city_identifier: 0,
  neighborhood_identifier: 0,
  zip_code: "0",
  calles: "",
  actividades: "",
  lead: "",
  comisionEsquema: "",
  comentarios: "",
  identifier: 1,
  imageExtension: "",
  business_name: "",
  name: "",
  comments: "",
  important_notes: "",
  category_identifier: 0,
  client_category_identifier: 0,
  feedback: "",
};

export const objetoPrueba = {
  user_identifier: 1,
  general: {
    register_identifier: 1,
    //////////////////
    identifier: 1,
    name: "",
    registration_type_identifier: 0,
    provider_type_identifier: 0,
    business_name: "",
    street: "",
    number: "",
    neighborhood_identifier: 0,
    city_identifier: 0,
    country_identifier: 0,
    zip_code: "",
    observations: "",
    imagen:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGBRngpUatqb3xvyTrjzmIv5QeSLQbWBqZ8Q&usqp=CAU",
    type: "",
    web: "",
    category_identifier: 0,
    client_category_identifier: 0,
    feedback: "",
    comments: "",
  },
  contact_list: [
    {
      contact_identifier: 0,
      //////////////////
      identifier: uuidv4(),
      name: "",
      email: "",
      personal_phone: "",
      extension: "",
      charge_identifier: 0,
      web: "",
      status: 8,
      charge: "",
    },
  ],
  contract: {
    start_date: "",
    end_date: "",
    file: "",
    file_extension: "png",
  },
  service_list: [
    {
      name: "",
      start_date: "",
      end_date: "",
      service_type_identifier: 0,
      view_identifier: 0,
      capacity: "",
      season_identifier: 0,
      rate: 8,
      service_key: "",
      identifier: uuidv4(),
    },
  ],
  fee_list: [
    {
      name: "",
      fee: "",
      start_date: "",
      end_date: "",
      currency_identifier: 0,
      season_identifier: 0,
      observations: "",
      identifier: uuidv4(),
    },
  ],
  tax_data: {
    contact_identifier: 1,
    ////////////////////////////
    name: "",
    business_name: "",
    rfc: "",
    bank_account: "",
    clabe: "",
    bank: "",
    currency_identifier: 0,
    email: "",
  },
};

export const contactInfoEmpty: clientInterface = {
  clientInfo: {
    business_name: "",
    city: "",
    country: "",
    identifier: 0,
    name: "",
    neighborhood: "",
    number: 0,
    observations: "",
    provider_type: "",
    registration_type: "",
    status: "",
    street: "",
    zipcode: "",
  },
};

export const contratoContextInicial: contratoContextI = {
  contract_identifier: 0,
  start_date: "",
  end_date: "",
  cancellation_policies: "",
  observations: "",
  files: [],
};

export const contratoIncial: contratoContextInterface = {
  file_identifier: 1,
  contract_identifier: 1,
  file_name: "",
  fileName: "string",
  file: "string",
  file_extension: "string",
  start_date: "string",
  end_date: "string",
  identificador: "string",
  status_identifier: 0,
};

let identificador = uuidv4();
let identifierValidity = uuidv4();

export const fiscalContextInicial: contextFiscalI = {
  tax_identifier: 0,
  ////////////////////////////////
  name: "",
  business_name: "",
  rfc: "",
  email: "",
  currency_identifier: 1,
  bank_accounts_list: [
    {
      bank_account_identifier: 0,
      numberCard: "",
      clabe: "",
      identifier: identificador,
      bank_identifier: 0,
      currency_element: 0,
    },
  ],
};

export const dataServicesInicial: dataServicesI = {
  services_fees: [
    {
      fee_type_identifier: 0,
      service_identifier: 0,
      service_name: "",
      service_type_identifier: 0,
      service_capacity: "",
      service_comments: "",
      service_key: "",
      service_category_identifier: 0,
      service_category: "",
      service_mealplan_identifier: 0,
      continent_identifier: 0,
      minimum_stay: "",
      market_restrictions: "",
      operating_days: [],
      initial_schedule: "",
      final_schedule: "",
      transport_type_identifier: 0,
      /////LOS SIGUIENTES  3 no van a servicio
      fee_is_included: true,
      type_fee: 0,
      identifier_list: identificador,
      description: "",
      minimum_to_operate: "",

      /////ARREGLOS DE ObJETOS A ENVIAR A SERVICIOS
      service_fee_list: [
        {
          fee_identifier: 0,
          fee_BBT: 0,
          ////////////////////////////
          fee_currency_identifier: 0,
          fee_iva: 0,
          fee: 0,
          fee_total: 0,
          fee_SGL: 0,
          fee_DBL: 0,
          fee_TPL: 0,
          fee_CPL: 0,
          fee_BB: 0,
          fee_MAID: 0,
          fee_FP: 0,
          fee_TSGL: 0,
          fee_TDBL: 0,
          fee_TTPL: 0,
          fee_TCPL: 0,
          fee_TFP: 0,
          fee_TMAID: 0,
          fee_TBB: 0,
          fee_tax: 0,
          fee_key: identificador,
          fee_capacity: "",
          vehicle: ""
        },
      ],
      service_blackout_list: [
        {
          blackout_identifier: 0,
          blackout_startdate: "",
          blackout_enddate: "",
          blackoutIdentifier: identifierValidity,
        },
      ],
      fee_validity_list: [
        {
          validity_identifier: 0,
          //////////////////////////
          validity_startdate: "",
          validity_enddate: "",
          identifierValidity: identifierValidity,
        },
      ],
      service_minimum_stay_list: [
        {
          minimum_stay: "",
          minimum_stay_startdate: "",
          minimum_stay_enddate: "",
          minimuStayIdentifier: identifierValidity,
          status_identifier: null,
          minimum_stay_identifier: null,
        },
      ],
    },
  ],
};
/////////////////////////////////  CONTEXTO COTIZACION
const personalInfoInicial: personalInfoI = {
  name: "",
  idCotizacion: "",
  typeCotizacion: 0,
  typeAgency: 0,
  serieId: 0,
  includesSerie: false,
  pax: 0,
  min: 0,
  max: 0,
  TL: 0,
  arrivingData: "arrivingData",
  departureDate: "departureData",
  agencia: "Sin agencias",
  typeRoom: 2,
  numberDaysOpenQuote: 0,
  modeCotizacion: "Normal",
  selectedSerieOption: "No",
  serieSelected: 0,
  isShowingDates: false,
  dateStartOpen: "",
  dateEndOpen: "",
  status_identifier: 4,
  quote_identifier: 0,
  optional_quote: false,
};

const servicioDayElementInicial: servicioDayElementI = {
  
  identifierDay: "identificadorDia",
  validityStartDay: "",
  validityEndDay: "",
  category: 0,
  lendertype: 0,
  ocupacion: 0,
  providerType: 0,
  serviceType: 0,
  tarifa: 0,
  isAvailable: true,
  travelRoute: "",
  service_identifier: 1,

  serviciosIndividuales: [
    {
      category: "",
      lendertype: 0,
      lenderDescripcion: "Nombre del prestador actual DOSSS",
      ocupacion: "",
      ocupacionDescripcion: "Nombre de la ocupación actual DOSS",
      providerType: 0,
      providerDescripcion: "Nombre del proveedor actual DOSS",
      serviceType: 0,
      serviceDescripcion: "Descripción del servicio actual DOSS",
      tarifa: 0,
      identifierService: "ide",
      typeRoom: 1,
      dateRequired: "",
      currencyName: "Peso",
      currencyId: 1,
      number_of_services: 0,
      service_name_identifier: 0,
      service_name: "",
      isIncludedOpt: false, 
    },
  ],
};

export const serviciosDaysElementsInicial: serviciosDaysElementsI = {
  servicioDayElement: [servicioDayElementInicial],
};

export const generalElementInicial: generalElementI = {
  category: "",
  lendertype: 0,
  lenderDescripcion: "Nombre del prestador actual DOSSS",
  ocupacion: 0,
  ocupacionDescripcion: "Nombre de la ocupación actual DOSS",
  providerType: 0,
  providerDescripcion: "Nombre del proveedor actual DOSS",
  serviceType: 0,
  serviceDescripcion: "Descripción del servicio actual DOSS",
  tarifa: 0,
  identifierService: "ide",
  numberServices: 0,
  totalPrice: 0,
  currencyName: "Peso",
  currencyId: 1,
};

const generalsElementsInicial: generalsElementsI = {
  generalElement: [],
};

const totalPricesInicial: totalPricesI = {
  totalPrice: 0,
  granTotal: 0,
};

const identificadoComment = uuidv4();
const feeChargesInicial: feeChargesI = {
  currentMoney: 0,
  changeType: 18.5,
  comision: 0,
  comisonAuto: 0,
  cortesia: 0,
  courtesy_type_identifier: 2,
  comments: [
    {
      comment_identifier: 0,
      comment: "",
      comment_date: "",
      status_identifier: 0,
      commentIdentifier: identificadoComment,
    },
  ],
};
const feeGeneralInicial: QuoteGeneralElement = {
  service_type: 0,
  provider_id: 0,
  service_name_id: 0,
  fee_price: 0,
  number_days: 0,
  total_price: 0,
  identifierGeneralService: "",
  currency: "Peso",
  currency_identifier: 2,
  expenses_type_identifier: 0,
  general_service_identifier: 0,
  status_identifier: 1,
  isIncludedOpt: false,
};

const generalServicesArray: QuoteGeneralElement[] = [];
const tipoServicioDefault: number[] = [
  18, 18, 29, 29, 20, 19, 21, 9, 22, 23, 28, 24, 25, 26, 27,
];
const finalServiciosBase: QuoteGeneralElement[] = []; 
const serviciosBase : QuoteGeneralElement[] = [{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 1094,
  service_name_id: 0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,
  expenses_type_identifier: 9,
  status_identifier: 1, 
  fee_identifier: 0
}, 
{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 1101,
  service_name_id: 0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,
  expenses_type_identifier: 9,
  fee_identifier: 0,
  status_identifier: 1,
}, 
{
  ...feeGeneralInicial,
  service_type: 9,
  provider_id: 2117,
  service_name_id: 0,
  fee_identifier: 0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,
  expenses_type_identifier: 9,

  status_identifier: 1,
},
{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 1095,
  service_name_id: 0,
  fee_identifier:0, 
  fee_price: 0,
  number_days: 1,
  total_price: 0,

  expenses_type_identifier: 9,

  status_identifier: 1,
},
{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 1096,
  service_name_id: 0,
  fee_identifier: 0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,

  expenses_type_identifier: 9,

  status_identifier: 1,
},
{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 1097,
  service_name_id: 0,
  fee_identifier: 0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,

  expenses_type_identifier: 9,
 
  status_identifier: 1,
},
{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 1098,
  service_name_id: 0,
  fee_identifier:0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,
  
  expenses_type_identifier: 9,

  status_identifier: 1,
},
{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 1102,
  service_name_id: 0,
  fee_identifier:0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,
  
  expenses_type_identifier: 9,

  status_identifier: 1,
},
{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 2121,
  service_name_id: 0,
  fee_identifier:0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,

  expenses_type_identifier: 9,
  status_identifier: 1,
},
{
  ...feeGeneralInicial,
  service_type: 35,
  provider_id: 0,
  service_name_id: 0,
  fee_price: 0,
  number_days: 0,
  total_price: 0,
  currency: "Dolar",
  currency_identifier: 1,
  expenses_type_identifier: 9,

  status_identifier: 1,
},
{
  ...feeGeneralInicial,
  service_type: 29,
  provider_id: 781,
  service_name_id: 0,
  fee_identifier:0,
  fee_price: 0,
  number_days: 1,
  total_price: 0,

  expenses_type_identifier: 9,
  status_identifier: 1,
}
]

for (let i = 0; i < serviciosBase.length; i++) {
  const identificador = uuidv4();

  // Crear un nuevo objeto QuoteGeneralElement con un identifierGeneralService único
  const newFeeGeneral: QuoteGeneralElement = {
    ...serviciosBase[i],
    identifierGeneralService: identificador,
  };

  // Agregar el nuevo objeto al arreglo generalServicesArray
  finalServiciosBase.push(newFeeGeneral);
}

for (let i = 0; i < 12; i++) {
  const identificador = uuidv4();

  // Crear un nuevo objeto QuoteGeneralElement con un identifierGeneralService único
  const newFeeGeneral: QuoteGeneralElement = {
    ...feeGeneralInicial,
    identifierGeneralService: identificador,
    service_type: tipoServicioDefault[i],
  };

  // Agregar el nuevo objeto al arreglo generalServicesArray
  generalServicesArray.push(newFeeGeneral);
}

export const serviciosDaysElementsInicialTransporte: serviciosDaysElementsI = {
  servicioDayElement: [servicioDayElementInicial],
};

export const servicioDayElementInicialTransporte: servicioDayElementI = {
  identifierDay: "identificadorDia",
  validityStartDay: "",
  validityEndDay: "",
  category: 0,
  lendertype: 0,
  ocupacion: 0,
  providerType: 0,
  serviceType: 0,
  tarifa: 0,
  isAvailable: true,
  travelRoute: "",
  service_identifier: 1,

  serviciosIndividuales: [
    {
      category: "",
      lendertype: 0,
      lenderDescripcion: "Nombre del prestador actual DOSSS",
      ocupacion: "",
      ocupacionDescripcion: "Nombre de la ocupación actual DOSS",
      providerType: 0,
      providerDescripcion: "Nombre del proveedor actual DOSS",
      serviceType: 0,
      serviceDescripcion: "Descripción del servicio actual DOSS",
      tarifa: 0,
      identifierService: "ide",
      typeRoom: 1,
      dateRequired: "",
      currencyName: "Peso",
      currencyId: 1,
      number_of_services: 0,
      service_name_identifier: 0,
      service_name: "serviceDayy.service_name",
      isIncludedOpt: false,
    },
  ],
};
export const dataCotizacionInicial: contextCotizacionI = {
  personalInfo: personalInfoInicial,
  serviciosDayElements: serviciosDaysElementsInicial,
  generalsElements: generalsElementsInicial,
  totalPrice: totalPricesInicial,
  feeCharges: feeChargesInicial,
  generalServices: finalServiciosBase,
  serviciosDayElementsTransporte: serviciosDaysElementsInicialTransporte,
};

export const userInicial: userI = {
  user_identifier: 0,
  role: "",
  user_name: "",
  status: "",
  group: "",
  user_role: 0,
  user_role_name: "",
  group_identifier: 0, 
  agency: "",
  alias: "" 
   
}

export interface initialPropertiesCotizationI {
  total_base_sgl?: number | undefined;
  total_base_dbl?: number | undefined;
  total_base_tpl?: number | undefined;
  simple_supplement?: number | undefined;
  triple_reduction?: number | undefined;
}
export const initialPropertiesCotization: initialPropertiesCotizationI = {
  total_base_sgl:0,
  total_base_dbl:0,
  total_base_tpl: 0,
  simple_supplement: 0,
  triple_reduction: 0,
}

export const initalListOpcional: OptionalQuote[] = [
 
]