import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import EDITAR from "../../../images/edit.png";
import StyledTable from "../tables/StyledTable";
import {
  GeneralsData,
  bodyResponseProvider,
  ContactData,
  Tax,
  Service,
  Contract,
} from "../../../interfaces/ProviderTypes";
import guardar from "../../../images/GUARDARDATOS.png";
import Generales from "../layout/Generales";
import ContactLayoutNew from "../layout/ContactLayoutNew";
import GetRowContact from "../layout/GetRowContact";
import GetContact from "../layout/GetContact";
import { v4 as uuidv4 } from "uuid";
import {
  objectCard,
  serviceObject,
  blackoutObject,
  validityDates,
  feeIndividual,
  stayDates,
} from "../../../interfaces/GeneralTypes";
import { objetoPrueba } from "../../../context/initialContext";
import {
  fiscalContextInicial,
  registerGeneralData,
} from "../../../context/initialContext";
import GetFiscal from "../layout/GetFiscal";
import {
  faFilePdf,
  faFileImage,
  faClose,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import GetContract from "../layout/GetContract";
import {
  contratoContextI,
  contratoContextInterface,
} from "../../../context/InterfacesContext";
import { dataServicesInicial } from "../../../context/initialContext";
import { useNavigate } from "react-router-dom";
import {
  BankAccount,
  ServiceElement,
  ServiceBlackout,
  FeeElement,
  ServiceValidity,
} from "../../../interfaces/ProviderTypes";
import ServiceGIndividual from "../layout/ServiceGIndividual";
import { Fee } from "../../../interfaces/ProviderTypes";
import ServiceHIndividual from "../layout/ServiceHIndividual";
import { Link } from "react-router-dom";
import { contratoContextInicial } from "../../../context/initialContext";
import GetServicios from "../layout/GetServicios";

export default function ClientDetails() {
  const {
    showInfoContact,
    setShowInfoContact,
    contactCurrentInfo,
    idCurrentProvider,
    setIdCurrentProvider,
    setShowLoader,
    registerGeneral,
    setRegisterGeneral,
    registerData,
    setRegisterData,
    fiscalContext,
    setFiscalContext,
    dataServicesContext,
    setDataServicesContext,
    contratoContext,
    setContratoContext,
  } = useContext(AppContext);
  // const [userInfo, setUserInfo] =

  const [generalData, setGeneralData] = useState<GeneralsData>();
  const [editionMode, setEditionMode] = useState({
    generalEditionMode: false,
  });
  const [contactoServiceList, setContactoServiceList] = useState<ContactData[]>(
    []
  );
  const [serviciosList, setServiciosList] = useState<Service[]>();
  const [taxes, setTaxes] = useState<Tax>();
  const [contractData, setContractData] = useState<Contract>();
  const [servicesListAPI, setServicesListAPI] = useState<ServiceElement>();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("idCurrentProvider: ");
    console.log(idCurrentProvider);
    setRegisterGeneral(registerGeneralData);
    setFiscalContext(fiscalContextInicial);
    const getData = async () => {
      setShowLoader(true);
      const provedor: bodyResponseProvider = await fetchServer(
        `/GetRegisterDetail/${idCurrentProvider}`
      ).catch((e) => {
        console.log("HERREE");
        alert("No se pudo recuperar la información del proovedor");
      });
      console.log("RESPUESTA SOBRE PROVEDOR DEL SERVIDOR: ");
      console.log(provedor.data);
      setGeneralData(provedor?.data?.register_detail.generals);
      setContactoServiceList(provedor?.data?.register_detail.contact);
      setTaxes(provedor?.data?.register_detail.tax);
      setShowLoader(false);
      if (provedor.data.register_detail.services) {
        setServiciosList(provedor?.data?.register_detail.services);
      }
      setContractData(provedor?.data?.register_detail.contract);
    };
    getData();
  }, []);

  useEffect(() => {
    console.log("General data change: ");
    console.log(generalData);
  }, [generalData]);

  const getInfoProvider = () => {
    const finalContactList = contactoServiceList?.map((contacto, index) => {
      return {
        contact_identifier: contacto.contact_identifier,
        ////////////////////////////////////
        identifier: contacto.contact_identifier.toString(),
        name: contacto.contact_name,
        email: contacto.contact_email,
        personal_phone: contacto.contact_phone,
        extension: contacto.contact_extension.toString(),
        charge_identifier: contacto.charge_identifier,
        web: "contacto.web",
        status: 110,
        charge: contacto.charge
      };
    });

    console.log("ASI QUEDARA CONTACT LIST: ");
    console.log(finalContactList);
    if (generalData) {
      setRegisterGeneral({
        ...registerGeneral,
        nombreComercial: generalData?.name,
        registration_type_identifier: generalData.registration_type_identifier,
        lender_type_identifier: generalData.lender_type_identifier,
        provider_type_identifier: generalData.provider_type_identifier,
        claveGeneral: generalData.key,
        razonSocial: generalData.business_name ? generalData.business_name : "",
        rfc: generalData.rfc,
        id: generalData.id,
        categoriaGeneral: generalData.category_identifier,
        ultimaCompra: generalData.last_purchase,
        telefono: generalData.number,
        web: generalData.web,
        country_identifier: generalData.country_identifier,
        city_identifier: generalData.city_identifier,
        neighborhood_identifier: generalData.neighborhood_identifier,
        zip_code: generalData.zip_code,
        calles: generalData.street,
        actividades: generalData?.activities ? generalData?.activities : "",
        lead: generalData.lead,
        comisionEsquema: generalData.commission_scheme,
        comentarios: generalData.comments,
        identifier: 1,
        imageExtension: "IMAGEN",
        name: generalData.name,
        business_name: generalData.business_name,
        comments: generalData.comments,
        important_notes: generalData.important_notes,
        feedback: generalData.feedback,
        category_identifier: generalData.category_identifier
      });
      setRegisterData({
        ...registerData,
        general: {
          ...registerData.general,
          identifier: generalData.register_identifier,
          name: generalData.name,
          registration_type_identifier:
            generalData.registration_type_identifier,
          provider_type_identifier: generalData.provider_type_identifier,
          business_name: generalData.business_name,
          street: generalData.street,
          number: generalData.number,
          neighborhood_identifier: generalData.neighborhood_identifier,
          city_identifier: generalData.city_identifier,
          country_identifier: generalData.country_identifier,
          zip_code: generalData.zip_code,
          observations: generalData.comments,
          imagen: "string",
          type: "string",
          web: generalData.web,
          comments: generalData.comments,
        },
        contact_list: finalContactList
          ? finalContactList
          : objetoPrueba.contact_list,
      });
      if (taxes) {
        let finalTaxesData: objectCard[] | null = taxes?.bank_accounts_list?.map(
          (tax, index) => {
            let identificador = uuidv4();
            const taxObject: objectCard = {
              bank_account_identifier: tax.bank_account_identifier,
              //////////////////////////////
              numberCard: tax.bank_account,
              clabe: tax.clabe,
              identifier: identificador,
              bank_identifier: tax.bank_identifier,
              currency_element: tax.currency_identifier,
            };
            return taxObject;
          }
        );
        console.log("ASI LUCEN LOS DATOS DE IMPUESTOS: ");
        console.log(taxes);
        console.log(finalTaxesData);
        let identificadorB = uuidv4();
        setFiscalContext({
          ...fiscalContext,
          tax_identifier: taxes.tax_identifier,
          name: taxes.name,
          business_name: taxes.business_name,
          rfc: taxes.rfc,
          email: taxes.email,
          currency_identifier: 1,
          bank_accounts_list: !finalTaxesData  ? [ {
            bank_account_identifier:0, 
            /////////////////////
            numberCard: "",
            clabe: "",
            identifier: identificadorB, 
            bank_identifier: 0,
            currency_element: 0
          }]: finalTaxesData,
        });
      }
    } else {
      alert("No se pudo consultar información: ");
      return;
    }
    setDataServicesContext({
      ...dataServicesContext,
      services_fees: serviciosList
        ? adaptServicesFromApi(serviciosList)
        : dataServicesInicial.services_fees,
    });
    if (contractData) {
      const arrayFiles: contratoContextInterface[] = contractData.files.map(
        (file, index) => {
          let identificador = uuidv4();
          const currentContrato: contratoContextInterface = {
            file_identifier: file.file_identifier,
            contract_identifier: file.contract_identifier,
            //////////////////////
            fileName: file.file_alt_name,
            file: file.file_url,
            file_extension: file.fileExtension,
            start_date: "a",
            end_date: "string",
            identificador: identificador,
            file_name: file.file_name,
            status_identifier: file.status_identifier,
          };
          return currentContrato;
        }
      );
      setContratoContext({
        ...contratoContext,
        contract_identifier: contractData.contract_identifier,
        start_date: contractData?.start_date,
        end_date: contractData?.end_date,
        files: arrayFiles,
        observations: contractData?.observations,
        cancellation_policies: contractData?.cancellation_policies
      });
      console.log("ESTA ES LA INFORMACION DE TU CONTRATO: ");
      console.log(contractData);
    }
  };
  useEffect(() => {
    console.log("ASI QUEDO CONTRATO CONTEXT: ");
    console.log(contratoContext);
  }, [contratoContext]);
  useEffect(() => {
    console.log("REGISTER DATA HA CAMBIADO: ");
    console.log(registerData);
  }, [registerData]);
 
  
  return (
    <ScrollWraper>
      <ClientDetailStyles>
        <div className="x-mark-box">
          <Link to="/dashboard/Providers-List" className="link link-item">
            <FontAwesomeIcon
              icon={faXmark}
              className="x-mark"
              onClick={() => {
                setIdCurrentProvider(0);
                setRegisterGeneral(registerGeneralData);
                setContratoContext(contratoContextInicial);
                setDataServicesContext(dataServicesInicial);
                setFiscalContext(fiscalContextInicial);
                setRegisterData(objetoPrueba);
                setIdCurrentProvider(0);
                // setShowInfoContact(!showInfoContact);
              }}
            />
          </Link>
        </div>
        {/* GENERALES */}
        <Generales
          editionMode={editionMode}
          setEditionMode={setEditionMode}
          generalData={generalData}
          setGeneralData={setGeneralData}
          getInfoProvider={getInfoProvider}
        />

        {/* CONTACTO */}

        <div className="first-box">
          <GetContact
            contactoServiceList={contactoServiceList}
            getInfoProvider={getInfoProvider}
          />
        </div>

        {/* CONTRATO */}
        <div className="first-box">
          <GetContract
            contractData={contractData}
            getInfoProvider={getInfoProvider}
          />
        </div>

        {/* TARIFA/SERVICIO */}
        <div className="first-box">
          <GetServicios
            getInfoProvider={getInfoProvider}
            serviciosList={serviciosList}
            setShowInfoContact={setShowInfoContact}
            generalData={generalData}
          />
        </div>
     

        {/* FISCAL */}
        <div className="first-box">
          <GetFiscal
            taxes={taxes}
            registerGeneral={registerGeneral}
            getInfoProvider={getInfoProvider}
            nombre={generalData?.name}
            business_name={generalData?.business_name}
          />
        </div>
      </ClientDetailStyles>
    </ScrollWraper>
  );
}

const ClientDetailStyles = styled.div`
  .bar-show-fee {
    
  }
  .general-service {
    border: rgba(8, 166, 165, 1) solid 2px;
  }
  .info-general-fee {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 0.5rem;
    margin-bottom: 1rem;
  }
  .blackout-container {
    margin-top: 0.8rem;
  }
  .service-individual {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .filaa-fee {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .elements-servicio h2 {
    margin-bottom: 0.8rem;
  }
  .icon-contact-save {
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 1rem;
  }
  .trash {
    margin-right: 1rem;
    cursor: pointer;
  }
  .icon-contact-img {
    cursor: pointer;
    margin-right: 0.5rem;
  }
  .fila div h3 {
    font-size: 0.8rem;
  }
  .fila {
    border: grey solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 95%;
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .fila div {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: grey solid 1px;
    overflow: hidden;
  }
  .fila h3 {
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 30em;
  }
  .box-containerr {
  }

  .filaa div h3 {
    font-size: 0.8rem;
  }
  .filaa {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    width: 50%;
    margin-top: 0.5rem;
    padding: 0.5rem;    
  }
  .filaa div {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .filaa h3 {
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 30em;
  }
  input {
    width: 100%;
    max-width: 100%;
  }
  .buttons-icons {
    justify-content: center !important;
  }
  /////////////////////////////////////////////////////////////////////////////////
  .title-header-modified {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
  .no-border-bottom {
    border-bottom: none !important;
  }
  .container-file {
    padding-left: 2rem;
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
  }
  .icon-format {
    color: orange;
    font-size: 1.2rem;
  }
  .title-files {
    border-bottom: grey solid 1px;
    padding-left: 2rem;
    padding-bottom: 0.5rem;
  }
  .second-files {
    width: 80%;
  }
  .content-second {
    grid-template-columns: 1fr 2fr !important;

    align-items: center;
    justify-content: center;
  }
  .box-files {
    border: grey solid 1px;
    padding-top: 1rem;
  }
  .titles-fiscal h2 {
    margin-bottom: 0.8rem;
  }
  .data-accounts div h2 {
    color: black !important;
    font-size: 1rem !important;
    font-weight: lighter !important;
  }
  .third-box {
    border: grey solid 1px !important;
    border-top: none !important;
  }
  .x-mark-box {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 10vw;
    padding-top: 20vh;
    position: fixed;
    top: -150px;
    right: -50px;
  }
  .x-mark {
    font-size: 2.5rem;
    cursor: pointer;
  }

  .title-first {
    //background-color: rgb(8, 166, 165)  ;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    //padding-top: 2rem;
  }

  .title-first img {
    width: 50px;
    height: 50px;

    margin: 0px 20px;
    cursor: pointer;
  }

  .title-first h3 {
    color: #000000;
  }
  .content-first-box h4,
  .content-second-box h4,
  .content-tirth-box h4 {
    margin-bottom: 0.5rem;
    font-size: 1vw;
    
  }
  .content-first {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 0.5rem;
    padding: 0.8rem;
  }
  .content-first-box div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
  }
  .container-data {
    margin-bottom: 0.5rem;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(211, 211, 211, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  z-index: 100;
  padding: 50px 0px;

  .first-box {
    border: rgb(8, 166, 165) solid 2px;
    width: 80%;
    height: auto;
    background-color: white;
    border-radius: 0.9rem;

    display: flex;
    flex-direction: column;
    padding: 20px 50px;
    /* display: grid;
        grid-template-columns: 1fr 3fr; */
    //overflow-y: scroll;
    margin-bottom: 0.5rem;
  }

  .table-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px 0px 0px 0px;
  }
  .button-container {
    display: flex;
    justify-content: right;
    padding-right: 3vw;
    margin-top: 1rem;
  }
  .plus-symbol {
    color: rgb(8, 166, 165) !important;
  }
  .title-header {
    border: rgb(8, 166, 165) solid 2px;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 95%;
    margin: 0 auto;
  }
  .title-header div h2 {
    color: rgb(8, 166, 165);
    font-size: medium;
    text-align: center;
  }
  .title-header div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-container {
  }
  .plus-symbol {
    font-size: 2rem;
  }
  .title-contacto {
    color: rgb(8, 166, 165);
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .container-fields-ones,
  .container-fields-two {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 90%;
    margin: 0 auto;
    padding: 0.5rem;
  }
  h2 {
    font-size: 1rem;
  }
  .button-container {
    display: flex;
    justify-content: right;
    padding-right: 3vw;
    margin-top: 1rem;
  }
  .plus-icon {
    font-size: 1.8rem;
    cursor: pointer;
    color: rgb(8, 166, 165) !important;
  }

  .second-box {
    border: rgb(8, 166, 165) solid 2px;
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;    
    grid-column-gap: 1rem;
    padding: 0.8rem;
    justify-items: center;
    align-items: center;
    
  }
  @media screen and (max-width: 900px){
    .second-box{
      width: 100%;
    }
  }
  .second-box div h2 {
    color: rgb(8, 166, 165);
    font-size: 1.2rem;
  }
  .data-container-dos {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
    justify-items: center; /* Centra horizontalmente los elementos */
    align-items: center; /* Centra verticalmente los elementos */
  }
  .title-fiscales {
    color: rgb(8, 166, 165);
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .data-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center; /* centrado horizontal */
    align-items: center; /* centrado vertical */

    grid-row-gap: 0.5rem;
  }
  
`;
const TittleLbl = styled.div`
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 1rem;
`;
const ScrollWraper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
function adaptServicesFromApi(apiData: Service[]): serviceObject[] {
  let dataAdapted: serviceObject[] = [];
  dataAdapted = apiData.map((servicio, index) => {
    let identificador = uuidv4();
    let identificadorDos = uuidv4();
    let identificadorTres = uuidv4();
    const identifierValidity = uuidv4();
    const tarifas: Array<feeIndividual> = servicio.fees.map((fee, index) => {
      let identificador = uuidv4();
      const currentFee: feeIndividual = {
        fee_identifier: fee.fee_identifier,
        fee_BBT: fee.fee_BBT,
        //////////////////////////////
        fee_currency_identifier: fee.fee_currency_identifier,
        fee_iva: fee.fee_iva,
        fee: fee.fee,
        fee_tax: fee.fee_tax,
        fee_total: fee.fee_total,
        fee_SGL: fee.fee_SGL,
        fee_DBL: fee.fee_DBL,
        fee_TPL: fee.fee_TPL,
        fee_CPL: fee.fee_CPL,
        fee_FP: fee.fee_FP,
        fee_BB: fee.fee_BB,
        fee_MAID: fee.fee_MAID,
        fee_TSGL: fee.fee_TSGL,
        fee_TDBL: fee.fee_TDBL,
        fee_TTPL: fee.fee_TTPL,
        fee_TCPL: fee.fee_TCPL,
        fee_TFP: fee.fee_TFP,
        fee_TBB: fee.fee_BBT,
        fee_TMAID: fee.fee_TMAID,
        fee_key: fee.fee_identifier.toString(),
        fee_capacity: fee.fee_capacity,
        vehicle: fee.vehicle
      };
      return currentFee;
    });
    const blackoutNewList: Array<blackoutObject> =
      servicio.service_blackout_list.map((currentBlackout, index) => {
        return {
          blackout_identifier: currentBlackout.blackout_identifier,
          blackout_startdate: currentBlackout.blackout_startdate,
          blackout_enddate: currentBlackout.blackout_enddate,
          blackoutIdentifier: currentBlackout.blackout_identifier.toString(),
        };
      });

    const validityList: Array<validityDates> =
      servicio.service_validity_list.map((validity, index) => {
        return {
          validity_identifier: validity.validity_identifier,
          validity_startdate: validity.validity_startdate,
          validity_enddate: validity.validity_enddate,
          identifierValidity: validity.validity_identifier.toString(),
        };
      });

    const estanciaList: Array<stayDates> =  servicio.service_minimum_stay_list.map((minimum, index)=>{
      const objetoRespuesta:stayDates = {
        minimum_stay: minimum.minimum_stay,
        minimum_stay_startdate: minimum.minimum_stay_startdate,
        minimum_stay_enddate: minimum.minimum_stay_enddate,
        minimum_stay_identifier: minimum.minimum_stay_identifier, 
        status_identifier: minimum.status_identifier,
        minimuStayIdentifier: minimum.minimum_stay_identifier?.toString() ? minimum.minimum_stay_identifier?.toString(): identifierValidity
      }
      return objetoRespuesta; 
    })
    const servicioFinal: serviceObject = {
      service_identifier: servicio.service_identifier,
      service_name: servicio.service_name,
      service_type_identifier: servicio.service_type_identifier,
      service_capacity: servicio.service_capacity,
      service_comments: servicio.service_comments,
      service_key: identificadorTres,
      service_category_identifier: servicio.service_category_identifier,
      service_category: servicio.service_alt_category,
      service_mealplan_identifier: servicio.service_mealplan_identifier,
      fee_type_identifier: servicio.fee_type_identifier,
      continent_identifier: servicio.continent_identifier,
      minimum_stay: servicio.minimum_stay,
      market_restrictions: servicio.market_restrictions,
      operating_days: servicio.operating_days,
      initial_schedule: servicio.initial_schedule,
      final_schedule: servicio.final_schedule,
      transport_type_identifier: servicio.transport_type_identifier,
      description: servicio.description,
      minimum_to_operate: servicio.minimum_to_operate,
            /////ARREGLOS DE OBJETOS A ENVIAR A SERVICIOS      
      service_fee_list: tarifas,
      service_blackout_list: blackoutNewList.length == 0 ? [
        {
          blackout_identifier: 0,
          blackout_startdate: "",
          blackout_enddate: "",
          blackoutIdentifier: identifierValidity,
        },
      ]:blackoutNewList,
      fee_validity_list: validityList.length == 0 ? [
        {
                          validity_identifier: 0,
                          /////////////
                          validity_startdate: "",
                          validity_enddate: "",
                          identifierValidity: identifierValidity,
                        }
        ]: validityList,
        service_minimum_stay_list: servicio.service_minimum_stay_list.length == 0 ? [
          {
            minimum_stay: "",
            minimum_stay_startdate: "",
            minimum_stay_enddate: "",
            minimuStayIdentifier: identifierValidity,
            status_identifier: null,
            minimum_stay_identifier: null
          }
        ]:estanciaList,
      /////LOS SIGUIENTES no van a servicio
      fee_is_included: false,
      type_fee: servicio.fee_type_identifier,
      identifier_list: identificadorDos,
    };

    return servicioFinal;
  });
  return dataAdapted;
}
