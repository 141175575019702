import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBars } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import ResumenElement from "./ResumenElement";
import DayResumen from "./DayResumen";

interface ResumenBarI {
  isShowingBarOptions: boolean;
  setIsShowingBarOptions: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ResumenBar({
  isShowingBarOptions,
  setIsShowingBarOptions,
}: ResumenBarI) {
  const { setDataCotizacionContext, dataCotizacionContext } =
    useContext(AppContext);
  return (
    <ResumenBarStyles>
      <div className="header-bar">
        <h3>Resumen</h3>
        <FontAwesomeIcon
          className="bars"
          icon={faBars}
          onClick={() => setIsShowingBarOptions(!isShowingBarOptions)}
        />
      </div>

      <div className="content-bar">
        {dataCotizacionContext?.serviciosDayElements?.servicioDayElement[0]
          ?.serviciosIndividuales[0]?.serviceDescripcion !=
          "Descripción del servicio actual DOSS" &&
          dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
            (dayServicios, index) => {
              return (
                <>
                  <DayResumen
                    dayServicios={dayServicios}
                    numberDay={index + 1}
                  />
                </>
              );
            }
          )}
      </div>
    </ResumenBarStyles>
  );
}

const ResumenBarStyles = styled.div`
  height: 100%;
  margin-top: 2rem;
  max-height: 70vh;
  min-height: 70vh;
  border: #f6921e solid 1px;
  border-top-right-radius: 1.2rem;
  border-top-left-radius: 1.2rem;
  background-color: white;

  .content-bar {
    max-height: 60vh;
    min-height: 60vh;
    overflow-y: scroll;
  }
  .content-bar h3 {
    color: black;
    padding: 0.5rem;
  }
  .bars {
    color: white !important;
    background-color: #f6921e !important;
    box-shadow: none !important;
  }
  .header-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.8rem;
    background-color: #f6921e;
    border-top-right-radius: 1.2rem;
    border-top-left-radius: 1.2rem;
  }
  h3 {
    font-size: 1vw;
    color: white;
  }
  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1.8vw;
    }
  }
`;
