import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";

export default function TextBoxBigInstructions() {
  const { language, setLanguage } = useLanguage();
  return (
    <TextBoxBigInstructionStyle>
      <div className="big-box">
        <h3 className="title-box">
          {translations[language].box_instructions_proforma.title}
        </h3>
        <br />
        <h3 className="title-box">{translations[language].extra_fields.important}</h3>
        <div className="container-text-one">
          <div>
            <div>
              <h3>{translations[language].box_instructions_proforma.one}</h3>
            </div>
            <div>
              <h3>{translations[language].box_instructions_proforma.two}</h3>
            </div>
            <div>
              <h3>{translations[language].box_instructions_proforma.three}</h3>
            </div>
            <div>
              <h3>{translations[language].box_instructions_proforma.four}</h3>
            </div>
          </div>
          <div>
            <div>
              <h3>{translations[language].box_instructions_proforma.five}</h3>
            </div>
            <div>
              <h3>{translations[language].box_instructions_proforma.six}</h3>
            </div>
            <div>
              <h3>{translations[language].box_instructions_proforma.seven}</h3>
            </div>
          </div>
        </div>
        <div>
            <h3 className="refunds">{translations[language].extra_fields.fees_refund}</h3>
        </div>
        <div>
            <h3 className="refunds-details">{translations[language].box_instructions_proforma.refund_policy}</h3>
        </div>
      </div>
    </TextBoxBigInstructionStyle>
  );
}

const TextBoxBigInstructionStyle = styled.div`
  .big-box {
    border: black solid 1px;
    padding: 0.8rem;
  }

  .title-box {
    font-size: 0.85vw;
  }
  .refunds-details{
    font-size: 0.85vw;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
  .container-text-one div h3 {
    font-size: 0.85vw;
    font-weight: 300;
    margin-bottom: 0.5rem;
    
  }
  .refunds{
    font-size: 0.85vw;

    margin-bottom: 0.5rem;
  }
  .container-text-one {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
   
  }
`;
