import { isEditable } from "@testing-library/user-event/dist/utils";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface Props<T> {
  field: string;
  text: string;
  txtWidth: number;
  value?: string;
  setGeneralData: React.Dispatch<React.SetStateAction<T>>;
  onChange?: (value: string) => void;
   minDate ?: string,
   height?: number,
   isEditableS?: boolean
}
interface styledInputProps {
    percentageWidth: number;
  }
function DateInput<T>(props: Props<T>) {
  const [inputValue, setInputValue] = useState(props.value || '');
useEffect(()=>{

},[props.value])
  const obtenerValor = (event: React.ChangeEvent<HTMLInputElement>) => {


  //  if(!props.isEditableS){
  //   return; 
  //  }
    const value = event.target.value;

    const input = event.target;
    const dateParts = input.value.split('-');
    const year = dateParts[0];
    if (year.length > 4) {
    return;
    } else {

    }
    setInputValue(value);
    props.setGeneralData((prevProvider) => ({
      ...prevProvider,
      [props.field]: value,
    }));

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <div style={{width:  props.txtWidth + "%", maxWidth: props.txtWidth + "%"}}>
<PrincipalContainer  style={{width:  "100%", maxWidth: "100%"}}>
        <EntryContainer>
          <Entry
            id={props.field}
            type="date"
            placeholder={props.text}
            style={{
              width: "100%",
              height: props.height ? props.height: ""
            }}
            onChange={obtenerValor}
            value={props.value}
            autoComplete="off"
            className={inputValue ? "has-value" : ""}
            min={props.minDate ?? ""}

          />
          <Label htmlFor={props.field}>{props.text}</Label>
        </EntryContainer>
      </PrincipalContainer>
    </div>


  );
}

export default DateInput;

const Entry = styled.input`
  background-color: #ffffff;
  border-style: none;
  border-bottom: 2px solid #b1b1b1;
  color: "#000000";
  cursor: text;
  outline: none;
  border: solid rgb(8, 166, 165) 2px;

  padding-left: 20px;
  /* margin-bottom: 0.5rem; */
  font-size: 0.85vw;
  padding: 0.5rem 0.5rem;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 900px){
    font-size: 1.6vw;
  }
  @media screen and (max-width: 700px){
   min-height: 32px !important;
   font-size: 2.3vw;
  }

  &::placeholder:not(.has-value) {
    position: absolute;
    top: -0.5rem;
    left: 1rem;
    font-size: 1rem;
    color: #b1b1b1;
    pointer-events: none;
    transition: all 0.2s ease-out;
    z-index: 1;
  }
`;

const PrincipalContainer = styled.div`

  height: auto;
  display: flex;

`;

const EntryContainer = styled.div`
  width: 100%;
  position: relative;
  max-width: 100%;
`;

const Label = styled.label`
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  font-size: 0.8vw;
  color: rgb(8, 166, 165);
  pointer-events: none;
  transition: all 0.2s ease-out;
  z-index: 1;
  background-color: aliceblue;
  @media screen and (max-width: 900px){
    font-size: 1.5vw;
  }
  @media screen and (max-width: 700px){
    font-size: 2.3vw;
  }
`;