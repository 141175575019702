import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import ServiciosLayout from "../../components/layout/ServiciosLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPencil,
  faPen,
  faFloppyDisk,
  faCopy,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../components/entrys/CustomInput";
import { v4 as uuidv4 } from "uuid";
import CustomInputt from "../../components/entrys/CustomInputt";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
  serviceObject,
  feeIndividual,
  transportTypeI,
  continentI,
  stayDates,
  expensesI,
} from "../../../interfaces/GeneralTypes";
import Vigencia from "./Vigencia";
import ImpuestosGet from "./ImpuestosGet";
import deletedatos from "../../../images/deletelogo.png";
import guardar from "../../../images/GUARDARDATOS.png";
import edit from "../../../images/edit.png";
import agregar from "../../../images/AGREGAR.png";
import copiar from "../../../images/DUPLICAR.png";
import TarifaIndividualG from "./TarifaIndividualG";
import TarifaHab from "../pages/TarifaHab";
import CustomInputtt from "../entrys/CustomInputtt";
import BlackoutElement from "./BlackoutElement";
import { validityDates } from "../../../interfaces/GeneralTypes";
import {
  horariosServicios,
  diasSemana,
} from "../../../interfaces/GeneralTypes";
import EstanciaElement from "./EstanciaElement";
import DeleteConfirmation from "../common/DeleteConfirmation";
import TarifaTransporte from "./TarifaTransporte";

interface tarifaProps {
  setDataServices: React.Dispatch<React.SetStateAction<dataServicesI>>;
  tarifa: serviceObject;
  currencyList: coinInterface[];
  dataServices: dataServicesI;
  addNewServicio: () => void;
  isShowingAddButton: boolean;
  tipoTarifa: number;
  setTipoTarifa: React.Dispatch<React.SetStateAction<number>>;
  isEditMode: boolean;
  deleteServicio: (tarifa: serviceObject) => void;
}

export default function TarifaGeneral({
  setDataServices,
  tarifa,
  currencyList,
  dataServices,
  addNewServicio,
  isShowingAddButton,
  tipoTarifa,
  setTipoTarifa,
  isEditMode,
  deleteServicio,
}: tarifaProps) {
  const [editionMode, setEditionMode] = useState(false);

  const { catalogsData } = useCatalogs();
  const [serviceList, setServiceList] = useState<serviceInterface[]>([]);
  const [foodPlan, setFoodPlan] = useState<maealI[]>([]);

  const [categoryServices, setCategoryServices] = useState<categoriaI[]>([]);
  const {
    registerData,
    setRegisterData,
    dataServicesContext,
    setDataServicesContext,
    registerGeneral,
    setRegisterGeneral,
  } = useContext(AppContext);

  const [localTarifa, setLocalTarifa] = useState<serviceObject>(tarifa);
  const [tarifaType, setTarifaType] = useState<number>(
    tarifa.service_type_identifier
  );
  const [transportType, setTransportType] = useState<transportTypeI[]>([]);
  const [continentList, setContinentList] = useState<continentI[]>([]);
  const [expensesList, setExpensesList] = useState<expensesI[]>([]);
  useEffect(() => {
    setCategoryServices(catalogsData?.category_list);
    setCategoryServices(catalogsData?.category_list);
    setServiceList(catalogsData?.service_type_list);
    setFoodPlan(catalogsData?.meal_plan_list);
    setTransportType(catalogsData?.transport_service_type_list);
    setContinentList(catalogsData?.continent_list);
    setExpensesList(catalogsData?.expenses_category_list);
  }, [catalogsData]);
  useEffect(() => {
    console.log("TARIFA POR ASIGNAR: ");
    console.log(localTarifa);
    console.log("ESTATUS INICIAL DEL CONTEXTO: ");
    console.log(dataServices);
  }, []);

  const copyTheSameFee = () => {
    // Identificador del objeto que quieres copiar
    let identificadorOriginal = tarifa.identifier_list;

    let newIdentifier = uuidv4();
    // Encuentra el índice del objeto original en el arreglo
    let newServicesFeeList = dataServices.services_fees;
    let indiceOriginal = newServicesFeeList.findIndex(
      (tarifaActual) => tarifaActual.identifier_list === identificadorOriginal
    );
    if (indiceOriginal !== -1) {
      // Obtiene el objeto original
      let objetoOriginal = newServicesFeeList[indiceOriginal];

      // Crea una copia del objeto original
      let copiaObjeto = Object.assign({}, objetoOriginal);

      // Asigna un nuevo valor a identifier_list en la copia del objeto
      copiaObjeto.identifier_list = newIdentifier;
      const copiaEditada: serviceObject = {
        ...copiaObjeto,
        service_identifier: 0,
        fee_validity_list: copiaObjeto.fee_validity_list.map(
          (validity, index) => {
            return {
              ...validity,
              validity_identifier: 0,
            };
          }
        ),
        service_minimum_stay_list: copiaObjeto.service_minimum_stay_list.map(
          (minimum, index) => {
            return {
              ...minimum,
              minimum_stay_identifier: 0,
            };
          }
        ),
        service_fee_list: copiaObjeto.service_fee_list.map((fee, index) => {
          return {
            ...fee,
            fee_identifier: 0,
          };
        }),
        service_blackout_list: copiaObjeto.service_blackout_list.map(
          (blackout, index) => {
            return {
              ...blackout,
              blackout_identifier: 0,
            };
          }
        ),
      };

      // Inserta la copia después del objeto original
      newServicesFeeList.splice(indiceOriginal + 1, 0, copiaEditada);
    }
    setDataServices({
      ...dataServices,
      services_fees: newServicesFeeList,
    });
    console.log("COPIANDO OBJETO");
  };
  useEffect(() => {
    const arrayServices = dataServices.services_fees;
    console.log("LOCAL TARIFA CHANGED: ");
    console.log(localTarifa);
    console.log("ARRAY SERVICES: ");
    console.log(arrayServices);
    let newServicesFeeList;
    ////CUUIDADO AQUI
    if(localTarifa.service_type_identifier == 18){
      newServicesFeeList = arrayServices.map((tarifaIndividual, index) => {
        return tarifaIndividual.identifier_list !== tarifa.identifier_list
          ? tarifaIndividual
          : {
              ...localTarifa,
                 fee_validity_list: localTarifa.fee_validity_list,
              service_blackout_list: tarifaIndividual.service_blackout_list,
            };
      });
    }else{
      newServicesFeeList = arrayServices.map((tarifaIndividual, index) => {
        return tarifaIndividual.identifier_list !== tarifa.identifier_list
          ? tarifaIndividual
          : {
              ...localTarifa,
              fee_validity_list: tarifaIndividual.fee_validity_list,
              service_blackout_list: tarifaIndividual.service_blackout_list,
            };
      });
    }
   
    console.log("ACTUALIZANDO ESTADO GLOBAL: ");
    console.log("NEW SERVICIES LIST: ");
    console.log(newServicesFeeList);
    setDataServices({
      ...dataServices,
      services_fees: newServicesFeeList,
    });
  }, [localTarifa]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "yes";
    setIsTaxesAvailable(value);
    if (!value) {
      /////NO OLVIDES TRANSFORMAR EL VALOR DE TAXES A 0!!!!!!!!!!!!!!!!!!!!!!!!!!1
      setLocalTarifa({
        ...localTarifa,
        fee_is_included: false,
      });
    } else {
      setLocalTarifa({
        ...localTarifa,
        fee_is_included: true,
      });
    }
  };

  const addNewTarifa = () => {
    let identificador = uuidv4();
    let identifierValidity = uuidv4();
    console.log("Adding new TARIFAA------------------------");
    if (localTarifa.service_type_identifier == 18) {
      console.log("Agregar solo para transporte:");
      console.log("Adding new TARIFAA------------------------");
      console.log("VIGENCIAS ACTUALES:::");
      const getVigencias = dataServices.services_fees.find(
        (servicio, index) => {
          return servicio.identifier_list == localTarifa.identifier_list;
        }
      );
      console.log(localTarifa.identifier_list);
      console.log(dataServices.services_fees);
      console.log("De aquí podríamos obtener las vigencias::: ");
      console.log(getVigencias?.fee_validity_list);
      if (getVigencias?.fee_validity_list) {
        setLocalTarifa({
          ...localTarifa,
          fee_validity_list: [
            ...getVigencias.fee_validity_list,
            {
              validity_identifier: 0,
              /////////////
              validity_startdate: "",
              validity_enddate: "",
              identifierValidity: identifierValidity,
            },
          ],
          service_fee_list: [
            ...localTarifa.service_fee_list,
            {
              fee_identifier: 0,
              fee_BBT: 0,
              /////////////////////////////////
              fee_currency_identifier: 0,
              fee_iva: 0,
              fee: 0,
              fee_total: 0,
              fee_SGL: 0,
              fee_DBL: 0,
              fee_TPL: 0,
              fee_CPL: 0,
              fee_BB: 0,
              fee_MAID: 0,
              fee_FP: 0,
              fee_TSGL: 0,
              fee_TDBL: 0,
              fee_TTPL: 0,
              fee_TCPL: 0,
              fee_TFP: 0,
              fee_TMAID: 0,
              fee_TBB: 0,
              fee_tax: 0,
              fee_key: identificador,
              fee_capacity: "",
              vehicle: "",
            },
          ],
        });
      }
      return;
    } else {
      setLocalTarifa({
        ...localTarifa,
        service_fee_list: [
          ...localTarifa.service_fee_list,
          {
            fee_identifier: 0,
            fee_BBT: 0,
            /////////////////////////////////
            fee_currency_identifier: 0,
            fee_iva: 0,
            fee: 0,
            fee_total: 0,
            fee_SGL: 0,
            fee_DBL: 0,
            fee_TPL: 0,
            fee_CPL: 0,
            fee_BB: 0,
            fee_MAID: 0,
            fee_FP: 0,
            fee_TSGL: 0,
            fee_TDBL: 0,
            fee_TTPL: 0,
            fee_TCPL: 0,
            fee_TFP: 0,
            fee_TMAID: 0,
            fee_TBB: 0,
            fee_tax: 0,
            fee_key: identificador,
            fee_capacity: "",
            vehicle: "",
          },
        ],
      });
      return;
    }

    ///////////////////////////////////////////////////////////
  };
  useEffect(() => {
    console.log("local tarifa has changed:");
    console.log(localTarifa);
  }, [localTarifa]);
  const [isTaxesAvailable, setIsTaxesAvailable] = useState<boolean>(
    localTarifa.fee_is_included
  );
  const [isShowingFee, setIsShowingFee] = useState<boolean>(true);
  const addNewEstancia = () => {
    // const currentEstancia: Array<stayDates> | undefined =
    // dataServices?.services_fees.find((currentTarifa, index) => {
    //   return currentTarifa.identifier_list == tarifa.identifier_list;
    // })?.service_minimum_stay_list;
    let identifierEstancia = uuidv4();
    const newServicesList = dataServices.services_fees.map(
      (currentTarifa, vigencia) => {
        return currentTarifa.identifier_list != tarifa.identifier_list
          ? currentTarifa
          : {
              ...currentTarifa,
              service_minimum_stay_list: [
                ...currentTarifa.service_minimum_stay_list,
                {
                  minimum_stay: "",
                  minimum_stay_startdate: "",
                  minimum_stay_enddate: "",
                  minimuStayIdentifier: identifierEstancia,
                  status_identifier: 0,
                  minimum_stay_identifier: null,
                },
              ],
            };
      }
    );
    setDataServices({
      ...dataServices,
      services_fees: newServicesList,
    });
  };
  const addNewVigencia = () => {
    /////VALIDACION
    const currentArrayVigencia: Array<validityDates> | undefined =
      dataServices?.services_fees.find((currentTarifa, index) => {
        return currentTarifa.identifier_list == tarifa.identifier_list;
      })?.fee_validity_list;
    // Verificar si los campos validity_startdate y validity_enddate no están vacíos
    const hasEmptyDates = currentArrayVigencia?.some(
      (validity: validityDates) => {
        return (
          validity.validity_startdate.trim() === "" ||
          validity.validity_enddate.trim() === ""
        );
      }
    );

    if (hasEmptyDates) {
      alert(
        "El arreglo contiene campos de fecha vacíos. Ingresa los campos restantes."
      );
      return;
    } else {
    }
    /////FIN DE VALIDACION

    let identifierValidity = uuidv4();
    const newServicesList = dataServices.services_fees.map(
      (currentTarifa, vigencia) => {
        return currentTarifa.identifier_list != tarifa.identifier_list
          ? currentTarifa
          : {
              ...currentTarifa,
              fee_validity_list: [
                ...currentTarifa.fee_validity_list,
                {
                  validity_identifier: 0,
                  /////////////
                  validity_startdate: "",
                  validity_enddate: "",
                  identifierValidity: identifierValidity,
                },
              ],
            };
      }
    );
    setDataServices({
      ...dataServices,
      services_fees: newServicesList,
    });
  };
  const [diasSeleccionados, setDiasSeleccionados] = useState<string[]>(
    tarifa.operating_days
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const diaSeleccionado = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setDiasSeleccionados([...diasSeleccionados, diaSeleccionado]);
      setLocalTarifa({
        ...localTarifa,
        operating_days: [...diasSeleccionados, diaSeleccionado],
      });
    } else {
      setDiasSeleccionados(
        diasSeleccionados.filter((dia) => dia !== diaSeleccionado)
      );

      setLocalTarifa({
        ...localTarifa,
        operating_days: diasSeleccionados.filter(
          (dia) => dia !== diaSeleccionado
        ),
      });
    }
  };
  const addBlackout = () => {};
  const deleteBlackout = () => {};
  useEffect(() => {
    console.log("BLACKOUT LIST FATHER:::::::::::::::::::::::::::::");
    console.log(tarifa.service_blackout_list);
  }, []);

  const handleTodosLosDiasChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const todosLosDiasArray = diasSemana.map((dia) => dia.nombre);

    setDiasSeleccionados(isChecked ? todosLosDiasArray : []);
    if (!isChecked) {
      setLocalTarifa({
        ...localTarifa,
        operating_days: [],
      });
    } else {
      setLocalTarifa({
        ...localTarifa,
        operating_days: todosLosDiasArray,
      });
    }
  };
  const todosLosDiasChecked = diasSeleccionados.length === diasSemana.length;

  const [isShowingStay, setIsShowingStay] = useState<boolean>(true);
  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteServicio(tarifa);
    setIsShownDelete(false);
    ////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  return (
    <>
      <TarifaGeneralStyle serviceType={localTarifa.service_type_identifier}>
        {!isEditMode && (
          <div className="show-info-toggle">
            <h3>{localTarifa.service_name}</h3>
            <FontAwesomeIcon
              icon={faAngleDown}
              className="angle-down"
              onClick={() => {
                setIsShowingFee(!isShowingFee);
              }}
            />
          </div>
        )}

        {isShowingFee && (
          <>
            <div className="tarifa-bloque">
              <div>
                <h3 className="tarifas-title">Tarifa</h3>
              </div>
              <div className="tarifa-extra-data">
                <div className="servicios-box-extra">
                  {/* --------------------------------------------------INFORMACIÓN GENERAL DE TARIFAS -----------------------------------*/}
                  <CustomInputt
                    text="Nombre del Servicio"
                    txtWidth={100}
                    setGeneralData={setLocalTarifa}
                    field="service_name"
                    value={localTarifa.service_name}
                  />
                  <div className="box-select">
                    {localTarifa.service_type_identifier != 0 && (
                      <h3 className="titulo-select">Tipo de Servicio</h3>
                    )}
                    <SelectListdefault
                      className="select-default-dos"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                      }}
                      id="sl6"
                      onChange={(e) => {
                        console.log("CATEGORIA ID SELECTED: ");
                        console.log(e.target.value);

                        setLocalTarifa({
                          ...localTarifa,
                          service_type_identifier: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Tipo de Servicio
                      </option>

                      {serviceList?.length > 0
                        ? serviceList.map((item) => {
                            return (
                              <option
                                key={item.identifier}
                                value={item.identifier}
                                selected={
                                  item.identifier ==
                                  localTarifa.service_type_identifier
                                }
                              >
                                {item.description}
                              </option>
                            );
                          })
                        : null}
                    </SelectListdefault>
                  </div>

                  {(localTarifa.service_type_identifier == 10 ||
                    localTarifa.service_type_identifier == 1) && (
                    <>
                      <div className="box-select">
                        {registerGeneral.category_identifier != 0 && (
                          <h3 className="titulo-select">Categoría</h3>
                        )}
                        <SelectListdefault
                          className="select-default-dos"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                          }}
                          id="sl6"
                          onChange={(e) => {
                            console.log("CATEGORIA ID SELECTED: ");
                            console.log(e.target.value);

                            setLocalTarifa({
                              ...localTarifa,
                              service_category_identifier: parseInt(
                                e.target.value
                              ),
                            });
                            setRegisterGeneral({
                              ...registerGeneral,
                              category_identifier: parseInt(e.target.value),
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            Categoría
                          </option>

                          {categoryServices?.length > 0
                            ? categoryServices.map((item) => {
                                return (
                                  <option
                                    key={item.identifier}
                                    value={item.identifier}
                                    selected={
                                      item.identifier ==
                                      registerGeneral.category_identifier
                                    }
                                  >
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </SelectListdefault>
                      </div>
                    </>
                  )}
                  {localTarifa.service_type_identifier == 29 && (
                    <>
                      <div className="box-select">
                        {localTarifa.service_category_identifier != 0 && (
                          <h3 className="titulo-select">Categoría</h3>
                        )}
                        <SelectListdefault
                          className="select-default-dos"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                          }}
                          id="sl6"
                          onChange={(e) => {
                            console.log("CATEGORIA ID SELECTED: ");
                            console.log(e.target.value);

                            setLocalTarifa({
                              ...localTarifa,
                              service_category_identifier: parseInt(
                                e.target.value
                              ),
                            });
                            // setRegisterGeneral({
                            //   ...registerGeneral,
                            //   category_identifier: parseInt(e.target.value),
                            // });
                          }}
                        >
                          <option value="" disabled selected>
                            Categoría
                          </option>

                          {expensesList?.length > 0
                            ? expensesList.map((item) => {
                                return (
                                  <option
                                    key={item.identifier}
                                    value={item.identifier}
                                    selected={
                                      item.identifier ==
                                      localTarifa.service_category_identifier
                                    }
                                  >
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </SelectListdefault>
                      </div>
                    </>
                  )}
                  {localTarifa.service_type_identifier !== 10 &&
                  localTarifa.service_type_identifier !== 1 &&
                  localTarifa.service_type_identifier !== 29 ? (
                    <CustomInputt
                      text="Categoría"
                      txtWidth={100}
                      setGeneralData={setLocalTarifa}
                      field="service_category"
                      value={localTarifa.service_category}
                    />
                  ) : null}

                  {localTarifa.service_type_identifier == 10 && (
                    <>
                      <div className="box-select">
                        {localTarifa.service_mealplan_identifier != 0 && (
                          <h3 className="titulo-select">Plan de alimentos</h3>
                        )}
                        <SelectListdefault
                          className="select-default-dos"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                          }}
                          id="sl6"
                          onChange={(e) => {
                            setLocalTarifa({
                              ...localTarifa,
                              service_mealplan_identifier: parseInt(
                                e.target.value
                              ),
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            Plan de alimentos
                          </option>

                          {foodPlan.map((item) => {
                            return (
                              <option
                                key={item.identifier}
                                value={item.identifier}
                                selected={
                                  item.identifier ==
                                  localTarifa.service_mealplan_identifier
                                }
                              >
                                {item.description}
                              </option>
                            );
                          })}
                        </SelectListdefault>
                      </div>
                    </>
                  )}

                  <CustomInputt
                    text="Comentarios"
                    txtWidth={100}
                    setGeneralData={setLocalTarifa}
                    field="service_comments"
                    value={localTarifa.service_comments}
                  />

                  <ImpuestosGet
                    isTaxesAvailable={isTaxesAvailable}
                    setIsTaxesAvailable={setIsTaxesAvailable}
                    handleOptionChange={handleOptionChange}
                    tarifa={tarifa}
                    localTarifa={localTarifa}
                    setLocalTarifa={setLocalTarifa}
                    isTaxesIncluded={tarifa.fee_is_included}
                    serviceCurrentType={localTarifa.service_type_identifier}
                  />
                  {(localTarifa.service_type_identifier == 18 ||
                    localTarifa.service_type_identifier == 17) && (
                    <>
                      <div className="box-select">
                        {localTarifa.transport_type_identifier != 0 && (
                          <h3 className="titulo-select">Tipo de Transporte</h3>
                        )}
                        <SelectListdefault
                          className="select-default-dos"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                          }}
                          id="sl6"
                          onChange={(e) => {
                            setLocalTarifa({
                              ...localTarifa,
                              transport_type_identifier: parseInt(
                                e.target.value
                              ),
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            Tipo de Transporte
                          </option>

                          {transportType.map((item) => {
                            return (
                              <option
                                key={item.identifier}
                                value={item.identifier}
                                selected={
                                  item.identifier ==
                                  localTarifa.transport_type_identifier
                                }
                              >
                                {item.description}
                              </option>
                            );
                          })}
                        </SelectListdefault>
                      </div>

                      <div className="box-select">
                        {localTarifa.initial_schedule != "" && (
                          <h3 className="titulo-select">Horario Inicial</h3>
                        )}
                        <SelectListdefault
                          className="select-default-dos"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                          }}
                          id="sl6"
                          onChange={(e) => {
                            setLocalTarifa({
                              ...localTarifa,
                              initial_schedule: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            Horario inicial
                          </option>

                          {horariosServicios.map((item) => {
                            return (
                              <option
                                key={item}
                                value={item}
                                selected={item == localTarifa.initial_schedule}
                              >
                                {item}
                              </option>
                            );
                          })}
                        </SelectListdefault>
                      </div>

                      <SelectListdefault
                        className="select-default-dos"
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                        }}
                        id="sl6"
                        onChange={(e) => {
                          setLocalTarifa({
                            ...localTarifa,
                            final_schedule: e.target.value,
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Horario final
                        </option>

                        {horariosServicios.map((item) => {
                          return (
                            <option
                              key={item}
                              value={item}
                              selected={item == localTarifa.final_schedule}
                            >
                              {item}
                            </option>
                          );
                        })}
                      </SelectListdefault>

                      <CustomInputt
                        text="Mínimo para operar"
                        txtWidth={100}
                        setGeneralData={setLocalTarifa}
                        field="minimum_to_operate"
                        value={localTarifa.minimum_to_operate}
                      />
                    </>
                  )}

                  {(localTarifa.service_type_identifier == 18 ||
                    localTarifa.service_type_identifier == 17) && (
                    <>
                      <div className="child-tour">
                        <h3>Descripción del TOUR</h3>
                        <textarea
                          className="text-container"
                          name={""}
                          id={""}
                          placeholder=""
                          onChange={(e) => {
                            setLocalTarifa({
                              ...localTarifa,
                              description: e.target.value,
                            });
                          }}
                          value={localTarifa.description}
                        />
                        {/* <CustomInputt
                    text="Descripción de tour"
                    txtWidth={100}
                    setGeneralData={setLocalTarifa}
                    field="description"
                    value={localTarifa.description}
                  /> */}
                      </div>
                    </>
                  )}

                  {localTarifa.service_type_identifier == 10 && (
                    <>
                      <div className="box-select">
                        {localTarifa.continent_identifier != 0 && (
                          <h3 className="titulo-select">Motivo</h3>
                        )}
                        <SelectListdefault
                          className="select-default-dos"
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                          }}
                          id="sl6"
                          onChange={(e) => {
                            setLocalTarifa({
                              ...localTarifa,
                              continent_identifier: parseInt(e.target.value),
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            Mercado
                          </option>

                          {continentList.map((item) => {
                            return (
                              <option
                                key={item.identifier}
                                value={item.identifier}
                                selected={
                                  item.identifier ==
                                  localTarifa.continent_identifier
                                }
                              >
                                {item.description}
                              </option>
                            );
                          })}
                        </SelectListdefault>
                      </div>

                      <CustomInputt
                        text="Restricciones mércado"
                        txtWidth={100}
                        setGeneralData={setLocalTarifa}
                        field="market_restrictions"
                        value={localTarifa.market_restrictions}
                      />
                      {/* <CustomInputt
                  text="Estancia mínima"
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="minimum_stay"
                  value={localTarifa.minimum_stay}
                /> */}
                    </>
                  )}
                </div>
                <br />
                <div className="comentarios-section-extra"></div>
              </div>
              <div></div>
              {(localTarifa.service_type_identifier == 18 ||
                localTarifa.service_type_identifier == 17 ||
                localTarifa.service_type_identifier == 36 ||
                (localTarifa.service_type_identifier == 29 && localTarifa.service_category_identifier == 1)
                )
                
                && (
                <>
                  <h3 className="title-weeks-day">Días que opera</h3>
                  <div>
                    <div className="week-days">
                      {diasSemana.map((dia) => (
                        <label key={dia.id}>
                          <input
                            type="checkbox"
                            value={dia.nombre}
                            checked={diasSeleccionados.includes(dia.nombre)}
                            onChange={handleCheckboxChange}
                          />
                          {dia.nombre}
                        </label>
                      ))}
                      <label>
                        <input
                          type="checkbox"
                          value="Todos los días"
                          checked={todosLosDiasChecked}
                          onChange={handleTodosLosDiasChange}
                        />
                        Todos los días
                      </label>
                    </div>
                  </div>
                </>
              )}

              <br />

              <div className="tarifa-out">
                <div className="tarifa-inside">
                  {/*------------------------------------RENDERIZAR ARRAY TARIFA  INDIVIDUAL ENCABEZADOS/TITULOS TIPO 1 -----------------------------------*/}
                  {localTarifa.service_type_identifier !== 10 &&
                    localTarifa.service_type_identifier !== 18 && (
                      <div className="tarifas-general-header">
                        <div>
                          <h3>Moneda</h3>
                        </div>
                        {localTarifa.service_type_identifier === 18 && (
                          <div>
                            <h3>Capacidad</h3>
                          </div>
                        )}
                        <div>
                          <h3>Tarifa</h3>
                        </div>
                        <div>
                          <h3>IVA</h3>
                        </div>
                        {localTarifa.service_type_identifier !== 18 && (
                          <div>
                            <h3>
                              {localTarifa.service_type_identifier == 16
                                ? "Servicio %"
                                : " Impuesto %"}
                            </h3>
                          </div>
                        )}

                        <div>
                          <h3>Total</h3>
                        </div>
                        <div></div>
                      </div>
                    )}
                  {/* ----------------------------------------------------CONTENIDO DE LAS TARIFAS GENERALES------------------------------------------*/}

                  {localTarifa.service_type_identifier !== 10 &&
                    localTarifa.service_type_identifier !== 18 &&
                    localTarifa.service_fee_list
                      .filter((tarifa, index) => {
                        return localTarifa.service_type_identifier == 18
                          ? true
                          : index === 0;
                      })
                      .map((tarifaCurrent) => {
                        return (
                          <TarifaIndividualG
                            tarifa={tarifaCurrent}
                            service_fee_list={localTarifa.service_fee_list}
                            localTarifaParent={localTarifa}
                            setLocalTarifaParent={setLocalTarifa}
                            key={tarifaCurrent.fee_key}
                            isTaxesIncluded={tarifa.fee_is_included}
                            serviceCurrentType={
                              localTarifa.service_type_identifier
                            }
                          />
                        );
                      })}
                  {/* -----------------------------------------CONTENIDO DE LAS TARIFAS DE TRANSPORTE ------------------------------------------------*/}
                  {localTarifa.service_type_identifier == 18 && (
                    <div className="tarifas-general-header-dos">
                      <div>
                        <h3>Moneda</h3>
                      </div>

                      <div>
                        <h3>Capacidad</h3>
                      </div>
                      <div>
                        <h3>Vehiculo</h3>
                      </div>
                      <div>
                        <h3>Tarifa</h3>
                      </div>
                      <div>
                        <h3>IVA</h3>
                      </div>

                      <div>
                        <h3>Total</h3>
                      </div>
                      <div>
                        <h3>Vig. inicial</h3>
                      </div>
                      <div>
                        <h3>Vig. final</h3>
                      </div>
                      <div></div>
                    </div>
                  )}
                  {localTarifa.service_type_identifier === 18 &&
                    localTarifa.service_fee_list
                      .filter((tarifa, index) => {
                        return localTarifa.service_type_identifier == 18;
                      })
                      .map((tarifaCurrent, index) => {
                        return (
                          <TarifaTransporte
                            tarifaFees={tarifaCurrent}
                            service_fee_list={localTarifa.service_fee_list}
                            localTarifaParent={localTarifa}
                            setLocalTarifaParent={setLocalTarifa}
                            key={tarifaCurrent.fee_key}
                            isTaxesIncluded={tarifa.fee_is_included}
                            serviceCurrentType={
                              localTarifa.service_type_identifier
                            }
                            indiceTarifa={index}
                            vigenciasList={tarifa.fee_validity_list}
                            dataServices={dataServices}
                            setDataServices={setDataServices}
                            tarifa={tarifa}
                          />
                        );
                      })}
                </div>
              </div>

              {localTarifa.service_type_identifier == 18 && (
                <div className="add-tarifa-container-plus">
                  <img
                    src={agregar}
                    width="30px"
                    alt="agregar"
                    onClick={addNewTarifa}
                    className="plus-icon-blackout"
                  />
                </div>
              )}
              {/* --------------------------------------------------CONTENIDO DE LAS TARIFAS DE HABITACIÓN--------------------------------- */}

              {localTarifa.service_type_identifier == 10 &&
                localTarifa.service_fee_list
                  .filter((tarifa, index) => index == 0)
                  .map((currentTarifa, index) => {
                    return (
                      <TarifaHab
                        tarifa={currentTarifa}
                        service_fee_list={localTarifa.service_fee_list}
                        localTarifaParent={localTarifa}
                        setLocalTarifaParent={setLocalTarifa}
                        setDataServices={setDataServices}
                        currencyList={currencyList}
                        dataServices={dataServices}
                        key={currentTarifa.fee_key}
                        addNewServicio={addNewServicio}
                        isShowingAddButton={
                          dataServices.services_fees.length - 1 == index
                            ? true
                            : false
                        }
                        tipoTarifa={tipoTarifa}
                        setTipoTarifa={setTipoTarifa}
                        isTaxesIncluded={tarifa.fee_is_included}
                      />
                    );
                  })}

              {/*----------------------------------------------- CONTENIDO DE VIGENCIAS---------------------------------------- */}
              <br />
              {/* Cuidado de no mover este bloque de codigo cuando haya servicio == 18, por alguna razón, si se quita, falla (al parecer ya se arregló esta falla) */}
              <div>
                {localTarifa.service_type_identifier !== 18 &&
                  tarifa.fee_validity_list.map((vigenciaData, index) => {
                    return (
                      <Vigencia
                        dataServices={dataServices}
                        setDataServices={setDataServices}
                        tarifa={tarifa}
                        vigenciaData={vigenciaData}
                        key={vigenciaData.identifierValidity}
                        indice={index}
                        addNewVigencia={addNewVigencia}
                        isShowingAddButton={
                          tarifa.fee_validity_list.length - 1 == index
                            ? true
                            : false
                        }
                      />
                    );
                  })}
              </div>

              {/* ------------------------------------------CONTENIDO DE BLACKOUT--------------------------------- */}
              <br />
              <br />
              {localTarifa.service_type_identifier == 10 && (
                <>
                  {tarifa.service_blackout_list.map((blackout, index) => {
                    return (
                      <BlackoutElement
                        blackout={blackout}
                        key={blackout.blackoutIdentifier}
                        dataServices={dataServices}
                        setDataServices={setDataServices}
                        addBlackout={addBlackout}
                        deleteBlackout={deleteBlackout}
                        tarifa={tarifa}
                        isShowingAddButton={
                          tarifa.service_blackout_list.length - 1 == index
                            ? true
                            : false
                        }
                        indice={index}
                      />
                    );
                  })}
                </>
              )}
              <br />
              <br />
              {localTarifa.service_type_identifier == 10 && (
                <>
                  <div className="box-estancias">
                    <h3>Estancia mínima</h3>
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="icon-estancia"
                      onClick={() => {
                        setIsShowingStay(!isShowingStay);
                      }}
                    />
                  </div>
                  {isShowingStay &&
                    tarifa.service_minimum_stay_list.map((stay, index) => {
                      return (
                        <EstanciaElement
                          stay={stay}
                          key={stay.minimuStayIdentifier}
                          dataServices={dataServices}
                          setDataServices={setDataServices}
                          addNewEstancia={addNewEstancia}
                          tarifa={tarifa}
                          isShowingAddButton={
                            tarifa.service_minimum_stay_list.length - 1 == index
                              ? true
                              : false
                          }
                          indice={index}
                        />
                      );
                    })}
                </>
              )}
            </div>
          </>
        )}
        <div className="icons-contenedor">
          <div>
            <img
              src={copiar}
              width="30px"
              className="copy-symbol"
              onClick={copyTheSameFee}
            />
            <img
              src={deletedatos}
              width="30px"
              className="copy-symbol"
              onClick={handleDeleteClick}
            />
            {/* <FontAwesomeIcon 
         className="copy-symbol"
         icon={faTrash}
         onClick={deleteServicio}
       /> */}
            {isShowingAddButton ? (
              <img
                src={agregar}
                width="30px"
                className="plus-symbol"
                onClick={addNewServicio}
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                  marginLeft: "1rem",
                }}
              />
            ) : null}
          </div>
        </div>
      </TarifaGeneralStyle>
      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar el servicio ${localTarifa.service_name}?`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </>
  );
}
interface styleI {
  serviceType: number;
}
const TarifaGeneralStyle = styled.div<styleI>`
  .box-select {
    position: relative;
    width: 100%;
  }
  .titulo-select {
    position: absolute;
    top: -0.5rem;
    font-size: 0.85vw !important;
    background-color: white;
    margin-left: 1rem;
    color: rgb(8, 166, 165);
    font-weight: 300;
  }
  .text-container {
    resize: none;
    width: 100%;
    height: 200px;
    padding: 0.9rem;
    border: rgb(8, 166, 165) solid 2px;
    outline: none;
  }
  .child-tour {
    width: 100%;
    grid-column: span 3;
    margin-bottom: 1rem;
  }
  .child-tour h3 {
    font-size: 0.85vw;
    color: rgb(8, 166, 165);
    margin-bottom: 0.5rem;
  }
  .box-estancias {
    width: 60%;
    margin: 0 auto;
    transform: translateX(-0.45rem);
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .icon-estancia {
    font-size: 1.2rem;
    cursor: pointer;
    color: black;
    cursor: pointer;
  }
  .box-estancias {
    color: rgb(8, 166, 165);
  }
  @media screen and (max-width: 900px) {
    .box-estancias {
      width: 70%;
    }
    .box-estancias h3 {
      font-size: 1rem !important;
    }
  }
  .title-weeks-day {
    font-size: 0.85vw;
    color: rgb(8, 166, 165);
  }
  .week-days {
    margin-top: 1rem;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 50%;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
  }
  .week-days label {
    font-size: 0.85vw;
  }
  @media screen and (max-width: 900px) {
    .week-days {
      width: 100%;
    }
    .week-days label {
      font-size: 1.8vw;
    }
    .title-weeks-day {
      font-size: 2vw !important;
    }
  }
  .tarifas-general-header div h3 {
    font-size: 0.85vw !important;
  }
  .tarifas-general-header-dos div h3 {
    font-size: 0.85vw !important;
  }
  .tarifas-title {
    font-size: 0.9vw;
    color: grey !important;
  }
  @media screen and (max-width: 900px) {
    .tarifas-general-header div h3 {
      font-size: 1.6vw !important;
    }
    .tarifas-general-header-dos div h3 {
      font-size: 1.3vw !important;
    }
    .tarifas-title {
      font-size: 1.8vw !important;
    }
  }
  h3 {
    font-size: 1.1vw !important;
  }
  .vigencia-inicial-title h3,
  .vigencia-final-title h3,
  .date-one-title h3,
  .date-two-title h3 {
    font-size: 0.85vw !important;
  }
  @media screen and (max-width: 900px) {
    .vigencia-inicial-title {
    }
    .vigencia-inicial-title h3,
    .vigencia-final-title h3,
    .date-one-title h3,
    .date-two-title h3 {
      font-size: 1.6vw !important;
    }
  }
  .vigencia-header-one {
    display: flex;
  }
  .vigencia-final-title {
  }
  .tarifa-extra-data {
  }

  .plus-vigencia {
    width: 85% !important;
    margin-top: 1rem;
  }
  .add-tarifa-container-plus {
    display: flex;
    justify-content: right;
    padding-right: 2rem;
    cursor: pointer;
  }
  .servicios-box-extra {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    justify-items: center;
    grid-row-gap: 1rem;
  }
  @media screen and (max-width: 700px) {
    .servicios-box-extra {
      grid-template-columns: 1fr 1fr;
    }
  }
  .select-default-dos {
    border: rgb(8, 166, 165) solid 2px;
  }
  .angle-down {
    font-size: 2rem;
    cursor: pointer;
  }
  .show-info-toggle {
    border: rgb(8, 166, 165) solid 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
  h3 {
    font-size: 1rem;
  }
  .taxes-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .input-style-data {
    max-width: 100%;
  }
  .icons-contenedor {
    margin-bottom: 2rem;
    display: flex;
    justify-content: right;
    padding: 0.9rem;
    align-items: center;
    margin-right: 5rem;
  }
  .copy-symbol {
    font-size: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
  }

  .tarifa-bloque {
    border-top: none;
    padding: 1.3rem;
    width: ${(props) => (props.serviceType == 10 ? "" : "100%")};
    margin: ${(props) => (props.serviceType == 10 ? "" : "0 auto")};
  }
  @media screen and (max-width: 900px) {
    .tarifa-bloque {
      width: 100%;
    }
  }
  margin: 0;
  .input-style-data::-webkit-inner-spin-button,
  .input-style-data::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .plus-container {
    display: flex;
    justify-content: right;
    padding-right: 10vw;
  }
  .finalLine {
    border: rgb(8, 166, 165) solid 1px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .icon-edit {
    font-size: 1.2rem;
    margin-right: 1rem;
    cursor: pointer;
  }
  .trash {
    color: red;
  }

  .tarifa-container {
    border: grey solid 1px;
    margin: 1rem 0 1rem 0;
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
  }
  .tarifa-container div {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: grey solid 1px;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    padding-left: 0.17rem;
    padding-right: 0.17rem;
  }
  .tarifa-container-dos {
    border: grey solid 1px;
    margin: 1rem 0 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.6fr 1fr 1fr 1fr 0.7fr;
    justify-items: center;
    align-items: center;
  }
  .tarifa-container-dos div {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: grey solid 1px;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    padding-left: 0.17rem;
    padding-right: 0.17rem;
  }
  .vigencia-header {
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr;
    justify-items: center;
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    .vigencia-header {
      width: 70%;
    }
  }
  .vigencia-data-container {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    border: rgb(8, 166, 165) solid 2px;
    grid-column: span 2;
  }
  .vigencia-box {
    margin-top: 1.4rem;
  }
  .vigencia-title {
    margin-bottom: 1rem;
    color: rgb(8, 166, 165);
  }
  margin-bottom: 2rem;
  @media screen and (max-width: 700px){
    .tarifa-inside{
    border: black solid 1px; 
    width: 180%;
  }
  .tarifa-out{
    border: grey solid 1px; 
    width: 100%;
    overflow: auto;
  }
  }

`;
const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  align-items: center;
  max-height: 38.4px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
