import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../../Operaciones/Components/RowCotizacion";
import {
  EntryDataResponse,
  ProviderInterfaceOP,
  QuoteItemI,
  operationDataI,
  operationUpdateI,
  updateProviderI,
} from "../../Operaciones/InterfacesOperacion/OperacionesI";
import {
  contratoContextI,
  contratoContextInterface,
  servicioDayElementI,
} from "../../../../context/InterfacesContext";
import IndividualService from "../../Operaciones/Components/IndividualService";
import deleteImage from "../../../../images/deletelogo.png";
import agregarImage from "../../../../images/AGREGAR.png";
import { Link, useNavigate } from "react-router-dom";
import { OperationContext } from "../../../../context/operationContext";
import { PendingPayment, RootObject } from "../InterfacesContabilidad";
import { fetchServer } from "../../../../services/fetchServer";

interface prestadorI {
  prestador: ProviderInterfaceOP;
  listProvider: ProviderInterfaceOP[];
  setListProvider: React.Dispatch<React.SetStateAction<ProviderInterfaceOP[]>>;
  isGastosComprobar?: boolean;
}

export default function ElementPrestadorCont({
  prestador,
  setListProvider,
  listProvider,
  isGastosComprobar,
}: prestadorI) {
  const {
    setShowSearchProviders,
    idOperation,
    currentUser,
    setCurrentUser,
    contratoContext,
    setContratoContext,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const {
    currentOperation,
    setCurrentOperation,
    currentEntrada,
    setCurrentEntrada,
    providerTypeOperacion,
    setProviderTypeOperacion,
    idProveedorSelected,
    setIdProveedorSelected,
    idCurrentPayment
  } = useContext(OperationContext);

  const borrarElemento = async () => {
    console.log("borrando");
    console.log("El siguiente es el prestador que estás borrando: ");
    console.log(prestador);
    console.log("Servicios totales::");

    const listaActualizada = listProvider.filter((currentProvedor, index) => {
      return (
        currentProvedor.register_identifier != prestador.register_identifier
      );
    });

    const listToDelete: updateProviderI[] = listProvider
      .filter((currentProvedor, index) => {
        return (
          currentProvedor.register_identifier == prestador.register_identifier
        );
      })
      .map((currentProvedor, index) => {
        return {
          service_identifier: 0, // no mandar cuando estas eliminando
          fee_identifier: 0, // no mandar cuando estas eliminando
          provider_identifier: currentProvedor.register_identifier,
          status_identifier: 2,
        };
      });

    const bodyUpdate: operationUpdateI = {
      operation_identifier: idOperation,
      operation_data: {
        alias: currentOperation.quote.alias,
        pax: currentOperation.quote.pax,
        sgl: currentOperation.quote.sgl,
        dbl: currentOperation.quote.dbl,
        tpl: currentOperation.quote.tpl,
        cpl: currentOperation.quote.cpl,
        adults: currentOperation.quote.adults,
        childs: currentOperation.quote.childs,
        status_identifier: 1,
      },
      services: listToDelete,
    };

    console.log("Así se va mandar la info a borrar");
    console.log(listToDelete);

    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/update`;

    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyUpdate),
      });

      if (response.ok) {
        const data = await response.json();

        console.log("Elemento actualizado/borrado");
        console.log(data);
        setListProvider(listaActualizada);
        ///////////////////////////////////OBTENER DATOS DE OPERACION

        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al asignar usuario");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al realizar la busqueda");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };

  const getEntradaN = async (versionSeleccionada: number) => {
    const bodyRequest = {
      operation_identifier: idOperation,
      provider_identifier: prestador.register_identifier,
      entry_number: versionSeleccionada,
      provider_type_identifier: prestador.provider_type_identifier,
    };

    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/getEntryDetail`;

    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        const data: EntryDataResponse = await response.json();

        console.log("Datos de Entrada");
        console.log(data.data);
        console.log(data.data.entry_data);
        console.log(data.data.entry_services_list);
        console.log(data.data.files);

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
        setProviderTypeOperacion(prestador.provider_type_identifier);
        setCurrentEntrada(data.data);
        const filesData: contratoContextInterface[] = data.data.files.map(
          (document, index) => {
            const currentFile: contratoContextInterface = {
              file_identifier: document.file_identifier,
              contract_identifier: 0, ////file_identifier
              file_name: document.file_alt_name,
              fileName: document.file_alt_name,
              file: document.file_url,
              file_extension: "pdf",
              start_date: "",
              end_date: "",
              identificador: document.file_identifier.toString(),
              status_identifier: 1,
            };
            return currentFile;
          }
        );
        const getContrato: contratoContextI = {
          contract_identifier: 0,
          ///////////////////////////////
          start_date: "",
          end_date: "",
          cancellation_policies: "",
          observations: "",
          files: filesData,
        };
        setContratoContext(getContrato);

        navigate("/dashboard/Operaciones/Desgloce-Entrada");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al asignar usuario");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al realizar la busqueda");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };

  const payProvedor = async() => {
    setIdProveedorSelected(prestador.register_identifier);
    navigate("/dashboard/Contabilidad/Contabilidad-Pago");
       const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint +
      `/accounting/PendingPayments/${currentOperation.quote.quote_identifier}/${idProveedorSelected}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        // console.log(response);
        console.log("bien");
        const data: RootObject = await response.json();
        console.log(data.data.pending_payments);

        console.log(data.data.provider);
        let arregloPayments: PendingPayment[];
        arregloPayments = data.data.pending_payments;
   



        // if (data.data.pending_payments) {
        //   if (data.data.pending_payments.length > 0) {
        //   }else{
        //     alert("No hay pagos para este registro 1.");
        //     return;

        //   }
        // }else{
          
        //   alert("No hay pagos para este registro 2.");
        //   return;
        // }

        // console.log(data.data.results);
        // setListResultsGroups(data.data.results);
      } else {
        
        alert("No hay pagos para este registro 3.");
          return;
          }
    } catch (error) {
      
      alert("No hay pagos para este registro 4.");
      return; 
    }


  };

  return (
    <ElementPrestadorStyle>
      <div className="element-container">
        <div className="element-data">
          <div>
            <h3>{prestador.provider_name}</h3>
          </div>
          <div>
            <h3>Prestador name</h3>
          </div>
          <div>
            {!isGastosComprobar && <button onClick={payProvedor}>Pagar</button>}
          </div>
        </div>
        <div className="element-buttons"></div>
      </div>
    </ElementPrestadorStyle>
  );
}

const ElementPrestadorStyle = styled.div`
  .get-link {
    cursor: pointer;
  }
  .icon {
    cursor: pointer;
  }
  .element-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .element-container {
    display: flex;
  }
  .element-data {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-right: black solid 1px;
    padding: 0.5rem;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    justify-content: center;
    align-items: center;
  }
  .element-buttons {
    /* width: 8%; */
  }
  h3 {
    font-size: 0.85vw;
  }
`;
