import React, { useEffect, useState, useContext, Fragment } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { AppContext } from "../../../context/appContext";
import { ApiResponse } from "../../../interfaces/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import SearchElement from "./SearchElement";
import { QuoteElementI, QuoteListI } from "./interfaces/CotizacionI";
import GeneralesList from "./GeneralesList";
import { useCatalogsDos } from "../../../context/CatalogContextDos";
import { bodyI, quoteSearchParams } from "../../../interfaces/GeneralTypes";
import DateInput from "../../components/entrys/DateInput";

export default function SearchCotizacion() {
  const [textSearch, setTextSearch] = useState<string>("");
  const [listSearchResults, setListSearchResults] = useState<QuoteElementI[]>(
    []
  );
  const { catalogsDataDos } = useCatalogsDos();
  const [listParams, setListParams] = useState<quoteSearchParams[]>([]);
  const [idParamSelected, setIdParamSelected] = useState<number>(4);
  const [bodySearch, setBodySearch] = useState<bodyI>({
    dateSearch: "",
    textSearch: "",
  });

  useEffect(() => {
    console.log("Texto de busqueda:");
    console.log(textSearch);
  }, [textSearch]);

  useEffect(() => {
    console.log("Datos que vienen del método: ");
    console.log(catalogsDataDos);
    setListParams(catalogsDataDos.quote_search_parameter_list);
  }, [catalogsDataDos]);
  useEffect(() => {
    console.log("Nueva lista de parametros actualizada: ");
    console.log(listParams);
  }, [listParams]);

  const findCotizaciones = async () => {
    if (!textSearch.trim() && !bodySearch.dateSearch.trim()) {
      alert("Por favor, introduce la cotización que deseas buscar.");
      setListSearchResults([]);
      return;
    }
    console.log("Se están buscando las cotizaciones...");
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    console.log("REACT BACK END POINT: ");
    console.log(backEndPoint);
    const searchTerm =
      idParamSelected == 2 || idParamSelected == 1
        ? bodySearch.dateSearch
        : bodySearch.textSearch;

    const urlModified =
      backEndPoint + `/quote/Quotes/${idParamSelected}/${searchTerm}`;
    console.log("URL FINAL :::", urlModified);
    try {
      const response = await fetch(urlModified, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Verificar si la respuesta no fue exitosa
      if (!response.ok) {
        setListSearchResults([]);
        throw new Error(`HTTP error! status: ${response.status}`);

        alert("Error"); 
        return;
      }

      if (response.ok) {
        ////RESPUESTA CORRECTA
        const data = await response.json();

        console.log("VALOR DE LA CONSULTA:");
        console.log(data.data);
        setListSearchResults(data.data.quotes);
        if (data.data.quotes.length == 0) {
          alert("No se encontraron coincidencias.");
          setListSearchResults([]);
          return;
        }
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      setListSearchResults([]);
      alert("No se encontró la cotización introducida.");
    }
  };

  useEffect(() => {
    console.log("Id param selected: ");
    console.log(idParamSelected);
    setBodySearch({
      dateSearch: "",
      textSearch: "",
    });
    setListSearchResults([]);
  }, [idParamSelected]);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      findCotizaciones();
    }
  };
  useEffect(() => {
    console.log("LA LISTA DE RESULTADOS ES LA SIGUIENTE:");
    console.log(listSearchResults);
  }, [listSearchResults]);
  useEffect(() => {
    console.log("Te presento bodySearch:");
    console.log(bodySearch);
  }, [bodySearch]);
  return (
    <SearchCotizacionStyles>
      <div className="box-container">
        <div className="element-client-box">
          <div className="title-box">
            <h3>Busqueda de cotización</h3>
            <div className="elements-search">
              <div className="combo-box-container">
                <SelectListdefault
                  style={{
                    width: "80%",
                    maxWidth: "80%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    setIdParamSelected(parseInt(e.target.value));
                  }}
                >
                  <option value="" disabled selected>
                    Criterio de busqueda
                  </option>

                  {listParams?.length > 0
                    ? listParams.map((item) => {
                        return (
                          <Fragment>
                            <option
                              key={item.description}
                              value={item.identifier}
                              selected={item.identifier == idParamSelected}
                            >
                              {item.description}
                            </option>
                          </Fragment>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>
              {idParamSelected == 2 || idParamSelected == 1 ? (
                <>
                  <DateInput
                    text="Fecha"
                    txtWidth={30}
                    setGeneralData={setBodySearch}
                    field="dateSearch"
                    value={bodySearch.dateSearch}
                    height={46.6}
                  />
                  <div className="icon-container" onClick={findCotizaciones}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </div>
                </>
              ) : (
                <div className="search-box-container">
                  <div className="input-container">
                    <input
                      type="text"
                      value={bodySearch.textSearch}
                      onChange={(e) => {
                        setTextSearch(e.target.value);
                        setBodySearch({
                          ...bodySearch,
                          textSearch: e.target.value,
                        });
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="icon-container" onClick={findCotizaciones}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </div>
                </div>
              )}
            </div>
            <div></div>
          </div>

          {/* <SearchElement /> */}
          {listSearchResults?.map((quote, index) => {
            return <SearchElement key={quote.quote_identifier} quote={quote} />;
          })}
        </div>
      </div>
    </SearchCotizacionStyles>
  );
}

const Entry = styled.input`
  height: 46.6px;
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 46.6px !important;
  min-height: 46.6px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  ///////////////////////////////////
`;

const SearchCotizacionStyles = styled.div`
  .elements-search {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
  }
  .combo-box-container {
    width: 200px;
    height: 10px;
  }
  .search-box-container {
    border: rgb(8, 166, 165) solid 2px;
    width: 70%;
    display: flex;
    /* margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
    */
  }
  .input-container {
    width: 85%;
  }
  .input-container input {
    width: 100%;
    padding: 0.8rem;
    border: none;
    outline: none;
    border-right: rgb(8, 166, 165) solid 2px;
  }
  .icon-container {
    width: 15%;
    background-color: rgb(8, 166, 165);

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: ease all 0.7s;
  }
  .icon-container:hover {
    background-color: white;
    color: rgb(8, 166, 165);
  }
  /* position: absolute; */
  .title-box {
    text-align: center;

    margin-bottom: 1rem;
    border-radius: 0.6rem;
  }
  .title-box h3 {
    font-size: 2.7rem;
    font-weight: bolder;
    color: rgb(8, 166, 165);
  }
  .element-client-box {
    border: rgb(8, 166, 165);
    background-color: rgba(133, 198, 195, 0.5);
    min-height: 100vh;
    width: 85%;
    margin: 0 auto;
    border-radius: 0.4rem;
    padding: 1rem 0 1.3rem 1.3rem;
    margin-top: 2rem;
  }
  .box-container {
    margin-bottom: 3rem;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #4caf50;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  @media screen and (max-width: 900px) {
    /* Estilos para dispositivos móviles */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    tr {
      margin-bottom: 10px;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
      text-align: left;
    }

    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
      font-weight: bold;
    }
  }
`;
