import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontawesomeObject } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";

export default function UserInfo() {
  const [showOptions, setShowOptions] = useState(false);

  const handleProfileClick = () => {
    setShowOptions((prev) => !prev);
  };

  return (
    <UserInfoStyle>
      <div className="picture-profile-container" onClick={handleProfileClick}>
        {showOptions && (
          <div className="options-container">
            <Link to="/">
              {" "}
              <div className="option">Cerrar sesión</div>
            </Link>

            <div className="option">Opciones</div>
          </div>
        )}
      </div>

      <h4>User Info</h4>
    </UserInfoStyle>
  );
}

const UserInfoStyle = styled.div`
  /* border: black solid 1px; */
  padding: 1rem;
  border-bottom: white solid 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  .picture-profile {
    width: 50px;
    max-height: 100%;
    max-width: 100%;
  }
  .picture-profile-container {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    background-color: grey;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }
  .options-container {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    padding: 0.5rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 150px;
  }
  .option {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    &:hover {
      background-color: #f2f2f2;
    }
  }
  @media screen and (min-width: 900px) {
    width: 320px;
    border-bottom: none;
    margin-bottom: 0rem;
  }
`;
