import { Fragment,useState } from "react";
import styled from 'styled-components'
import GrlLabel from "../labels/GrlLabel";

import Observacionesimg from "../../../images/observaciones.png"
import StyledButton from "../buttons/StyledButton";

interface Props {
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

function Observaciones({showModal, setShowModal}:Props) {

    const PrincipalContainer = styled.div`
        width: 100%;
        height: 100%;
        display: ${showModal ? "flex" : "none"};
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        `;
    return (
        <Fragment>
            <PrincipalContainer onClick={(e) => setShowModal(!showModal)} >
                <Modal>
                    <ActionImg src={Observacionesimg}></ActionImg>
                    <ModalSecondLabel>Observaciones</ModalSecondLabel>
                    <ModalPrase>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                        laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
                        ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                        in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero
                        eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore
                        te feugait nulla facilisi.
                        Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
                        ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
                        ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                    </ModalPrase>
                    <StyledButton text="Guardar" btnAlign="center"></StyledButton>
                </Modal>
            </PrincipalContainer>
        </Fragment>
    );
}

export default Observaciones;

const Modal = styled.div`
    width: 80%;
    height: auto;
    background-color: #ffffff;
    border-radius: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0px 50px 0px;
`;

const ActionImg = styled.img`
    width: 80px;
    height: auto;
    cursor: pointer;
    `;

const ModalSecondLabel = styled.div`
    font-size: 20px;
    height: auto;
    width: 80%;
    color: #08a6a5;
    text-align: start;
    margin: 20px 0px;
`;

const ModalPrase = styled.div`
    font-size: 15px;
    text-align: justify;
    height: auto;
    width: 80%;
    color: #000000;
    text-align: start;
    margin-bottom: 50px;
`;