import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from '../../../images/AGREGAR.png'; 
import CotizacionInfo from "./CotizacionInfo";


export default function PriceBox() {

 
  return (
    <PriceBoxStyles>
      <br />
      <br />
    
    </PriceBoxStyles>
  )
}

const PriceBoxStyles = styled.div`


`
