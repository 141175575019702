import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { ApiResponseOpcionalI, OptionalQuote, QuoteDetail } from "./interfaces/CotizacionI";
import agregarIcon from "../../../images/AGREGAR.png";
import deleteIcon from "../../../images/deletelogo.png";
import editIcon from '../../../images/edit.png';
import { feeChargesI, generalsElementsI, personalInfoI, servicioDayElementI, servicioIndividual, serviciosDaysElementsI } from "../../../context/InterfacesContext";
import { v4 as uuidv4 } from "uuid";
import { CommentI, QuoteGeneralElement } from "../../../interfaces/GeneralTypes";
import { AppContext } from "../../../context/appContext";
import { useNavigate } from "react-router-dom";


interface ElementOpcionalI {
  addNewOpcional: () => void;
  deleteCurrentOpcional: (id_currentOpcional: string) => void;
  opcional: OptionalQuote;
  editCurrentElement: (
    id_currentOpcional: string,
    actualElemento: OptionalQuote
  ) => void;
  listElementosOpcionales: OptionalQuote[];
  setElementosOpcionales: React.Dispatch<React.SetStateAction<OptionalQuote[]>>
}

export default function ElementOpcional({
  addNewOpcional,
  deleteCurrentOpcional,
  opcional,
  listElementosOpcionales, 
  setElementosOpcionales
}: ElementOpcionalI) {

  const navigate = useNavigate();
  const [listOpcionales, setListOpcionales] = useState<OptionalQuote[]>([]);
  const [opcionalEstado, setOpcionalEstado] = useState<OptionalQuote>(opcional);
 
  const {setDataCotizacionContext, dataCotizacionContext, setShowLoader, setTitleAlert, setContentAlert , setCotizacionMode, setShowAlert , listOpcionalesContext, setListOpcionalesContext} = useContext(AppContext); 

  const getProviderList = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified = backEndPoint + `/quote/OptionalQuotes`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        
        
        const data: ApiResponseOpcionalI = await response.json();
  console.log("LISTA DE OPCIONALES:");
  console.log(data.data.optional_quotes_list); 
        let identifierLocalOptional = uuidv4();
        const listaOpcionales = data.data.optional_quotes_list.map((opcional, index)=>{
          return {
            ...opcional,
            id_local: identifierLocalOptional
          }
        })
        setListOpcionales(listaOpcionales);
 
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al realizar la solicitud!:");
        // setContentAlert(
        //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        // );
        // setShowAlert(true);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      //   alert("ERROR AL REALIZAR LA SOLICITUD");
      //   setTitleAlert("Error al realizar la solicitud:");
      //   setContentAlert(
      //     "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      //   );
    }
  };


  const getCotizacion = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint + `/quote/QuoteDetail/${opcionalEstado.identifier}`;

    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data = await response.json();

        console.log("VALOR DE LA CONSULTA:");
        console.log(data.data);
        const quoteResponse: QuoteDetail = data.data.quote_detail;
        console.log("RESPUESTA DEL SERVIDOR PARAMETRIZADA EN INTERFAZ");
        console.log(quoteResponse);

        const personalInfoInicial: personalInfoI = {
          name: quoteResponse.quote.name,
          idCotizacion: quoteResponse.quote.quote_identifier.toString(),
          typeCotizacion: quoteResponse.quote.type,
          typeAgency: 1,
          serieId: quoteResponse.quote.serie_identifier,
          includesSerie: true,
          pax: quoteResponse.quote.PAX,
          min: quoteResponse.quote.MIN,
          max: quoteResponse.quote.MAX,
          TL: quoteResponse.quote.TL,
          arrivingData: quoteResponse.quote.arrival_date,
          departureDate: quoteResponse.quote.departure_date,
          agencia: quoteResponse.quote.agency,
          typeRoom: 2,
          numberDaysOpenQuote: quoteResponse.quote.number_of_days,
          modeCotizacion: quoteResponse.quote.serie ? "Serie" : quoteResponse.quote.optional_quote ? 'Opcional': "Normal",
          selectedSerieOption: "No",
          serieSelected: 0,
          isShowingDates: false,
          dateStartOpen: "",
          dateEndOpen: "",
          status_identifier: quoteResponse.quote.status_identifier,
          quote_identifier: quoteResponse.quote.quote_identifier,
          optional_quote: quoteResponse.quote.optional_quote,
          total_base_sgl: quoteResponse.quote.total_base_sgl,
          total_base_dbl: quoteResponse.quote.total_base_dbl,
          total_base_tpl: quoteResponse.quote.total_base_tpl,
          simple_supplement: quoteResponse.quote.simple_supplement,
          triple_reduction: quoteResponse.quote.triple_reduction,
        };

        ///////////////////Recuperando servicios individuales

        const serviciosDaysElementsInicial: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.quote_individual_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
               
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: serviceDayy.occupation,
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    status_identifier: serviceDayy.status_identifier,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: 0,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    vigencias:serviceDayy.validity_list,
                    fee_identifier: serviceDayy.fee_identifier,
                    isIncludedOpt: false,
                    
                  };
                  return individualServicio;
                });
                
              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              
                
              };
              return servicio;
            }
          ),
        };
     
        //////////////////Recuperando servicios Generales.
        console.log("Getting comments: ");
        console.log(quoteResponse.quote.comments);
        const dataCommentsArray: CommentI[] = quoteResponse.quote.comments.map(
          (currentComment, index) => {
            return {
              comment_identifier: currentComment.comment_identifier,
              comment: currentComment.comment,
              comment_date: currentComment.comment_date,
              status_identifier: currentComment.status_identifier,
              commentIdentifier: currentComment.comment_identifier.toString(),
            };
          }
        );
      
        const cargosFee: feeChargesI = {
          currentMoney: 0,
          changeType: quoteResponse.quote.exchange_rate,
          comision: quoteResponse.quote.commission,
          comisonAuto: quoteResponse.quote.commission_auto,
          cortesia: quoteResponse.quote.courtesy,
          comments: dataCommentsArray,
          courtesy_type_identifier:
            quoteResponse.quote.courtesy_type_identifier,
        };
        const generalElementos: generalsElementsI = {
          generalElement: [],
        };
        let serviciosGenerales: QuoteGeneralElement[] = [];
        let serviciosTransporteGenerales: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.general_transport_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: "",
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: serviceDayy.number_of_services ?? 1,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    fee_identifier: serviceDayy.fee_identifier,
                    isIncludedOpt: false,
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
           
              };
              return servicio;
            }
          ),
        };
        if (quoteResponse.quote_general_services) {
          serviciosGenerales = quoteResponse.quote_general_services.map(
            (generalS, index) => {
              let identifierGUID = uuidv4();
              const currentServicioGeneral: QuoteGeneralElement = {
                service_type: generalS.service_type_identifier,
                provider_id: generalS.provider_identifier,
                service_name_id: generalS.service_identifier,
                fee_price: generalS.fee,
                number_days: generalS.number_of_services,
                total_price: generalS.fee * generalS.number_of_services,
                identifierGeneralService: identifierGUID,
                currency: quoteResponse.quote.currency,
                currency_identifier: quoteResponse.quote.currency_identifier,
                expenses_type_identifier: generalS.expenses_type_identifier,
                general_service_identifier: generalS.general_service_identifier,
                status_identifier: generalS.service_identifier,
                fee_identifier: generalS.service_identifier,
                isIncludedOpt: false, 
              };
              return currentServicioGeneral;
            }
          );
        } else {
          serviciosGenerales = [];
        }

        setDataCotizacionContext({
          ...dataCotizacionContext,
          personalInfo: personalInfoInicial,
          serviciosDayElements: serviciosDaysElementsInicial,
          generalsElements: generalElementos,
          serviciosDayElementsTransporte: serviciosTransporteGenerales,
          totalPrice: {
            totalPrice: 0,
            granTotal: 0,
          },
          feeCharges: cargosFee,
          generalServices: serviciosGenerales,
        });
       
        setListOpcionalesContext([]); 
        setTitleAlert("La solicitud se realizó correctamente");
        setContentAlert("Accediste a la siguiente información de cotización.");
        setCotizacionMode(2);
        setShowAlert(true);
        setShowLoader(false);
        navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        setTitleAlert("Error al realizar la solicitud!:");
        setContentAlert(
          "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        );
        setShowAlert(true);
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      alert("ERROR AL REALIZAR LA SOLICITUD");

      setTitleAlert("Error al realizar la solicitud:");
      setContentAlert(
        "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      );
      setShowLoader(false);
    }
  };
  useEffect(() => {
    getProviderList();
  }, []);
  const deleteElemento = () => {
    deleteCurrentOpcional(opcional.id_local);
  };
  const editCurrentElement = (identificador: number) => {
    const objetoSeleccionado:OptionalQuote | undefined = listOpcionales.find((opcional, indice)=>opcional.identifier == identificador);
  
    if(objetoSeleccionado){
        setOpcionalEstado({
            ...opcionalEstado,
            identifier: objetoSeleccionado.identifier,
            name: objetoSeleccionado.name,
            total_base_sgl: objetoSeleccionado.total_base_sgl,
            total_base_dbl: objetoSeleccionado.total_base_dbl,
            total_base_tpl: objetoSeleccionado.total_base_tpl,
            arrival_date: objetoSeleccionado.arrival_date,
            departure_date: objetoSeleccionado.departure_date,
            id_local: objetoSeleccionado.id_local,
            pax: objetoSeleccionado.pax 
        })
    
    }
  };
  useEffect(()=>{
    console.log("opcionalEstado"); 
    console.log(opcionalEstado);
    const newArrayOpcionales = listElementosOpcionales.map((elementoOpcional, index)=>{
      return elementoOpcional.id_local !== opcional.id_local ? elementoOpcional :{
        ...opcionalEstado
      }
    })
    console.log("actualización del array: "); 
    console.log(newArrayOpcionales); 
    setElementosOpcionales(newArrayOpcionales); 
  },[opcionalEstado])
  return (
    <ElementOpcionalStyle>
      <div className="element-opcional">
        <div>
          <SelectListdefault
            style={{
              width: "90%",
              maxWidth: "90%",
            }}
            id="sl6"
            onChange={(e) => {
              // setGeneralServiceData({
              //   ...generalServiceData,
              //   service_type: parseInt(e.target.value),
              //   provider_id: null,
              //   service_name_id: null,
              //   fee_price: 0,
              //       number_days: 0,
              //       total_price: 0
              // });
              // getListProviders(parseInt(e.target.value));
                editCurrentElement(parseInt(e.target.value))
            }}
              value={
                 opcionalEstado.identifier || ""
              }
          >
            <option value="" disabled selected>
              Servicio
            </option>

            {listOpcionales?.length ?? 0 > 0
              ? listOpcionales?.map((item) => {
                  return (
                    <option key={item.identifier} value={item.identifier}>
                      {item.name}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>
        </div>
        <div>
          <h3>{opcionalEstado.total_base_sgl}</h3>
        </div>
        <div>
          <h3>{opcionalEstado.total_base_dbl}</h3>
        </div>
        <div>
          <h3>{opcionalEstado.total_base_tpl}</h3>
        </div>
        <div>
          <h3>{opcionalEstado.departure_date}</h3>
        </div>
        <div>
          <h3>{ listOpcionales.find((opcional, indice)=>opcional.identifier == opcionalEstado.identifier)?.pax ?? 1}</h3>
        </div>
        <div>
          <img src={editIcon} alt="" width={25} onClick={getCotizacion} />
          <img src={deleteIcon} alt="" width={25} onClick={deleteElemento} />
        </div>
      </div>
    </ElementOpcionalStyle>
  );
}

const ElementOpcionalStyle = styled.div`
  img {
    cursor: pointer;
  }
  .element-opcional {
    border: black solid 1px;
    width: 70%;
    margin: 0 auto;
  }
  .element-opcional {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    justify-content: center;
    align-items: center;
  }
  .element-opcional div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem 0.2rem 0.5rem;
  width: 100% !important;
  min-width: 100%;
  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.7vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  .fake-icon {
    max-width: 10px !important;
  }
  /* white-space: pre-wrap; */
  @media screen and (max-width: 900px) {
    font-size: 1.2vw;
  }
`;
