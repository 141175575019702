import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import EDITAR from "../../../images/edit.png";
import StyledTable from "../tables/StyledTable";
import {
  GeneralsData,
  bodyResponseProvider,
  ContactData,
} from "../../../interfaces/ProviderTypes";
import guardar from "../../../images/GUARDARDATOS.png";
import Generales from "../layout/Generales";
import ContactLayoutNew from "../layout/ContactLayoutNew";
import GetRowContact from "../layout/GetRowContact";
import { useNavigate } from "react-router-dom";

interface GetContactI {
  contactoServiceList: ContactData[];
  getInfoProvider: () => void
}
export default function GetContact({ contactoServiceList, getInfoProvider }: GetContactI) {
  const navigate = useNavigate();
  const {
    setShowInfoContact,
  } = useContext(AppContext);

  const editarContactos = () =>{
    getInfoProvider(); 
    navigate("/dashboard/Registro/Contacto");
    setShowInfoContact(false); 
  }
  return (
    <GetContactStyles>
      
        <div className="title-first">
          <h3>Contacto</h3>
          <img src={EDITAR} onClick={editarContactos}></img>
        </div>
        {
          contactoServiceList?.length > 0 && (       <div className="box-container">
          <br />
          <br />
          <div className="title-header">
            <div>
              <h2>Nombre de contacto</h2>
            </div>
            <div>
              <h2>Correo electrónico</h2>
            </div>

            <div>
              <h2>Teléfono</h2>
            </div>
            <div>
              <h2>Ext.</h2>
            </div>
            <div>
              <h2>Puesto</h2>
            </div>
            <div></div>
          </div>
          {contactoServiceList
            ?.map((contacto, index) => {
              return {
                identifier: contacto.contact_identifier.toString(),
                name: contacto.contact_name,
                email: contacto.contact_email,
                personal_phone: contacto.contact_phone,
                extension: contacto.contact_extension.toString(),
                charge: contacto.charge,
                web: "contacto.web",
                status: 110,
              };
            })
            .map((contacto, index) => {
              return (
                <GetRowContact key={contacto.identifier} contacto={contacto} />
              );
            })}
        </div>)
        }
 
      
    </GetContactStyles>
  );
}

const GetContactStyles = styled.div`
    
 `;
