export function calcularNumeroDeNoches(diaUno: string, diaDos: string): number {
    // Convertir las cadenas de texto a objetos de tipo Date
    const fechaUno = new Date(diaUno);
    const fechaDos = new Date(diaDos);

    // Calcular la diferencia en milisegundos entre las dos fechas
    const diferenciaEnTiempo = fechaDos.getTime() - fechaUno.getTime();

    // Calcular la diferencia en días y restar 1 para excluir la noche de salida
    const diferenciaEnDias = Math.floor(diferenciaEnTiempo / (1000 * 60 * 60 * 24));

    return diferenciaEnDias;
}