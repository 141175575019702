import { Fragment } from "react";
import styled from 'styled-components'
import { catalogElement } from "../../interfaces/interfaces";
import StyledButton from "../components/buttons/StyledButton";
import StyledTable from "../components/tables/StyledTable";

interface catalogProps {
    catalogElements: Array<catalogElement>;
    setCatalogElements: React.Dispatch<React.SetStateAction<catalogElement[]>>;
    handleChangeArray: (dataViajeSelected: catalogElement) => void;
    deleteElementArray: (dataViajeClave: string) => void;
  }

function WebCatalog({catalogElements, setCatalogElements, handleChangeArray, deleteElementArray}:catalogProps) {
    return (
        <Fragment>
            <PrincipalContainer>
                <StyledTable
                    columns={["Clave","Nombre","Moneda","Tarifa","IVA","Impuesto","Total","Temporada","Fecha inicial","Fecha final",""]}
                    action="CRUD"
                    data={catalogElements}
                    ></StyledTable>
            </PrincipalContainer>
            <ButtonsContainer>
                <StyledButton text="Guardar" btnAlign="center"></StyledButton>
                <StyledButton text="Exportar" btnAlign="center"></StyledButton>
            </ButtonsContainer>
        </Fragment>
    );
}

export default WebCatalog;

const PrincipalContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    `;

const ButtonsContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;

    @media screen and (max-width:900px){
        flex-wrap: wrap;
    }
    `;

