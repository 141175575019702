import React from "react";
import styled from "styled-components";
import { ProviderResponse } from "../InterfacesOperacion/OperacionesI";
import { formatearCantidad } from "../../../components/functionsTs/utilidades";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DataPrestadorI {
  currentPrestador: ProviderResponse;
}

export default function HojaControlElement({
  currentPrestador,
}: DataPrestadorI) {
  return (
    <HojaControlElementStyle>
      <div className="element-container">
        <div className="element-data">
          <div>
            <h3>{currentPrestador.provider_name}</h3>
          </div>
          <div>
            <h3>{currentPrestador.provider_clave}</h3>
          </div>
          <div>
            {currentPrestador.entry_advance_mxn !== 0 ? (
              <h3
                className="get-link"
                //    onClick={()=>{getEntradaN(4)}}
              >
                {currentPrestador.mxn_payment_status} 
              </h3>
            ) : (
              <h3>{currentPrestador.usd_payment_status} </h3>
            )}
          </div>
          <div>
            <h3
              className="get-link"
              // onClick={()=>{getEntradaN(1)}}
            >
              {currentPrestador.Entry_no}
            </h3>
          </div>
          <div>
            <h3
              className="get-link"
              //    onClick={()=>{getEntradaN(2)}}
            >
              {currentPrestador.arrival_date}
            </h3>
          </div>
          <div>
            <h3
              className="get-link"
              //    onClick={()=>{getEntradaN(3)}}
            >
              {currentPrestador.departure_date}
            </h3>
          </div>
          <div>
            {currentPrestador.entry_advance_mxn !== 0 ? (
              <h3
                className="get-link"
                //    onClick={()=>{getEntradaN(4)}}
              >
                ${formatearCantidad(currentPrestador.entry_advance_mxn)} 
              </h3>
            ) : (
              <h3>
                ${formatearCantidad(currentPrestador.entry_advance_usd)} 
              </h3>
            )}
          </div>

          <div>
            {currentPrestador.entry_advance_mxn !== 0 ? (
              <h3
                className="get-link"
                //    onClick={()=>{getEntradaN(4)}}
              >
                {" "}
                Pesos
              </h3>
            ) : (
              <h3> Dolar</h3>
            )}
          </div>

          {/* <div className="container-clip">

          </div> */}
          <div className="special-celda">
            <h3
              className="get-link"
              //    onClick={()=>{getEntradaN(6)}}
            >
              {currentPrestador.payday_limit}
            </h3>
          </div>
        </div>
        <div className="element-buttons"></div>
      </div>
      {currentPrestador.has_files && (
        <FontAwesomeIcon className="icono-usable" icon={faPaperclip} />
      )}
    </HojaControlElementStyle>
  );
}

const HojaControlElementStyle = styled.div`
  .special-celda h3 {
    text-align: center !important;
  }
  position: relative;
  .icono-usable {
    position: absolute;
    top: 0.6rem;
    right: -1.2rem;
    font-size: 1.2rem;
  }

  .container-clip {
    text-align: center;
    color: blue;
  }

  .get-link {
    cursor: pointer;
  }
  .icon {
    cursor: pointer;
  }
  .element-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .element-container {
    display: flex;
  }
  .element-data {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-right: black solid 1px;
    padding: 0.3rem;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    justify-content: center;
    align-items: center;
  }
  .element-buttons {
    width: 0%;
  }
  h3 {
    font-size: 0.8vw;
    text-align: center;
    font-weight: 300 !important;
  }
`;
