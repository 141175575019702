import React, { useState } from "react";
import {
  RegisterGeneralI,
  RegisterInterface,
  dataServicesI,
} from "../interfaces/GeneralTypes";
import { v4 as uuidv4 } from "uuid";
import { clientInterface } from "../interfaces/interfaces";
import {
  registerGeneralData,
  objetoPrueba,
  userInicial,
  initialPropertiesCotization,
  initialPropertiesCotizationI,
  initalListOpcional,
} from "./initialContext";
import { contactInfoEmpty } from "./initialContext";
import {
  contratoIncial,
  contratoContextInicial,
  fiscalContextInicial,
} from "./initialContext";
import {
  contratoContextI,
  contratoContextInterface,
  contextFiscalI,
  contextCotizacionI,
  userI,
} from "./InterfacesContext";
import { dataServicesInicial } from "./initialContext";
import { dataCotizacionInicial } from "./initialContext";
import { OptionalQuote } from "../alico/pages/Cotizacion/interfaces/CotizacionI";

interface AppProviderProps {
  children: React.ReactNode;
}

interface AppContextInterface {
  showLoader: boolean;
  setShowLoader: (show: boolean) => void;
  showAlert: boolean;
  setShowAlert: (show: boolean) => void;
  titleAlert: string;
  setTitleAlert: (title: string) => void;
  contentAlert: string;
  setContentAlert: (content: string) => void;
  registerData: RegisterInterface;
  setRegisterData: (content: RegisterInterface) => void;
  showInfoContact: boolean;
  setShowInfoContact: (show: boolean) => void;
  contactCurrentInfo: clientInterface;
  setContactCurrentInfo: (content: clientInterface) => void;
  registerGeneral: RegisterGeneralI;
  setRegisterGeneral: React.Dispatch<React.SetStateAction<RegisterGeneralI>>;
  contratoContext: contratoContextI;
  setContratoContext: React.Dispatch<React.SetStateAction<contratoContextI>>;
  dataServicesContext: dataServicesI;
  setDataServicesContext: React.Dispatch<React.SetStateAction<dataServicesI>>;
  fiscalContext: contextFiscalI;
  setFiscalContext: React.Dispatch<React.SetStateAction<contextFiscalI>>;
  idCurrentProvider: number;
  setIdCurrentProvider: React.Dispatch<React.SetStateAction<number>>;
  dataCotizacionContext: contextCotizacionI;
  setDataCotizacionContext: React.Dispatch<
    React.SetStateAction<contextCotizacionI>
  >;
  showSeachServices: boolean;
  setShowSearchServices: (show: boolean) => void;
  idCurrentDayService: string;
  setIdCurrentDayService: React.Dispatch<React.SetStateAction<string>>;
  cotizationDate: string;
  setCotizationDate: React.Dispatch<React.SetStateAction<string>>;
  serviciosType: number;
  setServiciosType: React.Dispatch<React.SetStateAction<number>>;
  route: string;
  setRoute: React.Dispatch<React.SetStateAction<string>>;
  currentCurrency: number;
  setCurrentCurrency: React.Dispatch<React.SetStateAction<number>>;
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  openCotizaciconNumber: number;
  setOpenCotizacionNumber: React.Dispatch<React.SetStateAction<number>>;
  showGastosGenerales: boolean;
  setShowGastosGenerales: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSerieOption: string;
  setSelectedSerieOption: React.Dispatch<React.SetStateAction<string>>;
  cotizacionMode: number;
  setCotizacionMode: React.Dispatch<React.SetStateAction<number>>;
  numberDaySearch: number;
  setNumberDaySearch: React.Dispatch<React.SetStateAction<number>>;
  showDeleteNotification: boolean;
  setShowDeleteNotification: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: userI;
  setCurrentUser: React.Dispatch<React.SetStateAction<userI>>;
  showSearchProviders: boolean;
  setShowSearchProviders: React.Dispatch<React.SetStateAction<boolean>>;
  idOperation: number;
  setIdOperation: React.Dispatch<React.SetStateAction<number>>;
  cotizationExtraProperties: initialPropertiesCotizationI;
  idProforma: number,
  setIdProforma: React.Dispatch<
  React.SetStateAction<number>
>,
  setCotizationExtraProperties: React.Dispatch<
    React.SetStateAction<initialPropertiesCotizationI>
  >;
  listOpcionalesContext: OptionalQuote[],
  setListOpcionalesContext:  React.Dispatch<
  React.SetStateAction<OptionalQuote[]>
>;
  
}

const AppContext = React.createContext<AppContextInterface>({
  showLoader: false,
  setShowLoader: () => {},
  showAlert: false,
  setShowAlert: () => {},
  titleAlert: "",
  setTitleAlert: () => {},
  contentAlert: "",
  setContentAlert: () => {},
  registerData: objetoPrueba,
  setRegisterData: () => {},
  showInfoContact: false,
  setShowInfoContact: () => {},
  contactCurrentInfo: contactInfoEmpty,
  setContactCurrentInfo: () => {},
  registerGeneral: registerGeneralData,
  setRegisterGeneral: () => {},
  contratoContext: contratoContextInicial,
  setContratoContext: () => {},
  dataServicesContext: dataServicesInicial,
  setDataServicesContext: () => {},
  fiscalContext: fiscalContextInicial,
  setFiscalContext: () => {},
  idCurrentProvider: 0,
  setIdCurrentProvider: () => {},
  dataCotizacionContext: dataCotizacionInicial,
  setDataCotizacionContext: () => {},
  showSeachServices: false,
  setShowSearchServices: () => {},
  idCurrentDayService: "",
  setIdCurrentDayService: () => {},
  cotizationDate: "",
  setCotizationDate: () => {},
  serviciosType: 1,
  setServiciosType: () => {},
  route: "",
  setRoute: () => {},
  currentCurrency: 1,
  setCurrentCurrency: () => {},
  selectedOption: "option1",
  setSelectedOption: () => {},
  selectedSerieOption: "Yes",
  setSelectedSerieOption: () => {},
  openCotizaciconNumber: 0,
  setOpenCotizacionNumber: () => {},
  showGastosGenerales: false,
  setShowGastosGenerales: () => {},
  cotizacionMode: 1,
  setCotizacionMode: () => {},
  numberDaySearch: 0,
  setNumberDaySearch: () => {},
  showDeleteNotification: false,
  setShowDeleteNotification: () => {},
  currentUser: userInicial,
  setCurrentUser: () => {},
  showSearchProviders: false,
  setShowSearchProviders: () => {},
  idOperation: 0,
  setIdOperation: () => {},
  cotizationExtraProperties: initialPropertiesCotization,
  setCotizationExtraProperties: () => {},
  listOpcionalesContext: [],
  setListOpcionalesContext: () =>{},
  idProforma: 0,
  setIdProforma: () => {}
});

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [titleAlert, setTitleAlert] = useState<string>("");
  const [contentAlert, setContentAlert] = useState<string>("");
  const [registerData, setRegisterData] =
    useState<RegisterInterface>(objetoPrueba);
  const [showInfoContact, setShowInfoContact] = useState<boolean>(false);
  const [contactCurrentInfo, setContactCurrentInfo] =
    useState<clientInterface>(contactInfoEmpty);
  const [registerGeneral, setRegisterGeneral] =
    useState<RegisterGeneralI>(registerGeneralData);
  const [contratoContext, setContratoContext] = useState<contratoContextI>(
    contratoContextInicial
  );
  const [dataServicesContext, setDataServicesContext] =
    useState<dataServicesI>(dataServicesInicial);
  const [fiscalContext, setFiscalContext] =
    useState<contextFiscalI>(fiscalContextInicial);
  const [idCurrentProvider, setIdCurrentProvider] = useState<number>(0);
  const [dataCotizacionContext, setDataCotizacionContext] =
    useState<contextCotizacionI>(dataCotizacionInicial);
  const [showSeachServices, setShowSearchServices] = useState<boolean>(false);
  const [idCurrentDayService, setIdCurrentDayService] = useState<string>("");
  const [cotizationDate, setCotizationDate] = useState<string>("");
  const [serviciosType, setServiciosType] = useState<number>(0);
  const [route, setRoute] = useState<string>("");
  const [currentCurrency, setCurrentCurrency] = useState<number>(1);
  const [selectedOption, setSelectedOption] = useState<string>("Normal");
  const [selectedSerieOption, setSelectedSerieOption] = useState<string>("Yes");
  const [openCotizaciconNumber, setOpenCotizacionNumber] = useState<number>(0);
  const [showGastosGenerales, setShowGastosGenerales] =
    useState<boolean>(false);
  const [cotizacionMode, setCotizacionMode] = useState<number>(1);
  const [numberDaySearch, setNumberDaySearch] = useState<number>(0);
  const [showDeleteNotification, setShowDeleteNotification] =
    useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<userI>(userInicial);
  const [showSearchProviders, setShowSearchProviders] =
    useState<boolean>(false);
  const [idOperation, setIdOperation] = useState<number>(0);
  const [cotizationExtraProperties, setCotizationExtraProperties] =
    useState<initialPropertiesCotizationI>(initialPropertiesCotization);
   const[listOpcionalesContext, setListOpcionalesContext] = useState<OptionalQuote[]>([]);
   const [idProforma, setIdProforma] = useState<number>(0);

  return (
    <AppContext.Provider
      value={{
        showLoader,
        setShowLoader,
        showAlert,
        setShowAlert,
        titleAlert,
        setTitleAlert,
        contentAlert,
        setContentAlert,
        registerData,
        setRegisterData,
        showInfoContact,
        setShowInfoContact,
        contactCurrentInfo,
        setContactCurrentInfo,
        registerGeneral,
        setRegisterGeneral,
        contratoContext,
        setContratoContext,
        dataServicesContext,
        setDataServicesContext,
        fiscalContext,
        setFiscalContext,
        idCurrentProvider,
        setIdCurrentProvider,
        dataCotizacionContext,
        setDataCotizacionContext,
        showSeachServices,
        setShowSearchServices,
        idCurrentDayService,
        setIdCurrentDayService,
        cotizationDate,
        setCotizationDate,
        serviciosType,
        setServiciosType,
        route,
        setRoute,
        currentCurrency,
        setCurrentCurrency,
        selectedOption,
        setSelectedOption,
        openCotizaciconNumber,
        setOpenCotizacionNumber,
        showGastosGenerales,
        setShowGastosGenerales,
        selectedSerieOption,
        setSelectedSerieOption,
        cotizacionMode,
        setCotizacionMode,
        numberDaySearch,
        setNumberDaySearch,
        showDeleteNotification,
        setShowDeleteNotification,
        currentUser,
        setCurrentUser,
        showSearchProviders,
        setShowSearchProviders,
        idOperation,
        setIdOperation,
        cotizationExtraProperties,
        setCotizationExtraProperties,
        listOpcionalesContext, setListOpcionalesContext,
        idProforma, setIdProforma
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
