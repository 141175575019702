import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  Country,
  City,
  Neighborhood,
  Zipcode,
  RegistrationType,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from '../../../images/AGREGAR.png'; 
interface Contacto {
    identifier: string;
    name: string;
    email: string;
    personal_phone: string;
    extension: string;
    charge: string;
    web: string;
    status: number;
      
  }
  
  interface propsContacto {
    contacto: Contacto;     
  }
  
  //


export default function GetRowContact({
    contacto,    
  }: propsContacto) {
    const {
        identifier,
        name,
        email,
        personal_phone,
        extension,
        charge,
        web,
        status,
      } = contacto;
  return (
    <GetRowContactStyles>
        <div className="box-container">
        <div className="fila">
          <div>
            <h3>{name}</h3>
          </div>
          <div>
          <h3>{email}</h3>
          </div>
          
          <div>
          <h3>{personal_phone}</h3>
          </div>
          <div>
          <h3>{extension == "0" ? "": extension}</h3>
          </div>
          <div>
          <h3>{charge}</h3>
          </div>
        
        </div>
      </div>
    </GetRowContactStyles>
  )
}


const GetRowContactStyles = styled.div`
.icon-contact-save{
    font-size: 1.2rem;
    cursor: pointer; 
    margin-right: 1rem; 
}
.trash{
  margin-right: 1rem;
  cursor: pointer; 
}
.icon-contact-img{
  cursor: pointer; 
  margin-right: 0.5rem;
}
h3{
    font-size: 0.8rem;
}
  .fila {
    border: grey solid 1px;
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr !important;
    width: 95%;
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    
  }
  .fila div {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: grey solid 1px; 
    overflow: hidden;
  }
  .fila h3 {
    text-align: center;
    word-wrap: break-word;
  word-break: break-all;
  max-width: 30em; 
  }
  input {
    width: 100%;
    max-width: 100%;
  }
  .buttons-icons{
    justify-content: center !important;
    
  }
`