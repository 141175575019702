import React, { useCallback, useState, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import { FileI } from "../../../interfaces/GeneralTypes";
import FileElement from "../../components/layout/FileElement";
import { v4 as uuidv4 } from "uuid";
import DateInput from "../../components/entrys/DateInput";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface fileInputI {
  setcontractObject: React.Dispatch<
    React.SetStateAction<{
      start_date: string;
      end_date: string;
      file: string;
      file_extension: string;
    }>
  >;
  setLocalFileData: React.Dispatch<React.SetStateAction<FileI | undefined>>;
  contractObject: {
    start_date: string;
    end_date: string;
    file: string;
    file_extension: string;
  };
  fileLocalName: {
    file: string;
    start_date: string;
    end_date: string;
    file_extension: string;
    nameFile: string;
    identificador: string;
  };
  currentPicture: string;
  setCurrentPicture: React.Dispatch<React.SetStateAction<string>>
}

export default function FileInputDrop({
  setcontractObject,
  fileLocalName,
  contractObject,
  setLocalFileData,
  currentPicture,
  setCurrentPicture
}: fileInputI) {
 
  const [isPictureFocused, setIsPictureFocused] = useState<boolean>(false);
  const { setRegisterData, registerData } = useContext(AppContext);
  useEffect(() => {
    if (
      currentPicture ==
      "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
    ) {
      setIsPictureFocused(false);
    } else {
      setIsPictureFocused(true);
    }
  }, [currentPicture]);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64Data = reader.result as string;
        const extension = file.name.split(".").pop(); // Obtener la extensión del archivo

        console.log("Archivo en base64:", base64Data);
        console.log("Extensión:", extension);
        setCurrentPicture(base64Data);
        setcontractObject({
          ...contractObject,
          file: base64Data,
          file_extension: extension ?? "",
        });

        setLocalFileData({
          ...fileLocalName,
          file: base64Data,
          file_extension: extension ?? "",
          fileName: file.name,
          file_identifier: 0,
          contract_identifier: 0,
          file_name: "",
          status_identifier: 0,
        });
        setRegisterData({
          ...registerData,
          contract: {
            ...contractObject,
            file: base64Data,
            file_extension: extension ?? "",
          },
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={isDragActive ? "dropzone-active" : "dropzone"}
    >
      <input {...getInputProps()} />

      {isDragActive ? (
        <div className="picture-container">
          <div className="picture-box">
            {isPictureFocused ? (
              <img
                src={currentPicture}
                alt="Archivo seleccionado"
                className="picture-img"
              />
            ) : (
              <>
                <div>
                  <FontAwesomeIcon
                    icon={faFileArrowUp}
                    className="icon-upload"
                  />
                </div>

                <p>Arrastra los archivos aquí...</p>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="picture-container">
          <div className="picture-box">
            {isPictureFocused ? (
              <img
                src={currentPicture}
                alt="Archivo seleccionado"
                className="picture-img"
              />
            ) : (
              <div>
                <div>
                  <FontAwesomeIcon
                    icon={faFileArrowUp}
                    className="icon-upload"
                  />
                </div>

                <p>Selecciona o arrastra los archivos aquí...</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const ContratoStyle = styled.div`
  .picture-box {
    border: rgb(8, 166, 165) solid 1px;
    height: 250px;
    width: 250px;
    cursor: pointer;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
