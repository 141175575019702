import React, { useEffect, useContext } from "react";
import { useState } from "react";
import Navbar from "../layout/Navbar";
import { BrowserRouter, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AsideBar from "../layout/AsideBar";
import styled from "styled-components";
import { ComponentType } from "react";
import Catalogo from "./Catalogo";
import Welcome from "./Welcome";
import Contabilidad from "./Contabilidad";
import ErrorPage from "./ErrorPage";
import { Outlet } from "react-router-dom";
import SearchBox from "../common/SearchBox";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faXmark,
  faFloppyDisk,
  faFileArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface styledAppProps {
  showSideBar: boolean;
  showInfoContact: boolean;
}

export default function Dashboard() {
  const navigate = useNavigate();
  const { showInfoContact, registerData, setRegisterData } =
    useContext(AppContext);
  const { titleAlert, setTitleAlert } = useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const { showAlert, setShowAlert } = useContext(AppContext);
  const { showLoader, setShowLoader } = useContext(AppContext);
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const [showSideBar, setShowSideBar] = useState<boolean>(true);
  const leavePage = () => {
    navigate("/dashboard/Providers-List");
  };
  const saveInfoComplete = async () => {
    console.log("IMAGEN");
    console.log("ES BASE 64????????");
    console.log(isBase64Image(registerData.contract.file));
    console.log("Save info complete: ??? ");
    const url = "https://corecms.alicomx.com/api/provider/register";
    const objetoPorValidar = {
      identifier: 1,
      name: registerData.general.name,
      registration_type_identifier:
        registerData.general.registration_type_identifier,
      provider_type_identifier: registerData.general.provider_type_identifier,
      business_name: registerData.general.business_name,
      street: registerData.general.street,
      number: registerData.general.number,
      neighborhood_identifier: registerData.general.neighborhood_identifier,
      city_identifier: registerData.general.city_identifier,
      country_identifier: registerData.general.country_identifier,
      zip_code: registerData.general.zip_code,
      observations: registerData.general.observations,
    };

    if (!validarObjeto(objetoPorValidar)) {
      setTitleAlert("Error al guardar");
      setContentAlert(
        "Por favor, introduce todos los datos generales, antes de continuar"
      );
      setShowAlert(true);
      return;
    }
    setShowLoader(true);
    const newProviderFinal = {
      user_identifier: 1,
      general: {
        identifier: 1,
        name: registerData.general.name,
        registration_type_identifier:
          registerData.general.registration_type_identifier,
        provider_type_identifier: registerData.general.provider_type_identifier,
        business_name: registerData.general.business_name,
        street: registerData.general.street,
        number: registerData.general.number,
        neighborhood_identifier: registerData.general.neighborhood_identifier,
        city_identifier: registerData.general.city_identifier,
        country_identifier: registerData.general.country_identifier,
        zip_code: 1,
        observations: registerData.general.observations,
        type: "string",
        category: "string",
        lastPurchase: "string",
        feedback: "string",
        lead: "string",
        commissionScheme: "string",
        image: "base64string",
        imageExtension: "png",
      },
      contact_list: null,
      contract: null,
      service_list: null,
      fee_list: null,
      tax_data: null,
    };
    console.log("OBJETO A ENVIAR");
    console.log(newProviderFinal);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newProviderFinal),
      });
      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        setShowLoader(false);
        setTitleAlert("Guardado correcto");
        setContentAlert(
          "Tus datos fueron guardados correctamente, puedes consultarlos en el catalogo."
        );
        setShowAlert(true);
        navigate("/dashboard/Providers-List");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        setShowLoader(false);
        setTitleAlert("Error al guardar");
        setContentAlert(
          "Tus datos no fueron guardados correctamente, por favor, verificalos."
        );
        setShowAlert(true);
        console.log(":c");
      }
    } catch (error) {
      ///ERROR AL REALIZAR PETICION
    }
  };
  function isBase64Image(text: string): boolean {
    const regex = /^data:image\/([a-zA-Z]*);base64,/;
    return regex.test(text);
  }
  function validarObjeto(objeto: any): boolean {
    const propiedadesPorValidar = [
      "name",
      "registration_type_identifier",
      "provider_type_identifier",
      "business_name",
      "street",
      "number",
      "neighborhood_identifier",
      "city_identifier",
      "country_identifier",
      "observations",
      "imagen",
    ];

    for (let propiedad of propiedadesPorValidar) {
      if (objeto[propiedad] === "" || objeto[propiedad] === 0) {
        return false;
      }
    }

    return true;
  }
  return (
    <AppStyles showSideBar={showSideBar} showInfoContact={showInfoContact}>
      <div className="box-content">
        <div className="non-printable-section">
          <AsideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        </div>

        <div className={`second-section  ${screenWidth ? "" : "hide-element"}`}>
          <div className="non-printable-section">
            <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
          </div>

          <div className="content-app">
            <Outlet />
          </div>
        </div>
      </div>
    </AppStyles>
  );
}

const AppStyles = styled.div<styledAppProps>`

  @media print {
    /* Estilo para imprimir secciones imprimibles */
    .printable-section {
      display: block;
    }
    margin-top: -5rem;
    /* Estilo para ocultar secciones no imprimibles */
    .non-printable-section {
      display: none;
    }
    .second-section {
      display: none;
    }
  }
  .x-mark,
  .upload-document,
  .save-document {
    font-size: 3.2rem;
    color: rgb(8, 166, 165);
    cursor: pointer;
  }
  .x-mark {
    color: red;
  }
  .buttons {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 1rem;
    bottom: 0.2rem;
    background-color: #f2f2f2;
    z-index: 9;
  }

  position: relative;
  .box-content {
    display: flex;
  }

  .second-section {
    width: 80%;
    background-color: #b1b1b1;
    display: ${(props) => (false ? "none" : "block")};
  }
  @media screen and (min-width: 900px) {
    .content-app {
      position: ${(props) => (false ? "fixed" : "absolute")};
      width: ${(props) => (props.showSideBar ? "80% !important" : "100%")};
      left: ${(props) => (props.showSideBar ? "20% !important" : "0%")};
    }
  }
  .content-app {
    position: ${(props) => (false ? "fixed" : "absolute")};
    width: ${(props) => (props.showSideBar ? "100%" : "100%")};
    left: ${(props) => (props.showSideBar ? "0%" : "0%")};
    top: 5rem;
    /* background-color: #f2f2f2; */
    min-height: calc(100vh - 5rem);
  }

  @media screen and (min-width: 900px) {
    .second-section {
      display: block;
    }
  }
`;
