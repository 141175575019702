import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomInputt from "../entrys/CustomInputt";


interface feeInterface {
  name: string;
  fee: string;
  start_date: string;
  end_date: string;
  currency_identifier: number;
  season_identifier: number;
  observations: string;
  identifier: string;
}

interface tarifaLayoutProps {
  currencyList: coinInterface[];
  seasons: seasonInterface[];
  feeData: tarifaList;
  editService: (identifier: string, tarifaModified: tarifaList) => void;
  deleteService: (identifier: string) => void;
}
interface coinInterface {
  identifier: number;
  description: string;
  abbreviation: string;
}
interface seasonInterface {
  identifier: number;
  description: string;
}
interface tarifaList {
  name: string;
  fee: string;
  start_date: string;
  end_date: string;
  currency_identifier: number;
  season_identifier: number;
  observations: string;
  identifier: string;
}
export default function TarifaLayout({
  currencyList,
  seasons,
  feeData,
  editService,
  deleteService
}: tarifaLayoutProps) {
  const {
    name,
    fee,
    start_date,
    end_date,
    currency_identifier,
    season_identifier,
    observations,
    identifier,
  } = feeData;

  const [feeObject, setFeeObject] = useState({
    name: name,
    fee: fee,
    start_date: start_date,
    end_date: end_date,
    currency_identifier: currency_identifier,
    season_identifier: season_identifier,
    observations: observations,
    identifier: identifier,
  });
  useEffect(() => {
    console.log("Fee object ha sido modificado: ");
    console.log(feeObject);
    editService(identifier, feeObject);
  }, [feeObject]);
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;

    console.log(newDate);

    setFeeObject({
      ...feeObject,
      start_date: newDate,
    });
  };

  const handleDateChangeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;

    console.log(newDate);

    setFeeObject({
      ...feeObject,
      end_date: newDate,
    });
  };
  const deleteElement = () => {
    console.log("Borrando elemento");
    deleteService(feeObject.identifier); 
  };

  return (
    <TarifaLayoutStyles>
   
      <div className="data-container">
        <CustomInputt<feeInterface>
          text="Nombre"
          txtWidth={70}
          setGeneralData={setFeeObject}
          field="name"
          value={name}
        />
        <CustomInputt<feeInterface>
          text="Tarifa"
          txtWidth={70}
          setGeneralData={setFeeObject}
          field="fee"
          value={fee.toString()}
        />

        <SelectListdefault
            style={{
              width: "70%", maxWidth: "70%"
            }}
          id="sl6"
          onChange={(e) => {
            console.log(e.target.value);
            // setserviceListObject({
            //   ...serviceListObject,
            //   season_identifier: parseInt(e.target.value)
            // })
            setFeeObject({
              ...feeObject,
              currency_identifier: parseInt(e.target.value),
            });
          }}
        >
          <option value="" disabled selected>
            Moneda
          </option>

          {currencyList.map((item) => {
            return (
              <Fragment>
                <option key={item.description} value={item.identifier} selected = {item.identifier == feeObject.currency_identifier}>
                  {item.description}
                </option>
              </Fragment>
            );
          })}
        </SelectListdefault>
        <SelectListdefault
            style={{
              width: "70%", maxWidth: "70%"
            }}
          id="sl6"
          onChange={(e) => {
            console.log(e.target.value);
            setFeeObject({
              ...feeObject,
              season_identifier: parseInt(e.target.value),
            });
          }}
        >
          <option value="" disabled selected>
            Temporada
          </option>

          {seasons.map((item) => {
            return (
              <Fragment>
                <option key={item.description} value={item.identifier} selected = {item.identifier == feeObject.season_identifier}>
                  {item.description}
                </option>
              </Fragment>
            );
          })}
        </SelectListdefault>

        <div>
          <h3>Fecha de inicio</h3>
          <input type="date" onChange={handleDateChange} 
          value = {feeObject.start_date}
          />
        </div>
        <div>
          <h3>Fecha fin</h3>
          <input type="date" onChange={handleDateChangeTwo} 
              value = {feeObject.end_date}
          />
        </div>
        <CustomInputt<feeInterface>
          text="Observaciones"
          txtWidth={70}
          setGeneralData={setFeeObject}
          field="observations"
          value = {feeObject.observations}
        />
        <FontAwesomeIcon
          icon={faTrash}
          className="trash-icon"
          onClick={deleteElement}
        />
           
      </div>
      <div className="line"></div>
    </TarifaLayoutStyles>
  );
}

const TarifaLayoutStyles = styled.div`
  .trash-icon {
    font-size: 2rem;
    color: rgb(8, 166, 165);
    cursor: pointer;
  }
  .line{
    border: rgb(8, 166, 165) solid 1px;
    width: 95%;
    margin: 0 auto; 
    margin-bottom: 2rem; 
    margin-top: 2rem; 
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(133, 198, 195);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 18px;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
`;
