import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { OperationISearch } from "../../InterfacesContabilidad";
interface AgenciaElementI{
    agencia: OperationISearch
}
export default function AgenciaElement({agencia}:AgenciaElementI) {
  return (
    <AgenciaElementStyles>
            <div className="content-info">
                <div>
                    <h3>{agencia.alias}</h3>
                </div>
                <div>
                    <h3>{agencia.initial_date} - {agencia.final_date}</h3>
                </div>
                <div>
                    <h3>{agencia.proforma_total}</h3>
                </div>
            </div>
    </AgenciaElementStyles>
  )
}
const AgenciaElementStyles = styled.div`
    .content-info{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr; 
        width: 50%;
        margin: 0 auto;
        border: black solid 1px; 
        padding: 0.5rem;
        border-top: none;
    }
    .content-info div h3{
        text-align: center;
        font-weight: 300;
        font-size: 0.85vw;
    }
`