import React, { useEffect, useContext } from "react";
import { useState } from "react";
import styled from "styled-components";
import CatalogoElement from "../common/CatalogoElement";
import { catalogElement } from "../../../interfaces/interfaces";

interface catalogProps {
  catalogElements: Array<catalogElement>;
  setCatalogElements: React.Dispatch<React.SetStateAction<catalogElement[]>>;
  handleChangeArray: (dataViajeSelected: catalogElement) => void;
  deleteElementArray: (dataViajeClave: string) => void;
}

export default function CatalogoMobile({
  catalogElements,
  setCatalogElements,
  handleChangeArray,
  deleteElementArray,
}: catalogProps) {
  useEffect(()=>{
    localStorage.setItem("lastPage","0");
  },[])
  return (
    <CatalogoMobileStyles>
      <div className="catalogo-mobile-container">
        {catalogElements.length > 0 &&
          catalogElements.map((dataViaje: catalogElement) => {
            return (
              <CatalogoElement
                key={dataViaje.clave}
                catalogElement={dataViaje}
                handleChangeArray={handleChangeArray}
                deleteElementArray={deleteElementArray}
              />
            );
          })}

        <button></button>
      </div>
    </CatalogoMobileStyles>
  );
}

const CatalogoMobileStyles = styled.div`
  background-color: #f4f4f4;
  padding-top: 2rem;
  padding-bottom: 2rem !important;
  min-height: 100vh;
  .catalogo-mobile-container {
    background-color: white;
    width: 90%;
    margin: 0 auto;
    border-top-left-radius: 1.3rem;
    border-top-right-radius: 1.3rem;
    border-bottom-left-radius: 1.3rem;
    border-bottom-right-radius: 1.3rem;
    padding: 1rem;
    padding-bottom: 2.1rem;
  }
`;
