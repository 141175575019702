import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  Proforma,
  ProformaOptionalPackage,
  ProformaPrincipalPackage,
} from "./ProformaI";
import ElementProforma from "./ElementProforma";
import ElementOpcional from "../../Cotizacion/ElementOpcional";
import ElementOptional from "./ElementOptional";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";


interface OptionalPackagesI {
  proforma: Proforma;
  proformaOpcionalPackages: ProformaOptionalPackage[];
  setProformaOpcionalPackages: React.Dispatch<React.SetStateAction<ProformaOptionalPackage[]>>
}

export default function OptionalPackages({
  proforma,
  proformaOpcionalPackages,
  setProformaOpcionalPackages
}: OptionalPackagesI) {

  const { language, setLanguage } = useLanguage();

  return (
    <OptionalPackagesStyles>
      {
        proformaOpcionalPackages && proformaOpcionalPackages.length > 0 &&<div className="header-table-one">
        <div>
          <h3>{translations[language].hotels_optionals_table.title_services}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_optionals_table.city}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_optionals_table.status}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_optionals_table.arrive}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_optionals_table.departure}</h3>
        </div>
        <div>
          <h3>{translations[language].hotels_optionals_table.number_passenger}</h3>
        </div>
        <div>
          <h3>Comisión %</h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3>  </h3>
        </div>

        <div>
          <h3>{translations[language].hotels_optionals_table.prices}</h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3> </h3>
        </div>

        <div>
          <h3>TOTAL</h3>
        </div>
      </div>
      }
      
      {
        proformaOpcionalPackages.map((optionalPackage, index)=>{
            return <>
                <ElementOptional
                optionalPackage={optionalPackage}
                key = {optionalPackage.optional_quote_identifier}
                setProformaOpcionalPackages = {setProformaOpcionalPackages}
                proformaOpcionalPackages ={proformaOpcionalPackages}
                />
            </>
        })
      }
    </OptionalPackagesStyles>
  );
}

const OptionalPackagesStyles = styled.div`
     .container-principal-package {
    border: black solid 1px;
  }
  .header-table-one {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: black solid 1px;
    padding: 0.5rem;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
  }
  .header-table-one div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
`;
