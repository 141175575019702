import React from "react";
import styled from "styled-components";
import { ExpenseProviderResponse } from "../../Operaciones/InterfacesOperacion/OperacionesI";
import { formatearCantidad } from "../../../components/functionsTs/utilidades";
interface ElementoGastoI {
  expense: ExpenseProviderResponse;
}
export default function ElementGastoAux({ expense }: ElementoGastoI) {
  return (
    <ElementGastoStyles>
      <div className="element-container">
        <div className="element-data">
          <div>
            <h3>{expense.provider_name}</h3>
          </div>
          <div>
            <h3>{expense.provider_clave}</h3>
          </div>
          <div>
            {expense.entry_total_mxn != 0 ? (
              <h3>{expense.mxn_payment_date}</h3>
            ) : (
              <h3>{expense.usd_payment_date}</h3>
            )}
          </div>
          <div>
            <h3
              className="get-link"
              //    onClick={()=>{getEntradaN(6)}}
            >
              {expense.guide_payment_policy}
            </h3>
          </div>
          <div>
            {/* <Link to="/dashboard/Operaciones/Desgloce-Proveedor-Hotel">
              L
            </Link> */}

            <h3
              className="get-link"
              // onClick={()=>{getEntradaN(1)}}
            >
              {expense.Entry_no}
            </h3>
          </div>
          <div>
            <h3
              className="get-link"
              //    onClick={()=>{getEntradaN(2)}}
            >
              {expense.arrival_date}
            </h3>
          </div>
          <div>
            <h3
              className="get-link"
              //    onClick={()=>{getEntradaN(3)}}
            >
              {expense.departure_date}
            </h3>
          </div>
          <div>
            <h3
              className="get-link"
              //    onClick={()=>{getEntradaN(4)}}
            >
              ${formatearCantidad(expense.entry_total_mxn)}{" "}
            </h3>
          </div>
          <div>
            <h3>${formatearCantidad(expense.entry_total_usd)} </h3>
          </div>
        </div>
        <div className="element-buttons"></div>
      </div>
    </ElementGastoStyles>
  );
}

const ElementGastoStyles = styled.div`
  .get-link {
    cursor: pointer;
  }
  .icon {
    cursor: pointer;
  }
  .element-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .element-container {
    display: flex;
  }
  .element-data {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-right: black solid 1px;
    padding: 0.3rem;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    justify-content: center;
    align-items: center;
  }
  .element-buttons {
    width: 0%;
  }
  h3 {
    font-size: 0.8vw;
    text-align: center;
    font-weight: 300 !important;
  }
`;
