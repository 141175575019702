import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { PaymentDataGastosIC, PendingPayment, PendingPaymentGastosComprobar } from "../InterfacesContabilidad";
import { OperationContext } from "../../../../context/operationContext";

interface ElementPayGastosI{
    paymentCurrent: PendingPaymentGastosComprobar;
    paymentsGastos: PendingPaymentGastosComprobar[];
    setPaymentsGastos: React.Dispatch<React.SetStateAction<PendingPaymentGastosComprobar[]>>;
    paymentData: PaymentDataGastosIC;
}
export default function ElementPayGastos({paymentCurrent,paymentsGastos, setPaymentsGastos, paymentData}:ElementPayGastosI) {
    const { currentOperation, contabilidadMode } = useContext(OperationContext);
    const [localPayment, setLocalPayment] = useState<PendingPayment>(paymentCurrent);
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(paymentCurrent.payment_status == "Pagado"){
        return; 
      }
        setLocalPayment({
          ...localPayment,
          isSelected: event.target.checked,
        });
        const newArregloPayments:  PendingPaymentGastosComprobar[] = paymentsGastos.map(
          (currentPayment, index) => {
            const pagoActual: PendingPaymentGastosComprobar = {
              ...currentPayment,
              isSelected:
                currentPayment.id_local != paymentCurrent.id_local
                  ? currentPayment.isSelected
                  : event.target.checked,
            };
            return pagoActual;
          }
        );
        setPaymentsGastos(newArregloPayments); 
    }
  return (
    <ElementPayGastosStyles>
           <div className="element-table"
           style={{
            backgroundColor: localPayment.payment_status == "Pagado" ?  "#DBFCDB": "",
                  // display: localPayment.payment_status == "Pagado" ? 'none': '', 
           }}
           >
        <div className="check-box">
          <input
            type="checkbox"
            checked={localPayment.isSelected}
            onChange={handleCheckboxChange}
          />
        </div>
        <div>
          <h3>{"Gastos a comprobar"}</h3>
        </div>
        <div>
          <h3>{localPayment.provider_name}</h3>
        </div>
        <div>
          <h3>{localPayment.guide_name}</h3>
        </div>
        <div>
          <h3>${localPayment.advance_mxn.toFixed(2)}</h3>
        </div>
        <div>
          <h3>${localPayment.advance_usd.toFixed(2)}</h3>
        </div>
        <div>
          <h3>{contabilidadMode == 1 ? currentOperation.quote.name: paymentData.alias}</h3>
        </div>
        <div>
          <h3>{localPayment.payday_limit}</h3>
        </div>
        <div>
          <h3>{localPayment.initial_date}</h3>
        </div>
        <div>
          <h3>{localPayment.final_date}</h3>
        </div>
        
      </div>
    </ElementPayGastosStyles>
  )
}

const ElementPayGastosStyles = styled.div`
      .check-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .element-table {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: 0 auto;
    padding: 0.55rem;
  }
  .element-table div h3 {
    font-size: 0.85vw;
    text-align: center;
    font-weight: 300;
  }

`