import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import { QuoteItemI } from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import IndividualService from "./IndividualService";
import TransporteServiceOpt from "./TransporteServiceOpt";

interface IndividualTransporteI{
  servicioDayTransporte :servicioDayElementI
  checkboxAll: boolean,
    setCheckBoxAll: React.Dispatch<React.SetStateAction<boolean>>
}



export default function IndividualTransporteOpt({servicioDayTransporte, checkboxAll, setCheckBoxAll}:IndividualTransporteI) {

  return (
    <IndividualTransporteStyle>
     {
        servicioDayTransporte.serviciosIndividuales.map((servicioTransporte, index)=>{
           return <TransporteServiceOpt
           servicioTransporte = {servicioTransporte}
           servicioDayTransporte = {servicioDayTransporte}
           checkboxAll = {checkboxAll}
                 setCheckBoxAll = {setCheckBoxAll}
           />
        })
     }
    </IndividualTransporteStyle>
  );
}

const IndividualTransporteStyle = styled.div`
  .header-table-content {
    border: black solid 1px;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    align-items: center;
  }
  h3 {
    color: black !important;
  }
  .combo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
