import React, { createContext, useContext, useEffect, useState } from "react";
import { AppContext } from "./appContext";
import { CatalogsData,Country, City, Neighborhood, Zipcode, Currency, Season, RegistrationType, ProviderType, ServiceType, View, Bank, CatalogsContextType, CatalogsContextTypeDos } from "../interfaces/GeneralTypes";
import { CatalogsDataDos,QuoteType, Series } from "../interfaces/GeneralTypes";

const CatalogsContextDos = createContext<CatalogsContextTypeDos>({
  catalogsDataDos: {
    quote_types_list: [],
  series_list: [],
  general_services_list: [],
  expenses_category_list: [],
  quote_status_list: [],
  quote_search_parameter_list: [],
  service_type_list: []
  },
});
interface AppProviderProps {
    children: React.ReactNode;
  }

const CatalogsProviderDos: React.FC<AppProviderProps> = ({ children }) => {
    const { showLoader, setShowLoader } = useContext(AppContext);
  const [catalogsDataDos, setCatalogsDataDos] = useState<CatalogsDataDos>({
    quote_types_list: [],
    series_list: [],
    general_services_list: [],
    expenses_category_list: [],
    quote_status_list: [] ,
    quote_search_parameter_list: [],
    service_type_list: []
  });
    
  useEffect(() => {
    setShowLoader(true);
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/QuoteCatalogs`)
      .then((response) => response.json())
      .then((data) => {
        console.log('Tatayendo datos de la api');
        console.log(data.data);
        setCatalogsDataDos(data.data)
        setShowLoader(false); 
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <CatalogsContextDos.Provider value={{ catalogsDataDos }}>
      {children}
    </CatalogsContextDos.Provider>
  );
};

const useCatalogsDos = () => useContext(CatalogsContextDos);

export { CatalogsProviderDos, useCatalogsDos };