import { Fragment } from "react";
import styled from 'styled-components'
import GrlLabel from "../labels/GrlLabel";

type BtnProps = {
    text: string;
    btnAlign: string;
}
function StyledButton(props: BtnProps) {

    const PrincipalContainer = styled.div`
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: ${props.btnAlign};
        align-items: center;
        `;

    return (
        <Fragment>
            <PrincipalContainer>
                <Button>{props.text}</Button>
            </PrincipalContainer>
        </Fragment>
    );
}

export default StyledButton;

const Button = styled.button`
    width: 300px;
    background-color: #08A6A5;
    color: white;
    font-weight: bold;
    border: none;
    padding: 8px 30px;
    cursor: pointer;
    border-radius: 7px;
    margin-bottom: 20px;
`;