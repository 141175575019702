import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";



export default function AlertMessage() {
  const { showAlert, setShowAlert } = useContext(AppContext);
  const {titleAlert, setTitleAlert} = useContext(AppContext); 
  const {contentAlert, setContentAlert} = useContext(AppContext);
 
  const handleClick = () => {
    setShowAlert(false);
  };

   
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.keyCode === 27) {
        setShowAlert(false);
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  return (
    <AlertMessageStyle>
      <div className="card-message-container">
        <div className="card-box-x">
          <FontAwesomeIcon
            icon={faXmark}
            className="x-mark"
            onClick={() => handleClick()}
          />
        </div>
        <div className="card-box-text">
          <h4>{titleAlert}</h4>
          <br />
          <h5>{contentAlert}</h5>
        </div>
      </div>
    </AlertMessageStyle>
  );
}

const AlertMessageStyle = styled.div`
  border: black solid 3px;
    position: fixed; 
    top: 0; 
    max-height: 100vh;
    overflow-y: hidden; 
    

    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center; 
    z-index: 10;
    -webkit-box-shadow: 1px 3px 11px -3px rgba(0,0,0,0.47); 
        box-shadow: 1px 3px 11px -3px rgba(0,0,0,0.47);
    .card-message-container{
        border: white solid 1px;
        width: 300px; 
        height: 200px; 
        background-color: white; 
        border-radius: 0.8rem;
    }
    .card-box-x{
        margin: 0rem 0.5rem 0.5rem 0rem; 
        display: flex;
        justify-content: flex-end;

    }
    .card-box-text{
        width: 90%;
        margin: 0 auto;
    }
    .card-box-text h4{
        font-size: 1.1rem;
        color: #08a6a5;
    }
    .card-box-text h5{
        font-size: 0.9rem;

    }
    .x-mark{
        font-size: 2rem; 
        cursor: pointer;
    }
  

    @media screen and (min-width: 900px){
        .card-message-container{
            border: black solid 1px; 
            width: 500px;
            height: 260px;
            padding-top: 1rem;
            padding-right: 0.3rem;
        }
    }

`;
