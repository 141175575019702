import React, { useState } from "react";
import styled from "styled-components";
import ReembolsoDiaContent from "./ReembolsoDiaContent";
import { MonthlyData, RefundObjectDelete } from "../InterfacesContabilidad";
import FormReembolso from "./FormReembolso";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDown,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
interface ReembolsoElementI {
  month: MonthlyData;
  getInitialData: () => Promise<void>;
  modeSwitch: number;
  setListResults: React.Dispatch<React.SetStateAction<MonthlyData[]>>;
  listResults: MonthlyData[] ;
  listForDelete: RefundObjectDelete[];
  setListForDelete: React.Dispatch<React.SetStateAction<RefundObjectDelete[]>>;
  deleteMode: boolean
}
export default function ReembolsoMesContent({
  month,
  getInitialData,
  modeSwitch,
  setListResults,
  listResults,
  listForDelete,
  setListForDelete,
  deleteMode
}: ReembolsoElementI) {
  const [isShowingDays, setIsShowingDays] = useState<boolean>(false);
  return (
    <ReembolsoElementStyle>
      {/* Aquí colocaras el arreglo que reenderizara cada día del mes */}
      {modeSwitch == 1 && (
        <div className="header-mes">
          <div>
            <h3>{month.month}</h3>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div className="flecha-box">
            {month.items.length >  0 && (
              <FontAwesomeIcon
                icon={faAngleDown}
                className="flecha"
                onClick={() => {
                  setIsShowingDays(!isShowingDays);
                }}
              />
            )}
          </div>
        </div>
      )}
      {
        modeSwitch == 2 &&      <div className="header-mes header-mes-dos">
        <div>
          <h3>{month.month}</h3>
        </div>
    
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div className="flecha-box">
          {month.items.length > 0 && (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="flecha"
              onClick={() => {
                setIsShowingDays(!isShowingDays);
              }}
            />
          )}
        </div>
      </div>
      }
 
      {isShowingDays &&
        month.items.map((currentDay, index) => {
          return (
            <ReembolsoDiaContent
              key={currentDay.refund_identifier}
              currentDay={currentDay}
              getInitialData={getInitialData}
              modeSwitch = {modeSwitch}
              setListResults = {setListResults}
              listResults= {listResults}
              listForDelete = {listForDelete}
              setListForDelete = {setListForDelete} 
              deleteMode={deleteMode}
            />
          );
        })}
    </ReembolsoElementStyle>
  );
}

const ReembolsoElementStyle = styled.div`
  .flecha {
    font-size: 1.5rem;
    color: #d86e3a;
    cursor: pointer;
  }
  .flecha-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-mes {
    border: black solid 1px;
    width: 140%;
    margin: 0 auto;
    background-color: #f7dcc4;
    padding: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .header-mes div {
  }
  .header-mes div h3 {
    font-size: 1vw;
  }
  .header-mes-dos {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;
