import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";
import { BankAccountI, Proforma } from "./ProformaI";
interface CardPaymentI {
  bankAccountList: BankAccountI[];
  proforma: Proforma;
  setProforma: React.Dispatch<React.SetStateAction<Proforma>>
}

export default function CardPayment({ bankAccountList , proforma, setProforma}: CardPaymentI) {
  const [listAccounts, setListAccounts] = useState([]);
  const { language, setLanguage } = useLanguage();
  const [currentPaymentData, setCurrentPaymentData] = useState<BankAccountI>({
    bank_account_identifier: proforma.bank_account_identifier,
    beneficiary: "",
    address_1: "",
    address_2: "",
    bank: "",
    clabe: "",
    account_number: "",
    iban: "",
    aba: "",
    branch: "",
    swift_code_1: "",
    swift_code_2: "",
    reference: "",
    sucursal: "",
    corresponding_bank: "",
  });
  useEffect(() => {
    console.log("Card payment from child");
    console.log(bankAccountList);
  }, [bankAccountList]);
  const handleChooseAccount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const bankAccountIdentifierSelected: number = parseInt(e.target.value);
    const cardSelected: BankAccountI | undefined = bankAccountList.find(
      (bankAccount, index) =>
        bankAccount.bank_account_identifier == bankAccountIdentifierSelected
    );
    if (cardSelected) {
      setCurrentPaymentData(cardSelected);
    }
    setProforma({
        ...proforma,
        bank_account_identifier: bankAccountIdentifierSelected
    })
  };

  const [firstTimeChange, setFirstTimeChange] = useState(true);

  useEffect(()=>{
    if (proforma.bank_account_identifier !== 0 && firstTimeChange) {
        const bankAccountIdentifierSelected: number = proforma.bank_account_identifier;
        const cardSelected: BankAccountI | undefined = bankAccountList.find(
          (bankAccount, index) =>
            bankAccount.bank_account_identifier == bankAccountIdentifierSelected
        );
        if (cardSelected) {
          setCurrentPaymentData(cardSelected);
        }
    }
 
   
  },[proforma.bank_account_identifier, firstTimeChange])
  return (
    <CardPaymentStyles>
      <div className="card-component">
        <SelectListdefaultDos
          style={{
            width: "80%",
            maxWidth: "80%",
          }}
          id="sl6"
          onChange={handleChooseAccount}
          //   value={bodySearch    .idUser || ""}
        >
          <option value="" disabled selected>
            {translations[language].payment_information_card.title_combobox}
          </option>

          {bankAccountList?.length > 0
            ? bankAccountList.map((item) => {
                return (
                  <>
                    <option
                      key={item.bank_account_identifier}
                      value={item.bank_account_identifier}
                      selected={item.bank_account_identifier == currentPaymentData.bank_account_identifier}
                    >
                      {item.bank}
                    </option>
                  </>
                );
              })
            : null}
        </SelectListdefaultDos>
        <br />
        <div className="text-card">
          <h3>DOLARES</h3>
          <h3>{translations[language].payment_information_card.name + " : "+currentPaymentData.beneficiary}</h3>
          <h3>{translations[language].payment_information_card.bank_name +  " : "+currentPaymentData.bank} </h3>
          <h3>{translations[language].payment_information_card.branch + " : "+currentPaymentData.branch} </h3>
          <h3>{translations[language].payment_information_card.account+" : "+currentPaymentData.account_number} </h3>
          <h3>ABA: {currentPaymentData.aba}</h3>
          <h3>CLABE: {currentPaymentData.clabe}</h3>
          <h3>SWIFT: {currentPaymentData.swift_code_1}</h3>
          <h3>
            {" "}
            {
              translations[language].payment_information_card
                .intermediary_bank_usa
            }{" "}
            BANCO
          </h3>
          <h3>SWIFT: {currentPaymentData.swift_code_2}</h3>
        </div>
      </div>
    </CardPaymentStyles>
  );
}

const CardPaymentStyles = styled.div`
  width: 40%;
  .card-component {
    border: black solid 1px;
    padding: 0.8rem;
  }
  @media screen and (max-width: 900px) {
    width: 60%;
  }
  margin-bottom: 2rem;
  .text-card {
    
  }
  .text-card h3 {
    font-size: 0.85vw;
    margin: 0.5rem;
  }
`;

const SelectListdefaultDos = styled.select`
  width: 100% !important;
  max-width: 100% !important;
  border: 2px solid rgb(8, 166, 165);
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  max-height: 46.6px !important;
  min-height: 46.6px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  ///////////////////////////////////
`;
