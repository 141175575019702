import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContabilidadSearch from "./ContabilidadSearch";
import ContabilidadSearchDos from "./ContabilidadSearchDos";
import DefinicionPrestadoresCont from "./Components/DefinicionPrestadoresCont";
import SavePago from "./SavePago";
import SavePagoGastos from "./SavePagoGastos";
import Reembolsos from "./Reembolsos/Reembolsos";
import Auxiliares from "./Auxiliares/Auxiliares";
import EstadosCuenta from "./EstadosCuenta/EstadosCuenta";

export default function ManageContabilidad() {
  return (
    <ManageContabilidadStyles>
      <Routes>
        <Route
          path="Manage-Contabilidad"
          element={<ContabilidadSearch />}
        ></Route>
        <Route
          path="Contabilidad-Busqueda"
          element={<ContabilidadSearchDos />}
        ></Route>
        <Route
          path="Contabilidad-Prestadores"
          element={<>
    
          <DefinicionPrestadoresCont/>
          </>}
        ></Route>
         <Route
          path="Contabilidad-Pago"
          element={<>
    
          <SavePago/>
          </>}
        ></Route>
          <Route
          path="Contabilidad-Pago-Gastos-Comprobar"
          element={<>
    
          <SavePagoGastos/>
          </>}
        ></Route>
          <Route
          path="Contabilidad-Reembolsos"
          element={<>
    
          <Reembolsos/>
          </>}
        ></Route>
            <Route
          path="Contabilidad-Auxiliares"
          element={<>
    
          <Auxiliares/>
          </>}
        ></Route>
            <Route
          path="Contabilidad-EstadosCuenta"
          element={<>
          <EstadosCuenta/>
      
          </>}
        ></Route>
      </Routes>
    </ManageContabilidadStyles>
  );
}

const ManageContabilidadStyles = styled.div``;
