import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import {
  EntryAdvance,
  EntryData,
  EntryService,
  ProviderInterfaceOP,
  QuoteItemI,
} from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import deleteImage from "../../../../images/deletelogo.png";
import agregarImage from "../../../../images/AGREGAR.png";
import { Link } from "react-router-dom";
import HeaderInfo from "../HeaderInfo";
import edit from "../../../../images/edit.png";
import DesgloceHotel from "./DesgloceHotel";
import { OperationContext } from "../../../../context/operationContext";
import ElementDesgloce from "../Components/ElementDesgloce";
import { formatearCantidad } from "../../../components/functionsTs/utilidades";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";

interface DesgloceDolaresI {
  isEditionMode: boolean;
  setIsEditionMode: React.Dispatch<React.SetStateAction<boolean>>;
  tableTitle: string;
  cantidad1: string;
  cantidad2: string;
}

export default function DesgloceDolares({
  isEditionMode,
  setIsEditionMode,
  tableTitle,
  cantidad1,
  cantidad2,
}: DesgloceDolaresI) {
  ////currentEntrada guarda los detalles de una entrada en especificco

  const {
    currentEntrada,
    providerTypeOperacion,
    setCurrentEntrada,
    setProviderTypeOperacion,
  } = useContext(OperationContext);
  /////ANTICIPO SETTINGS
  const [anticipoInicial, setAnticipoInicial] = useState<number>(
    currentEntrada.entry_data.advance_usd
  );
  const [pagoSinAnticipoInicial, setPagoSinAnticipoInicial] = useState<number>(
    currentEntrada.entry_data.total_without_advance_usd
  );

  const [listServicesRestaurant, setListServicesRestaurant] = useState<
    EntryService[]
  >(currentEntrada.entry_services_list.usd_services_list);

  const [entryData, setEntryData] = useState<EntryData>(
    currentEntrada.entry_data
  );

  /////ANTICIPO SETTINGS
  useEffect(() => {
    if (currentEntrada.entry_data.entry_number == 1) {
      setAnticipoInicial(0);
    }
  }, []);
  useEffect(() => {
    setEntryData(currentEntrada.entry_data);
  }, [currentEntrada.entry_data]);

  useEffect(() => {
    setCurrentEntrada({
      ...currentEntrada,
      entry_data: entryData,
      entry_services_list: {
        ...currentEntrada.entry_services_list,
        usd_services_list: listServicesRestaurant,
      },
    });

    console.log(
      "-----------------------------------ACtualizando contexto------------------------------------------------"
    );
    console.log({
      ...currentEntrada,
      entry_data: entryData,
      entry_services_list: {
        ...currentEntrada.entry_services_list,
        usd_services_list: listServicesRestaurant,
      },
    });
  }, [listServicesRestaurant, entryData]);
  ///////////CAMBIAR ESTO EN componente PESOS.
  useEffect(() => {
    console.log("CONTEXTO CAMBIO");
    console.log(currentEntrada);
    setListServicesRestaurant(
      currentEntrada.entry_services_list.usd_services_list
    );
  }, [currentEntrada.entry_services_list.usd_services_list]);

  const totalTarifa = listServicesRestaurant.reduce((total, item) => {
    return total + item.quantity1 * item.quantity2 * item.unitary_fee;
  }, 0);

  const subtotalServicio = listServicesRestaurant.reduce((total, item) => {
    return (
      total +
      item.quantity1 *
        item.quantity2 *
        item.unitary_fee *
        ((100 - (item.iva + item.taxes)) / 100)
    );
  }, 0);

  const precioIva = listServicesRestaurant.reduce((total, item) => {
    return (
      total +
      item.quantity1 * item.quantity2 * item.unitary_fee * (item.iva / 100)
    );
  }, 0);

  const precioPropinas = listServicesRestaurant.reduce((total, item) => {
    return (
      total +
      item.quantity1 * item.quantity2 * item.unitary_fee * (item.taxes / 100)
    );
  }, 0);

  const totalFinal = subtotalServicio + precioIva + precioPropinas;
  const precioSinAnticipo = totalFinal - entryData.advance_usd;

  // useEffect(()=>{
  //   console.log("ESTAS AQUIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII")
  //   console.log("Cambio anticipo:");
  //   console.log(entryData.advance_usd);
  //   if(entryData.total_without_advance_usd == 0){
  //     setEntryData({
  //       ...entryData,
  //       total_without_advance_usd: totalFinal

  //     })
  //   }

  // },[])
  const [entryAdvancesServices, setEntryAdvancesServices] = useState<
    EntryAdvance[]
  >([]);
  useEffect(() => {
    if (currentEntrada.entry_advances) {
      const arrayToSave = currentEntrada.entry_advances.filter(
        (advance, index) => {
          return advance.currency_identifier == 1;
          //  && advance.entry_number <= entryData.entry_number
        }
      );
      setEntryAdvancesServices(arrayToSave);
    }
  }, [currentEntrada.entry_advances]);

  useEffect(() => {
    if (entryAdvancesServices) {
      if (entryAdvancesServices.length > 0) {
        const nuevoArreglo: EntryAdvance[] = entryAdvancesServices.map(
          (adelanto, index) => {
            return entryData.entry_number != adelanto.entry_number &&
              adelanto.currency_identifier == 1
              ? adelanto
              : {
                  ...adelanto,
                  advance: entryData.advance_usd,
                };
          }
        );
        console.log("REsultado del cambiio");
        console.log(nuevoArreglo);
        setEntryAdvancesServices(nuevoArreglo);
      }
    }
  }, [entryData.advance_usd]);
  const totalAvances = entryAdvancesServices
    .filter((advance, index) => advance.entry_number <= entryData.entry_number)
    .reduce((total, item) => item.advance + total, 0);

  return (
    <DesgloceGuiaStyle>
      <h3 className="title-tabla dolar-title">{tableTitle} (USD)</h3>

      <div className="header-hotel">
        <div className="title">
          <h3>Nombre</h3>
        </div>
        <div className="title">
          <h3>Vig. Inicial</h3>
        </div>
        <div className="title">
          <h3>Vig Final</h3>
        </div>

        <div className="title">
          <h3>{cantidad1}</h3>
        </div>

        <div className="title">
          <h3>{cantidad2}</h3>
        </div>

        <div className="title">
          <h3>IVA</h3>
        </div>

        <div className="title title-final">
          <h3>{tableTitle == "Habitación" ? "Servicios" : "Impuestos"}</h3>
        </div>

        <div className="title">
          <h3>Tarifa Unitario</h3>
        </div>

        <div className="title">
          <h3>Tarifa Total</h3>
        </div>

        <div className="title">
          <h3>Moneda</h3>
        </div>
      </div>
      <div className="titulo">
        <h3>{tableTitle}</h3>
      </div>
      {/*  */}
      {listServicesRestaurant.map((currentService, index) => {
        return (
          <>
            <ElementDesgloce
              key={currentService.entry_service_identifier}
              isEditionMode={isEditionMode}
              currentService={currentService}
              listServices={listServicesRestaurant}
              setListServices={setListServicesRestaurant}
            />
          </>
        );
      })}
      {/* RESUMEN DE RESULTADOS------- */}
      <div className="space title-precio">
        <h3>
          Total en{" "}
          {currentEntrada.entry_services_list.usd_services_list.length > 0 &&
            (currentEntrada.entry_services_list.usd_services_list[0]
              .currency_identifier == 1
              ? "Dolares"
              : "Pesos")}{" "}
          ${formatearCantidad(totalTarifa)}
        </h3>
      </div>
      <div className="container-prices-box">
        <div>
          <h3>Subtotal del servicio</h3>
        </div>
        <div>
          <h3>Impuestos</h3>
        </div>
        <div>
          <h3>IVA</h3>
        </div>
        <div>
          <h3>Total</h3>
        </div>
        <div>
          <h3>Anticipo</h3>
        </div>
        <div>
          <h3>Total sin anticipo</h3>
        </div>
      </div>
      <div className="conatiner-data">
        <div>
          <h3>${formatearCantidad(subtotalServicio)}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(precioPropinas)}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(precioIva)}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(totalFinal)}</h3>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isEditionMode ? (
            <>
              <NumericFormat
                prefix="$"
                style={{
                  outline: "none",

                  WebkitTapHighlightColor: "transparent", // Para navegadores Webkit (como Chrome y Safari)
                  textAlign: "center",
                  width: "70%",
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  const precio = convertirStringANumero(value);
                  console.log("Valor convertido:");
                  console.log(precio);
                  if (isNaN(precio)) {
                    /////ANTICIPO SETTINGS
                    setEntryData({
                      ...entryData,
                      advance_usd: 0,
                      total_without_advance_usd: totalFinal,
                    });
                  } else {
                    const parsedValue = precio;
                    if (!isNaN(parsedValue)) {
                      /////ANTICIPO SETTINGS
                      setEntryData({
                        ...entryData,
                        advance_usd: parsedValue,
                        total_without_advance_usd:
                          entryData.entry_number === 1
                            ? totalFinal - parsedValue
                            : pagoSinAnticipoInicial - parsedValue,
                      });
                    }
                  }
                }}
                value={entryData.advance_usd}
              />
              {/* <input
                style={{
                  textAlign: "center",
                  width: "70%",
                }}
                type="number" // Cambiado a type="number"
                inputMode="decimal"
                placeholder="Tarifa"
                value={entryData.advance_usd}
                className="input-style-data"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "") {
                    /////ANTICIPO SETTINGS
                    setEntryData({
                      ...entryData,
                      advance_usd: 0,
                      total_without_advance_usd: totalFinal,
                    });
                  } else {
                    const parsedValue = parseFloat(value);
                    if (!isNaN(parsedValue)) {
                      /////ANTICIPO SETTINGS
                      setEntryData({
                        ...entryData,
                        advance_usd: parsedValue,
                        total_without_advance_usd:
                          entryData.entry_number === 1
                            ? totalFinal - parsedValue
                            : pagoSinAnticipoInicial - parsedValue,
                      });
                    }
                  }
                }}
              /> */}
            </>
          ) : (
            <h3>{entryData.advance_usd}</h3>
          )}
        </div>
        <div>
          <h3> ${formatearCantidad(totalFinal - totalAvances)}</h3>
        </div>
      </div>
      <br />
      <div className="tables-resume-entradas">
        <div>
          <div className="table-one">
            <div>
              <h3>No de Anticipo</h3>
            </div>
            <div>
              <h3>1</h3>
            </div>
            <div>
              <h3>2</h3>
            </div>
            <div>
              <h3>3</h3>
            </div>
            <div>
              <h3>4</h3>
            </div>
            <div>
              <h3>5</h3>
            </div>
            <div>
              <h3>6</h3>
            </div>
          </div>
          <div className="table-one table-one-content">
            <div>
              <h3 className="resume-total">Total a pagar</h3>
            </div>
            {entryAdvancesServices &&
              entryAdvancesServices.length > 0 &&
              entryAdvancesServices
                .filter((entrada, index) => {
                  return entrada.currency_identifier == 1;
                })
                .map((entrada, index) => {
                  return (
                    <>
                      <div
                        style={{
                          backgroundColor:
                            entrada.entry_number == entryData.entry_number
                              ? "rgb(237, 244, 243)"
                              : "",
                        }}
                      >
                        <h3
                          style={{
                            color:
                              entrada.entry_number > entryData.entry_number
                                ? "grey"
                                : "",
                            fontWeight:
                              entrada.entry_number > entryData.entry_number
                                ? "600"
                                : "",
                          }}
                        >
                          {entrada.advance}
                        </h3>
                      </div>
                    </>
                  );
                })}
          </div>
          <div>
            <div></div>
          </div>
        </div>

        <div className="table-two">
          <div className="first-row">
            <h3>
              Total a pagar{" "}
              {totalFinal - entryData.advance_usd == totalFinal
                ? "(Liquidación)"
                : "(Anticipo)"}
            </h3>
          </div>
          <div className="second-row">
            <h3 className="dolar-title">
              $
              {totalFinal - entryData.advance_usd == totalFinal
                ? formatearCantidad(totalFinal - totalAvances)
                : entryAdvancesServices.find(
                    (servicio) =>
                      servicio.entry_number == entryData.entry_number &&
                      servicio.currency_identifier == 1
                  )?.advance}{" "}
              USD
            </h3>
          </div>
        </div>
      </div>
      {/*-----------------------------------------FIN DE RESUMEN DE RESULTADOS------- */}

      {/* FIN DE COMPONENTE */}
    </DesgloceGuiaStyle>
  );
}

const DesgloceGuiaStyle = styled.div`
  .dolar-title {
    font-weight: 600;
  }
  .input-style-data {
    max-width: 100%;
  }

  .input-style-data::-webkit-inner-spin-button,
  .input-style-data::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /////////////////NUEVO CSS
  .second-row h3 {
    color: black !important;
  }
  .table-two {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .table-two div h3 {
    text-align: center;
    font-size: 0.85vw;
    color: white;
  }
  .table-two div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first-row {
    border-bottom: black solid 1px;
    background-color: #3498db;
  }
  .tables-resume-entradas {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap: 1rem;
    margin-bottom: 1rem;
  }
  .table-one {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
    background-color: rgb(52,152,219, 0.1);
  }
  .table-one-content {
    background-color: white;
    border-top: none;
    padding: 0;
  }
  .table-one-content div {
    padding: 0.5rem;
  }
  .table-one div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
  .table-two {
    border: black solid 1px;
  }
  .conatiner-data {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
  }
  .conatiner-data div h3 {
    font-size: 0.85vw;
    text-align: center;
    font-weight: 600;
  }
  .container-prices-box {
    border: black solid 1px;
    border-bottom: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
    background-color: rgb(52,152,219, 0.1);
  }
  .container-prices-box div h3 {
    font-size: 0.85vw;
    text-align: center;
    font-weight: 600;
  }
  .title-precio {
    padding: 0.5rem;
  }
  .title-precio h3 {
    font-size: 0.9vw;
    text-align: right;
    padding-right: 1rem;
  }

  .space {
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-top: black solid 1px;
    height: 2rem;
  }
  .header-hotel {
    background-color: #3498db;
  }
  .header-hotel div h3 {
    color: white;
  }
  ///////////FIN DEL NUEVO CSSS
  /////////////////////
  .container-hotel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    border-left: black solid 1px;

    border-top: black solid 1px;
  }
  .container-hotel div {
    padding: 0.8rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
  .title-tabla {
    font-size: 0.85vw;
    margin-bottom: 0.6rem;
  }

  .container-hotel div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
  .container-hotel div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }
  ////////////////////
  .titulo {
    border-left: black solid 1px;
    border-right: black solid 1px;
    padding: 0.5rem;
  }
  .titulo h3 {
    font-size: 0.85vw;
  }
  .header-hotel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: black solid 1px;
    border-left: black solid 1px;

    border-top: black solid 1px;
  }
  .header-hotel div {
    padding: 0.4rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
  .header-hotel div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
  .header-hotel div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }

  ///////////////////////////

  .price-one {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    border-left: black solid 1px;

    border-top: black solid 1px;
  }
  .price-one div {
    padding: 0.8rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
  .price-one div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
  .price-one div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }
  .hidden-boder {
    border: transparent solid 1px !important ;
  }
  .total-pesos {
    border-left: black solid 1px;
  }
  .last-file {
    border-bottom: black solid 1px;
  }
  @media print {
    .container-hotel div {
      padding: 0.4rem;
    }
    .title h3 {
      font-size: 0.2vw;
    }
    .title-tabla {
      margin: 0.4rem 0;
      font-size: 1.3vw;
    }
    .header-hotel {
      background-color: white !important;
    }
    .header-hotel div h3 {
      color: black !important;
    }
    .first-row h3 {
      color: black !important;
    }
    .resume-total {
      color: black;
    }
  }
`;

function convertirStringANumero(str: string): number {
  // Eliminar caracteres no numéricos excepto el punto decimal
  const numeroLimpio = str.replace(/[^0-9.-]/g, "");

  // Parsear el string a número
  const numero = parseFloat(numeroLimpio);

  return numero;
}
