import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";
import { ProformaAdvance } from "./ProformaI";
interface ElementLimitPayment{
    anticipo: ProformaAdvance
}
export default function ElementLimitPayment({anticipo}:ElementLimitPayment) {
  return (
    <div className="limit-payment-element">
      <div>
        <h3>{anticipo.payday_limit}</h3>
      </div>
      <div>
        <h3>{anticipo.total}</h3>
      </div>
    </div>
  );
}

const LimitPaymentStyles = styled.div``;
