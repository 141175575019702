import React, {
  ChangeEvent,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import RowCotizacion from "./Components/RowCotizacion";
import HeaderInfo from "./HeaderInfo";
import ListServices from "./ListServices";
import { useNavigate } from "react-router-dom";
import {
  OptionalQuote,
  QuoteDetail,
  QuoteElementI,
} from "../Cotizacion/interfaces/CotizacionI";
import {
  feeChargesI,
  generalsElementsI,
  personalInfoI,
  servicioDayElementI,
  servicioIndividual,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import { v4 as uuidv4 } from "uuid";
import {
  CommentI,
  QuoteGeneralElement,
} from "../../../interfaces/GeneralTypes";
import {
  GenerarCotizacionI,
  QuoteListElementI,
} from "./InterfacesOperacion/OperacionesI";
import { Link } from "react-router-dom";

export default function CotizacionDetails() {
  const {
    dataCotizacionContext,
    setDataCotizacionContext,
    setShowLoader,
    setCotizacionMode,
    currentUser,
    setCurrentUser,
    setListOpcionalesContext
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [listHistorialQuotes, setListHistorialQuotes] = useState<
    QuoteListElementI[]
  >([]);
  const { showAlert, setShowAlert } = useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const { titleAlert, setTitleAlert, idOperation, setIdOperation } =
    useContext(AppContext);

  const [quotationSelected, setQuotationSelected] = useState<number>(
    parseInt(dataCotizacionContext.personalInfo.idCotizacion)
  );

  const getSubListQuotes = async () => {
    if (!dataCotizacionContext.personalInfo.idCotizacion) {
      return;
    }
    const elementSelected = listHistorialQuotes.filter(
      (currentQuote, index) => {
        return (
          currentQuote.identifier ==
          parseInt(dataCotizacionContext.personalInfo.idCotizacion)
        );
      }
    );
    if (elementSelected.length > 0) {
      if (elementSelected[0].version !== 1) {
        return;
      }
    }

    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    setShowLoader(true);
    const urlModified =
      backEndPoint +
      `/operations/getSecondaryQuotes/${dataCotizacionContext.personalInfo.idCotizacion}`;

    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data = await response.json();

        console.log(
          "-------------------------------------------LISTA DE COTIZACIONES:::::"
        );
        console.log(data);
        setListHistorialQuotes(data.data.quotes);
        setShowLoader(false);
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al realizar la solicitud!:");
        // setContentAlert(
        //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        // );
        // setShowAlert(true);
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      //   alert("ERROR AL REALIZAR LA SOLICITUD");

      //   setTitleAlert("Error al realizar la solicitud:");
      //   setContentAlert(
      //     "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      //   );
      setShowLoader(false);
    }
  };

  useEffect(() => {
    console.log("Recuperando lista de cotización."); 
    getSubListQuotes();
  }, [dataCotizacionContext.personalInfo.idCotizacion]);
  useEffect(()=>{
    getSubListQuotes(); 
  },[])

  useEffect(() => {
    console.log("Asi quedo listHistorialQuotes:::::____________---");
    console.log(listHistorialQuotes);
  }, [listHistorialQuotes]);

  const duplicateQuote = async () => {
    const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/duplicateQuote`;

    const bodySearch = {
      quote_identifier: dataCotizacionContext.personalInfo.idCotizacion,
    };

    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodySearch),
      });

      if (response.ok) {
        const data = await response.json();

        console.log("Cotización original");
        console.log(dataCotizacionContext.personalInfo.idCotizacion);
        console.log("Copia creada con éxito");
        const idCotizacionDuplicada = data.data.quote_identifier;
        console.log(data.data.quote_identifier);
        ///////////////////////////////////OBTENER DATOS DE COTIZACION
        const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

        const urlModified =
          backEndPoint + `/quote/QuoteDetail/${idCotizacionDuplicada}`;

        try {
          const response = await fetch(urlModified, {
            method: "GET", // Cambio del método POST a GET
            headers: {
              "Content-Type": "application/json",
            },
            // Eliminar el cuerpo ya que las peticiones GET no llevan body
          });

          if (response.ok) {
            ////RESPUESTA CORRECTA
            console.log(response);
            console.log("bien");
            const data = await response.json();

            console.log("VALOR DE LA CONSULTA:");
            console.log(data.data);
            const quoteResponse: QuoteDetail = data.data.quote_detail;
            console.log("RESPUESTA DEL SERVIDOR PARAMETRIZADA EN INTERFAZ");
            console.log(quoteResponse);

            const personalInfoInicial: personalInfoI = {
              name: quoteResponse.quote.name,
              idCotizacion: quoteResponse.quote.quote_identifier.toString(),
              typeCotizacion: quoteResponse.quote.type,
              typeAgency: 1,
              serieId: quoteResponse.quote.serie_identifier,
              includesSerie: true,
              pax: quoteResponse.quote.PAX,
              min: quoteResponse.quote.MIN,
              max: quoteResponse.quote.MAX,
              TL: quoteResponse.quote.TL,
              arrivingData: quoteResponse.quote.arrival_date,
              departureDate: quoteResponse.quote.departure_date,
              agencia: quoteResponse.quote.agency,
              typeRoom: 2,
              numberDaysOpenQuote: quoteResponse.quote.number_of_days,
              modeCotizacion: quoteResponse.quote.serie ? "Serie" : "Normal",
              selectedSerieOption: "No",
              serieSelected: 0,
              isShowingDates: false,
              dateStartOpen: "",
              dateEndOpen: "",
              status_identifier: quoteResponse.quote.status_identifier,
              quote_identifier: quoteResponse.quote.quote_identifier,
              operation_identifier: quoteResponse.quote.operation_identifier ?? 0
            };

            ///////////////////Recuperando servicios individuales

            const serviciosDaysElementsInicial: serviciosDaysElementsI = {
              servicioDayElement: quoteResponse.quote_individual_services.map(
                (individualCurrentService, index) => {
                  let identifierDayUID = uuidv4();
                  const serviciosIndividualesArray: servicioIndividual[] =
                    individualCurrentService.services.map(
                      (serviceDayy, index) => {
                        let identifierDayUIDK = uuidv4();

                        const individualServicio: servicioIndividual = {
                          category: serviceDayy.category,
                          lendertype: serviceDayy.lender_type_identifier,
                          lenderDescripcion: serviceDayy.lender_type,
                          ocupacion: serviceDayy.occupation,
                          ocupacionDescripcion: serviceDayy.occupation,
                          providerType: serviceDayy.provider_identifier,
                          providerDescripcion: serviceDayy.provider_name,
                          serviceType: serviceDayy.service_type_identifier,
                          serviceDescripcion: serviceDayy.service_name,
                          tarifa: serviceDayy.fee,
                          identifierService: identifierDayUIDK,
                          typeRoom:
                            serviceDayy.occupation == "SGL"
                              ? 0
                              : serviceDayy.occupation == "DBL"
                              ? 1
                              : serviceDayy.occupation == "TPL"
                              ? 2
                              : 3,
                          dateRequired: individualCurrentService.service_date,
                          currencyName: quoteResponse.quote.currency,
                          currencyId: quoteResponse.quote.currency_identifier,
                          tarifaSingleComplementConverted:
                            serviceDayy.single_complement,
                          tarifaTripleSuplementConverted:
                            serviceDayy.triple_complement,
                          status_identifier: serviceDayy.status_identifier,
                          individual_service_identifier:
                            serviceDayy.individual_service_identifier,
                          number_of_services: 0,
                          service_name_identifier:
                            serviceDayy.service_identifier,
                          service_name: serviceDayy.service_name,
                          vigencias: serviceDayy.validity_list,
                          isIncludedOpt: false,
                          fee_identifier: serviceDayy.fee_identifier
                        };
                        return individualServicio;
                      }
                    );

                  const servicio: servicioDayElementI = {
                    identifierDay: identifierDayUID,
                    validityStartDay: individualCurrentService.service_date,
                    validityEndDay: individualCurrentService.service_date,
                    category: 0,
                    lendertype: 0,
                    ocupacion: 0,
                    providerType: 0,
                    serviceType: 0,
                    tarifa: 0,
                    isAvailable: true,
                    travelRoute: individualCurrentService.service_tittle,
                    serviciosIndividuales: serviciosIndividualesArray,
                    service_identifier:
                      individualCurrentService.service_identifier,
                  };
                  return servicio;
                }
              ),
            };

            //////////////////Recuperando servicios Generales.
            console.log("Getting comments: ");
            console.log(quoteResponse.quote.comments);
            const dataCommentsArray: CommentI[] =
              quoteResponse.quote.comments.map((currentComment, index) => {
                return {
                  comment_identifier: currentComment.comment_identifier,
                  comment: currentComment.comment,
                  comment_date: currentComment.comment_date,
                  status_identifier: currentComment.status_identifier,
                  commentIdentifier:
                    currentComment.comment_identifier.toString(),
                };
              });

            const cargosFee: feeChargesI = {
              currentMoney: 0,
              changeType: quoteResponse.quote.exchange_rate,
              comision: quoteResponse.quote.commission,
              comisonAuto: quoteResponse.quote.commission_auto,
              cortesia: quoteResponse.quote.courtesy,
              comments: dataCommentsArray,
              courtesy_type_identifier:
                quoteResponse.quote.courtesy_type_identifier,
            };
            const generalElementos: generalsElementsI = {
              generalElement: [],
            };
            let serviciosGenerales: QuoteGeneralElement[] = [];
            let serviciosTransporteGenerales: serviciosDaysElementsI = {
              servicioDayElement: quoteResponse.general_transport_services.map(
                (individualCurrentService, index) => {
                  let identifierDayUID = uuidv4();
                  const serviciosIndividualesArray: servicioIndividual[] =
                    individualCurrentService.services.map(
                      (serviceDayy, index) => {
                        let identifierDayUIDK = uuidv4();
                        const individualServicio: servicioIndividual = {
                          category: serviceDayy.category,
                          lendertype: serviceDayy.lender_type_identifier,
                          lenderDescripcion: serviceDayy.lender_type,
                          ocupacion: "",
                          ocupacionDescripcion: serviceDayy.occupation,
                          providerType: serviceDayy.provider_identifier,
                          providerDescripcion: serviceDayy.provider_name,
                          serviceType: serviceDayy.service_type_identifier,
                          serviceDescripcion: serviceDayy.service_name,
                          tarifa: serviceDayy.fee,
                          identifierService: identifierDayUIDK,
                          typeRoom:
                            serviceDayy.occupation == "SGL"
                              ? 0
                              : serviceDayy.occupation == "DBL"
                              ? 1
                              : serviceDayy.occupation == "TPL"
                              ? 2
                              : 3,
                          dateRequired: individualCurrentService.service_date,
                          currencyName: quoteResponse.quote.currency,
                          currencyId: quoteResponse.quote.currency_identifier,
                          tarifaSingleComplementConverted:
                            serviceDayy.single_complement,
                          tarifaTripleSuplementConverted:
                            serviceDayy.triple_complement,
                          individual_service_identifier:
                            serviceDayy.individual_service_identifier,
                          number_of_services:
                            serviceDayy.number_of_services ?? 1,
                          service_name_identifier:
                            serviceDayy.service_identifier,
                          service_name: serviceDayy.service_name,
                          isIncludedOpt: false,
                          fee_identifier: serviceDayy.fee_identifier
                        };
                        return individualServicio;
                      }
                    );

                  const servicio: servicioDayElementI = {
                    identifierDay: identifierDayUID,
                    validityStartDay: individualCurrentService.service_date,
                    validityEndDay: individualCurrentService.service_date,
                    category: 0,
                    lendertype: 0,
                    ocupacion: 0,
                    providerType: 0,
                    serviceType: 0,
                    tarifa: 0,
                    isAvailable: true,
                    travelRoute: individualCurrentService.service_tittle,
                    serviciosIndividuales: serviciosIndividualesArray,
                    service_identifier:
                      individualCurrentService.service_identifier,
                  };
                  return servicio;
                }
              ),
            };
            if (quoteResponse.quote_general_services) {
              serviciosGenerales = quoteResponse.quote_general_services.map(
                (generalS, index) => {
                  let identifierGUID = uuidv4();
                  const currentServicioGeneral: QuoteGeneralElement = {
                    service_type: generalS.service_type_identifier,
                    provider_id: generalS.provider_identifier,
                    service_name_id: generalS.service_identifier,
                    fee_price: generalS.fee,
                    number_days: generalS.number_of_services,
                    total_price: generalS.fee * generalS.number_of_services,
                    identifierGeneralService: identifierGUID,
                    currency: quoteResponse.quote.currency,
                    currency_identifier:
                      quoteResponse.quote.currency_identifier,
                    expenses_type_identifier: generalS.expenses_type_identifier,
                    general_service_identifier:
                      generalS.general_service_identifier,
                    status_identifier: generalS.service_identifier,
                    service_name: generalS.service_name,
                    provider_name: generalS.provider_name,
                    isIncludedOpt: false,
                    fee_identifier: generalS.fee_identifier
                  };
                  return currentServicioGeneral;
                }
              );
            } else {
              serviciosGenerales = [];
            }
            const optionalContextList:OptionalQuote[]  = quoteResponse.optional_groups.map((opcional, index)=>{
              let id_localString = uuidv4();
              const elementOpcional:OptionalQuote = {
                identifier: opcional.optional_quote_identifier,
                name: opcional.optional_quote_name,
                total_base_sgl: opcional.total_base_sgl,
                total_base_dbl: opcional.total_base_dbl,
                total_base_tpl: opcional.total_base_tpl,
                arrival_date: opcional.arrival_date,
                departure_date: opcional.departure_date,
                id_local: id_localString,
                optional_group_identifier: opcional.optional_group_identifier,
                optional_quote_identifier: opcional.optional_quote_identifier
              }
              return elementOpcional
            })
            setListOpcionalesContext(optionalContextList); 

            setDataCotizacionContext({
              ...dataCotizacionContext,
              personalInfo: personalInfoInicial,
              serviciosDayElements: serviciosDaysElementsInicial,
              generalsElements: generalElementos,
              serviciosDayElementsTransporte: serviciosTransporteGenerales,
              totalPrice: {
                totalPrice: 0,
                granTotal: 0,
              },
              feeCharges: cargosFee,
              generalServices: serviciosGenerales,
            });

            setTitleAlert("La solicitud se realizó correctamente");
            setContentAlert(
              "Accediste a la siguiente información de cotización."
            );
            setCotizacionMode(3);
            setShowAlert(true);
            setShowLoader(false);

            navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
          } else {
            ///RESPUESTA INCORRECTA DE SERVICIOS
            // setShowLoader(false);
            // setTitleAlert("Error al realizar la solicitud!:");
            // setContentAlert(
            //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
            // );
            // setShowAlert(true);
            // setShowLoader(false);
            alert("Respuesta incorrecta de servidor.");
          }
        } catch (error) {
          ///ERROR AL REALIZAR LA PETICION
          alert("Error al realizar la petición.");
        }
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al asignar usuario");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al realizar la busqueda");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      // setShowLoader(false);
    }
    // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
  };

  const getQuoteSelectedFromcComboList = async (idQuotation: number) => {
    console.log("isQuotation selected:::::");
    console.log(idQuotation);
    console.log("-------------------------------------------");

    ///////////////////////////////////OBTENER DATOS DE COTIZACION
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified = backEndPoint + `/operations/QuoteDetail/${idQuotation}`;

    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });
      setShowLoader(true);
      console.log("RESPUESTA:");
      if (response.ok) {
        ////RESPUESTA CORRECTA
        setShowLoader(true);
        console.log(response);
        console.log("bien");
        const data = await response.json();

        console.log("VALOR DE LA CONSULTA:");
        console.log(data.data);
        const quoteResponse: QuoteDetail = data.data.quote_detail;
        console.log("RESPUESTA DEL SERVIDOR PARAMETRIZADA EN INTERFAZ");
        console.log(quoteResponse);
        setShowAlert(false);
        const personalInfoInicial: personalInfoI = {
          name: quoteResponse.quote.name,
          idCotizacion: quoteResponse.quote.quote_identifier.toString(),
          typeCotizacion: quoteResponse.quote.type,
          typeAgency: 1,
          serieId: quoteResponse.quote.serie_identifier,
          includesSerie: true,
          pax: quoteResponse.quote.PAX,
          min: quoteResponse.quote.MIN,
          max: quoteResponse.quote.MAX,
          TL: quoteResponse.quote.TL,
          arrivingData: quoteResponse.quote.arrival_date,
          departureDate: quoteResponse.quote.departure_date,
          agencia: quoteResponse.quote.agency,
          typeRoom: 2,
          numberDaysOpenQuote: quoteResponse.quote.number_of_days,
          modeCotizacion: quoteResponse.quote.serie ? "Serie" : "Normal",
          selectedSerieOption: "No",
          serieSelected: 0,
          isShowingDates: false,
          dateStartOpen: "",
          dateEndOpen: "",
          status_identifier: quoteResponse.quote.status_identifier,
          quote_identifier: quoteResponse.quote.quote_identifier,
          operation_identifier: quoteResponse.quote.operation_identifier ?? 0,
        };

        ///////////////////Recuperando servicios individuales

        const serviciosDaysElementsInicial: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.quote_individual_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();

                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: serviceDayy.occupation,
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    status_identifier: serviceDayy.status_identifier,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: 0,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    vigencias: serviceDayy.validity_list,
                    isIncludedOpt: false,
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              };
              return servicio;
            }
          ),
        };

        //////////////////Recuperando servicios Generales.
        console.log("Getting comments: ");
        console.log(quoteResponse.quote.comments);
        const dataCommentsArray: CommentI[] = quoteResponse.quote.comments.map(
          (currentComment, index) => {
            return {
              comment_identifier: currentComment.comment_identifier,
              comment: currentComment.comment,
              comment_date: currentComment.comment_date,
              status_identifier: currentComment.status_identifier,
              commentIdentifier: currentComment.comment_identifier.toString(),
            };
          }
        );

        const cargosFee: feeChargesI = {
          currentMoney: 0,
          changeType: quoteResponse.quote.exchange_rate,
          comision: quoteResponse.quote.commission,
          comisonAuto: quoteResponse.quote.commission_auto,
          cortesia: quoteResponse.quote.courtesy,
          comments: dataCommentsArray,
          courtesy_type_identifier:
            quoteResponse.quote.courtesy_type_identifier,
        };
        const generalElementos: generalsElementsI = {
          generalElement: [],
        };
        let serviciosGenerales: QuoteGeneralElement[] = [];
        let serviciosTransporteGenerales: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.general_transport_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: "",
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: serviceDayy.number_of_services ?? 1,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    isIncludedOpt: false,
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              };
              return servicio;
            }
          ),
        };
        if (quoteResponse.quote_general_services) {
          serviciosGenerales = quoteResponse.quote_general_services.map(
            (generalS, index) => {
              let identifierGUID = uuidv4();
              const currentServicioGeneral: QuoteGeneralElement = {
                service_type: generalS.service_type_identifier,
                provider_id: generalS.provider_identifier,
                service_name_id: generalS.service_identifier,
                fee_price: generalS.fee,
                number_days: generalS.number_of_services,
                total_price: generalS.fee * generalS.number_of_services,
                identifierGeneralService: identifierGUID,
                currency: quoteResponse.quote.currency,
                currency_identifier: quoteResponse.quote.currency_identifier,
                expenses_type_identifier: generalS.expenses_type_identifier,
                general_service_identifier: generalS.general_service_identifier,
                status_identifier: generalS.service_identifier,
                service_name: generalS.service_name,
                provider_name: generalS.provider_name,
                isIncludedOpt: false,
              };
              return currentServicioGeneral;
            }
          );
        } else {
          serviciosGenerales = [];
        }

        setDataCotizacionContext({
          ...dataCotizacionContext,
          personalInfo: personalInfoInicial,
          serviciosDayElements: serviciosDaysElementsInicial,
          generalsElements: generalElementos,
          serviciosDayElementsTransporte: serviciosTransporteGenerales,
          totalPrice: {
            totalPrice: 0,
            granTotal: 0,
          },
          feeCharges: cargosFee,
          generalServices: serviciosGenerales,
        });

        setShowLoader(false);
        // alert("Cotización recuperada correctamente.");
      } else {
        alert("Respuesta incorrecta de servidor.");
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      alert("Error al realizar la petición.");
      setShowLoader(false);
    }

    ///////////////////////////////////////////////////////////
  };

  const modifyVersion = async () => {
    ///////////////////////////////////OBTENER DATOS DE COTIZACION
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint +
      `/quote/QuoteDetail/${dataCotizacionContext.personalInfo.idCotizacion}`;

    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data = await response.json();

        console.log("VALOR DE LA CONSULTA:");
        console.log(data.data);
        const quoteResponse: QuoteDetail = data.data.quote_detail;
        console.log("RESPUESTA DEL SERVIDOR PARAMETRIZADA EN INTERFAZ");
        console.log(quoteResponse);

        const personalInfoInicial: personalInfoI = {
          name: quoteResponse.quote.name,
          idCotizacion: quoteResponse.quote.quote_identifier.toString(),
          typeCotizacion: quoteResponse.quote.type,
          typeAgency: 1,
          serieId: quoteResponse.quote.serie_identifier,
          includesSerie: true,
          pax: quoteResponse.quote.PAX,
          min: quoteResponse.quote.MIN,
          max: quoteResponse.quote.MAX,
          TL: quoteResponse.quote.TL,
          arrivingData: quoteResponse.quote.arrival_date,
          departureDate: quoteResponse.quote.departure_date,
          agencia: quoteResponse.quote.agency,
          typeRoom: 2,
          numberDaysOpenQuote: quoteResponse.quote.number_of_days,
          modeCotizacion: quoteResponse.quote.serie ? "Serie" : "Normal",
          selectedSerieOption: "No",
          serieSelected: 0,
          isShowingDates: false,
          dateStartOpen: "",
          dateEndOpen: "",
          status_identifier: quoteResponse.quote.status_identifier,
          quote_identifier: quoteResponse.quote.quote_identifier,
          operation_identifier: dataCotizacionContext.personalInfo.operation_identifier ?? 0,
        };

        ///////////////////Recuperando servicios individuales

        const serviciosDaysElementsInicial: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.quote_individual_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();

                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: serviceDayy.occupation,
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    status_identifier: serviceDayy.status_identifier,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: 0,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    vigencias: serviceDayy.validity_list,
                    isIncludedOpt: false,
                    fee_identifier: serviceDayy.fee_identifier
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              };
              return servicio;
            }
          ),
        };

        //////////////////Recuperando servicios Generales.
        console.log("Getting comments: ");
        console.log(quoteResponse.quote.comments);
        const dataCommentsArray: CommentI[] = quoteResponse.quote.comments.map(
          (currentComment, index) => {
            return {
              comment_identifier: currentComment.comment_identifier,
              comment: currentComment.comment,
              comment_date: currentComment.comment_date,
              status_identifier: currentComment.status_identifier,
              commentIdentifier: currentComment.comment_identifier.toString(),
            };
          }
        );

        const cargosFee: feeChargesI = {
          currentMoney: 0,
          changeType: quoteResponse.quote.exchange_rate,
          comision: quoteResponse.quote.commission,
          comisonAuto: quoteResponse.quote.commission_auto,
          cortesia: quoteResponse.quote.courtesy,
          comments: dataCommentsArray,
          courtesy_type_identifier:
            quoteResponse.quote.courtesy_type_identifier,
        };
        const generalElementos: generalsElementsI = {
          generalElement: [],
        };
        let serviciosGenerales: QuoteGeneralElement[] = [];
        let serviciosTransporteGenerales: serviciosDaysElementsI = {
          servicioDayElement: quoteResponse.general_transport_services.map(
            (individualCurrentService, index) => {
              let identifierDayUID = uuidv4();
              const serviciosIndividualesArray: servicioIndividual[] =
                individualCurrentService.services.map((serviceDayy, index) => {
                  let identifierDayUIDK = uuidv4();
                  const individualServicio: servicioIndividual = {
                    category: serviceDayy.category,
                    lendertype: serviceDayy.lender_type_identifier,
                    lenderDescripcion: serviceDayy.lender_type,
                    ocupacion: "",
                    ocupacionDescripcion: serviceDayy.occupation,
                    providerType: serviceDayy.provider_identifier,
                    providerDescripcion: serviceDayy.provider_name,
                    serviceType: serviceDayy.service_type_identifier,
                    serviceDescripcion: serviceDayy.service_name,
                    tarifa: serviceDayy.fee,
                    identifierService: identifierDayUIDK,
                    typeRoom:
                      serviceDayy.occupation == "SGL"
                        ? 0
                        : serviceDayy.occupation == "DBL"
                        ? 1
                        : serviceDayy.occupation == "TPL"
                        ? 2
                        : 3,
                    dateRequired: individualCurrentService.service_date,
                    currencyName: quoteResponse.quote.currency,
                    currencyId: quoteResponse.quote.currency_identifier,
                    tarifaSingleComplementConverted:
                      serviceDayy.single_complement,
                    tarifaTripleSuplementConverted:
                      serviceDayy.triple_complement,
                    individual_service_identifier:
                      serviceDayy.individual_service_identifier,
                    number_of_services: serviceDayy.number_of_services ?? 1,
                    service_name_identifier: serviceDayy.service_identifier,
                    service_name: serviceDayy.service_name,
                    isIncludedOpt: false,
                    fee_identifier: serviceDayy.fee_identifier
                  };
                  return individualServicio;
                });

              const servicio: servicioDayElementI = {
                identifierDay: identifierDayUID,
                validityStartDay: individualCurrentService.service_date,
                validityEndDay: individualCurrentService.service_date,
                category: 0,
                lendertype: 0,
                ocupacion: 0,
                providerType: 0,
                serviceType: 0,
                tarifa: 0,
                isAvailable: true,
                travelRoute: individualCurrentService.service_tittle,
                serviciosIndividuales: serviciosIndividualesArray,
                service_identifier: individualCurrentService.service_identifier,
              };
              return servicio;
            }
          ),
        };
        if (quoteResponse.quote_general_services) {
          serviciosGenerales = quoteResponse.quote_general_services.map(
            (generalS, index) => {
              let identifierGUID = uuidv4();
              const currentServicioGeneral: QuoteGeneralElement = {
                service_type: generalS.service_type_identifier,
                provider_id: generalS.provider_identifier,
                service_name_id: generalS.service_identifier,
                fee_price: generalS.fee,
                number_days: generalS.number_of_services,
                total_price: generalS.fee * generalS.number_of_services,
                identifierGeneralService: identifierGUID,
                currency: quoteResponse.quote.currency,
                currency_identifier: quoteResponse.quote.currency_identifier,
                expenses_type_identifier: generalS.expenses_type_identifier,
                general_service_identifier: generalS.general_service_identifier,
                status_identifier: generalS.service_identifier,
                service_name: generalS.service_name,
                provider_name: generalS.provider_name,
                isIncludedOpt: false,
                fee_identifier: generalS.fee_identifier
              };
              return currentServicioGeneral;
            }
          );
        } else {
          serviciosGenerales = [];
        }
        
        const optionalContextList:OptionalQuote[]  = quoteResponse.optional_groups.map((opcional, index)=>{
          let id_localString = uuidv4();
          const elementOpcional:OptionalQuote = {
            identifier: opcional.optional_quote_identifier,
            name: opcional.optional_quote_name,
            total_base_sgl: opcional.total_base_sgl,
            total_base_dbl: opcional.total_base_dbl,
            total_base_tpl: opcional.total_base_tpl,
            arrival_date: opcional.arrival_date,
            departure_date: opcional.departure_date,
            id_local: id_localString,
            optional_group_identifier: opcional.optional_group_identifier,
            optional_quote_identifier: opcional.optional_quote_identifier
          }
          return elementOpcional
        })
        setListOpcionalesContext(optionalContextList); 
        setDataCotizacionContext({
          ...dataCotizacionContext,
          personalInfo: personalInfoInicial,
          serviciosDayElements: serviciosDaysElementsInicial,
          generalsElements: generalElementos,
          serviciosDayElementsTransporte: serviciosTransporteGenerales,
          totalPrice: {
            totalPrice: 0,
            granTotal: 0,
          },
          feeCharges: cargosFee,
          generalServices: serviciosGenerales,
        });
        setTitleAlert("La solicitud se realizó correctamente");
        setContentAlert("Accediste a la siguiente información de cotización.");
        setCotizacionMode(4);
        setShowAlert(true);
        setShowLoader(false);

        alert("Cotización recuperada correctamente.");
        navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        alert("Respuesta incorrecta de servidor.");
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      alert("Error al realizar la petición.");
    }
    ///////////////////////////////////////////////////////////
  };

  const createOperation = async () => {
    let arrayServiciosIndividuales = [];
    let arrayServiciosTransporte = [];
    let arrayServiciosGenerales = [];
    let arrayTotalServicios = [];

    const serviciosIndividualesFiltrados =
      dataCotizacionContext.serviciosDayElements.servicioDayElement
        .map((dayC, i) => {
          return dayC.serviciosIndividuales;
        })
        .map((individualListC, j) => {
          return individualListC.filter((currentService, index) => {
            return currentService.isIncludedOPS;
          });
        })
        .map((individualListSelected, indice) => {
          return individualListSelected.map((currentService, ij) => {
            return {
              fee_identifier: currentService.fee_identifier ?? 0,
              service_identifier:
                currentService.individual_service_identifier ?? 0,
            };
          });
        });

    const serviciosIndividualesFiltradosOpt =
      dataCotizacionContext?.serviciosDayElementsOpt?.servicioDayElement
        .map((dayC, i) => {
          return dayC.serviciosIndividuales;
        })
        .map((individualListC, j) => {
          return individualListC.filter((currentService, index) => {
            return currentService.isIncludedOPS;
          });
        })
        .map((individualListSelected, indice) => {
          return individualListSelected.map((currentService, ij) => {
            return {
              fee_identifier: currentService.fee_identifier ?? 0,
              service_identifier:
                currentService.individual_service_identifier ?? 0,
            };
          });
        });

    const serviciosIndividualesTransporteFiltrados =
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement
        .map((dayC, i) => {
          return dayC.serviciosIndividuales;
        })
        .map((individualListC, j) => {
          return individualListC.filter((currentService, index) => {
            return currentService.isIncludedOPS;
          });
        })
        .map((individualListSelected, indice) => {
          return individualListSelected.map((currentService, ij) => {
            return {
              fee_identifier: currentService.fee_identifier ?? 0,
              service_identifier:
                currentService.individual_service_identifier ?? 0,
            };
          });
        });
    const serviciosIndividualesTransporteFiltradosOpt =
      dataCotizacionContext?.serviciosDayElementsTransporteOpt?.servicioDayElement
        .map((dayC, i) => {
          return dayC.serviciosIndividuales;
        })
        .map((individualListC, j) => {
          return individualListC.filter((currentService, index) => {
            return currentService.isIncludedOPS;
          });
        })
        .map((individualListSelected, indice) => {
          return individualListSelected.map((currentService, ij) => {
            return {
              fee_identifier: currentService.fee_identifier ?? 0,
              service_identifier:
                currentService.individual_service_identifier ?? 0,
            };
          });
        });

    const serviciosGeneralesFiltrados = dataCotizacionContext.generalServices
      .filter((serviceGeneral, index) => {
        return serviceGeneral.isIncludedOPS;
      })
      .map((serviceGeneral, index) => {
        return {
          fee_identifier: serviceGeneral.fee_identifier ?? 0,
          service_identifier: serviceGeneral.general_service_identifier,
        };
      });
    const serviciosGeneralesFiltradosOpt =
      dataCotizacionContext?.generalServices
        ?.filter((serviceGeneral, index) => {
          return serviceGeneral.isIncludedOPS;
        })
        .map((serviceGeneral, index) => {
          return {
            fee_identifier: serviceGeneral.fee_identifier ?? 0,
            service_identifier: serviceGeneral.general_service_identifier,
          };
        });

    console.log("Array multidimensional con identificador de servicios");
    console.log(serviciosIndividualesFiltrados);
    console.log("Array 2");
    console.log(serviciosIndividualesTransporteFiltrados);
    console.log("Array 3");
    console.log(serviciosGeneralesFiltrados);
    console.log("Suma de arrays");
    let serviciosIndividualesFiltradosArray: GenerarCotizacionI[] = [];
    let serviciosTransporteFiltradosArray: GenerarCotizacionI[] = [];
    let serviciosGeneralesFiltradosArray: GenerarCotizacionI[] = [];
    console.log(serviciosGeneralesFiltradosOpt);
    console.log(serviciosIndividualesTransporteFiltradosOpt);
    console.log(serviciosIndividualesFiltradosOpt);
    /////VALidar que no estén vacios los opcionales, pues estos pueden llegar vacios, si están vacios, el array permanece con cero elementos
    if (
      serviciosIndividualesFiltradosOpt &&
      serviciosIndividualesFiltradosOpt.length > 0
    ) {
      serviciosIndividualesFiltradosArray = [
        ...serviciosIndividualesFiltradosOpt,
      ].flat();
    }
    if (
      serviciosIndividualesTransporteFiltradosOpt &&
      serviciosIndividualesTransporteFiltradosOpt.length > 0
    ) {
      serviciosTransporteFiltradosArray = [
        ...serviciosIndividualesTransporteFiltradosOpt,
      ].flat();
    }
    if (
      serviciosGeneralesFiltradosOpt &&
      serviciosGeneralesFiltradosOpt.length > 0
    ) {
      serviciosGeneralesFiltradosArray = [
        ...serviciosGeneralesFiltradosOpt,
      ].flat();
    }

    ///////SE suman todos los arreglos, tanto opcionales como normales, para enviar a operación.
    
    const arrayTotal: GenerarCotizacionI[] = [
      ...serviciosIndividualesFiltrados,
      ...serviciosIndividualesTransporteFiltrados,
      ...serviciosGeneralesFiltrados,
      ...serviciosIndividualesFiltradosArray,
      ...serviciosTransporteFiltradosArray,
      ...serviciosGeneralesFiltradosArray,
    ].flat();
    console.log("ARray total final:::::");
    console.log(arrayTotal);
    const bodyRequest = {
      quote_identifier: parseInt(dataCotizacionContext.personalInfo.idCotizacion),
      services_identifier_list: arrayTotal,
    };

    const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/register`;

    const bodySearch = {
      quote_identifier: dataCotizacionContext.personalInfo.idCotizacion,
      services_identifier_list: "",
    };
    setShowLoader(true);
    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        const data = await response.json();

        console.log("Cotización original");
        console.log(dataCotizacionContext.personalInfo.idCotizacion);
        console.log("Copia creada con éxito");
        const idCotizacionDuplicada = data.data.quote_identifier;
        console.log(data.data.operation_identifier);
        ///////////////////////////////////OBTENER DATOS DE OPERACION
        const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
        const urlOperacion = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/getQuoteProviders/${data.data.operation_identifier}`;
        setIdOperation(data.data.operation_identifier);
        setShowLoader(false);

        navigate("/dashboard/Operaciones/Definicion-Prestador");
      } else {
        console.error("Error in response:", response);
        alert("Error al crear operación");
        setShowLoader(false);
        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al realizar la busqueda");
      console.error("Error in request:", error);
      setShowLoader(false);
      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };
  const getOperacionDefinicionEntradas = () => {
    
    
    setIdOperation(dataCotizacionContext.personalInfo.operation_identifier ?? 0);
    // setIdProforma(dataCotizacionContext.personalInfo.pro);
    navigate("/dashboard/Operaciones/Definicion-Prestador");
  };

  return (
    <CotizacionDetailStyles>
      <h3 className="title-h3">Datos de la cotización</h3>
      <HeaderInfo />
      <div className="container-edit">
        <div className="first-menu">
          <SelectListdefault
            style={{
              width: "80%",
              maxWidth: "80%",
            }}
            id="sl6"
            onChange={(e) => {
              console.log("HERE");
              console.log(e.target.value);
              getQuoteSelectedFromcComboList(parseInt(e.target.value));
            }}
            value={dataCotizacionContext.personalInfo.idCotizacion || ""}
          >
            <option value="" disabled selected>
              Historial de cotizaciones
            </option>

            {listHistorialQuotes.length > 0
              ? listHistorialQuotes.map((item) => {
                  return (
                    <Fragment>
                      <option
                        key={item.identifier}
                        value={item.identifier}
                        // selected={item.identifier == personalInfo.typeCotizacion}
                      >
                        {item.name}
                      </option>
                    </Fragment>
                  );
                })
              : null}
          </SelectListdefault>
        </div>
        <button className="boton-new-version" onClick={duplicateQuote}>
          Nueva versión
        </button>
        <button
          className="boton-new-version edition-boton"
          onClick={modifyVersion}
        >
          Modificar versión
        </button>
      </div>
      {dataCotizacionContext.personalInfo.status_identifier != 9 ? (
        <ListServices />
      ) : null}

      {dataCotizacionContext.personalInfo.status_identifier != 9 ? (
        <button className="boton-siguiente" onClick={createOperation}>
          Siguiente
        </button>
      ) : (
        <button
          className="boton-siguiente"
          onClick={getOperacionDefinicionEntradas}
        >
          Consultar operación
        </button>
      )}
    </CotizacionDetailStyles>
  );
}

const CotizacionDetailStyles = styled.div`
  .boton-siguiente {
    background-color: rgb(8, 166, 165);
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.2rem;
    margin-top: 2rem;
    transition: 0.2s all linear;
    border: rgb(8, 166, 165) 1px solid;
    color: white;
    cursor: pointer;
  }
  .boton-siguiente:hover {
    background-color: white;
    color: rgb(8, 166, 165);
  }
  .container-edit {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 2rem;
  }
  .first-menu {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .boton-new-version {
    outline: none;
    border: none;
    padding: 0.7rem 1.3rem;
    border-radius: 0.4rem;
    background-color: #29aae1;
    color: white;
    cursor: pointer;
    transition: 0.4s all linear;
    border: #29aae1 solid 1px;
  }
  .boton-new-version:hover {
    background-color: white;
    color: #29aae1;
    border: #29aae1 solid 1px;
  }
  .edition-boton {
    background-color: rgb(246, 146, 30);
    border: rgb(246, 146, 30) solid 1px;
  }
  .edition-boton:hover {
    background-color: white;
    border-color: rgb(246, 146, 30);
    color: rgb(246, 146, 30);
  }
`;

const SelectListdefault = styled.select`
  width: 40%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;

  max-height: 40.8px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  ///////////////////////////////////
`;
