import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Provider } from "../../../interfaces/interfaces";
import { ApiResponse } from "../../../interfaces/interfaces";
interface searchBarInterface{
    results: Provider[],
    setSearchText: React.Dispatch<React.SetStateAction<string>>,
    setData: React.Dispatch<React.SetStateAction<{
        provider_list: Provider[];
    }>>; 
    setResultsList: React.Dispatch<React.SetStateAction<{
      provider_list: Provider[];
  }>>
}
export default function SearchResultsClient({results, setSearchText, setData, setResultsList}: searchBarInterface) {
    const handleClick = (elementProvider: Provider) => {
        console.log("Diste click en la busqueda!!");
    
        const result: Provider[] = [elementProvider];
        console.log(result);
        setSearchText(elementProvider.name);
        // setCatalogElements(result);
        setData({
            provider_list: result
        });

        // setData(resultsSearch);
        
        setResultsList({
          provider_list: result,
        });
        
      };
  return (
    <SearchResultsClientStyles>
         

        {results.map((result: Provider) => (
        <div
        //   key={result.clave}
          className="search-result"
          onClick={() => {
            handleClick(result);
          }}
        >
          <p>{result.name}</p>
       
        </div>
      ))}
    </SearchResultsClientStyles>
  )
}

const SearchResultsClientStyles = styled.div`
position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid black;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  .search-result {
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: lightgray;
    }
  }
  width: 60%;
  margin: 0 auto;
`
