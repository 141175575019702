import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import EDITAR from "../../../images/edit.png";
import StyledTable from "../tables/StyledTable";
import {
  GeneralsData,
  bodyResponseProvider,
} from "../../../interfaces/ProviderTypes";
import guardar from "../../../images/GUARDARDATOS.png";
import { useNavigate } from "react-router-dom";
interface propsGeneralesI {
  editionMode: {
    generalEditionMode: boolean;
  };
  setEditionMode: React.Dispatch<
    React.SetStateAction<{
      generalEditionMode: boolean;
    }>
  >;
  generalData: GeneralsData | undefined;
  setGeneralData: React.Dispatch<
    React.SetStateAction<GeneralsData | undefined>
  >;
  getInfoProvider: () => void;
}
export default function Generales({
  editionMode,
  setEditionMode,
  generalData,
  setGeneralData,
  getInfoProvider,
}: propsGeneralesI) {
  const navigate = useNavigate();
  const { setShowInfoContact } = useContext(AppContext);
  const editarGenerales = () => {
    getInfoProvider();

    navigate("/dashboard/Registro/Generales");
    setShowInfoContact(false);
  };
  return (
    <div className="first-box">
      <div className="title-first">
        <h3>Generales</h3>
        {editionMode.generalEditionMode ? (
          <img src={guardar}></img>
        ) : (
          <img
            src={EDITAR}
            onClick={() => {
              editarGenerales();
            }}
          ></img>
        )}
      </div>
      <div className="content-first">
        <div className="content-first-box">
          <TittleLbl>Informacion</TittleLbl>

          <div className="container-data">
            <h4>Nombre comercial: </h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.name}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      name: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.name}</h4>
            )}
          </div>
          <div className="container-data">
            <h4>Tipo de proovedor:</h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.provider_type}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      provider_type: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.provider_type}</h4>
            )}
          </div>
          <div className="container-data">
            <h4>Tipo de alta:</h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.registration_type}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      registration_type: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.registration_type}</h4>
            )}
          </div>
          <div className="container-data">
            <h4>Razón social:</h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.business_name}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      business_name: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.business_name}</h4>
            )}
          </div>
          {generalData?.registration_type_identifier == 2 && (
            <div className="container-data">
              <h4>RFC:</h4>
              {editionMode.generalEditionMode ? (
                <input
                  type="text"
                  value={generalData?.registration_type}
                  onChange={(e) => {
                    if (generalData) {
                      setGeneralData({
                        ...generalData,
                        registration_type: e.target.value,
                      });
                    }
                  }}
                />
              ) : (
                <h4>{generalData?.rfc}</h4>
              )}
            </div>
          )}

          <div className="container-data">
            <h4>Tipo de prestador:</h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.lender_type}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      lender_type_identifier: parseInt(e.target.value),
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.lender_type}</h4>
            )}
          </div>
          {generalData?.provider_type_identifier == 11 && (
            <div className="container-data">
              <h4>Categoría:</h4>
              <h4>{generalData?.category}</h4>
            </div>
          )}
        </div>

        <div className="content-first-box">
          <TittleLbl>Dirección</TittleLbl>
          <div className="container-data">
            <h4>País :</h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.country_identifier}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      country_identifier: parseInt(e.target.value),
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.country}</h4>
            )}
          </div>
          <div className="container-data">
            <h4>C.P: </h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.zip_code}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      zip_code: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.zip_code}</h4>
            )}
          </div>
          <div className="container-data">
            <h4>Colonia: </h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.neighborhood}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      neighborhood: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.neighborhood}</h4>
            )}
          </div>
          <div className="container-data">
            <h4>Ciudad: </h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.zip_code}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      zip_code: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.city}</h4>
            )}
          </div>
          <div className="container-data">
            <h4>Calle: </h4>
            {editionMode.generalEditionMode ? (
              <input
                type="text"
                value={generalData?.street}
                onChange={(e) => {
                  if (generalData) {
                    setGeneralData({
                      ...generalData,
                      street: e.target.value,
                    });
                  }
                }}
              />
            ) : (
              <h4>{generalData?.street}</h4>
            )}
          </div>
        </div>
        <div className="content-first-box">
          <TittleLbl>Notas importantes</TittleLbl>
          <div className="container-data">
            <h4>Detalles:</h4>
            {editionMode.generalEditionMode ? (
              <input type="text" value={generalData?.city} />
            ) : (
              <h4>{generalData?.important_notes}</h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const TittleLbl = styled.div`
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 1rem;
`;
