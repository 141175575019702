import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import GerenteTable from "./GerenteTable";
import OptionSwitch from "./Components/OptionSwitch";
import CotizacionDetails from "./CotizacionDetails";
import { BrowserRouter, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";



export default function OperationsContainer() {
  const { currentUser, setCurrentUser, dataCotizacionContext} = useContext(AppContext); 
  const [optionTableSelected, setOptionTableSelected] = useState<number>(2);

  const [currentPage, setCurrentPage] = useState(1);

  const [grupoActual, setGrupoActual] = useState(()=>{
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null ? parseInt(grupo): currentUser.group_identifier
  })
  return (
    <OperationsStyles>
      <h3 className="title-h3">{optionTableSelected == 2 ? "Cotizaciones por asignar.": "Cotizaciones asignadas."}</h3>
      {
       (grupoActual == 1 || grupoActual == 1017 || grupoActual == 1013 || grupoActual == 1012 ) &&   <OptionSwitch
        optionTableSelected={optionTableSelected}
        setOptionTableSelected={setOptionTableSelected}
        currentPage = {currentPage}
        setCurrentPage = {setCurrentPage }
      />
      }
   
    
      <br />
      <GerenteTable optionTableSelected ={optionTableSelected}
         currentPage = {currentPage}
         setCurrentPage = {setCurrentPage} 
      />
      <br />
      <br />
      {/* LISTA DE SERVICIOS DE COTIZACION LISTA SELECCIONABLE */}
    
    {
      dataCotizacionContext.personalInfo.idCotizacion &&     <CotizacionDetails />
    }
    <div id="data-cotization">
      
      </div>
    
    </OperationsStyles>
  );
}

const OperationsStyles = styled.div`
  .title-h3 {
    color: rgb(8, 166, 165);
    margin-bottom: 1rem;
  }
`;
