import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  PendingPayment,
  ProviderContabilidadI,
} from "./InterfacesContabilidad";
import ElementPayment from "./Components/ElementPayment";

interface TablePagoI {
  listPayments: PendingPayment[];
  setListPayments: React.Dispatch<React.SetStateAction<PendingPayment[]>>;
  providerInfo: ProviderContabilidadI;
  setProviderInfo: React.Dispatch<React.SetStateAction<ProviderContabilidadI>>;
}
export default function TablePago({
  listPayments,
  setListPayments,
  providerInfo,
}: TablePagoI) {
  const sumaMXN = listPayments.reduce(
    (total, item) => total + item.advance_mxn,
    0
  );
  const sumaUSD = listPayments.reduce(
    (total, item) => total + item.advance_usd,
    0
  );

  return (
    <TablePagoStyle>
      <br />
      <br />
      <div className="header-table">
        <div>
          <h3>Liquidar</h3>
        </div>
        <div>
          <h3>Prestador</h3>
        </div>
        <div>
          <h3>Clave</h3>
        </div>
        <div>
          <h3>Entrada</h3>
        </div>
        <div>
          <h3>Total Pesos</h3>
        </div>
        <div>
          <h3>Total Dolares</h3>
        </div>
        <div>
          <h3>Grupo</h3>
        </div>
        <div>
          <h3>Enviado a Pago</h3>
        </div>
        <div>
          <h3>Fecha Llegada</h3>
        </div>
        <div>
          <h3>Fecha Salida</h3>
        </div>
      </div>
      {listPayments.map((payment, index) => {
        return (
          <div key={payment.id_local}>
            <ElementPayment
              payment={payment}
              providerInfo={providerInfo}
              listPayments={listPayments}
              setListPayments={setListPayments}
            />
          </div>
        );
      })}
      {listPayments?.length == 0 && <h3>No hay pagos por mostrar</h3>}
      <div className="container-prices">
        <h3>Total pesos: ${sumaMXN.toFixed(2)}</h3>
        <h3>Total dolares: ${sumaUSD.toFixed(2)}</h3>
      </div>
    </TablePagoStyle>
  );
}

const TablePagoStyle = styled.div`
  .container-prices {
    width: 85%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .container-prices h3 {
    font-size: 0.9vw;
  }
  .header-table {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 85%;
    margin: 0 auto;
    padding: 0.55rem;
  }
  .header-table div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
`;
