import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  Country,
  City,
  Neighborhood,
  Zipcode,
  RegistrationType,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCopy,
  faTrash,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
  serviceObject,
  feeIndividual,
} from "../../../interfaces/GeneralTypes";
import CustomInputt from "../entrys/CustomInputt";
import CustomInputtt from "../entrys/CustomInputtt";
import Vigencia from "../layout/Vigencia";
import CustomInputNew from "../entrys/CustomInputNew";
import ImpuestosGet from "../layout/ImpuestosGet";
import CustomInputttt from "../entrys/Inputttt";
import copiar from "../../../images/DUPLICAR.png";
import deletedatos from "../../../images/deletelogo.png";
import guardar from "../../../images/GUARDARDATOS.png";
import edit from "../../../images/edit.png";
import agregar from "../../../images/AGREGAR.png";
import BlackoutElement from "../layout/BlackoutElement";

interface tarifaProps {
  setDataServices: React.Dispatch<React.SetStateAction<dataServicesI>>;
  tarifa: feeIndividual;
  currencyList: coinInterface[];
  dataServices: dataServicesI;
  addNewServicio: () => void;
  isShowingAddButton: boolean;
  tipoTarifa: number;
  setTipoTarifa: React.Dispatch<React.SetStateAction<number>>;
  service_fee_list: Array<feeIndividual>;
  localTarifaParent: serviceObject;
  setLocalTarifaParent: React.Dispatch<React.SetStateAction<serviceObject>>;
  isTaxesIncluded: boolean;
}

export default function TarifaHab({
  setDataServices,
  tarifa,
  currencyList,
  dataServices,
  addNewServicio,
  isShowingAddButton,
  tipoTarifa,
  setTipoTarifa,
  localTarifaParent,
  setLocalTarifaParent,
  isTaxesIncluded,
}: tarifaProps) {
  const [editionMode, setEditionMode] = useState(false);
  const { catalogsData } = useCatalogs();
  const [serviceList, setServiceList] = useState<serviceInterface[]>([]);
  const [foodPlan, setFoodPlan] = useState<maealI[]>([]);
  const [categoryServices, setCategoryServices] = useState<categoriaI[]>([]);
  const [localTarifa, setLocalTarifa] = useState<feeIndividual>(tarifa);
  useEffect(() => {
    // setSeasons(catalogsData?.season_list);
    // setCurrencyList(catalogsData?.currency_list);
    // setViewList(catalogsData?.view_list);
    setServiceList(catalogsData?.service_type_list);
    setCategoryServices(catalogsData?.category_list);
    setFoodPlan(catalogsData?.meal_plan_list);
  }, [catalogsData]);
  useEffect(() => {
    console.log("LOCAL TARIFA CAMBIO: ");
    console.log(localTarifa);
    const newArrayTarifa = localTarifaParent.service_fee_list.map(
      (currentTarifa, index) => {
        return currentTarifa.fee_key !== tarifa.fee_key
          ? currentTarifa
          : {
              ...localTarifa,
            };
      }
    );

    setLocalTarifaParent({
      ...localTarifaParent,
      service_fee_list: newArrayTarifa,
    });
  }, [localTarifa]);
  const addBlackout = () => {
    ///Obtenemos el arreglo
    const arrayServices = localTarifaParent.service_fee_list;
    let identificador = uuidv4();

    /////
  };

  const addVigencia = () => {
    ////obtenemos el arreglo del objeto
  };
  const deleteServicio = () => {
    if (localTarifaParent.service_fee_list.length == 1) {
      alert("No puedes borrar más tarifas");
      return;
    }
    console.log("Borrando servicio");
  };

  /////CALCULO DE TARIFA TOTAL
  useEffect(() => {
    ////Calculamos el costo total de tarifa cuando cambien los datos de fee_iva, fee_tax y fee, luego asignamos fee_total (de cada tipo) en el objeto local
    ////Posteriormente, en otro useEffect, al cambiar el objecto localTarifa, se actualiza el array de todas las tarifas.
    let impuesto = isNaN(localTarifa.fee_tax) ? 0 : localTarifa.fee_tax;
    const costoTotalTarifaSGL =
      localTarifa.fee_SGL +
      localTarifa.fee_SGL * (impuesto / 100) +
      localTarifa.fee_SGL * (localTarifa.fee_iva / 100)
        ? localTarifa.fee_SGL +
          localTarifa.fee_SGL * (impuesto / 100) +
          localTarifa.fee_SGL * (localTarifa.fee_iva / 100)
        : 0.0;
    const costoTotalTarifaDBL =
      localTarifa.fee_DBL +
      localTarifa.fee_DBL * (impuesto / 100) +
      localTarifa.fee_DBL * (localTarifa.fee_iva / 100)
        ? localTarifa.fee_DBL +
          localTarifa.fee_DBL * (impuesto / 100) +
          localTarifa.fee_DBL * (localTarifa.fee_iva / 100)
        : 0.0;
    const costoTotalTarifaTPL =
      localTarifa.fee_TPL +
      localTarifa.fee_TPL * (impuesto / 100) +
      localTarifa.fee_TPL * (localTarifa.fee_iva / 100)
        ? localTarifa.fee_TPL +
          localTarifa.fee_TPL * (impuesto / 100) +
          localTarifa.fee_TPL * (localTarifa.fee_iva / 100)
        : 0.0;
    const costoTotalTarifaCPL =
      localTarifa.fee_CPL +
      localTarifa.fee_CPL * (impuesto / 100) +
      localTarifa.fee_CPL * (localTarifa.fee_iva / 100)
        ? localTarifa.fee_CPL +
          localTarifa.fee_CPL * (impuesto / 100) +
          localTarifa.fee_CPL * (localTarifa.fee_iva / 100)
        : 0.0;
    const costoTotalTarifaFP =
      localTarifa.fee_FP +
      localTarifa.fee_FP * (impuesto / 100) +
      localTarifa.fee_FP * (localTarifa.fee_iva / 100)
        ? localTarifa.fee_FP +
          localTarifa.fee_FP * (impuesto / 100) +
          localTarifa.fee_FP * (localTarifa.fee_iva / 100)
        : 0.0;
    const costoTotalTarifaBB = localTarifa.fee_BB;

    const costoTotalTarifaMAID = localTarifa.fee_MAID;
    setLocalTarifa({
      ...localTarifa,
      fee_TSGL: parseFloat(costoTotalTarifaSGL.toFixed(2)),
      fee_TDBL: parseFloat(costoTotalTarifaDBL.toFixed(2)),
      fee_TTPL: parseFloat(costoTotalTarifaTPL.toFixed(2)),
      fee_TCPL: parseFloat(costoTotalTarifaCPL.toFixed(2)),
      fee_TFP: parseFloat(costoTotalTarifaFP.toFixed(2)),
      fee_TBB: parseFloat(costoTotalTarifaBB.toFixed(2)),
      fee_TMAID: parseFloat(costoTotalTarifaMAID.toFixed(2)),
    });
  }, [
    localTarifa.fee_MAID,
    localTarifa.fee_iva,
    localTarifa.fee_tax,
    localTarifa.fee_SGL,
    localTarifa.fee_DBL,
    localTarifa.fee_TPL,
    localTarifa.fee_CPL,
    localTarifa.fee_FP,
    localTarifa.fee_BB,
  ]);

  ///CUIDADO AQUI
  // useEffect(() => {
  //   if (!isTaxesIncluded) {
  //     setLocalTarifa({
  //       ...localTarifa,
  //       fee_tax: 0,
  //     });
  //   }
  // }, [isTaxesIncluded]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "yes";
  };

  const [isShowingFee, setIsShowingFee] = useState<boolean>(true);

  return (
    <TarifaHabStyles>
      {isShowingFee && (
        <>
          <div className="tarifa-container-tres">
            <div className="first-row">
              <SelectListdefault
                style={{
                  width: "100%",
                  maxWidth: "100%",
                }}
                id="sl6"
                onChange={(e) => {
                  setLocalTarifa({
                    ...localTarifa,
                    fee_currency_identifier: parseInt(e.target.value),
                  });
                }}
              >
                <option value="" disabled selected>
                  Moneda
                </option>

                {currencyList.length > 0
                  ? currencyList.map((item) => {
                      return (
                        <option
                          key={item.identifier}
                          value={item.identifier}
                          selected={
                            item.identifier == tarifa.fee_currency_identifier
                          }
                        >
                          {item.description}
                        </option>
                      );
                    })
                  : null}
              </SelectListdefault>

              <CustomInputtt<feeIndividual>
                text="IVA %"
                txtWidth={100}
                setGeneralData={setLocalTarifa}
                field="fee_iva"
                value={localTarifa.fee_iva}
                typeData="number"
              />
              {true ? (
                <CustomInputtt<feeIndividual>
                  text="Impuesto %"
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_tax"
                  value={localTarifa.fee_tax}
                  typeData="number"
                />
              ) : (
                <CustomInputttt<feeIndividual>
                  text="Impuesto %"
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_tax"
                  value={0}
                  typeData="number"
                  isAvailable={false}
                />
              )}
            </div>
            <div className="tarifa-hab-box">
              <div className="option-hab">
                <div></div>
                <div></div>
                <div>
                  <h4>Tarifa total</h4>
                </div>
              </div>
              <div className="option-hab">
                <h4>SGL</h4>
                <CustomInputtt<feeIndividual>
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_SGL"
                  value={localTarifa.fee_SGL}
                  typeData="number"
                />
                <CustomInputNew
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_TSGL"
                  value={localTarifa.fee_TSGL}
                  typeData="number"
                />
              </div>
              <div className="option-hab">
                <h4>DBL</h4>
                <CustomInputtt<feeIndividual>
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_DBL"
                  value={localTarifa.fee_DBL}
                  typeData="number"
                />
                <CustomInputNew
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_TDBL"
                  value={localTarifa.fee_TDBL}
                  typeData="number"
                />
              </div>
              <div className="option-hab">
                <h4>TPL</h4>
                <CustomInputtt<feeIndividual>
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_TPL"
                  value={localTarifa.fee_TPL}
                  typeData="number"
                />
                <CustomInputNew
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_fee_TTPL"
                  value={localTarifa.fee_TTPL}
                  typeData="number"
                />
              </div>
              <div className="option-hab">
              <h4>CPL</h4>
                <CustomInputtt<feeIndividual>
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_CPL"
                  value={localTarifa.fee_CPL}
                  typeData="number"
                />
                   <CustomInputNew
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_TCPL"
                  value={localTarifa.fee_TCPL}
                  typeData="number"
                />
              </div>
              <div className="option-hab">
              <h4>Child</h4>
                <CustomInputtt<feeIndividual>
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_FP"
                  value={localTarifa.fee_FP}
                  typeData="number"
                />
                  <CustomInputNew
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_TFP"
                  value={localTarifa.fee_TFP}
                  typeData="number"
                />
              </div>
              <div className="option-hab">

              </div>
            
              <div className="option-hab">
              <h4>BB</h4>
                <CustomInputtt<feeIndividual>
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_BB"
                  value={localTarifa.fee_BB}
                  typeData="number"
                />
                  <CustomInputNew
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_TBB"
                  value={localTarifa.fee_TBB}
                  typeData="number"
                />
              </div>
              <div className="option-hab">
              <h4>MAID</h4>
                <CustomInputtt<feeIndividual>
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_MAID"
                  value={localTarifa.fee_MAID}
                  typeData="number"
                />
                 <CustomInputNew
                  text=""
                  txtWidth={100}
                  setGeneralData={setLocalTarifa}
                  field="fee_TMAID"
                  value={localTarifa.fee_TMAID}
                  typeData="number"
                />
              </div>
            </div>          
          </div>
        </>
      )}
    </TarifaHabStyles>
  );
}
const TarifaHabStyles = styled.div`
h4{
  font-size: 0.85vw;
}
  @media screen and (max-width: 900px){
    h4{
      font-size: 1.6vw;
    }
    
  }
  .tarifa-hab-box {
    margin-top: 1.5rem;
  }
  .first-row,
  .option-hab {
    width: 70%;
  }
  @media screen and (max-width: 900px){
    .first-row,
  .option-hab {
    width: 100%;
  }
  }
  .option-hab {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;

    margin: 0 auto;
    align-items: center;
  }
  .plus-container {
    display: flex;
    justify-content: right;
    padding-right: 10vw;
  }
  h3 {
    font-size: 1rem;
    text-align: center;
  }
  .show-info-toggle {
    border: rgb(8, 166, 165) solid 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
  .servicios-box-extra {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 1rem;
  }
  .select-default-dos {
    border: rgb(8, 166, 165) solid 2px;
  }
  .angle-down {
    font-size: 2rem;
    cursor: pointer;
  }

  .input-data {
    font-size: 18px;
    padding: 0.5rem 0.5rem;
    border: none;
    max-width: 100%;
  }
  .input-style-data::-webkit-inner-spin-button,
  .input-style-data::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .icons-contenedor {
    margin-bottom: 2rem;
    display: flex;
    justify-content: right;
    padding: 0.9rem;
  }
  .copy-symbol {
    font-size: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
  }
  .options-tarifa {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1.2rem;
  }

  .input-component {
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;
    margin: 0 auto;

    align-items: center;
  }
  .input-component h3 {
    font-size: 1.2vw;
  }
  .input-component input {
    background-color: #ffffff;
    border-style: none;

    cursor: text;
    outline: none;
    border: solid rgb(8, 166, 165) 2px;

    padding-left: 20px;
    margin-bottom: 0.5rem;

    padding: 0.5rem 0.5rem;
    position: relative;
    z-index: 1;
  }

  .tarifa-container-dos {
    padding: 1.3rem;
    
  }
  .first-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    margin: 0 auto;
  }
  .vigencia-header {
    border: rgb(8, 166, 165) solid 2px;
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr;
    justify-items: center;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.8rem;
  }
  .vigencia-box {
    margin-top: 1.4rem;
  }
  .vigencia-title {
    margin-bottom: 1rem;
    color: rgb(8, 166, 165);
  }
`;
const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: rgb(8, 166, 165) solid 2px;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  min-height: 38.4px !important;
  @media screen and (max-width: 900px){
    font-size: 1.6vw;
  }
`;
