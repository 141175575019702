import React, { useState } from "react";
import {
  RegisterGeneralI,
  RegisterInterface,
  dataServicesI,
} from "../interfaces/GeneralTypes";
import { v4 as uuidv4 } from "uuid";
import { clientInterface } from "../interfaces/interfaces";
import {
  registerGeneralData,
  objetoPrueba,
  userInicial,
} from "./initialContext";
import { contactInfoEmpty } from "./initialContext";
import {
  contratoIncial,
  contratoContextInicial,
  fiscalContextInicial,
} from "./initialContext";
import {
  contratoContextI,
  contratoContextInterface,
  contextFiscalI,
  contextCotizacionI,
  userI,
} from "./InterfacesContext";
import { dataServicesInicial } from "./initialContext";
import { dataCotizacionInicial } from "./initialContext";
import { InitialContextOP, OperationI } from "../alico/pages/Operaciones/InterfacesOperacion/OperacionesI";
import { initialResponseDataOP, operationInitialData} from "./initialOperationContext";
interface AppProviderProps {
    children: React.ReactNode;
  }

  interface AppContextInterface {
    showLoader: boolean;
    setShowLoader: (show: boolean) => void;
    currentOperation: OperationI,
    setCurrentOperation: React.Dispatch<React.SetStateAction<OperationI>>, 
    contadorOperacion: number, 
    setContadorOperacion: React.Dispatch<React.SetStateAction<number>>,
    currentEntrada: InitialContextOP,
    setCurrentEntrada: React.Dispatch<React.SetStateAction<InitialContextOP>>,
    providerTypeOperacion: number,
    setProviderTypeOperacion: React.Dispatch<React.SetStateAction<number>>,
    idProveedorSelected: number,
    setIdProveedorSelected: React.Dispatch<React.SetStateAction<number>>,
    idCurrentPayment: number,
    setIdCurrentPayment: React.Dispatch<React.SetStateAction<number>>,
    contabilidadMode: number,
    setContabilidadMode: React.Dispatch<React.SetStateAction<number>>,
    contabilidadLink: number, 
    setContabilidadLink: React.Dispatch<React.SetStateAction<number>>,
  }

  const OperationContext = React.createContext<AppContextInterface>({
    showLoader: false,
    setShowLoader: () => {},
    currentOperation: operationInitialData,
    setCurrentOperation: ()=> {},
    contadorOperacion: 0, 
    setContadorOperacion: ()=>{},
    currentEntrada: initialResponseDataOP,
    setCurrentEntrada: ()=>{},
    providerTypeOperacion: 0,
    setProviderTypeOperacion: () =>{},
    idProveedorSelected: 0,
    setIdProveedorSelected: () =>{},
    idCurrentPayment: 0,
    setIdCurrentPayment: () =>{},
    contabilidadMode: 1,
    setContabilidadMode: () =>{},
    contabilidadLink: 0,
    setContabilidadLink: () =>{}
  });

  const OperationProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [  currentOperation, setCurrentOperation] = useState<OperationI>(operationInitialData);
    const [contadorOperacion, setContadorOperacion] = useState<number>(0); 
    const [currentEntrada, setCurrentEntrada] = useState<InitialContextOP>(initialResponseDataOP);
    const [providerTypeOperacion, setProviderTypeOperacion] = useState<number>(0); 
    const [idProveedorSelected, setIdProveedorSelected] = useState<number>(0);
    const [idCurrentPayment, setIdCurrentPayment] = useState<number>(0);
    const [contabilidadMode, setContabilidadMode] = useState<number>(1); 
    const [contabilidadLink, setContabilidadLink] = useState<number>(0);

    return (
      <OperationContext.Provider
        value={{
          showLoader,
          setShowLoader,
          currentOperation, setCurrentOperation,
          contadorOperacion, setContadorOperacion,
          currentEntrada, setCurrentEntrada,
          providerTypeOperacion, setProviderTypeOperacion,
          idProveedorSelected, setIdProveedorSelected,
          idCurrentPayment, setIdCurrentPayment,
          contabilidadMode, setContabilidadMode,
          contabilidadLink, setContabilidadLink
        }}
      >
        {children}
      </OperationContext.Provider>
    );
  };

  
export { OperationContext, OperationProvider };
  