import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDown,
  faBan,
  faRotateRight,
  faXmark,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import edit from "../../../images/edit.png";
import {
  servicioDayElementI,
  servicioIndividual,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import deletelogo from "../../../images/deletelogo.png";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  serviceInterface,
  categoriaI,
  lenderI,
  City,
  maealI,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import { NameProvider, searchDataI } from "../../../interfaces/Cotizacion";
import { ProviderSearchList } from "../../../interfaces/Cotizacion";
import { generalElementI } from "../../../context/InterfacesContext";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import { obtenerFechaDesdeString } from "../RegisterNewProvider-Client/FunctionsTarifa";
import { useCatalogsDos } from "../../../context/CatalogContextDos";
import {
  ResponseServicesOp,
  ServiceOP,
} from "../Operaciones/InterfacesOperacion/OperacionesI";
interface SearchServiceElementI {
  proveedor: ServiceOP;
  changeCantidad: (provedorSelected: ServiceOP, cantidadUno: number, cantidadId: number) => void;
}
export default function SearchServiceElement({
  proveedor,
  changeCantidad,
}: SearchServiceElementI) {
  
  return (
    <SearchServiceElementStyle>
     
      <div className="element-container">
        <div className="container-data-info">
          <h3>
            <span className="title-servicio">{proveedor.provider_name}</span>{" "}
          </h3>
          <h3>
            {proveedor.service_name} {proveedor.room_type}{" "}
            <span className="money-info">
              ${proveedor.service_fee}{" "}
              {proveedor.currency_identifier == 1
                ? "USD"
                : proveedor.currency_identifier == 2
                ? "MXN"
                : ""}
            </span>{" "}
          </h3>
        </div>
        <div className="container-inputs">
          <input
            type="text"
            onChange={(e) => {
              const cantidad_uno: number = parseInt(e.target.value);
              changeCantidad(proveedor, cantidad_uno, 1);
            }}
          

          />
          <input type="text" 
           onChange={(e) => {
            const value = e.target.value;
            const cantidad_dos = e.target.value;
            if (cantidad_dos === "") {
              changeCantidad(proveedor, 0, 2);
            
                
        
            } else {
              const parsedValue = parseFloat(cantidad_dos);
              if (!isNaN(parsedValue)) {
           
                changeCantidad(proveedor, parseFloat(cantidad_dos), 2);
               
              }
            }


     
            
          }}
          
          value={proveedor.cantidad_dos}
          />
        </div>
      </div>
    </SearchServiceElementStyle>
  );
}

const SearchServiceElementStyle = styled.div`
  .encabezado-busqueda{
    border: black solid 1px; 
  }
`;
