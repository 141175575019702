import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import { Link } from "react-router-dom";
import PersonalInfo from "./PersonalInfo";
import FinancialData from "./FinancialData";
import ServiciosDays from "./ServiciosDays";
import {
  cotizacionListIndividual,
  cotizacionListResults,
  cotizacionListTriple,
} from "../../../context/InterfacesContext";

interface cotizacionInfoI {
  listSingle: cotizacionListIndividual[];
  setListSingle: React.Dispatch<
    React.SetStateAction<cotizacionListIndividual[]>
  >;
  listTriple: cotizacionListTriple[];
  setListTriple: React.Dispatch<React.SetStateAction<cotizacionListTriple[]>>;
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  handleOptionChange: (option: string) => void;
  handleSerieChange: (option: string) => void;
  selectedSerieOption: string;
  setSelectedSerieOption: React.Dispatch<React.SetStateAction<string>>;
}
export default function CotizacionInfo({
  listSingle,
  setListSingle,
  listTriple,
  setListTriple,
  selectedOption,
  setSelectedOption,
  handleOptionChange,
  handleSerieChange,
  selectedSerieOption,
  setSelectedSerieOption,
}: cotizacionInfoI) {
  const {
    setShowSearchServices,
    setIdCurrentDayService,
    setCotizationDate,
    setServiciosType,
    dataCotizacionContext,
    setDataCotizacionContext,
    route,
    setRoute,
    currentCurrency,
  } = useContext(AppContext);

  useEffect(() => {
    console.log("Este useEffect solo se ejecuta en individuales.");
    setServiciosType(1);
  }, []);
  return (
    <CotizacionInfoStyles>
      <PersonalInfo
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        handleOptionChange={handleOptionChange}
        handleSerieChange={handleSerieChange}
        selectedSerieOption={selectedSerieOption}
        setSelectedSerieOption={setSelectedOption}
      />
      <FinancialData />
      <ServiciosDays
        listSingle={listSingle}
        setListSingle={setListSingle}
        listTriple={listTriple}
        setListTriple={setListTriple}
      />

      <div className="button-box">
        <Link to="/dashboard/Alta-Cotizacion/CotizacionGeneral">
          <button className="next-button">Siguiente</button>
        </Link>
      </div>
    </CotizacionInfoStyles>
  );
}

const CotizacionInfoStyles = styled.div`
  .button-box {
    display: flex;
    justify-content: right;
    padding-right: 2rem;
  }
  .next-button {
    background-color: transparent;
    border: none;
    padding: 1rem;
    background-color: rgb(8, 166, 165);
    border-radius: 0.8rem;
    padding: 0.5rem 2rem;
    color: white;
    cursor: pointer;
  }
`;
