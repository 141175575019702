import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Proforma } from "./ProformaI";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";

interface GeneralInfoProformaI {
  proforma: Proforma;
  setProforma: React.Dispatch<React.SetStateAction<Proforma>>
}

export default function GeneralInfoProforma({
  proforma,
  setProforma
}: GeneralInfoProformaI) {
  const { language, setLanguage } = useLanguage();
  return (
    <GeneralInfoProformaStyles>
      <div className="container-info-proforma">
        <div className="dates-container">
          <h3>
            {translations[language].header_table.date_creation}:{" "}
            <span>{proforma.creation_date}</span>
          </h3>
          <h3>
            {translations[language].header_table.date_start_travel}:{" "}
            <span>{proforma.arrival_date}</span>
          </h3>
        </div>
        <div className="general-info">
          <div className="container-name">
            <h3>
              {translations[language].header_table.name_passengers}:
              {/* <span>{proforma.passengers_name}</span> */}
            </h3>
            <input type="text" className="pax-name"
            value = {proforma.passengers_name}
            onChange={(e)=>{
                setProforma({
                  ...proforma,
                  passengers_name: e.target.value
                })
            }}
            />
          </div>

          <h3>
            {translations[language].extra_fields.accomodation}:{" "}
            <span>
              {proforma.sgl > 0 ? proforma.sgl + " SGL / " : ""}{" "}
              {proforma.dbl > 0 ? proforma.dbl + " DBL /" + "" : ""}{" "}
              {proforma.tpl > 0 ? proforma.tpl + " TPL" : ""}
            </span>
          </h3>
          <h3>
            {translations[language].header_table.number_passengers}:{" "}
            <span>{proforma.operation_pax}</span>
          </h3>
          <h3>
            {translations[language].header_table.creator}:{" "}
            <span>{proforma.user}</span>
          </h3>
        </div>
      </div>
    </GeneralInfoProformaStyles>
  );
}

const GeneralInfoProformaStyles = styled.div`
.container-name{
  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;
}
.container-name h3{
  margin-bottom: 0rem !important;
}
input{
  margin-left: 1rem;
}
  .container-info-proforma {
    border: black solid 1px;
    padding: 1rem;
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
  .container-info-proforma div h3 {
    font-size: 0.85vw;

    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 900px) {
    .container-info-proforma div h3 {
      font-size: 1.3vw;
    }
  }
  .container-info-proforma div h3 span {
    font-weight: 300 !important;
  }
  .dates-container {
  }
  .general-info {
  }
`;
