import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import HeaderOpRead from "../../Operaciones/HeaderOpRead";
import { AppContext } from "../../../../context/appContext";
import {
  ExpenseResponse,
  HonoraryResponse,
  ProviderResponse,
  RootObjectResponse,
} from "../../Operaciones/InterfacesOperacion/OperacionesI"; 
import HojaControlPrestador from '../../Operaciones/HojaControl/HojaControlPrestador';
import HojaControlGasto from '../../Operaciones/HojaControl/HojaControlGasto';
import { formatearCantidad } from "../../../components/functionsTs/utilidades";
import HojaControlPrestadorAux from "./HojaControlPrestadorAux";
import HojaControlGastoAux from "./HojaControlGastoAux";
import { TotalesOperacionI } from "./AuxiliaresInterface";


interface HojaControlAuxI{
  totalOperacion: TotalesOperacionI,
  setTotalOperacion: React.Dispatch<React.SetStateAction<TotalesOperacionI>>,
  statusOperacion?: number,
  setStatusOperacion?: React.Dispatch<React.SetStateAction<number>>
}

export default function HojaControlAux({totalOperacion, setTotalOperacion, statusOperacion, setStatusOperacion}:HojaControlAuxI) {
  const { idOperation, setIdOperation, setIdProforma, currentUser, setShowLoader } =
    useContext(AppContext);
  const [listHotels, setListHotels] = useState<ProviderResponse[]>();
  const [listRestaurants, setListRestaurants] = useState<ProviderResponse[]>();
  const [listAgency, setListAgency] = useState<ProviderResponse[]>();
  const [listTransport, setListTransport] = useState<ProviderResponse[]>();
  const [listExpenses, setListExpenses] = useState<ExpenseResponse[]>();
  const [listHonorary, setListHonorary] = useState<HonoraryResponse[]>();

  const getHojaControlData = async () => {
    
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    // setShowLoader(true);

    //////CONSULTAR O CREAR PROFORMA
    //////operation s/getProforma/{OperacionID}/{ProformaID}

    const urlModified =
      backEndPoint + `/accounting/auxiliars/${idOperation}`;

    try {
      setShowLoader(true); 
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
          //   user_identifier: currentUser.user_identifier.toString(),
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data: RootObjectResponse = await response.json();
        console.warn("Datos recuperados:");
        console.log(data.data);
        setListHotels(data.data.providers.hotel);
        setListRestaurants(data.data.providers.restaurant);
        setListAgency(data.data.providers.agency);
        setListTransport(data.data.providers.transport);
        setListExpenses(data.data.providers.expenses);
        setListHonorary(data.data.providers.honorary);
     
        console.log("Adelantos::::");
        console.log(data.data.invoices); 
        if(data.data.invoices){
          setTotalOperacion({
            ...totalOperacion,
            total_proforma: data.data.proforma.proforma_total,
            currency_identifier: data.data.proforma.currency_identifier, 
            saveMode: 2, 
            datosGeneralesOperacion: data.data.invoices,
            tipoCambio: data.data.invoices.exchange_rate,
            status_identifier: data.data.quote.status_identifier
          })
        }else{
          setTotalOperacion({
            ...totalOperacion,
            total_proforma: data.data.proforma.proforma_total,
            currency_identifier: data.data.proforma.currency_identifier,
            status_identifier: data.data.quote.status_identifier 
          })
        }
        // const newAdvancesArray: ProformaAdvance[] =
        //   data.data.proforma_advances.map((proforma, index) => {
        //     let identificadorOpcional = uuidv4();
        //     return {
        //       ...proforma,
        //       id_local: identificadorOpcional,
        //     };
        //   });
        // setProformaAdvances(newAdvancesArray);
        // // setCurrentOperation(data.data);

        setShowLoader(false);
        setShowLoader(false)
      } else {
        // setShowLoader(false);
        setShowLoader(false)

      }
    } catch (error) {
      //   setShowLoader(false);
      setShowLoader(false)
    }
  };
  useEffect(() => {
    getHojaControlData();
  }, []);


  const sumaHotelMXM = listHotels?.[0]?.entry_total_mxn ?? 0;
  const sumaHotelMXNDOS = listHotels?.reduce((total, item) => total + item.entry_total_mxn, 0) ?? 0; 
  const sumaHotelUSD = listHotels?.[0]?.entry_total_usd ?? 0;
  const sumaHotelUSDDOS = listHotels?.reduce((total, item) => total + item.entry_advance_usd, 0) ?? 0; 



  const sumaRestMXN = listRestaurants?.[0]?.entry_total_mxn ?? 0;
  const sumaRestMXNDOS = listRestaurants?.reduce((total,item)=>total + item.entry_total_mxn, 0) ?? 0; 
  const sumaRestUSD = listRestaurants?.[0]?.entry_total_usd ?? 0;
  const sumaRestUSDDOS = listRestaurants?.reduce((total, item) => total + item.entry_advance_usd, 0) ?? 0; 

  const sumAgencyMXN = listAgency?.[0]?.entry_total_mxn ?? 0;
  const sumAgencyMXNDOS = listAgency?.reduce((total, item)=>total + item.entry_total_mxn, 0 ) ?? 0; 
  const sumAgencyUSD = listAgency?.[0]?.entry_total_usd ?? 0;
  const sumAgencyUSDDOS = listHotels?.reduce((total, item) => total + item.entry_advance_usd, 0) ?? 0; 

  const sumTransporteMXN = listTransport?.[0]?.entry_total_mxn ?? 0;
  const sumTransporteMXNDOS = listTransport?.reduce((total, item)=>total + item.entry_total_mxn, 0) ?? 0;

  const sumTransporteUSD = listTransport?.[0]?.entry_total_usd ?? 0;
  const sumTransporteUSDDOS = listTransport?.reduce((total, item) => total + item.entry_advance_usd, 0) ?? 0; 
  

  const sumHonoraryMXN = listHonorary?.[0]?.entry_total_mxn ?? 0;
  const sumHonoraryMXNDOS = listHonorary?.reduce((total, item)=>total + item.entry_total_mxn, 0) ?? 0; 


  const sumHonoraryUSD = listHonorary?.[0]?.entry_total_usd ?? 0;
  const sumHonoraryUSDDOS = listHonorary?.reduce((total, item) => total + item.entry_total_usd, 0) ?? 0; 

  

  const totalFinalMXNDOS =sumaHotelMXNDOS + sumaRestMXNDOS + sumAgencyMXNDOS + sumTransporteMXNDOS + sumHonoraryMXNDOS; 
  const totalFinalUSDDOS =sumaHotelUSDDOS + sumaRestUSDDOS + sumAgencyUSDDOS + sumTransporteUSDDOS + sumHonoraryUSDDOS;
  const totalFinalMXN =
    sumaHotelMXM +
    sumaRestMXN +
    sumAgencyMXN +
    sumTransporteMXN +
    sumHonoraryMXN;
  const totalFinalUSD =
    sumaHotelUSD +
    sumaRestUSD +
    sumAgencyUSD +
    sumTransporteUSD +
    sumHonoraryUSD;

  const sumaOtrosServicios = 0;
  useEffect(()=>{
    
    setTotalOperacion({
      ...totalOperacion,
      total_operacion_usd: (listExpenses?.[0]?.total_usd ?? 0) + totalFinalUSDDOS,
      total_operacion_mxn: (listExpenses?.[0]?.total_mxn ?? 0) + totalFinalMXNDOS, 
    })
  },[listExpenses, listHotels, listRestaurants, listHonorary]);

  return (
    <HojaControlStyle>
      <HeaderOpRead
      
      statusOperacion = {statusOperacion}
      setStatusOperacion = { setStatusOperacion}/>
      <div className="header-hoja-control">
        <div>
          <h3>Nombre</h3>
        </div>
        <div>
          <h3>Clave</h3>
        </div>
        <div>
          <h3>Pagado</h3>
        </div>
        <div>
          <h3>Poliza</h3>
        </div>
        <div>
          <h3>Entrada</h3>
        </div>
        <div>
          <h3>Fecha Entrada</h3>
        </div>
        <div>
          <h3>Fecha Salida</h3>
        </div>
        <div>
          <h3>Peso</h3>
        </div>
        <div>
          <h3>Dolares</h3>
        </div>
      
      </div>
      {/* Hoteles */}
      <HojaControlPrestadorAux prestador={listHotels} tipoPrestador={"Hotel"} />
      {/* Restaurantes */}
      <HojaControlPrestadorAux
        prestador={listRestaurants}
        tipoPrestador={"Restaurantes"}
      />
      {/* Agencias */}
      <HojaControlPrestadorAux prestador={listAgency} tipoPrestador={"Agencias"} />
      {/* Transporte */}
      <HojaControlPrestadorAux
        prestador={listTransport}
        tipoPrestador="Transporte"
      />
      {/* Gasto a comprobar */}
      {
        <div className="first-row">
          <div className="title-table title-table-sp">
            <h3>{"Gastos a comprobar"}</h3>
          </div>
          <div className="botons-one"></div>
        </div>
      }
      {listExpenses?.map((expense, index) => {
        return (
          <HojaControlGastoAux
            prestadorExpense={expense}
            tipoPrestador="Transporte"
          />
        );
      })}
      {/* <h3 className="final-price">
        {" "}
        Total servicios USD:{" "}
        ${formatearCantidad((listExpenses?.[0]?.total_usd ?? 0) + totalFinalUSD)}
      </h3>
      <h3 className="final-price">
        Total servicios MXN: {" "}
        ${formatearCantidad((listExpenses?.[0]?.total_mxn ?? 0) + totalFinalMXN)}
      </h3> */}


      <h3 className="final-price">
        {" "}
        Total servicios USD DOS:{" "}
        ${formatearCantidad((listExpenses?.[0]?.total_usd ?? 0) + totalFinalUSDDOS)}
      </h3>
      <h3 className="final-price">
        Total servicios MXN DOS: {" "}
        ${formatearCantidad((listExpenses?.[0]?.total_mxn ?? 0) + totalFinalMXNDOS)}
      </h3>
    </HojaControlStyle>
  );
}

const HojaControlStyle = styled.div`
  .final-price {
    margin-top: 1rem;
    font-size: 1vw;
  }
  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1.2vw;
    }
  }
  .icon {
    cursor: pointer;
  }
  .first-row {
    display: flex;
  }
  .botons-one {
    width: 0%;
    display: flex;
    justify-content: center;
  }
  .box-buttons {
    margin-left: 1rem;
  }
  .box-buttons button {
    border: none;
    padding: 0.2rem;
    font-size: 0.85vw;
    cursor: pointer;
  }
  .guia-boton {
    margin-right: 1rem;
    background-color: #f6921e;
    color: white;
  }
  .desglose-boton {
    background-color: #29aae1;
    color: white;
  }
  .title-table {
    display: flex;
    align-items: center;
  }

  .list-elements {
    width: 100%;
  }
  .title-table-sp {
    border: black solid 1px;
    color: black;
    background-color: rgba(8, 166, 165, 0.5);
    padding: 0.3rem !important;
    width: 100%;
    padding: 0.3rem;
  }
  .title-table {
    font-size: 0.75vw;
  }
  .header-hoja-control {
    margin-top: 1rem;
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    background-color: rgba(8, 166, 165);
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .header-hoja-control div h3 {
    font-size: 0.85vw;
    font-weight: 400;
    color: white;
    text-align: center;
  }
`;
