import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";

export default function ContabilidadSearchDos() {
  return (
    <ContabilidadSearchDosStyles>
      <h2>ContabilidadSearchDos</h2>
      
      <h2>ContabilidadSearchDos</h2>
      
      <h2>ContabilidadSearchDos</h2>
      
      <h2>ContabilidadSearchDos</h2>
      
      <h2>ContabilidadSearchDos</h2>
      
      <h2>ContabilidadSearchDos</h2>
      
      <h2>ContabilidadSearchDos</h2>
    </ContabilidadSearchDosStyles>
  )
}

const ContabilidadSearchDosStyles = styled.div``;