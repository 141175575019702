import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Proforma, ProformaOptionalPackage, ProformaPrincipalPackage } from "./ProformaI";
import { isEqual } from 'lodash';

interface ElementProformaI {
  currentCotizacion: ProformaPrincipalPackage;
  proforma: Proforma;
  proformaPrincipalPackages: ProformaPrincipalPackage[];
  setProformaPrincipalPackages: React.Dispatch<React.SetStateAction<ProformaPrincipalPackage[]>>
}

export default function   ElementProforma({
  currentCotizacion,
  proforma,
  proformaPrincipalPackages,
  setProformaPrincipalPackages
}: ElementProformaI) {

  const [cotizacionActual, setActualCotizacion] = useState<ProformaPrincipalPackage>(currentCotizacion);
  useEffect(()=>{
    if (!isEqual(cotizacionActual, currentCotizacion)) {
      const arregloOpcinalPackages: ProformaPrincipalPackage[] = proformaPrincipalPackages.map((packageCurrent, index) => {
        return packageCurrent.base_quote_identifier !== cotizacionActual.base_quote_identifier ? packageCurrent : cotizacionActual;
      });
      setProformaPrincipalPackages(arregloOpcinalPackages);
    }

  },[cotizacionActual]);
  

  useEffect(()=>{
    console.log("Se actualizo --------------------------- current cotization");
    setActualCotizacion(currentCotizacion); 
  },[currentCotizacion])
  
  return (
    <ElementProformaStyles>
      <div className="row-table-one">
        <div>
          <h3>{cotizacionActual.base_quote_name}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.city}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.estatus}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.arrival_date}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.departure_date}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.nights}</h3>
        </div>
        <div>
          {/* SGL */}
        {/* <input
              type="text"
              value={proforma.sgl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  // setDesgloceGeneralInfo({
                  //   ...desgloceGeneralInfo,
                  //   guide_courtesy: 0,
                  // });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                  }
                }
              }}
            /> */}
            <h3>{proforma.sgl}</h3>
   
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
           {/* SGL */}
           {/* <input
              type="text"
              value={cotizacionActual.total_sgl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  // setDesgloceGeneralInfo({
                  //   ...desgloceGeneralInfo,
                  //   guide_courtesy: 0,
                  // });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                  }
                }
              }}
            /> */}
            <h3>{cotizacionActual.total_sgl.toFixed(2)}</h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3>{(proforma.sgl*cotizacionActual.total_sgl).toFixed(2)}</h3>
        </div>
      </div>
      <div className="row-table-one">
        <div>
          <h3>{cotizacionActual.base_quote_name}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.city}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.estatus} </h3>
        </div>
        <div>
          <h3>{cotizacionActual.arrival_date}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.departure_date}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.nights}</h3>
        </div>
        <div>
          <h3>{}</h3>
        </div>
        <div>
            {/* TPL */}
            {/* <input
              type="text"
              value={proforma.dbl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  // setDesgloceGeneralInfo({
                  //   ...desgloceGeneralInfo,
                  //   guide_courtesy: 0,
                  // });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                  }
                }
              }}
            /> */}
            <h3>{proforma.dbl}</h3>
        </div>
        <div>
          <h3>{}</h3>
        </div>
        <div>
          <h3>{}</h3>
        </div>
        <div>
            {/* DBL */}
            {/* <input
              type="text"
              value={cotizacionActual.total_dbl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  // setDesgloceGeneralInfo({
                  //   ...desgloceGeneralInfo,
                  //   guide_courtesy: 0,
                  // });
                  setActualCotizacion({
                    ...cotizacionActual,
                    total_dbl: 0
                  })
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                    
                    setActualCotizacion({
                      ...cotizacionActual,
                      total_dbl: parsedValue
                    })
                  }
                }
              }}
            /> */}
            <h3>{cotizacionActual.total_dbl.toFixed(2)}</h3>
        </div>
        <div>
          <h3>{}</h3>
        </div>
        <div>
          <h3>{(proforma.dbl*cotizacionActual.total_dbl*2).toFixed(2)}</h3>  
        </div>
      </div>
      <div className="row-table-one">
        <div>
          <h3>{cotizacionActual.base_quote_name}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.city}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.estatus}   </h3>
        </div>
        <div>
          <h3>{cotizacionActual.arrival_date}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.departure_date}</h3>
        </div>
        <div>
          <h3>{cotizacionActual.nights}</h3>
        </div>
        <div>
          <h3>{}</h3>
        </div>
        <div>
          <h3>{}</h3>
        </div>
        <div>
             {/* TPL */}
             {/* <input
              type="text"
              value={proforma.tpl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  // setDesgloceGeneralInfo({
                  //   ...desgloceGeneralInfo,
                  //   guide_courtesy: 0,
                  // });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                  }
                }
              }}
            /> */}
            <h3>{proforma.tpl}</h3>
        </div>
        <div>
          <h3>{}</h3>
        </div>
        <div>
          <h3>{}</h3>
        </div>
        <div>
        {/* <input
              type="text"
              value={cotizacionActual.total_tpl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  // setDesgloceGeneralInfo({
                  //   ...desgloceGeneralInfo,
                  //   guide_courtesy: 0,
                  // });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                  }
                }
              }}
            /> */}
            <h3>{cotizacionActual.total_tpl.toFixed(2)}</h3>
        </div>
        <div>
          <h3>{(proforma.tpl*cotizacionActual.total_tpl*3).toFixed(2)}</h3>
        </div>
      </div>
    </ElementProformaStyles>
  );
}

const ElementProformaStyles = styled.div`
  margin-top: 0;
  .row-table-one {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: black solid 1px;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    border-top: none;
  }

  .row-table-one div h3 {
    text-align: center;
    font-size: 0.85vw;
    font-weight: 300 !important;
  }
  input{
    width: 95%;
    max-width: 95%;
    font-size: 0.85vw;
    text-align: center;
  }
`;
