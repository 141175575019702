import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import rombos from "../../../images/rombos.png";
import alicologo from "../../../images/alicologo.png";
import { fetchServer } from "../../../services/fetchServer";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import { AppContext } from "../../../context/appContext";
import { userI } from "../../../context/InterfacesContext";

export default function Login() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setPasssword] = useState("");
  const { showLoader, setShowLoader } = useContext(AppContext);
  const { showAlert, setShowAlert } = useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const { titleAlert, setTitleAlert, currentUser, setCurrentUser } =
    useContext(AppContext);

  useEffect(() => {
    // console.log("EMAIL: ");
    // console.log(userEmail);
    // console.log('Password: ');
    // console.log(userPassword);
    console.log("VALOR DE SHOW LOADER DESDE LOGIN: ");
    console.log(showLoader);

    localStorage.setItem("lastPage", "0");
  }, [userEmail, userPassword]);
  useEffect(() => {
    console.log("Datos del contexto: ");
    console.log(currentUser);
  }, [currentUser]);

  async function handleLogin() {
    // Validar que los campos no estén vacíos
    if (!userEmail || !userPassword) {
      setTitleAlert("Error al iniciar sesión");
      setContentAlert(
        "Por favor, complete los campos de correo electrónico y contraseña."
      );
      setShowAlert(true);
      return;
    }

    const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/login`;
    const user = userEmail;
    const password = userPassword;
    setShowLoader(true);
    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const usuarioInfo: userI = data.data.user;
        console.log("Login successful!!!!:", data);
        setCurrentUser(data.data.user);
        localStorage.setItem("usuarioLogeado", usuarioInfo.user_name);
        localStorage.setItem(
          "groupUsuario",
          usuarioInfo.group_identifier.toString()
        );
        localStorage.setItem("userId", usuarioInfo.user_identifier.toString());

        navigate("/dashboard/Registro");
        setShowLoader(false);
      } else {
        console.error("Error in response:", response);

        setShowLoader(false);
        setTitleAlert("Error al iniciar sesión");
        setContentAlert("Por favor, digite un email y contraseña valido.");
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error in request:", error);
      alert("Error en el servicio");
      setShowLoader(false);
    }
  }
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleLogin();
    }
  }

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.keyCode === 27) {
        setShowAlert(false);
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <LoginStyle>
      <div
        className="login-content"
        style={{ backgroundImage: `url(${rombos})` }}
      >
        <div className="login-box">
          <div className="login-data">
            <div className="formulario">
              <img
                src={alicologo}
                alt="Logo de Alico Tours"
                width="100"
                className="alico-picture"
              />
              <h4 className="login-start">Inicio de sesión</h4>
              <input
                type="text"
                className="input-element"
                value={userEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUserEmail(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              <input
                type="password"
                className="input-element"
                value={userPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasssword(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              <button className="boton-login" onClick={handleLogin}>
                Iniciar sesión
              </button>
              <br />
              <br />
              <div>Version 1.0.0 2406071242</div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyle>
  );
}

const LoginStyle = styled.div`
  @media screen and (min-width: 900px) {
  }
  .boton-login {
    border: none;
    outline: none;
    background-color: #08a6a5;
    color: white;
    padding: 0.4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 0.8rem;
    margin-top: 0.6rem;
  }
  .input-element {
    border: none;
    outline: none;
    background-color: transparent;
    border: grey 1px solid;
    margin-bottom: 0.5rem;
    padding: 0.3rem;
  }
  .login-start {
    margin-bottom: 1rem;
  }
  .formulario {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .alico-picture {
    margin-bottom: 1rem;
  }
  .login-content {
    border: black solid 1px;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #08a6a5;
    display: flex;
    align-items: center;
  }
  .login-data {
    width: 80%;
    margin: 0 auto;
    background-color: ${process.env.REACT_APP_BACKEND_ENDPOINT ==
    "https://corecms.alicomx.com/api"
      ? "white"
      : process.env.REACT_APP_BACKEND_ENDPOINT ==
        "https://dev.corecms.alicomx.com/api"
      ? "#DC3545"
      : "white"};
    border-radius: 1.2rem;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-box {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
  }

  @media screen and (min-width: 900px) {
    .login-box {
      width: 50%;
      height: 100%;
      margin: 0 auto;
      overflow-x: visible;
    }
    .login-data {
      min-width: 70vw !important;
      max-width: 70vw;
      height: 70vh;
      position: absolute;
      top: calc(50% - 35vh);
      left: calc(50% - 35vw);
    }

    .alico-picture {
      width: 200px;
      margin-bottom: 2rem !important;
    }
    .login-start {
      font-size: 1.5rem;
      margin-bottom: 2rem !important;
    }
    .input-element {
      margin-bottom: 1rem !important;
      min-width: 15vw;
      padding: 0.4rem !important;
    }

    .boton-login {
      width: 15vw;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-radius: 1rem;
    }
  }
`;
