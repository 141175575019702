import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { RefundObjectDelete } from "../InterfacesContabilidad";
interface OptionSwitchContabilidadI {
  modeSwitch: number;
  setModeSwitch: React.Dispatch<React.SetStateAction<number>>;
  deleteMode: boolean;
  setDeleteMode: React.Dispatch<React.SetStateAction<boolean>>;
  setListForDelete: React.Dispatch<React.SetStateAction<RefundObjectDelete[]>>;
}

export default function OptionSwitchContabilidad({
  modeSwitch,
  setModeSwitch,
  deleteMode,
  setDeleteMode,
  setListForDelete,
}: OptionSwitchContabilidadI) {
  return (
    <OptionSwitchCStyles>
      <div className="container-options">
        <div
          className={`${modeSwitch == 1 ? "active-boton" : ""}`}
          onClick={() => {
            setModeSwitch(1);
            setDeleteMode(false);
            setListForDelete([]);
          }}
        >
          <h3>Proveedores</h3>
        </div>
        <div
          className={`${modeSwitch == 2 ? "active-boton" : ""}`}
          onClick={() => {
            setModeSwitch(2);
            setDeleteMode(false);
            setListForDelete([]);
          }}
        >
          <h3>Agencias</h3>
        </div>
      </div>
    </OptionSwitchCStyles>
  );
}

const OptionSwitchCStyles = styled.div`
  .container-options {
    border-radius: 0.5rem;
    width: 250px;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 6px 2px;
  }
  .container-options div {
    border-radius: 0.3rem;
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    cursor: pointer;
  }
  .active-boton {
    border: #f6921e solid 1px;
    background-color: #f6921e;
  }
  h3 {
    font-weight: 600;
    font-size: 0.9vw;
  }
  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1.5vw;
    }
  }
`;
