import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import EDITAR from "../../../images/edit.png";
import StyledTable from "../tables/StyledTable";
import {
  GeneralsData,
  bodyResponseProvider,
  ContactData,
  Tax,
  Service,
  Contract,
} from "../../../interfaces/ProviderTypes";
import guardar from "../../../images/GUARDARDATOS.png";
import Generales from "../layout/Generales";
import ContactLayoutNew from "../layout/ContactLayoutNew";
import GetRowContact from "../layout/GetRowContact";
import GetContact from "../layout/GetContact";
import { v4 as uuidv4 } from "uuid";
import {
  objectCard,
  serviceObject,
  blackoutObject,
  validityDates,
  feeIndividual,
} from "../../../interfaces/GeneralTypes";
import { objetoPrueba } from "../../../context/initialContext";
import {
  fiscalContextInicial,
  registerGeneralData,
} from "../../../context/initialContext";
import GetFiscal from "../layout/GetFiscal";
import {
  faFilePdf,
  faFileImage,
  faClose,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import GetContract from "../layout/GetContract";
import {
  contratoContextI,
  contratoContextInterface,
} from "../../../context/InterfacesContext";
import { dataServicesInicial } from "../../../context/initialContext";
import { useNavigate } from "react-router-dom";
import {
  BankAccount,
  ServiceElement,
  ServiceBlackout,
  FeeElement,
  ServiceValidity,
} from "../../../interfaces/ProviderTypes";
import { Fee } from "../../../interfaces/ProviderTypes";

interface ServiceGeneralI {
  fee: Fee;
  serviceTypeId: number;
}

export default function ServiceHIndividual({
  fee,
  serviceTypeId,
}: ServiceGeneralI) {
  return (
    <ServiceGIndividualStyles>
      <div className="box-container">
        <div className="filaa">
          <p>
            Moneda: <span className="value">{fee.fee_currency}</span>
          </p>
          {fee.fee_iva != 0 && (
            <p>
              IVA: <span className="value">{fee.fee_iva} %</span>
            </p>
          )}

          {fee.fee_tax != 0 && (
            <p>
              Impuesto: <span className="value">{fee.fee_tax} %</span>
            </p>
          )}
        </div>
        <div className="columns-container">
          <div className="column-one">
            <div>{fee.fee_TSGL != 0 && <div>SGL: {fee.fee_SGL}</div>}</div>
            <div>{fee.fee_TDBL != 0 && <div>DBL: {fee.fee_DBL}</div>}</div>
            <div>{fee.fee_TTPL != 0 && <div>TPL: {fee.fee_TPL}</div>}</div>
            <div>{fee.fee_TCPL != 0 && <div>CPL: {fee.fee_CPL}</div>}</div>
            <div>{fee.fee_TFP != 0 && <div>Child: {fee.fee_FP}</div>}</div>
            
            <div>{fee.fee_BB != 0 && <div>BB: {fee.fee_BB}</div>}</div>
            <div>{fee.fee_MAID != 0 && <div>MAID: {fee.fee_MAID}</div>}</div>
          </div>
          <div className="column-two">
            <div>
            {fee.fee_TSGL != 0 && <div>TSGL: {fee.fee_TSGL}</div>}
            </div>
            <div>
            {fee.fee_TDBL != 0 && <div>TDBL: {fee.fee_TDBL}</div>}
            </div>
            <div>
            {fee.fee_TTPL != 0 && <div>TTPL: {fee.fee_TTPL}</div>}
            </div>
            <div>
            {fee.fee_TCPL != 0 && <div>TCPL: {fee.fee_TCPL}</div>}
            </div>
            <div>
            {fee.fee_TFP != 0 && <div>TChild: {fee.fee_TFP}</div>}
            </div>
            
            <div>
            {fee.fee_BBT != 0 && <div>TBB: {fee.fee_BBT}</div>}
            </div>
            <div>
            {fee.fee_TMAID != 0 && <div>TMAID: {fee.fee_TMAID}</div>}
            </div>                                                                        
            
          </div>
        </div>
      </div>
    </ServiceGIndividualStyles>
  );
}

const ServiceGIndividualStyles = styled.div`
  .columns-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 50%;
    border: rgba(8, 166, 165, 1) solid 2px;
    padding: 0.5rem;
    border-top: none;
  }
  .filaa {
    border: rgba(8, 166, 165, 0.8) solid 2px;
    width: 50%;
  }
  @media screen and (max-width: 900px) {
    .filaa,
    .columns-container {
      width: 100% !important;
    }
  }
  .column-one div,
  .column-two div {
    margin-bottom: 0.5rem;
  }
  .box-container {
  }
  font-size: 1vw;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
