import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import GerenteTable from "./GerenteTable";
import OptionSwitch from "./Components/OptionSwitch";
import CotizacionDetails from "./CotizacionDetails";
import { BrowserRouter, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import HeaderInfo from "./HeaderInfo";
import PrestadoresList from "./PrestadoresList";
import HeaderOpEdit from "./HeaderOpEdit";
import guardardatos from '../../../images/GUARDARDATOS.png'; 
import { OperationNewProviders, QuoteInterface } from "./InterfacesOperacion/OperacionesI";
import { OperationContext } from "../../../context/operationContext";
export default function DefinicionPrestador() {
    const {currentOperation} = useContext(OperationContext)

 
  const [statusOperacion, setStatusOperacion] = useState<number>(1);
   
  return (
    <DefinicionPrestadorStyle>
      <HeaderOpEdit 
      statusOperacion = {statusOperacion}
      setStatusOperacion = {setStatusOperacion}
      />
      <div className="definicion-title">
        <h3>Definición de prestadores</h3>
      </div>
      <PrestadoresList 
          statusOperacion = {statusOperacion}
          setStatusOperacion = {setStatusOperacion}
      />
      
  

    </DefinicionPrestadorStyle>
  );
}

const DefinicionPrestadorStyle = styled.div`

.buttons{
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 1rem;
  bottom: 0.2rem;
  background-color: #f2f2f2;
  z-index: 9  ;
}
.definicion-title {
    margin-top: 1rem;
    color: rgb(8, 166, 165);
  }
`;
