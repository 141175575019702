import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import ElementClient from "../layout/ElementClient";
import { AppContext } from "../../../context/appContext";
import { ApiResponse } from "../../../interfaces/interfaces";
import SearchBoxClients from "../common/SearchBoxClients";
import { DeleteProvedor } from "../../../interfaces/DeleteTypes";
import { GeneralDeleteI } from "../../../interfaces/GuardadoTypes";
import {
  GeneralsData,
  bodyResponseProvider,
} from "../../../interfaces/ProviderTypes";

export default function ProviderList() {
  const [data, setData] = useState<ApiResponse["data"]>({
    provider_list: [],
  });
  const [initialData, setInitialData] = useState<ApiResponse["data"]>({
    provider_list: [],
  });
  const [resultsList, setResultsList] = useState<ApiResponse["data"]>({
    provider_list: [],
  });

  const {
    showInfoContact,
    setShowInfoContact,
    contactCurrentInfo,
    showLoader,
    setShowLoader,
  } = useContext(AppContext);
  useEffect(() => {
    const getData = async () => {
      setShowLoader(true);
      const lugares = await fetchServer("/provider/GetProviderList");
      console.log("INFO");
      console.log(lugares);
      setData(lugares.data);
      setInitialData(lugares.data);
      setShowLoader(false);
    };
    getData();
    console.log("CONTACT INFO USE CONTEXT: ");
    console.log(contactCurrentInfo);
  }, []);
  const deleteProvider = async (idCurrentProvedor: number) => {
    const provedor: bodyResponseProvider = await fetchServer(
      `/GetRegisterDetail/${idCurrentProvedor}`
    ).catch((e) => {
      console.log("HERREE");
      alert("No se pudo recuperar la información del proovedor");
    });
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    const urlModified = backEndPoint + "/provider/update";
    console.log("RESPUESTA SOBRE PROVEDOR DEL SERVIDOR: ");
    console.log(provedor.data);
    const datosGenerales: GeneralsData =
      provedor?.data?.register_detail.generals;
    let datosGeneralesModificados: GeneralDeleteI = {
      register_identifier: datosGenerales.register_identifier,
      name: datosGenerales.name,
      registration_type_identifier: datosGenerales.registration_type_identifier,
      lender_type_identifier: datosGenerales.lender_type_identifier,
      provider_type_identifier: datosGenerales.provider_type_identifier,
      business_name: datosGenerales.business_name,
      street: datosGenerales.street,
      number: datosGenerales.number,
      neighborhood_identifier: datosGenerales.neighborhood_identifier,
      city_identifier: datosGenerales.city_identifier,
      country_identifier: datosGenerales.country_identifier,
      zip_code: datosGenerales.zip_code,
      important_notes: datosGenerales.important_notes,
      description: datosGenerales.description,
      id: datosGenerales.id,
      key: datosGenerales.key,
      rfc: datosGenerales.rfc,
      category: datosGenerales.category_identifier,
      last_purchase: datosGenerales.last_purchase,
      feedback: datosGenerales.feedback,
      lead: datosGenerales.lead,
      commission_scheme: datosGenerales.commission_scheme,
      web: datosGenerales.web,
      comments: datosGenerales.comments,
      category_identifier: datosGenerales.category_identifier,
      status_identifier: 2,
    };
    console.log("Datos generales:::");
    console.log(datosGenerales);
    const modifiedObject: DeleteProvedor = {
      user_identifier: 1,
      general: datosGeneralesModificados,
    };
    try {
      const response = await fetch(urlModified, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(modifiedObject),
      });
      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        alert("PRovedor borrado:");
        // setTitleAlert("Modificación correcta correcto");
        // setContentAlert(
        //   "Tus datos fueron modificados correctamente, puedes consultarlos en el catalogo."
        // );

        // setShowAlert(true);
        // setShowLoader(false);
        const getData = async () => {
          setShowLoader(true);
          const lugares = await fetchServer("/provider/GetProviderList");
          console.log("INFO");
          console.log(lugares);
          setData(lugares.data);
          setInitialData(lugares.data);
          setShowLoader(false);
        };
        getData();
        // setResultsList({
        //   provider_list: [],
        // });
        const newListResults = resultsList.provider_list.filter((elemento, index)=>{
          return elemento.identifier !== idCurrentProvedor
        });
        setResultsList({
          provider_list: newListResults
        }); 
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al BORRAR:");
        // setContentAlert(
        //   "Tus datos no fueron guardados correctamente, por favor, verificalos."
        // );
        // setShowAlert(true);
        // setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR PETICION
      alert("ERROR AL REALIZAR BORRADO");
      // setShowLoader(false);
    }
  };
  return (
    <ProviderListStyles>
      <div className="box-container">
        <div className="element-client-box">
          <div className="title-box">
            <h3>Consulta de provedor/cliente</h3>
            <SearchBoxClients
              data={data}
              setData={setData}
              initialData={initialData}
              resultsList={resultsList}
              setResultsList={setResultsList}
            />
          </div>

          {resultsList?.provider_list.length > 0
            ? resultsList?.provider_list
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((client) => (
                  <ElementClient
                    clientInfo={client}
                    deleteProvider={deleteProvider}
                  />
                ))
            : null}
        </div>
      </div>
    </ProviderListStyles>
  );
}

const ProviderListStyles = styled.div`
  /* position: absolute; */
  .title-box {
    text-align: center;

    margin: 0 auto;
    padding: 2rem 2.5rem;
    margin-bottom: 1rem;
    border-radius: 0.6rem;
  }
  .title-box h3 {
    font-size: 2.7rem;
    font-weight: bolder;
    color: rgb(8, 166, 165);
  }
  .element-client-box {
    border: rgb(8, 166, 165);
    background-color: rgba(133, 198, 195, 0.5);
    min-height: 100vh;
    width: 85%;
    margin: 0 auto;
    border-radius: 0.4rem;
    padding: 1rem 0 1.3rem 1.3rem;
    margin-top: 2rem;
  }
  .box-container {
    margin-bottom: 3rem;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #4caf50;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  @media screen and (max-width: 900px) {
    /* Estilos para dispositivos móviles */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    tr {
      margin-bottom: 10px;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
      text-align: left;
    }

    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
      font-weight: bold;
    }
  }
`;
