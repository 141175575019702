import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import EDITAR from "../../../images/edit.png";
import StyledTable from "../tables/StyledTable";
import {
  GeneralsData,
  bodyResponseProvider,
  ContactData,
  Tax,
  Service,
  Contract,
} from "../../../interfaces/ProviderTypes";
import guardar from "../../../images/GUARDARDATOS.png";
import Generales from "../layout/Generales";
import ContactLayoutNew from "../layout/ContactLayoutNew";
import GetRowContact from "../layout/GetRowContact";
import GetContact from "../layout/GetContact";
import { v4 as uuidv4 } from "uuid";
import {
  objectCard,
  serviceObject,
  blackoutObject,
  validityDates,
  feeIndividual,
} from "../../../interfaces/GeneralTypes";
import { objetoPrueba } from "../../../context/initialContext";
import {
  fiscalContextInicial,
  registerGeneralData,
} from "../../../context/initialContext";
import GetFiscal from "../layout/GetFiscal";
import {
  faFilePdf,
  faFileImage,
  faClose,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { transformarFecha } from "../../pages/Cotizacion/funciontes";

interface GetContractI {
  contractData: Contract | undefined;
  getInfoProvider: () => void;
}

export default function GetContract({
  contractData,
  getInfoProvider,
}: GetContractI) {
  const navigate = useNavigate();
  const { setShowInfoContact } = useContext(AppContext);

  const editarContrato = () => {
    getInfoProvider();
    navigate("/dashboard/Registro/Contrato");
    setShowInfoContact(false);
  };
  function abrirEnNuevaPestana(data: string): void {
    console.log("Datos del documento:"); 
    console.log(data);
    if (data.startsWith('http://') || data.startsWith('https://')) {
        // Si es una URL, abrir en una nueva pestaña
        window.open(data, '_blank');
    } else if (data.startsWith('data:')) {
        // Si es un archivo base64, crear un Blob y abrir en una nueva pestaña
        const byteCharacters = atob(data.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' }); // Cambia el tipo MIME según el archivo base64
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL, '_blank');
    } else {
        console.error('Formato no compatible: el string no es una URL válida ni un archivo base64.');
    }
}

function generarURL(direccionArchivo: string, variableEntorno: string, uuid: string): string {
  let ambiente: number;

  if (variableEntorno === "https://dev.corecms.alicomx.com/api") {
    ambiente = 1;
  } else if (variableEntorno === "https://corecms.alicomx.com/api") {
    ambiente = 2;
  } else {
    return ""; 
    throw new Error("Variable de entorno no válida");
  }

  const baseUrl = (ambiente === 1) ? "https://dev.corecms.alicomx.com" : "https://Corecms.alicomx.com";

  // Obtener la ruta del archivo sin el nombre del archivo
  const partesRuta = direccionArchivo.split('\\');
  partesRuta.pop();  // Eliminar el nombre del archivo
  const rutaSinNombreArchivo = partesRuta.join('\\');

  return `${baseUrl}/${rutaSinNombreArchivo.replace(/\\/g, "/").replace(/^.*?\bContracts\b/, "Contracts")}/${uuid}`;
}

  return (
    <GetContractStyles>
      <div className="title-first">
        <h3>Contrato</h3>
        <img src={EDITAR} onClick={editarContrato}></img>
      </div>
      <br />

      <div className="content-first content-second">
        <div className="content-first-box">
          <h4>Fecha inicio: {contractData?.start_date.trim() ? (transformarFecha(contractData?.start_date || "")): ""}</h4>
          
          <h4>Fecha fin: {contractData?.end_date.trim() ? transformarFecha(contractData?.end_date || ""): ""}</h4>
        </div>
        {contractData?.files && (
          <div className="content-second-box second-files">
            <div className="box-files">
              <div className="title-files">
                <h3>Tus archivos {""}</h3>
              </div>

              {contractData?.files.map((file, index) => {
                return (
                  <div
                    className={`container-file ${
                      contractData.files.length - 1 == index
                        ? "no-border-bottom"
                        : ""
                    }`}
                    onClick={()=>{
                      console.log("Liga al archivo"); 
                      console.log("archivo:");
                      console.log(file); 
                      // abrirEnNuevaPestana(file.file_url);
                      abrirEnNuevaPestana((generarURL(file.file_url, process.env.REACT_APP_BACKEND_ENDPOINT || "", file.file_name)))
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFileImage}
                      className="icon-format"
                    />{" "}
                    <h4>{file.file_alt_name}</h4>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </GetContractStyles>
  );
}

const GetContractStyles = styled.div``;
