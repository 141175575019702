import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../../../context/appContext";
import {
  BankAccountI,
  Proforma,
  ProformaAdvance,
  ProformaAdvanceData,
  ProformaData,
  ProformaOptionalPackage,
  ProformaOptionalPackageData,
  ProformaPrincipalPackage,
  ProformaPrincipalPackageDataa,
  ProformaRequest,
  ResponseObjectProforma,
  tazasI,
} from "./ProformaI";
import GeneralInfoProforma from "./GeneralInfoProforma";
import PrincipalPackages from "./PrincipalPackages";
import OptionalPackages from "./OptionalPackages";
import Anticipos from "./Anticipos";
import { v4 as uuidv4 } from "uuid";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";
import TextBoxBigInstructions from "./TextBoxBigInstructions";
import LimitPayment from "./LimitPayment";
import CardPayment from "./CardPayment";
import { useCatalogsOperacion } from "../../../../context/OperacionCatalog";
import guardardatos from "../../../../images/GUARDARDATOS.png";
import AdressTwo from "./AdressTwo";
import { NumericFormat } from "react-number-format";

export default function ManageProforma() {
  const { idProforma, setShowLoader, idOperation, currentUser } =
    useContext(AppContext);
  const { language, setLanguage } = useLanguage();

  const { catalogsDataOperaciones } = useCatalogsOperacion();

  const [proforma, setProforma] = useState<Proforma>({
    proforma_identifier: 0,
    operation_identifier: 0,
    cotizacion_identifier: 0,
    status_identifier: 0,
    user_identifier: 0,
    user: "",
    creation_date: "",
    passengers_name: "",
    sgl: 0,
    dbl: 0,
    tpl: 0,
    cpl: 0,
    quote_pax: 0,
    operation_pax: 0,
    courtesy: 0,
    currency_identifier: 0,
    currency: "",
    Total_mxn: 0,
    Total_usd: 0,
    arrival_date: "",
    bank_account_identifier: 0,
    exchange_rate: 0,
    exchange_rateEURDLS: 0,
  });
  const [proformaAdvances, setProformaAdvances] = useState<ProformaAdvance[]>(
    []
  );

  const [proformaOpcionalPackages, setProformaOpcionalPackages] = useState<
    ProformaOptionalPackage[]
  >([]);
  const [proformaPrincipalPackages, setProformaPrincipalPackages] = useState<
    ProformaPrincipalPackage[]
  >([]);
  const [bankAccountList, setBankAccountList] = useState<BankAccountI[]>([]);
  const [currentCurrencyProforma, setCurrentCurrency] = useState(1);
  const [euroADolar, setEuroADolar] = useState<number>(0);
  const [tazasCambio, setTazasCambio] = useState<tazasI>({
    tazaPesosDolares: 0,
    tazaEurosDolares: 0,
    tazaPesosEuros: 0,
  });
  ///////////////Se declara anticipo default, en caso de que el historial de anticipos, llegue vacio
  let identificadorDefault = uuidv4();
  const newElement: ProformaAdvance = {
    advance_identifier: 0,
    proforma_identifier: 0,
    payment_number: 0,
    payday_limit: "",
    currency_identifier: 1,
    currency: "Dólar",
    total: 0,
    id_local: identificadorDefault,
  };
  const newArrayAdvance: ProformaAdvance[] = [newElement];
  const [cambioDolarAPeso, setCambioDolarAPeso] = useState<number>(0);
  ////////////////////OBTENER PROFORMA////////////////////////////////////

  // useEffect para actualizar tazasCambio cuando euroADolar cambia
  useEffect(() => {
    const tazaPesosDolares = Math.pow(cambioDolarAPeso, -1);
    console.log("CCCCCCCCCCCC")
    if (!isNaN(euroADolar) && euroADolar !== 0) {
      const tazaEurosDolares = Math.pow(euroADolar, -1);
      const tazaPesosEuros = 1 / (cambioDolarAPeso * euroADolar);
      setTazasCambio({
        tazaEurosDolares: tazaEurosDolares,
        tazaPesosDolares: tazaPesosDolares,
        tazaPesosEuros: tazaPesosEuros,
      });
    } else {
      setTazasCambio({
        tazaEurosDolares: 0,
        tazaPesosDolares: tazaPesosDolares,
        tazaPesosEuros: 0,
      });
    }
  }, [euroADolar, cambioDolarAPeso]);
  const getProformaInfo = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    setShowLoader(true);

    //////CONSULTAR O CREAR PROFORMA
    //////operations/getProforma/{OperacionID}/{ProformaID}
    //// SI el id de la proforma, es 0, regresa el número de operación en OperaciónID, sino, regresa cero
    ///// EL e id de la proforma es cero, se crea una nueva proforma.

    const urlModified =
      backEndPoint +
      `/operations/getProforma/${
        idProforma == 0 ? idOperation : 0
      }/${idProforma}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data: ResponseObjectProforma = await response.json();
        const currentCurrencyProforma = data.data.proforma.currency_identifier;
        const exchange_rate_local = data.data.proforma.exchange_rate;
        const equivalenciaEuroDolar = data.data.proforma.exchange_rateEURDLS;

        setCambioDolarAPeso(exchange_rate_local);
        setEuroADolar(data.data.proforma.exchange_rateEURDLS);
        const tazaPesosDolares = Math.pow(exchange_rate_local, -1);
        let  tazaEurosDolares = 0; 
        if(data.data.proforma.exchange_rateEURDLS){
          
          tazaEurosDolares = Math.pow(data.data.proforma.exchange_rateEURDLS, -1);
        }
        const tazaPesosEuros = 1/(cambioDolarAPeso*data.data.proforma.exchange_rateEURDLS);
        ////////////////////TAZA EURO A DOLAR
        const tazasCambioCurrent = {
          tazaEurosDolares: tazaEurosDolares,
          tazaPesosDolares: tazaPesosDolares,
          tazaPesosEuros: tazaPesosEuros,
        }

        

        ///////////////////////FIN TAZA EURO A DOLAR

        console.log("Proforma actual");
        console.log(data.data.proforma);
        setProforma(data.data.proforma);
        console.log(data.data.proforma_advances);
        ///////////////////Adaptar moneda/////////////////////////////////////////////////////////////////////////////////////////////
        let proformaPrincipalArray: ProformaPrincipalPackage[];
        let proformaOpcionalPackagesArray: ProformaOptionalPackage[];
        let proformaAdvanceArray: ProformaAdvance[];
        if (data.data.proforma_principal_packages) {
          proformaPrincipalArray = data.data.proforma_principal_packages.map(
            (currentPackage, index) => {
              let objetoConCambio: ProformaPrincipalPackage;
              /////////////////Se requiere dolar, pero llega en pesos

              if (
                currentPackage.currency_identifier == 2 &&
                currentCurrencyProforma == 1
              ) {
                objetoConCambio = {
                  ...currentPackage,
                  total_sgl: currentPackage.total_sgl / exchange_rate_local,
                  total_dbl: currentPackage.total_dbl / exchange_rate_local,
                  total_tpl: currentPackage.total_tpl / exchange_rate_local,
                  total_mxn: currentPackage.total_mxn / exchange_rate_local,
                  total_usd: currentPackage.total_usd / exchange_rate_local,
                  currency_identifier: 1,
                  currency: "Dólar",
                };
                ////// Se requiere en pesos, pero llega en dls
              } else if (
                currentPackage.currency_identifier == 1 &&
                currentCurrencyProforma == 2
              ) {
                objetoConCambio = {
                  ...currentPackage,
                  total_sgl: currentPackage.total_sgl * exchange_rate_local,
                  total_dbl: currentPackage.total_dbl * exchange_rate_local,
                  total_tpl: currentPackage.total_tpl * exchange_rate_local,
                  total_mxn: currentPackage.total_mxn * exchange_rate_local,
                  total_usd: currentPackage.total_usd * exchange_rate_local,
                  currency_identifier: 2,
                  currency: "Peso",
                };

                ////Llega en dls, se requiere en EUROS
              }else if(
                currentPackage.currency_identifier == 1 &&
                currentCurrencyProforma == 3
              ){
                objetoConCambio = {
                  ...currentPackage,
                  total_sgl: currentPackage.total_sgl * tazasCambioCurrent.tazaEurosDolares,
                  total_dbl: currentPackage.total_dbl * tazasCambioCurrent.tazaEurosDolares,
                  total_tpl: currentPackage.total_tpl * tazasCambioCurrent.tazaEurosDolares,
                  total_mxn: currentPackage.total_mxn * tazasCambioCurrent.tazaEurosDolares,
                  total_usd: currentPackage.total_usd * tazasCambioCurrent.tazaEurosDolares,
                  currency_identifier: 3,
                  currency: "EURO",
                };
                ////Llega en pesos, se requiere en EUROS
              }else if(
                currentPackage.currency_identifier == 2 &&
                currentCurrencyProforma == 3
              ){
                objetoConCambio = {
                  ...currentPackage,
                  total_sgl: currentPackage.total_sgl *  tazasCambioCurrent.tazaPesosEuros,
                  total_dbl: currentPackage.total_dbl *  tazasCambioCurrent.tazaPesosEuros,
                  total_tpl: currentPackage.total_tpl *  tazasCambioCurrent.tazaPesosEuros,
                  total_mxn: currentPackage.total_mxn *   tazasCambioCurrent.tazaPesosEuros,
                  total_usd: currentPackage.total_usd *  tazasCambioCurrent.tazaPesosEuros,
                  currency_identifier: 3,
                  currency: "EURO",
                };
              } else{
                ///Llega en la moneda deseada
                objetoConCambio = {
                  ...currentPackage,
                };
              }

              return objetoConCambio;
            }
          );
          setProformaPrincipalPackages(proformaPrincipalArray);
        } else {
          setProformaPrincipalPackages([]);
        }

        if (data.data.proforma_optional_packages) {
          proformaOpcionalPackagesArray =
            data.data.proforma_optional_packages.map(
              (currentPackage, index) => {
                let objetoConCambio: ProformaOptionalPackage;
                
                if (
                  currentPackage.currency_identifier == 2 &&
                  currentCurrencyProforma == 1
                ) {
                  objetoConCambio = {
                    ...currentPackage,
                    total_sgl: currentPackage.total_sgl / exchange_rate_local,
                    total_dbl: currentPackage.total_dbl / exchange_rate_local,
                    total_tpl: currentPackage.total_tpl / exchange_rate_local,
                    currency_identifier: 1,
                    currency: "Dólar",
                  };
                  ////// Se requiere en pesos, pero llega en dls
                } else if (
                  currentPackage.currency_identifier == 1 &&
                  currentCurrencyProforma == 2
                ) {
                  objetoConCambio = {
                    ...currentPackage,
                    total_sgl: currentPackage.total_sgl * exchange_rate_local,
                    total_dbl: currentPackage.total_dbl * exchange_rate_local,
                    total_tpl: currentPackage.total_tpl * exchange_rate_local,
                    currency_identifier: 2,
                    currency: "Peso",
                  };
                  //// Se requiere en EUROS, llega en dls
                } else if(
                  currentPackage.currency_identifier == 1 &&
                  currentCurrencyProforma == 3
                ){
                  objetoConCambio = {
                    ...currentPackage,
                    total_sgl: currentPackage.total_sgl * tazasCambioCurrent.tazaEurosDolares,
                    total_dbl: currentPackage.total_dbl * tazasCambioCurrent.tazaEurosDolares,
                    total_tpl: currentPackage.total_tpl * tazasCambioCurrent.tazaEurosDolares,
                    currency_identifier: 3,
                    currency: "EURO",
                  };
                  /// Se requiere en EUROS, llega en pesos
                }else if(
                  currentPackage.currency_identifier == 2 &&
                  currentCurrencyProforma == 3
                ){
                  objetoConCambio = {
                    ...currentPackage,
                    total_sgl: currentPackage.total_sgl * tazasCambioCurrent.tazaPesosEuros,
                    total_dbl: currentPackage.total_dbl * tazasCambioCurrent.tazaPesosEuros,
                    total_tpl: currentPackage.total_tpl * tazasCambioCurrent.tazaPesosEuros,
                    currency_identifier: 3,
                    currency: "EURO",
                  };
                }else {
                  ///Llega en la moneda deseada
                  objetoConCambio = {
                    ...currentPackage,
                  };
                }
                console.log("Objeto con cual se operó:");
                console.log(currentPackage);
                console.log("Objeto ENTREGADO::");
                console.log(objetoConCambio);
                return objetoConCambio;
              }
            );

          setProformaOpcionalPackages(proformaOpcionalPackagesArray);
          console.log("Previo de opcional");
          console.log(data.data.proforma_optional_packages);

          console.log("NUEVO ARREGLO OPCIONAL:  ");
          console.log(proformaOpcionalPackagesArray);
        } else {
          setProformaOpcionalPackages([]);
        }

        if (data.data.proforma_advances) {
          proformaAdvanceArray = data.data.proforma_advances.map(
            (currentPackage, index) => {
              let objetoConCambio: ProformaAdvance;
              let identificadorOpcional = uuidv4();
              if (
                currentPackage.currency_identifier == 2 &&
                currentCurrencyProforma == 1
              ) {
                objetoConCambio = {
                  ...currentPackage,
                  currency_identifier: 1,
                  currency: "Dólar",
                  total: parseFloat(
                    (currentPackage.total / exchange_rate_local).toFixed(0)
                  ),
                  id_local: identificadorOpcional,
                };
                ////// Se requiere en pesos, pero llega en dls
              } else if (
                currentPackage.currency_identifier == 1 &&
                currentCurrencyProforma == 2
              ) {
                objetoConCambio = {
                  ...currentPackage,
                  currency_identifier: 2,
                  currency: "Peso",
                  total: parseFloat(
                    (currentPackage.total * exchange_rate_local).toFixed(0)
                  ),
                  id_local: identificadorOpcional,
                };
              } else {
                ///Llega en la moneda deseada
                let identificadorOpcional = uuidv4();
                objetoConCambio = {
                  ...currentPackage,
                  id_local: identificadorOpcional,
                };
              }
              return objetoConCambio;
            }
          );
          setProformaAdvances(proformaAdvanceArray);
        }

        ///////////////////////Fin de adaptar moneda///////////////////////////////////////////////////////////////////////
        console.log(data.data.proforma_optional_packages);

        console.log(data.data.proforma_principal_packages);

        setCurrentCurrency(data.data.proforma.currency_identifier);

        const newAdvancesArray: ProformaAdvance[] =
          data.data.proforma_advances.map((proforma, index) => {
            let identificadorOpcional = uuidv4();
            return {
              ...proforma,
              id_local: identificadorOpcional,
            };
          });
        if (newAdvancesArray.length > 0) {
          // setProformaAdvances(newAdvancesArray);
        } else {
          setProformaAdvances(newArrayAdvance);
        }

        // setCurrentOperation(data.data);

        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const updateProforma = async () => {
    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/proforma/update/${proforma.proforma_identifier}`;
    const proformaUpdate: ProformaData = {
      proforma_identifier: proforma.proforma_identifier,
      passengers_name: proforma.passengers_name ?? "",
      bank_account_identifier: proforma.bank_account_identifier,
      currency_identifier: currentCurrencyProforma,
      exchange_rateEURDLS: euroADolar
    };
    const proformaPrincipalPackageArray: ProformaPrincipalPackageDataa[] =
      proformaPrincipalPackages.map((packageElement, index) => {
        const packageData: ProformaPrincipalPackageDataa = {
          package_identifier: packageElement.package_identifier,
          city: packageElement.city,
          estatus: packageElement.estatus,
          total:
            packageElement.total_sgl * proforma.sgl +
            proforma.dbl * packageElement.total_dbl * 2 +
            proforma.tpl * packageElement.total_tpl * 3,
        };
        return packageData;
      });

    const proformaOptionalPackageArray: ProformaOptionalPackageData[] =
      proformaOpcionalPackages.map((opcional, index) => {
        const currentOpcional: ProformaOptionalPackageData = {
          optional_package_identifier: opcional.optional_package_identifier,
          city: opcional.city,
          estatus: opcional.estatus,
          total:
            opcional.optional_quote_pax * opcional.total_dbl +
            opcional.optional_quote_pax *
              opcional.total_dbl *
              (opcional.commission / 100),
        };
        return currentOpcional;
      });
    const proformaAdvancesArray: ProformaAdvanceData[] = proformaAdvances.map(
      (advance, index) => {
        const currentAdvance: ProformaAdvanceData = {
          advance_identifier: advance.advance_identifier,
          payday_limit: advance.payday_limit,
          total: advance.total,
          currency_identifier: advance.currency_identifier,
          total_proforma:
            proformaAdvances.length - 1 == index
              ? parseFloat((totalOpcionales + totalPrincipales).toFixed(2))
              : 0,
        };
        return currentAdvance;
      }
    );

    const bodyRequest: ProformaRequest = {
      proforma: proformaUpdate,
      proforma_principal_packages: proformaPrincipalPackageArray,
      proforma_optional_packages: proformaOptionalPackageArray,
      proforma_advances: proformaAdvancesArray,
    };


    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        const data = await response.json();
        alert("Se guardó proforma correctamente");
        console.log("Se guardó proforma correctamente:::");
        console.log(data);
        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION

        // navigate("/dashboard/Operaciones/Definicion-Prestador");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");
        setShowLoader(false);
        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);
      setShowLoader(false);
      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };

  useEffect(() => {
    console.log("Este es el id de la proforma");
    console.log(idProforma);
    getProformaInfo();
  }, []);
  useEffect(() => {
    console.log("Data de la operación");
    console.log(catalogsDataOperaciones.alico_bank_accounts_list);
    setBankAccountList(catalogsDataOperaciones.alico_bank_accounts_list);
  }, [catalogsDataOperaciones]);
  const totalOpcionales = proformaOpcionalPackages.reduce(
    (total, item) =>
      total +
      (item.optional_quote_pax * item.total_dbl +
        item.optional_quote_pax * item.total_dbl * (item.commission / 100)),
    0
  );
  const totalPrincipales = proformaPrincipalPackages.reduce(
    (total, item) =>
      total +
      (proforma.sgl * item.total_sgl +
        proforma.dbl * 2 * item.total_dbl +
        proforma.tpl * 3 * item.total_tpl),
    0
  );

  const totalAnticipos = proformaAdvances.reduce(
    (total, item) => total + item.total,
    0
  );
  const [isInicialMounted, setIsInicialMounted] = useState(true);

  useEffect(() => { 

    const proformaPrincipalArray = proformaPrincipalPackages.map(
      (currentPackage, index) => {
        let objetoConCambio: ProformaPrincipalPackage;
        /////////////////Se requiere dolar, pero llega en pesos

        if (
          currentPackage.currency_identifier == 2 &&
          currentCurrencyProforma == 1
        ) {
          objetoConCambio = {
            ...currentPackage,
            total_sgl: currentPackage.total_sgl / proforma.exchange_rate,
            total_dbl: currentPackage.total_dbl / proforma.exchange_rate,
            total_tpl: currentPackage.total_tpl / proforma.exchange_rate,
            total_mxn: currentPackage.total_mxn / proforma.exchange_rate,
            total_usd: currentPackage.total_usd / proforma.exchange_rate,
            currency_identifier: 1,
            currency: "Dólar",
          };
          ////// Se requiere en pesos, pero llega en dls
        } else if (
          currentPackage.currency_identifier == 1 &&
          currentCurrencyProforma == 2
        ) {
          objetoConCambio = {
            ...currentPackage,
            total_sgl: currentPackage.total_sgl * proforma.exchange_rate,
            total_dbl: currentPackage.total_dbl * proforma.exchange_rate,
            total_tpl: currentPackage.total_tpl * proforma.exchange_rate,
            total_mxn: currentPackage.total_mxn * proforma.exchange_rate,
            total_usd: currentPackage.total_usd * proforma.exchange_rate,
            currency_identifier: 2,
            currency: "Peso",
          };
        }
        ///// Se requiere en EUROS, llega en dolar
        else if (
          currentPackage.currency_identifier == 1 &&
          currentCurrencyProforma == 3
        ) {
          objetoConCambio = {
            ...currentPackage,
            total_sgl: currentPackage.total_sgl * tazasCambio.tazaEurosDolares,
            total_dbl: currentPackage.total_dbl * tazasCambio.tazaEurosDolares,
            total_tpl: currentPackage.total_tpl * tazasCambio.tazaEurosDolares,
            total_mxn: currentPackage.total_mxn * tazasCambio.tazaEurosDolares,
            total_usd: currentPackage.total_usd * tazasCambio.tazaEurosDolares,
            currency_identifier: 3,
            currency: "Euro",
          };

          ///// Se requiere en EUROS, llega en pesos:
        } else if (
          currentPackage.currency_identifier == 2 &&
          currentCurrencyProforma == 3
        ) {
          objetoConCambio = {
            ...currentPackage,
            total_sgl: currentPackage.total_sgl * tazasCambio.tazaPesosEuros,
            total_dbl: currentPackage.total_dbl * tazasCambio.tazaPesosEuros,
            total_tpl: currentPackage.total_tpl * tazasCambio.tazaPesosEuros,
            total_mxn: currentPackage.total_mxn * tazasCambio.tazaPesosEuros,
            total_usd: currentPackage.total_usd * tazasCambio.tazaPesosEuros,
            currency_identifier: 3,
            currency: "Euro",
          };
        } else if (
          currentPackage.currency_identifier == 3 &&
          currentCurrencyProforma == 2
        ) {
          ///Se requiere en pesos, llega en euros
          objetoConCambio = {
            ...currentPackage,
            total_sgl: currentPackage.total_sgl / tazasCambio.tazaPesosEuros,
            total_dbl: currentPackage.total_dbl / tazasCambio.tazaPesosEuros,
            total_tpl: currentPackage.total_tpl / tazasCambio.tazaPesosEuros,
            total_mxn: currentPackage.total_mxn / tazasCambio.tazaPesosEuros,
            total_usd: currentPackage.total_usd / tazasCambio.tazaPesosEuros,
            currency_identifier: 2,
            currency: "Peso",
          };
        } else if(
          currentPackage.currency_identifier == 3 &&
          currentCurrencyProforma == 1
        ){
          ///Se requiere en dolares, llega en euros
          objetoConCambio = {
            ...currentPackage,
            total_sgl: currentPackage.total_sgl / tazasCambio.tazaEurosDolares,
            total_dbl: currentPackage.total_dbl / tazasCambio.tazaEurosDolares,
            total_tpl: currentPackage.total_tpl / tazasCambio.tazaEurosDolares,
            total_mxn: currentPackage.total_mxn / tazasCambio.tazaEurosDolares,
            total_usd: currentPackage.total_usd / tazasCambio.tazaEurosDolares,
            currency_identifier: 1,
            currency: "Dólar",
          };
        }
        else{
          ///Llega en la moneda deseada
          objetoConCambio = {
            ...currentPackage,
          };
        }

        return objetoConCambio;
      }
    );
    const proformaOpcionalPackagesArray = proformaOpcionalPackages.map(
      (currentPackage, index) => {
        let objetoConCambio: ProformaOptionalPackage;
        if (
          currentPackage.currency_identifier == 2 &&
          currentCurrencyProforma == 1
        ) {
          objetoConCambio = {
            ...currentPackage,
            total_sgl: currentPackage.total_sgl / proforma.exchange_rate,
            total_dbl: currentPackage.total_dbl / proforma.exchange_rate,
            total_tpl: currentPackage.total_tpl / proforma.exchange_rate,
            currency_identifier: 1,
            currency: "Dólar",
          };
          ////// Se requiere en pesos, pero llega en dls
        } else if (
          currentPackage.currency_identifier == 1 &&
          currentCurrencyProforma == 2
        ) {
          objetoConCambio = {
            ...currentPackage,
            total_sgl: currentPackage.total_sgl * proforma.exchange_rate,
            total_dbl: currentPackage.total_dbl * proforma.exchange_rate,
            total_tpl: currentPackage.total_tpl * proforma.exchange_rate,
            currency_identifier: 2,
            currency: "Peso",
          };
        } else {
          ///Llega en la moneda deseada
          objetoConCambio = {
            ...currentPackage,
          };
        }

        return objetoConCambio;
      }
    );

      
    const proformaAdvanceArray = proformaAdvances.map(
      (currentPackage, index) => {
        let objetoConCambio: ProformaAdvance;
        if (
          currentPackage.currency_identifier == 2 &&
          currentCurrencyProforma == 1
        ) {
          objetoConCambio = {
            ...currentPackage,
            currency_identifier: 1,
            currency: "Dólar",
            total: parseFloat(
              (currentPackage.total / proforma.exchange_rate).toFixed(0)
            ),
          };
          ////// Se requiere en pesos, pero llega en dls
        } else if (
          currentPackage.currency_identifier == 1 &&
          currentCurrencyProforma == 2
        ) {
          objetoConCambio = {
            ...currentPackage,
            currency_identifier: 2,
            currency: "Peso",
            total: parseFloat(
              (currentPackage.total * proforma.exchange_rate).toFixed(0)
            ),
          };
        } else {
          ///Llega en la moneda deseada
          objetoConCambio = {
            ...currentPackage,
          };
        }
        return objetoConCambio;
      }
    );

    console.log("nuevo array de precios");
    console.log(proformaPrincipalArray);
    console.log("Opcionales actualizados");
    console.log(proformaOpcionalPackagesArray);
    console.log("Anticipos actualizados: ");
    console.log(proformaAdvanceArray);
    setProformaPrincipalPackages(proformaPrincipalArray);
    setProformaOpcionalPackages(proformaOpcionalPackagesArray);
    setProformaAdvances(proformaAdvanceArray);
  }, [currentCurrencyProforma,euroADolar]);

  useEffect(() => {
    console.log("Bank account list was updated");
    console.log(bankAccountList);
  }, [bankAccountList]);
  useEffect(() => {
    console.log("Valor de proforma cambió");
    console.log(proforma);
  }, [proforma]);
  const [grupoActual, setGrupoActual] = useState(() => {
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null ? parseInt(grupo) : currentUser.group_identifier;
  });

  const handleChangeEuro = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const precio = convertirStringANumero(value);
    console.log("Valor convertido:");
    console.log(precio);
    console.log("Factor conversión dolar a pesos:");
    console.log(cambioDolarAPeso);
    const tazaPesosDolares = Math.pow(cambioDolarAPeso, -1);
    console.log("tazaPesosDolares");
    console.log(tazaPesosDolares);

    if (isNaN(precio)) {
      /////ANTICIPO SETTINGS
      setEuroADolar(0);
      setTazasCambio({
        tazaEurosDolares: 0,
        tazaPesosDolares: tazaPesosDolares,
        tazaPesosEuros: 0,
      });
    } else {
      const parsedValue = precio;
      if (!isNaN(parsedValue)) {
        /////ANTICIPO SETTINGS
        const tazaEurosDolares = Math.pow(parsedValue, -1);
        console.log("tazaEurosDolares");
        console.log(tazaEurosDolares);
        console.log("tazaPesosDolares");
        const tazaPesosEuros = 1/(cambioDolarAPeso*parsedValue);
        console.log("FINAL: TAZA PESOS EUROS");
        console.log(tazaPesosEuros);
        setTazasCambio({
          tazaEurosDolares: tazaEurosDolares,
          tazaPesosDolares: tazaPesosDolares,
          tazaPesosEuros: tazaPesosEuros,
        });
        setEuroADolar(parsedValue);
      }
    }
  };

  return (
    <ManageProformaStyles>
      <GeneralInfoProforma proforma={proforma} setProforma={setProforma} />
      <div className="box-parent-buttons">
        <div>
          <button
            className="boton-language"
            onClick={() => setLanguage("es")}
            style={{
              backgroundColor: language == "es" ? "rgb(246, 146, 30)" : "",
            }}
          >
            Español
          </button>
          <button
            className="boton-language"
            style={{
              backgroundColor: language == "en" ? "rgb(246, 146, 30)" : "",
            }}
            onClick={() => setLanguage("en")}
          >
            Inglés
          </button>
          <button
            className="boton-language"
            onClick={() => setLanguage("fr")}
            style={{
              backgroundColor: language == "fr" ? "rgb(246, 146, 30)" : "",
            }}
          >
            Fránces
          </button>
        </div>
        <div>
          <button
            className="boton-language"
            onClick={() => {
              setCurrentCurrency(2);
            }}
            style={{
              backgroundColor:
                currentCurrencyProforma == 2 ? "rgb(246, 146, 30)" : "",
            }}
          >
            Pesos (MXN)
          </button>
          <button
            className="boton-language"
            onClick={() => {
              setCurrentCurrency(1);
            }}
            style={{
              backgroundColor:
                currentCurrencyProforma == 1 ? "rgb(246, 146, 30)" : "",
            }}
          >
            Dolares (USD)
          </button>

          {euroADolar !== 0 && (
            <button
              className="boton-language"
              onClick={() => {
                setCurrentCurrency(3);
              }}
              style={{
                backgroundColor:
                  currentCurrencyProforma == 3 ? "rgb(246, 146, 30)" : "",
              }}
            >
              Euros (EUR)
            </button>
          )}
        </div>
      </div>

      <h2 className="title-proforma">
        {translations[language].extra_fields.title_hotels}
      </h2>
      <PrincipalPackages
        proformaPrincipalPackages={proformaPrincipalPackages}
        proforma={proforma}
        setProformaPrincipalPackages={setProformaPrincipalPackages}
      />
      <OptionalPackages
        proforma={proforma}
        proformaOpcionalPackages={proformaOpcionalPackages}
        setProformaOpcionalPackages={setProformaOpcionalPackages}
      />
      <br />
      <div className="resumen-calculos">
        <div>
          {/* <h3>Total: {totalOpcionales}</h3>
              <h3>Total principales: {totalPrincipales}</h3> */}
          <h3>Total: {(totalOpcionales + totalPrincipales).toFixed(2)}</h3>
        </div>
      </div>
      <br />
      <br />

      <Anticipos
        proformaAdvances={proformaAdvances}
        setProformaAdvances={setProformaAdvances}
      />
      <br />

      <div className="resumen-calculos">
        <div>
          {/* <h3>Total: {totalOpcionales}</h3>
              <h3>Total principales: {totalPrincipales}</h3> */}
          <h3>
            {translations[language].extra_fields.remaining_payment}:{" "}
            {(totalOpcionales + totalPrincipales - totalAnticipos).toFixed(2)}
          </h3>
        </div>
      </div>
      <h3>Equivalencia de dolar a euro</h3>
      <br />
      <div className="container-conversion">
        <label htmlFor="">1 Euro equivale a </label>
        <NumericFormat
          prefix="$"
          style={{
            outline: "none",

            WebkitTapHighlightColor: "transparent", // Para navegadores Webkit (como Chrome y Safari)
            textAlign: "center",
          }}
          onChange={handleChangeEuro}
          value={euroADolar}
        />

        <label htmlFor="">dls</label>
      </div>
      <br />
      <CardPayment
        bankAccountList={bankAccountList}
        proforma={proforma}
        setProforma={setProforma}
      />
      <LimitPayment
        proformaAdvances={proformaAdvances}
        setProformaAdvances={setProformaAdvances}
      />
      <AdressTwo />
      <br />
      <TextBoxBigInstructions />
      {grupoActual !== 1018 && (
        <div className="buttons" style={{ backgroundColor: "transparent" }}>
          <img
            src={guardardatos}
            alt="leavePage"
            width="45px"
            onClick={updateProforma}
            style={{ cursor: "pointer", marginBottom: "1rem" }}
          />
        </div>
      )}
    </ManageProformaStyles>
  );
}

const ManageProformaStyles = styled.div`
  .box-parent-buttons {
    display: flex;
    justify-content: space-between;
  }
  .resumen-calculos div h3 {
    font-weight: 300;
    font-size: 0.85vw !important;
    text-align: end;
  }
  .title-proforma {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  .boton-language {
    margin-left: 0.5rem;
    margin-top: 1rem;
    border: none;
    padding: 0.3rem 1rem;
    border-radius: 0.2rem;
    background-attachment: fixed;
    background-color: rgb(246, 146, 30);
    border: black solid 1px;
    cursor: pointer;
    background-color: white;
  }
`;

function convertirStringANumero(str: string): number {
  // Eliminar caracteres no numéricos excepto el punto decimal
  const numeroLimpio = str.replace(/[^0-9.-]/g, "");

  // Parsear el string a número
  const numero = parseFloat(numeroLimpio);

  return numero;
}
